var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-avatar",
        {
          staticClass: "white--text",
          attrs: {
            color: "primary",
            left: ""
          }
        },
        [
          _c(
            "v-icon",
            {
              attrs: {
                dark: "",
                dense: ""
              }
            },
            [_vm._v(" mdi-calendar ")]
          )
        ],
        1
      ),
      _vm.value.date
        ? _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.fleet.FleetHomeVehicleAppointmentTimeline.".concat(
                          new Date(_vm.value.date).getDay()
                        )
                      )
                    ) +
                    " "
                )
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.value.date)) +
                    " "
                )
              ])
            ],
            1
          )
        : _c(
            "v-list-item-content",
            [_c("v-list-item-title", [_vm._v(" - ")])],
            1
          ),
      _c(
        "v-list-item-action",
        [
          _c(
            "Can",
            {
              attrs: {
                I: _vm.ActionEnum.UPDATE,
                a: _vm.ResourceEnum.HANDOVER
              }
            },
            [
              _c(
                "context-menu",
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: _vm.openEditDialog
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.verbs.edit")) + " ")]
                  ),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: _vm.openDeleteDialog
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.verbs.delete")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isUpdateDialogActive,
                title: _vm.$t("update") + "?",
                rightText: _vm.$t("update"),
                loading: _vm.isLoadingDate,
                rightLoading: _vm.isLoadingDate,
                rightColor: "success"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isUpdateDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isUpdateDialogActive = $event
                },
                close: function close($event) {
                  _vm.isUpdateDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isUpdateDialogActive = false
                },
                rightClick: function rightClick() {
                  return _vm.setPlannedHandoverDate(_vm.plannedHandoverDate)
                }
              }
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "full-width": ""
                        },
                        model: {
                          value: _vm.plannedHandoverDate,
                          callback: function callback($$v) {
                            _vm.plannedHandoverDate = $$v
                          },
                          expression: "plannedHandoverDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isDeleteDialogActive,
              title: _vm.$t("delete") + "?",
              rightText: _vm.$t("delete"),
              loading: _vm.isLoadingDate,
              rightLoading: _vm.isLoadingDate,
              rightColor: "error"
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isDeleteDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isDeleteDialogActive = $event
              },
              close: function close($event) {
                _vm.isDeleteDialogActive = false
              },
              leftClick: function leftClick($event) {
                _vm.isDeleteDialogActive = false
              },
              rightClick: _vm.removePlannedHandoverDate
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }