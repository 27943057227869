















import CostGroupTable from "@/components/cost/CostGroupTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import CostMixin from "./CostMixin.vue";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { handleError } from "@/lib/utility/handleError";
import { ICostGroup, CostGroup } from "@/models/cost-group.entity";

@Component({
  components: {
    CostGroupTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostGroupTableView extends mixins(PartnerFallbackMixin, CostMixin) {
  @Ref("costGroupTable")
  costGroupTable!: CostGroupTable;

  readonly AMOUNT_COST_GROUP = "amountCostGroup";

  /**
   * refs that will be set in new costs and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(this.breadCrumbs.CostGroupTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${CostGroupModule.paginationList.length} ${this.$t("cost.costGroupTable")}`,
        key: this.AMOUNT_COST_GROUP,
        disabled: true
      }
    ];
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("cost.allCosts"),
        key: "allCosts",
        exec: () => new CostGoToHelper(this.$router).goToCostTable({ partnerId: this.partnerId })
      }
    ];
  }

  async mounted() {
    this.trySetByRouteOrDefault();

    const costGroupId = this.$route.query.costGroupId as string;
    if (costGroupId) {
      const costGroup = new CostGroup({ partnerId: this.partnerId, id: costGroupId });
      this.costGroupTable?.setSelectedItem(costGroup);
    }
  }

  openSideCard(costGroup: ICostGroup) {
    new CostGoToHelper(this.$router).setUrl(
      CostGoToHelper.locations.costGroupTable({
        partnerId: this.partnerId,
        query: costGroup ? { costGroupId: costGroup.id } : {}
      })
    );
  }
}
