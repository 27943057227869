
























































































































import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import MHeader, { IAction } from "../../utility/mmmint/MHeader.vue";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { PartnerModule } from "@/store/modules/partner";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { $t } from "@/lib/utility/t";
import { ICompanyGroup } from "@/models/company-group.entity";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import {
  MrfiktivCustomFieldListElementViewModelGen,
  MrfiktivProjectConfigDtoGen,
  MrfiktivCustomFieldListElementDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import RefsCompanyGroup from "@/components/utility/RefsCompanyGroup.vue";
import ConfirmActionDialog from "../../utility/ConfirmActionDialog.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    CustomFieldConfigurationForm,
    MHeader,
    RefsCompanyGroup,
    ConfirmActionDialog,
    MDetailViewGrid,
    MActionList
  }
})
export default class CompanyGroupDetail extends mixins(PartnerFallbackMixin) {
  readonly VIEW_COMPANYS = "viewCompanys";
  readonly VIEW_COMPANY_GROUPS = "viewCompanyGroups";
  readonly EDIT_ACTION = "editAction";
  readonly DELETE_ACTION = "deleteAction";

  @Prop()
  value!: ICompanyGroup;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  isLoadingDescription = false;

  isLoadingTitle = false;

  isLoadingCustomFields = false;

  isEditingTitle = false;

  isEditCustomFields = false;

  editTitleCopy = this.value.title;

  isEditDescription = false;

  descriptionCopy = this.value.description ?? "";

  customFieldConfigCopy: MrfiktivCustomFieldListElementViewModelGen[] = [];

  get subtitle() {
    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get createdDate() {
    return simpleDoubleDigitDate(this.value.timestamp.created);
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = CompanyGoToHelper.breadCrumbs;

    return [
      breadCrumbs.CompanyGroupTable(this.value.partnerId),
      breadCrumbs.CompanyGroupCustomView(this.value.partnerId, this.value.id),
      breadCrumbs.CompanyGroupDetail(this.value.partnerId, this.value.id)
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("project.ticket.actions.openInNewTab"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyGroupDetail({
            partnerId: this.value.partnerId,
            companyGroupId: this.value.id,
            newTab: true
          })
      });
    }

    actions.push({
      text: $t("project.ticket.editTitle"),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startEditingTitle
    });

    return actions;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.COMPANY_GROUP
    };
  }

  get detailActions() {
    return [
      {
        text: this.$t("company.viewCompanys").toString(),
        key: this.VIEW_COMPANYS,
        icon: "mdi-open-in-new"
      },
      {
        text: this.$t("company.viewCompanyGroups").toString(),
        key: this.VIEW_COMPANY_GROUPS,
        icon: "mdi-open-in-new"
      },
      {
        text: this.$t("delete").toString(),
        key: this.DELETE_ACTION,
        icon: "mdi-trash-can",
        color: "red"
      }
    ];
  }

  async processAction(action: IAction) {
    switch (action.key) {
      case this.VIEW_COMPANYS:
        new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
          partnerId: this.value.partnerId,
          companyGroupId: this.value.id,
          viewId: "0",
          newTab: false
        });
        break;
      case this.VIEW_COMPANY_GROUPS:
        new CompanyGoToHelper(this.$router).goToCompanyGroupTable({
          partnerId: this.value.partnerId,
          newTab: false
        });
        break;
      case this.DELETE_ACTION:
        this.onDeleteItem();
        break;
    }
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  startEditingTitle() {
    this.editTitleCopy = this.value.title;
    this.isEditingTitle = true;
  }

  cancelEditTitle() {
    this.isEditingTitle = false;
    this.editTitleCopy = this.value.title;
  }

  async saveEditTitle() {
    this.isLoadingTitle = true;
    await this.value
      .updatePartial({
        title: this.editTitleCopy
      })
      .catch(handleError);
    this.editTitleCopy = this.value.title;

    this.isLoadingTitle = false;
    this.isEditingTitle = false;
  }

  startEditDescription() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        description: this.descriptionCopy
      })
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }

  startEditCustomFields() {
    if (!this.value) return;
    this.customFieldConfigCopy.splice(0);
    this.customFieldConfigCopy.push(...this.value.configuration.customFieldConfig);
    this.isEditCustomFields = true;
  }

  abortEditCustomFields() {
    if (!this.value) return;
    this.customFieldConfigCopy.splice(0);
    this.customFieldConfigCopy.push(...this.value.configuration.customFieldConfig);
    this.isEditCustomFields = false;
  }

  async saveCustomFields() {
    if (!this.value) return;
    this.isEditCustomFields = true;

    const customFieldConfig: MrfiktivCustomFieldListElementDtoGen[] = this.customFieldConfigCopy.map(c => ({
      ...c,
      customField: c.customField.id
    }));

    const configuration: MrfiktivProjectConfigDtoGen = {
      ...this.value.configuration,
      views: this.value.configuration.views.map(v => v.dto),
      isTemplate: this.value.configuration.isTemplate,
      customFieldConfig
    };

    this.isLoadingCustomFields = true;
    await this.value
      .updatePartial({
        configuration
      })
      .catch(handleError);

    this.isLoadingCustomFields = false;

    this.isEditCustomFields = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.value.partnerId);
  }
}
