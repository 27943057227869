






















import Card from "@/components/utility/Card.vue";
import { mongodbIdListValidation, mongodbIdValidation } from "@/lib/excelImportTransformation/transformers";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";
import { Fleet, IFleet } from "@/models/fleet.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import ExcelImport from "../utility/ExcelImport.vue";
import ExcelImportReport from "../utility/ExcelImportReport.vue";

@Component({
  components: { Card, ExcelImport, ExcelImportReport }
})
export default class FleetImportDialog extends Vue {
  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  loading = false;
  success = false;
  importedItems: IFleet[] = [];
  failedImports: { companyName: string; error: string }[] = [];
  totalItems = 0;

  // Gemeinsame Basiskonfiguration
  get config(): { [key: string]: IExcelImportConfig } {
    return {
      title: {
        label: this.$t("components.FleetImportDialog.titleLabel").toString(),
        hint: this.$t("components.FleetImportDialog.titleHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.titleLabel").toString(),
        transform: value => value.toString()
      },
      companyId: {
        label: this.$t("components.FleetImportDialog.companyIdLabel").toString(),
        hint: this.$t("components.FleetImportDialog.companyIdHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.companyIdLabel").toString(),
        transform: value => mongodbIdValidation(value)
      },
      parentId: {
        label: this.$t("components.FleetImportDialog.parentIdLabel").toString(),
        hint: this.$t("components.FleetImportDialog.parentIdHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.parentIdLabel").toString(),
        transform: value => mongodbIdValidation(value)
      },
      assignees: {
        label: this.$t("components.FleetImportDialog.assigneesLabel").toString(),
        hint: this.$t("components.FleetImportDialog.assigneesHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.assigneesLabel").toString(),
        transform: value => mongodbIdListValidation(value)
      }
    };
  }

  /**
   * Config to update fleets
   */
  get updateConfig(): { [key: string]: IExcelImportConfig } {
    return {
      fleetId: {
        label: this.$t("components.FleetImportDialog.fleetIdLabel").toString(),
        hint: this.$t("components.FleetImportDialog.fleetIdHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.fleetIdLabel").toString(),
        transform: value => mongodbIdValidation(value)
      },
      title: {
        label: this.$t("components.FleetImportDialog.titleLabel").toString(),
        hint: this.$t("components.FleetImportDialog.titleHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.titleLabel").toString(),
        transform: value => value.toString()
      },
      companyId: {
        label: this.$t("components.FleetImportDialog.companyIdLabel").toString(),
        hint: this.$t("components.FleetImportDialog.companyIdHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.companyIdLabel").toString(),
        transform: value => mongodbIdValidation(value)
      },
      parentId: {
        label: this.$t("components.FleetImportDialog.parentIdLabel").toString(),
        hint: this.$t("components.FleetImportDialog.parentIdHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.parentIdLabel").toString(),
        transform: value => mongodbIdValidation(value)
      },
      assignees: {
        label: this.$t("components.FleetImportDialog.assigneesLabel").toString(),
        hint: this.$t("components.FleetImportDialog.assigneesHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: this.$t("components.FleetImportDialog.assigneesLabel").toString(),
        transform: value => mongodbIdListValidation(value)
      }
    };
  }

  async update(items: { data: any[]; headers: any[] }) {
    this.importedItems = [];
    this.failedImports = [];
    this.totalItems = items.data.length;
    this.loading = true;

    if (!PartnerModule.partner.id) {
      this.$toast.error(this.$t("components.FleetImportDialog.partnerContextNotSet").toString());
      return;
    }

    for (const fleet of items.data) {
      try {
        const updateFleet = new Fleet({
          partnerId: PartnerModule.partner.id,
          id: fleet["fleetId"]
        });

        await updateFleet.fetch();

        if (this.updateConfig.parentId.import) {
          updateFleet.parentId = fleet["parentId"] || null;
        }

        if (fleet["companyId"]) {
          updateFleet.companyId = fleet["companyId"];
        }

        if (fleet["assignees"]) {
          updateFleet.assignees = [fleet["assignees"]];
        }

        this.$log.debug("createFleet", updateFleet);

        this.importedItems.push(await updateFleet.update());
      } catch (error) {
        const errorMessage = (error as Error)?.message || "Undefined import error";
        this.failedImports.push({ companyName: fleet["companyName"], error: errorMessage });
      }
    }
    this.loading = false;
    this.success = true;
  }

  async create(items: { data: any[]; headers: any[] }) {
    this.importedItems = [];
    this.failedImports = [];
    this.totalItems = items.data.length;
    this.loading = true;

    if (!PartnerModule.partner.id) {
      this.$toast.error(this.$t("components.FleetImportDialog.partnerContextNotSet").toString());
      return;
    }

    for (const fleet of items.data) {
      try {
        const newFleet = new Fleet({
          partnerId: PartnerModule.partner.id,
          title: fleet["title"]
        });

        if (fleet["parentId"]) {
          newFleet.parentId = fleet["parentId"];
        }

        if (fleet["companyId"]) {
          newFleet.companyId = fleet["companyId"];
        }

        if (fleet["assignees"]) {
          newFleet.assignees = [fleet["assignees"]];
        }

        this.$log.debug("createFleet", newFleet);

        this.importedItems.push(await newFleet.create());
      } catch (error) {
        const errorMessage = (error as Error)?.message || "Undefined import error";
        this.failedImports.push({ companyName: fleet["companyName"], error: errorMessage });
      }
    }
    this.loading = false;
    this.success = true;
  }
}
