var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          headers: _vm.headers,
          baseQuery: {
            partnerId: _vm.partnerId
          },
          controlElements: _vm._controlElements,
          predefinedFilter: _vm.predefinedFilter,
          showSelect: false
        },
        on: {
          "click:row": _vm.setSelectedItem
        },
        scopedSlots: _vm._u(
          [
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.COMPANY,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _c("company-create-dialog", {
                        attrs: {
                          hideButton: false,
                          partnerId: _vm.partnerId,
                          refs: _vm.refs
                        },
                        on: {
                          created: _vm.setSelectedItem
                        }
                      })
                    ],
                    1
                  ),
                  _vm._t("bodyActions")
                ]
              },
              proxy: true
            },
            {
              key: "item.isFleet",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  item.isFleet
                    ? _c(
                        "tooltip",
                        {
                          attrs: {
                            text: _vm.$t("objects.company.isFleet")
                          }
                        },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                small: ""
                              }
                            },
                            [_c("v-icon", [_vm._v(" mdi-car-multiple ")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.services",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return [
                  _c(
                    "span",
                    {
                      staticClass: "d-flex"
                    },
                    [
                      item.services && item.services.length > 0
                        ? _c(
                            "v-chip",
                            {
                              staticClass: "mr-1",
                              attrs: {
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "enums.CompanyServiceEnum.".concat(
                                        item.services[0]
                                      )
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      item.services.length > 1
                        ? _c(
                            "v-chip",
                            {
                              attrs: {
                                small: ""
                              }
                            },
                            [_vm._v("+ " + _vm._s(item.services.length - 1))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.groupId",
              fn: function fn(_ref3) {
                var item = _ref3.item
                return [_vm._v(" " + _vm._s(_vm.groupName(item.groupId)) + " ")]
              }
            },
            {
              key: "item.assignees",
              fn: function fn(_ref4) {
                var item = _ref4.item
                return [
                  _c("assignee-preview", {
                    attrs: {
                      value: item.assignees,
                      partnerId: _vm.partnerId
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.selectedItem
        ? _c("company-side-card", {
            attrs: {
              value: _vm.selectedItem
            },
            on: {
              close: function close() {
                return _vm.setSelectedItem(null)
              }
            }
          })
        : _vm._e(),
      _vm.isDeleteDialogActive && _vm.selectedItemForDelete
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error",
                rightLoading: _vm.isDeleteLoading
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-company", {
                attrs: {
                  item: _vm.selectedItemForDelete
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }