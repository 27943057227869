var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          headers: _vm.headers,
          baseQuery: {
            partnerId: _vm.partnerId
          },
          controlElements: _vm.controlElements,
          predefinedFilter: _vm.predefinedFilter,
          showSelect: false
        },
        on: {
          "click:row": _vm.setSelectedItem
        },
        scopedSlots: _vm._u(
          [
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.COMPANY
                      }
                    },
                    [
                      _c("company-create-dialog", {
                        attrs: {
                          hideButton: false,
                          partnerId: _vm.partnerId
                        }
                      })
                    ],
                    1
                  ),
                  _vm._t("bodyActions")
                ]
              },
              proxy: true
            },
            {
              key: "item.isFleet",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  item.isFleet
                    ? _c(
                        "v-chip",
                        [
                          _c("v-icon", [_vm._v(" mdi-car-multiple ")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("objects.company.isFleet")) +
                              " "
                          )
                        ],
                        1
                      )
                    : _c("div")
                ]
              }
            },
            {
              key: "item.services",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return _vm._l(item.services, function(service) {
                  return _c(
                    "v-chip",
                    {
                      key: service + Math.random(),
                      staticClass: "mr-1 my-1",
                      attrs: {
                        small: ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("enums.CompanyServiceEnum.".concat(service))
                          ) +
                          " "
                      )
                    ]
                  )
                })
              }
            },
            {
              key: "item.groupId",
              fn: function fn(_ref3) {
                var item = _ref3.item
                return [
                  item.groupId
                    ? _c("refs-company-group", {
                        key: "refs-company-group" + item.groupId,
                        attrs: {
                          itemAsync: _vm.getCompanyGroup(item.groupId)
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.companyName",
              fn: function fn(_ref4) {
                var item = _ref4.item
                return [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function click($event) {
                                    return _vm.setSelectedItem(item)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.companyName))]
                            )
                          ]),
                          item.firstName && item.lastName
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.firstName) +
                                    " " +
                                    _vm._s(item.lastName) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.contact",
              fn: function fn(_ref5) {
                var item = _ref5.item
                return [
                  item.contact
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(item.contact.email) + " ")
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(item.contact.phone) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.address",
              fn: function fn(_ref6) {
                var item = _ref6.item
                return [
                  item.address
                    ? _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(item.address.street) + " ")
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.address.city) +
                                    " " +
                                    _vm._s(item.address.zip) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.address.state) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "item.CountryCodeEnum.".concat(
                                          item.address.countryCode
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.userId",
              fn: function fn(_ref7) {
                var item = _ref7.item
                return [
                  _c("assignee-preview", {
                    attrs: {
                      value: [item.userId]
                    }
                  })
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.selectedItem
        ? _c("company-side-card", {
            attrs: {
              value: _vm.selectedItem
            },
            on: {
              close: function close() {
                return _vm.setSelectedItem(null)
              }
            }
          })
        : _vm._e(),
      _vm.isDeleteDialogActive && _vm.selectedItemForDelete
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error",
                rightLoading: _vm.isDeleteLoading
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-company", {
                attrs: {
                  item: _vm.selectedItemForDelete
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }