var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.TICKET
          }
        },
        [
          _vm._t(
            "activator",
            function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      text: "",
                      elevation: 0
                    },
                    on: {
                      click: _vm.open
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("acknowledge")) + " ")]
                )
              ]
            },
            {
              slotScope: {
                open: _vm.open
              }
            }
          )
        ],
        2
      ),
      _vm.isDialogActive
        ? _c(
            "confirm-action-dialog",
            {
              key: "confirm-action-dialog" + _vm.isDialogActive,
              attrs: {
                isDialogActive: _vm.isDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                loading: _vm.isLoading,
                title: _vm.$t("views.fleet.EventCard.markAsAcknowledged"),
                supressKeyboardActions: true,
                rightLoading: _vm.isLoading,
                rightDisabled: !_vm.valueTempCopy.length,
                leftText: _vm.$t("abort"),
                rightText: _vm.$t("confirm")
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDialogActive = $event
                },
                leftClick: _vm.close,
                rightClick: _vm.completeEvents,
                close: _vm.close
              }
            },
            _vm._l(_vm.valueTempCopy, function(event) {
              return _c("refs-event", {
                key: "ref-event-ack-dialog" + event.id,
                attrs: {
                  item: event
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "icon",
                      fn: function fn() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mr-2"
                            },
                            [
                              event.loading || event._isRecurring
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                      size: "24"
                                    }
                                  })
                                : event.ack
                                ? _c("v-icon", [_vm._v(" mdi-check ")])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }