var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _vm.isPartnerFallbackLoading
              ? _c("v-skeleton-loader", {
                  attrs: {
                    type: "list-item-two-line"
                  }
                })
              : _vm._e(),
            _c(
              "v-card",
              {
                attrs: {
                  elevation: "0",
                  flat: ""
                }
              },
              [
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: 12,
                      align: "center"
                    }
                  },
                  [
                    _c(
                      "v-form",
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.$t("common.nouns.search"),
                            dense: "",
                            flat: "",
                            outlined: "",
                            "hide-details": "",
                            clearable: "",
                            "clear-icon": "mdi-close-circle-outline",
                            disabled: _vm.isLoading
                          },
                          model: {
                            value: _vm.search,
                            callback: function callback($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.isLoading
                  ? _c("v-skeleton-loader", {
                      staticClass: "mx-auto",
                      attrs: {
                        type: "list-item-avatar"
                      }
                    })
                  : _c("v-treeview", {
                      attrs: {
                        "open-all": "",
                        search: _vm.search,
                        items: _vm.tree,
                        active: _vm.activeIds,
                        open: _vm.open,
                        transition: "",
                        activatable: "",
                        dense: ""
                      },
                      on: {
                        "update:active": function updateActive($event) {
                          _vm.activeIds = $event
                        },
                        "update:open": function updateOpen($event) {
                          _vm.open = $event
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "prepend",
                          fn: function fn(_ref) {
                            var item = _ref.item
                            return [
                              item.icon
                                ? _c("v-icon", [
                                    _vm._v(" " + _vm._s(item.icon) + " ")
                                  ])
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          var _vm$active, _vm$active2

          return [
            _vm.isLoading
              ? _c(
                  "v-container",
                  {
                    attrs: {
                      fluid: ""
                    }
                  },
                  [
                    _c("v-skeleton-loader", {
                      attrs: {
                        type:
                          "heading, actions, heading, table-row, list-item-two-line@4"
                      }
                    })
                  ],
                  1
                )
              : !_vm.active
              ? _c(
                  "v-container",
                  {
                    attrs: {
                      fluid: ""
                    }
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "fill-height",
                        attrs: {
                          fluid: "",
                          flat: ""
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "justify-center": "",
                              "align-center": ""
                            }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  shrink: ""
                                }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
                                    "max-height": "350px",
                                    contain: ""
                                  }
                                }),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "pt-8",
                                    attrs: {
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: ""
                                            },
                                            on: {
                                              click: _vm.selectFirst
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.fleet.FleetDetailView.select"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "v-container",
                  {
                    attrs: {
                      fluid: ""
                    }
                  },
                  [
                    _c("m-header", {
                      attrs: {
                        title: _vm.title,
                        breadCrumbs: _vm.breadCrumbs,
                        actions: _vm.isEditTitle ? [] : _vm.actions,
                        loading:
                          ((_vm$active = _vm.active) === null ||
                          _vm$active === void 0
                            ? void 0
                            : _vm$active._isLoadingCompany) ||
                          ((_vm$active2 = _vm.active) === null ||
                          _vm$active2 === void 0
                            ? void 0
                            : _vm$active2._isLoadingVehicles)
                      },
                      on: {
                        actionClicked: function actionClicked($event) {
                          return $event.exec()
                        },
                        alertClicked: function alertClicked($event) {
                          return $event.exec()
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.isEditTitle
                            ? {
                                key: "title",
                                fn: function fn() {
                                  return [
                                    _c("v-text-field", {
                                      staticClass: "mt-1 mb-n1",
                                      attrs: {
                                        label: _vm.$t("project.project.title"),
                                        outlined: "",
                                        loading: _vm.isUpdateTitle,
                                        readonly: _vm.isUpdateTitle
                                      },
                                      model: {
                                        value: _vm.tempTitle,
                                        callback: function callback($$v) {
                                          _vm.tempTitle = $$v
                                        },
                                        expression: "tempTitle"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mt-n6"
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              "x-small": "",
                                              elevation: 0,
                                              color: "info"
                                            },
                                            on: {
                                              click: _vm.updateTitle
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("save")) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              elevation: 0,
                                              color: "info",
                                              outlined: ""
                                            },
                                            on: {
                                              click: _vm.cancelUpdateTitle
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("cancel")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    }),
                    _c("debug", [_vm._v(" " + _vm._s(_vm.activeIds[0]))]),
                    _c("fleet-detail", {
                      attrs: {
                        value: _vm.fleet,
                        isLoading: _vm.isLoading,
                        vehicles: _vm.vehicles
                      },
                      on: {
                        delete: _vm.fetchFleet
                      }
                    })
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }