











































































import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    PaginatedTable,
    PartnerReportDetailSideCard,
    AssigneePreview
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerReportTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = ReportPaginationModule;

  title = this.$t("navigation.ThePartnerList.report");

  isLoading = false;
  isLoadingUpdate = false;

  selectedItems: IReport[] = [];
  selectedItem: IReport | null = null;
  isLoadingReport = false;
  showSideCard = false;

  async mounted() {
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      await this.trySetByRouteOrDefault();
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  getModule() {
    return ReportPaginationModule;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];

    breadCrumbs.push({
      text: this.$t("navigation.ThePartnerList.report"),
      disabled: true,
      exact: true,
      to: {
        name: "PartnerReportTable",
        params: { partnerId: this.partnerId }
      }
    });

    return breadCrumbs;
  }

  get chips() {
    const chips: IAction[] = [];

    chips.push({
      text: `${ReportPaginationModule.totalItems} ${this.$t("navigation.ThePartnerList.report")}`,
      key: "numberOfDocuments"
    });

    return chips;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [
      {
        text: this.$t("objects.report.timestamp.created").toString(),
        value: "timestamp.created",
        width: "120px",
        type: "date"
      },
      {
        text: this.$t("objects.thgMeterReading.status").toString(),
        value: "progressStatus",
        width: "100px"
      },
      {
        text: this.$t("project.ticket.assignees").toString(),
        value: "assignees",
        width: "150px"
      },
      {
        text: this.$t("objects.report.title").toString(),
        value: "title"
      }
    ];

    if (!this.isFleetPartner) {
      headers.push({
        text: this.$t("objects.report.customerName").toString(),
        value: "customerName"
      });
    }

    headers.push(
      ...[
        {
          text: this.$t("components.fleet.FleetVehicleDetailDamageCard.headers.damages").toString(),
          value: "damages",
          width: "150px"
        },
        {
          text: this.$t("objects.report.message").toString(),
          value: "message"
        }
      ]
    );

    return headers;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    predefinedFilter.push({
      name: "components.FilterCardPagination.predefinedFilter.ticket.open",
      filter: [
        new PageFilterElement({
          key: "progressStatus",
          value: ProgressStatusEnum.FINISHED,
          operation: PageFilterOperationEnum.NOT_EQUAL
        })
      ]
    });

    predefinedFilter.push({
      name: "project.ticket.mine",
      filter: [
        new PageFilterElement({
          key: "assignees",
          value: this.userId,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  get userId() {
    return UserModule.userId;
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  async setSelectedItem(item: IReport) {
    this.showSideCard = false;
    this.selectedItem = item;
    this.$nextTick(async () => {
      this.isLoadingReport = true;
      this.showSideCard = true;
      try {
        this.selectedItem = await item.fetch();
        PartnerModule.setReport(this.selectedItem);
      } catch (error) {
        handleError(error);
      } finally {
        this.isLoadingReport = false;
      }
    });
  }

  closeSideCard() {
    this.showSideCard = false;
    this.selectedItem = null;
  }

  async open(item: IReport) {
    this.$log.debug(item);
    await new GoToHelper(this.$router).goToReportDetail(item.id, item.partnerId);
  }

  async completedSelected() {
    this.isLoadingUpdate = true;
    try {
      const promises = this.selectedItems.map(r => r.updateProgressStatus(ProgressStatusEnum.FINISHED));
      await Promise.all(promises);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingUpdate = false;
    }
  }
}
