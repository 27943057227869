













































































































































































































import CreateHandoverFindingDialog from "@/components/handover/CreateHandoverFindingDialog.vue";
import TourHandoverFindingDialog, { TourDirectionEnum } from "@/components/handover/TourHandoverFindingDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import MImageMarker, { IMarker } from "@/components/utility/mmmint/MImageMarker.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import HandoverReportCard from "./HandoverReportCard.vue";
import HandoverReportTable from "./HandoverReportTable.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import PartnerReportDetailChangeProgress from "@/components/partner/PartnerReportDetailChangeProgress.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    PaginatedTable,
    CreateHandoverFindingDialog,
    HandoverReportTable,
    HandoverReportCard,
    MImageMarker,
    Debug,
    PartnerReportDetailChangeProgress,
    Tooltip,
    LatestEntriesCardEmpty,
    TourHandoverFindingDialog
  }
})
export default class HandoverTourView extends mixins(Vue, PermissionMixin, PartnerFallbackMixin) {
  title = this.$t("objects.handover.tour.title");

  isLoading = false;

  isImageLoaded = true;

  isImageDialogActive = false;

  handover: IHandover | null = null;

  vehicle: IVehicle | null = null;

  vehicles: IVehicle[] = [];

  report: IReport | null = null;

  reportIndex = 0;

  isBottomSheetOpen = false;

  direction = this.$route.query["dir"];

  get height() {
    return "calc(100vh - 64px - 100px - 200px - 30px)";
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get closeOnSwipe() {
    return {
      down: () => (this.isBottomSheetOpen = false)
    };
  }

  get reportSize() {
    return this.reports.length;
  }

  get handoverId() {
    return this.$route.params.handoverId;
  }

  get blueprintId() {
    return this.$route.params.blueprintId;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get blueprint() {
    return this.vehicle?.blueprints.find(b => b.id === this.blueprintId);
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("objects.handover.tour.title"),
      exact: true,
      disabled: true,
      to: {
        name: "HandoverTourView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id,
          blueprintId: this.blueprintId
        }
      }
    });

    return breadcrumbs;
  }

  get actions() {
    const actions: IAction[] = [];

    actions.push({
      icon: "mdi-plus",
      text: this.$t("finding.create.title").toString(),
      key: "newFinding",
      exec: () => (this.$refs.findingCreateDialog as CreateHandoverFindingDialog)?.open()
    });

    actions.push({
      text: this.$t("objects.handover.tour.start").toString(),
      key: "startTour",
      icon: "mdi-ray-start-arrow",
      exec: () => (this.$refs.tourDialog as TourHandoverFindingDialog)?.open()
    });

    return actions;
  }

  get bluePrintImageUrl() {
    return this.blueprint?.image.url;
  }

  get reports(): IReport[] {
    const reports = HandoverReportModule.filtered;
    return reports;
  }

  get markers(): IMarker[] {
    const markers: IMarker[] = this.reports
      .filter(r => r.position?.coordinates?.x && r.position?.coordinates?.y)
      .map(r => {
        return {
          top: r.position?.coordinates?.y,
          left: r.position?.coordinates?.x,
          text: r.handover?.number
        } as IMarker;
      });

    return markers;
  }

  get markersSorted(): IMarker[] {
    const markers: IMarker[] = this.markers;

    switch (this.direction) {
      case TourDirectionEnum.LeftToRight:
        markers.slice().sort((a, b) => a.left - b.left);
        break;
      case TourDirectionEnum.TopToBottom:
        markers.slice().sort((a, b) => a.top - b.top);
        break;
      case TourDirectionEnum.RightToLeft:
        markers.slice().sort((a, b) => b.left - a.left);
        break;
      case TourDirectionEnum.BottomToTop:
        markers.slice().sort((a, b) => b.top - a.top);
        break;
    }

    return this.markers.map(m => {
      const x = this.report?.position?.coordinates?.x;
      const y = this.report?.position?.coordinates?.y;

      // highlight the marker for the current report
      let color: string | undefined = undefined;
      if (x === m.left && y === m.top) {
        color = "rgb(76,175,80)";
      }

      return {
        left: m.left,
        top: m.top,
        text: m.text,
        color: color
      };
    });
  }

  get reportsSorted(): IReport[] {
    switch (this.direction) {
      case TourDirectionEnum.LeftToRight:
        return this.reports
          .slice()
          .sort((a, b) => (a.position?.coordinates?.x ?? 0) - (b.position?.coordinates?.x ?? 0));
      case TourDirectionEnum.TopToBottom:
        return this.reports
          .slice()
          .sort((a, b) => (a.position?.coordinates?.y ?? 0) - (b.position?.coordinates?.y ?? 0));
      case TourDirectionEnum.RightToLeft:
        return this.reports
          .slice()
          .sort((a, b) => (b.position?.coordinates?.x ?? 0) - (a.position?.coordinates?.x ?? 0));
      case TourDirectionEnum.BottomToTop:
        return this.reports
          .slice()
          .sort((a, b) => (b.position?.coordinates?.y ?? 0) - (a.position?.coordinates?.y ?? 0));
      default:
        return this.reports;
    }
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async fetchVehicles() {
    const partnerId = this.$route.params.partnerId;
    const vehicleIds = this.handover?.vehicleIds ?? [];

    const vehiclesAsync = vehicleIds.map(v =>
      new Vehicle({
        partnerId: partnerId,
        id: v
      }).fetch()
    );
    const vehicles = await Promise.all(vehiclesAsync);

    this.vehicle = vehicles.find(v => v.blueprints.find(b => b.id === this.$route.params.blueprintId)) ?? null;
    if (!this.vehicle) this.$log.error("No Vehicle found!");
    this.vehicles.splice(0);
    this.vehicles.push(...vehicles);
  }

  async fetchReports() {
    const partnerId = this.$route.params.partnerId;

    await HandoverReportModule.fetchAll({
      partnerId
    }).catch(handleError);
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.trySetByRouteOrDefault();

      HandoverReportModule.setFilters([
        new PageFilterElement({ key: "position.blueprint.id", value: this.blueprintId })
      ]);

      await this.fetchHandover();
      await Promise.all([
        this.fetchVehicles(),
        this.fetchReports().then(() => {
          if (this.reportsSorted.length) this.selectReport(0);
        })
      ]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    this.$nextTick(() => {
      if (this.bluePrintImageUrl) {
        (this.$refs.handoverTourMarker as MImageMarker).forceUpdate();
      }
    });
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  get mapToImage() {
    return (
      this.report?.attachment.map(i => {
        return {
          src: i.url,
          thumbnail: i.url,
          w: 0,
          h: 0,
          title: this.$t(`timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.${i.type}`)
        };
      }) ?? []
    );
  }

  async selectReport(index: number) {
    this.reportIndex = index;
    this.report = this.reportsSorted[this.reportIndex];

    if (!this.report) {
      throw new Error("No report found");
    }

    this.isImageLoaded = false;

    try {
      this.report = await this.report.fetch();
    } catch (error) {
      handleError(error);
    } finally {
      this.isImageLoaded = true;
    }

    if (this.isMobile) {
      this.isBottomSheetOpen = true;
    }
  }

  markerClicked(marker: IMarker) {
    this.$log.debug(marker);

    this.reportIndex = this.reportsSorted.findIndex(r => r.handover?.number === marker.text);

    this.selectReport(this.reportIndex);
  }

  goToReportDetail(r: IReport) {
    new GoToHelper(this.$router).goToReportDetail(r.id, r.partnerId, true);
  }

  scrollToImages() {
    document.getElementById("findingImages")?.scrollIntoView({ behavior: "smooth" });
  }
}
