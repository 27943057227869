var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      !_vm.hideButton
        ? _c(
            "v-btn",
            {
              attrs: {
                elevation: 0
              },
              on: {
                click: _vm.open
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
          )
        : _vm._e(),
      _vm.isDialogActive
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                width: "1050",
                persistent: true,
                supressKeyboardActions: true,
                title: _vm.$t("contract.createContract"),
                isDialogActive: _vm.isDialogActive,
                rightText: _vm.$t("create"),
                loading: _vm.isLoading,
                fullscreen: _vm.fullscreen,
                rightLoading: _vm.isLoading,
                rightDisabled: !_vm.isFormValid
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDialogActive = $event
                },
                leftClick: _vm.close,
                close: _vm.close,
                rightClick: _vm.onRightClick
              }
            },
            [
              _c(
                "div",
                {
                  on: {
                    dragover: function dragover($event) {
                      $event.preventDefault()
                      return _vm.startDrag.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.isDrag
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "z-index": "1",
                            top: "0",
                            left: "0",
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            "background-color": "rgba(200,200,200,0.5)"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "100%",
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              },
                              on: {
                                dragleave: function dragleave($event) {
                                  $event.preventDefault()
                                  return _vm.endDrag.apply(null, arguments)
                                },
                                drop: function drop($event) {
                                  $event.preventDefault()
                                  return _vm.onFileDrop($event)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: {
                                    transform: "scale(300%)"
                                  },
                                  attrs: {
                                    "x-large": ""
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.isFormValid,
                        callback: function callback($$v) {
                          _vm.isFormValid = $$v
                        },
                        expression: "isFormValid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pt-3"
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "7"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("objects.contract.title"),
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.contract.title,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "title", $$v)
                                  },
                                  expression: "contract.title"
                                }
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: Object.values(_vm.ContractTypeEnum),
                                  label: _vm.$t("objects.contract.type") + " *",
                                  rules: _vm.requiredRule,
                                  outlined: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function fn(_ref) {
                                        var item = _ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "enums.ContractTypeEnum.".concat(
                                                    item
                                                  )
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item",
                                      fn: function fn(_ref2) {
                                        var item = _ref2.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "enums.ContractTypeEnum.".concat(
                                                    item
                                                  )
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  516424700
                                ),
                                model: {
                                  value: _vm.contract.type,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "type", $$v)
                                  },
                                  expression: "contract.type"
                                }
                              }),
                              _c("template-editor", {
                                staticClass: "mb-3",
                                staticStyle: {
                                  height: "200px"
                                },
                                attrs: {
                                  outlined: true,
                                  label: _vm.$t("objects.contract.description")
                                },
                                model: {
                                  value: _vm.contract.description,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "description", $$v)
                                  },
                                  expression: "contract.description"
                                }
                              }),
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pr-1",
                                      attrs: {
                                        cols: "6"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "objects.contract.startDate"
                                          ),
                                          type: "date",
                                          outlined: "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.contract.startDateFormable,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "startDateFormable",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contract.startDateFormable"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-1",
                                      attrs: {
                                        cols: "6"
                                      }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "objects.contract.endDate"
                                          ),
                                          type: "date",
                                          outlined: "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.contract.endDateFormable,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "endDateFormable",
                                              $$v
                                            )
                                          },
                                          expression: "contract.endDateFormable"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("refs-select-form", {
                                attrs: {
                                  label: _vm.$t("objects.contract.clientIds"),
                                  partnerId: _vm.partnerId,
                                  small: true,
                                  categories: [
                                    _vm.ResourceEnum.COMPANY,
                                    _vm.ResourceEnum.PERSON
                                  ]
                                },
                                model: {
                                  value: _vm.contract.clientIds,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "clientIds", $$v)
                                  },
                                  expression: "contract.clientIds"
                                }
                              }),
                              _c("refs-select-form", {
                                attrs: {
                                  label: _vm.$t("objects.contract.providerIds"),
                                  partnerId: _vm.partnerId,
                                  small: true,
                                  categories: [
                                    _vm.ResourceEnum.COMPANY,
                                    _vm.ResourceEnum.PERSON
                                  ]
                                },
                                model: {
                                  value: _vm.contract.providerIds,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "providerIds", $$v)
                                  },
                                  expression: "contract.providerIds"
                                }
                              }),
                              _c("v-autocomplete", {
                                attrs: {
                                  "item-value": "id",
                                  items: _vm.groups,
                                  label: _vm.$t("objects.contract.groupId"),
                                  "auto-select-first": "",
                                  outlined: "",
                                  clearable: "",
                                  loading: _vm.isLoadingGroups
                                },
                                on: {
                                  click: _vm.refreshGroups
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function fn(_ref3) {
                                        var item = _ref3.item
                                        return [
                                          _vm._v(" " + _vm._s(item.title) + " ")
                                        ]
                                      }
                                    },
                                    {
                                      key: "selection",
                                      fn: function fn(_ref4) {
                                        var item = _ref4.item
                                        return [
                                          _vm._v(" " + _vm._s(item.title) + " ")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2714690748
                                ),
                                model: {
                                  value: _vm.contract.groupId,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "groupId", $$v)
                                  },
                                  expression: "contract.groupId"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "mt-n7 mb-n4",
                                  staticStyle: {
                                    display: "flex"
                                  }
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        text: "",
                                        elevation: 0,
                                        loading: _vm.isLoadingGroups
                                      },
                                      on: {
                                        click: _vm.refreshGroups
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("common.verbs.refresh")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-small": "",
                                        text: "",
                                        elevation: 0
                                      },
                                      on: {
                                        click: _vm.goToGroupCustomView
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("common.verbs.view")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm.group &&
                              _vm.group.configuration &&
                              _vm.group.configuration.customFieldConfig &&
                              _vm.group.configuration.customFieldConfig.length
                                ? _c(
                                    "div",
                                    [
                                      _vm.isLoadingCustomFieldValues
                                        ? _c("v-progress-linear", {
                                            attrs: {
                                              indeterminate: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _c("custom-field-list-form", {
                                        staticClass: "mt-6",
                                        attrs: {
                                          customFieldConfig:
                                            _vm.group.configuration
                                              .customFieldConfig
                                        },
                                        model: {
                                          value: _vm.contract.values,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.contract,
                                              "values",
                                              $$v
                                            )
                                          },
                                          expression: "contract.values"
                                        }
                                      }),
                                      _c("debug", [
                                        _vm._v(
                                          " contract.values: " +
                                            _vm._s(_vm.contract.values)
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " contract.groupId: " +
                                            _vm._s(_vm.contract.groupId)
                                        ),
                                        _c("br"),
                                        _vm._v(" group: " + _vm._s(_vm.group)),
                                        _c("br")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "5"
                              }
                            },
                            [
                              _c("cost-card-documents", {
                                ref: "costCardDocuments",
                                staticClass: "mb-4 mt-n5",
                                attrs: {
                                  disabledDragAndDrop: true,
                                  partnerId: _vm.contract.partnerId,
                                  costId: _vm.contract.id,
                                  loading: false
                                },
                                on: {
                                  created: _vm.onCreatedDocument
                                },
                                model: {
                                  value: _vm.contract.documentIds,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "documentIds", $$v)
                                  },
                                  expression: "contract.documentIds"
                                }
                              }),
                              _c("refs-select-form", {
                                attrs: {
                                  label: _vm.$t("objects.contract.refs"),
                                  partnerId: _vm.partnerId,
                                  small: true
                                },
                                model: {
                                  value: _vm.contract.refs,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.contract, "refs", $$v)
                                  },
                                  expression: "contract.refs"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("debug", [_vm._v(" " + _vm._s(_vm.contract) + " ")])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }