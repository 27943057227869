var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "span",
      {
        staticClass: "mr-2"
      },
      [
        _c(
          "excel-import",
          {
            attrs: {
              config: _vm.updateConfig,
              loading: _vm.loading,
              outlined: _vm.outlined,
              small: _vm.small,
              btnText: _vm.$t("common.nouns.update")
            },
            on: {
              uploadData: _vm.update
            }
          },
          [
            _c("excel-import-report", {
              attrs: {
                failedImports: _vm.failedImports,
                totalItems: _vm.totalItems,
                importedItems: _vm.importedItems
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "span",
      [
        _c(
          "excel-import",
          {
            attrs: {
              config: _vm.config,
              loading: _vm.loading,
              outlined: _vm.outlined,
              small: _vm.small
            },
            on: {
              uploadData: _vm.create
            }
          },
          [
            _c("excel-import-report", {
              attrs: {
                failedImports: _vm.failedImports,
                totalItems: _vm.totalItems,
                importedItems: _vm.importedItems
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }