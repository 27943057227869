import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import HandoverDetailView from "@/views/handover/HandoverDetailView.vue";
import HandoverReportTableView from "@/views/handover/HandoverReportTableView.vue";
import HandoverTableView from "@/views/handover/HandoverTableView.vue";
import InspectionDetailView from "@/views/handover/InspectionDetailView.vue";
import InspectionTableView from "@/views/handover/InspectionTableView.vue";
import SnapshotTableView from "@/views/handover/SnapshotTableView.vue";
import SnapshotDetailView from "@/views/handover/SnapshotDetailView.vue";
import ActivityTableView from "@/views/handover/ActivityTableView.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import HandoverTourView from "@/views/handover/HandoverTourView.vue";
import HandoverAgreementsTableView from "@/views/handover/HandoverAgreementsTableView.vue";
import HandoverAttachmentsTableView from "@/views/handover/HandoverAttachmentsTableView.vue";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";

Vue.use(VueRouter);

const routes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/handover",
    name: "HandoverTableView",
    component: HandoverTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-clipboard-text-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 35
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId",
    name: "HandoverDetailView",
    component: HandoverDetailView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/report",
    name: "HandoverReportTableView",
    component: HandoverReportTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/inspection",
    name: "InspectionTableView",
    component: InspectionTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/inspection/:inspectionId",
    name: "InspectionDetailView",
    component: InspectionDetailView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/agreement",
    name: "HandoverAgreementsTableView",
    component: HandoverAgreementsTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/attachment",
    name: "HandoverAttachmentsTableView",
    component: HandoverAttachmentsTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/snapshot",
    name: "SnapshotTableView",
    component: SnapshotTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/snapshot/:snapshotId",
    name: "SnapshotDetailView",
    component: SnapshotDetailView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/activity",
    name: "ActivityTableView",
    component: ActivityTableView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/handover/:handoverId/tour/:blueprintId",
    name: "HandoverTourView",
    component: HandoverTourView,
    permission: {
      resource: ResourceEnum.HANDOVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];

const router = new VueRouter({
  routes,
  base: ""
});

router.beforeEach(permissionGuard);

export default router;

export const HandoverRoutes = routes;
