













import { PersonRouteNames } from "@/lib/utility/person.go-to-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ImportOverviewCard, { IImportOverviewConfig } from "../utility/ImportOverviewCard.vue";
import PersonImportDialog from "./PersonImportDialog.vue";

@Component({
  components: { ImportOverviewCard, PersonImportDialog }
})
export default class PersonImportCard extends mixins(PermissionMixin) {
  readonly PersonRouteNames = PersonRouteNames;

  @Prop()
  partnerId!: string;

  get importConfig(): IImportOverviewConfig[] {
    return [
      {
        category: this.$t("person.people").toString(),
        items: [
          {
            title: this.$t("person.import.itemTitle").toString(),
            description: this.$t("person.import.itemDescription").toString(),
            component: "person-import-dialog",
            icon: "mdi-office-building",
            hasPermission: this.canCreatePerson
          }
        ]
      }
    ];
  }
}
