var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("the-layout-portal-list", {
        attrs: {
          title: _vm.$t(_vm.title),
          loading: _vm.loading,
          isLoadingMore: _vm.isLoadingMore,
          getModule: _vm.getModule
        },
        on: {
          loadMore: _vm.loadMore,
          refresh: _vm.refresh
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function fn() {
                return [_vm._t("title")]
              },
              proxy: true
            },
            {
              key: "options",
              fn: function fn() {
                return [
                  _vm.canCreateMessage
                    ? _c("template-dialog", {
                        ref: "templateDialog",
                        attrs: {
                          icon: "mdi-plus",
                          partnerId: _vm.partner._id,
                          to: [],
                          from: [_vm.partner]
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "listItem",
              fn: function fn(_ref) {
                var listItem = _ref.listItem
                return [
                  _c(
                    "v-list-item",
                    {
                      class: listItem.isRead ? "" : "font-weight-black",
                      on: {
                        click: function click($event) {
                          return _vm.set(listItem)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: "primary"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.avatar(listItem)) + " ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.displayName(listItem)) + " ")
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            {
                              staticClass: "text--primary"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(listItem.content.subject) + " "
                              )
                            ]
                          ),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              " " + _vm._s(_vm.itemFunctions.body(listItem))
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-list-item-action-text", [
                            _vm._v(_vm._s(_vm.itemFunctions.date(listItem)))
                          ]),
                          _c(
                            "span",
                            [
                              _c("assignee-preview", {
                                attrs: {
                                  value: listItem.assignees,
                                  amount: 3
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }