






















































































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import TimeSeriesLineChartCard from "@/components/analytics/TimeSeriesLineChartCard.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { AdminCountAnalyticsModule } from "@/store/modules/admin-count-analytics.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    PieChartCard,
    BarChartCard,
    AnalyticsDateRangeSelectorDialog,
    TimeSeriesLineChartCard
  }
})
export default class DesignGuidelinesDashboardExample extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;
  activeTab = 0;
  activated = false;

  analyticsColors = ["#ba3b3e", "#f63c42", "#f75c64", "#a4091f", "#565656", "#ff8a90", "#ba3b3e", "#ba3b3e", "#ba3b3e"];

  get dates() {
    return AdminCountAnalyticsModule.dateRange;
  }

  get breadCrumbs() {
    return [
      {
        text: "Design Guidelines",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelines",
          params: {}
        }
      },
      {
        text: "Example Dashboard",
        exact: true,
        disabled: true,
        to: {
          name: "DesignGuidelinesDashboardExample",
          params: {}
        }
      }
    ];
  }

  kpiChart1 = [
    {
      name: "Ø Schadenhöhe",
      value: 1082,
      icon: "mdi-currency-eur"
    }
  ];

  dataChart1 = [
    {
      name: "Teilkasko",
      value: 671.22
    },
    {
      name: "Vollkasko (nicht versichert)",
      value: 1858.84
    },
    {
      name: "Teilkasko (nicht versichert)",
      value: 500
    },
    {
      name: "Drittschaden",
      value: 1300
    }
  ];

  dataChart2 = [
    {
      name: "Schaden gemeldet",
      value: 5
    },
    {
      name: "Werkstatttermin vereinbart",
      value: 3
    },
    {
      name: "In Reparatur",
      value: 4
    },
    {
      name: "Schaden behoben",
      value: 10
    }
  ];

  kpiChart2 = [
    {
      name: "Offen",
      value: 12,
      icon: "mdi-circle-outline"
    },
    {
      name: "Abgeschlossen",
      value: 10,
      icon: "mdi-check-circle"
    }
  ];

  dataChart3 = [
    {
      name: "Privat",
      value: 10
    },
    {
      name: "Beruflich",
      value: 95 * 2
    }
  ];

  kpiChart3 = [
    {
      name: "Privatfahrten",
      value: 5,
      icon: "mdi-percent-outline"
    }
  ];

  dataChart4 = [
    { name: "Glasbruch", value: 642.68 },
    { name: "Begegnungsverkehr", value: 775.0 },
    { name: "Rangierschaden", value: 2501.48 },
    { name: "Aufsetzschaden", value: 400.0 },
    { name: "Parkschaden", value: 900.0 },
    { name: "Sonstige", value: 5482.51 }
  ];

  dataChart5 = [
    { name: "Muster Karosseriebau GmbH", damages: 4, value: 17700.0 },
    { name: "Rudolf Müller GmbH", damages: 1, value: 5479.59 },
    { name: "Autohaus Mustermann GmbH & Co. KG", damages: 1, value: 2635.9 },
    { name: "Autohaus Max Mustermann GmbH", damages: 1, value: 1848.23 },
    { name: "Autoglas Müller GmbH", damages: 1, value: 1787.16 },
    { name: "CARGLASS GmbH Berlin", damages: 1, value: 1365.64 },
    { name: "A.T. Mustermann GmbH", damages: 1, value: 965.15 },
    { name: "XYZ Gutachten GmbH", damages: 1, value: 838.32 },
    { name: "Autohaus Martin Müller & Söhne KG", damages: 1, value: 817.25 },
    { name: "ABC Consulting", damages: 1, value: 500.0 }
  ];

  dataChart8 = [
    { name: "Max Mustermann", damages: 4, value: 1770.0 },
    { name: "Lena Musterfrau", damages: 1, value: 1680.67 },
    { name: "Peter Müller", damages: 1, value: 1573.33 },
    { name: "Anna Schmidt", damages: 1, value: 1470.0 },
    { name: "Paul Weber", damages: 1, value: 1766.67 },
    { name: "Sophie Fischer", damages: 1, value: 1283.33 },
    { name: "Jonas Schneider", damages: 1, value: 1190.0 },
    { name: "Laura Becker", damages: 1, value: 1006.67 },
    { name: "Tim Hoffmann", damages: 1, value: 933.33 },
    { name: "Julia Wagner", damages: 1, value: 850.0 }
  ];

  dataChart6 = [
    { name: "Mo", value: 5 },
    { name: "Di", value: 3 },
    { name: "Mi", value: 8 },
    { name: "Do", value: 2 },
    { name: "Fr", value: 6 },
    { name: "Sa", value: 4 },
    { name: "So", value: 1 }
  ];

  myAlerts = [];

  imageMarkerSrc = "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg";

  // Image options for selection
  imageOptions = [
    {
      imageId: 1,
      text: "Image 1",
      src: "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg"
    },
    {
      imageId: 2,
      text: "Image 2",
      src: "https://www.schadensmeldung.digital/images/features/schadenmanagement/individueller-funnel.webp"
    }
  ];

  get tabs(): IAction[] {
    return [
      { text: "Schäden", key: "start", icon: "mdi-table" },
      { text: "Versicherungskosten", key: "view1", icon: "mdi-view-column-outline" }
    ];
  }

  get actions() {
    return [
      {
        text: "Bericht exportieren",
        key: "primaryAction",
        icon: "mdi-export",
        color: "info",
        count: 5
      }
    ];
  }

  get trendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: this.rawData.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: this.rawData.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: this.rawData.thirtyDayTrend
      }
    ];
  }

  get rawData() {
    return {
      sum: 814,
      dayTrend: [
        [1738195200000, 16],
        [1738281600000, 17],
        [1738368000000, 27],
        [1738454400000, 24],
        [1738540800000, 62],
        [1738627200000, 22],
        [1738713600000, 19],
        [1738800000000, 10],
        [1738886400000, 26],
        [1738972800000, 20],
        [1739059200000, 22],
        [1739145600000, 23],
        [1739232000000, 14],
        [1739318400000, 102],
        [1739404800000, 51],
        [1739491200000, 33],
        [1739577600000, 15],
        [1739664000000, 14],
        [1739750400000, 30],
        [1739836800000, 30],
        [1739923200000, 22],
        [1740009600000, 33],
        [1740096000000, 12],
        [1740182400000, 8],
        [1740268800000, 15],
        [1740355200000, 40],
        [1740441600000, 44],
        [1740528000000, 33],
        [1740614400000, 25],
        [1740700800000, 5]
      ],
      sevenDayTend: [
        [1738195200000, 386],
        [1738281600000, 358],
        [1738368000000, 373],
        [1738454400000, 377],
        [1738540800000, 262],
        [1738627200000, 218],
        [1738713600000, 187],
        [1738800000000, 181],
        [1738886400000, 190],
        [1738972800000, 183],
        [1739059200000, 181],
        [1739145600000, 142],
        [1739232000000, 134],
        [1739318400000, 217],
        [1739404800000, 258],
        [1739491200000, 265],
        [1739577600000, 260],
        [1739664000000, 252],
        [1739750400000, 259],
        [1739836800000, 275],
        [1739923200000, 195],
        [1740009600000, 177],
        [1740096000000, 156],
        [1740182400000, 149],
        [1740268800000, 150],
        [1740355200000, 160],
        [1740441600000, 174],
        [1740528000000, 185],
        [1740614400000, 177],
        [1740700800000, 170]
      ],
      thirtyDayTrend: [
        [1738195200000, 1749],
        [1738281600000, 1747],
        [1738368000000, 1733],
        [1738454400000, 1734],
        [1738540800000, 1781],
        [1738627200000, 1771],
        [1738713600000, 1771],
        [1738800000000, 1760],
        [1738886400000, 1738],
        [1738972800000, 1450],
        [1739059200000, 1383],
        [1739145600000, 1358],
        [1739232000000, 1333],
        [1739318400000, 1369],
        [1739404800000, 1384],
        [1739491200000, 1380],
        [1739577600000, 1364],
        [1739664000000, 1339],
        [1739750400000, 1352],
        [1739836800000, 1352],
        [1739923200000, 1343],
        [1740009600000, 1326],
        [1740096000000, 1305],
        [1740182400000, 1022],
        [1740268800000, 992],
        [1740355200000, 1020],
        [1740441600000, 1044],
        [1740528000000, 900],
        [1740614400000, 859],
        [1740700800000, 814]
      ]
    };
  }
}
