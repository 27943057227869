var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            ],
            1
          )
        : _vm.inspection
        ? _c(
            "v-container",
            [
              _c("inspection-detail", {
                attrs: {
                  value: _vm.inspection,
                  handover: _vm.handover,
                  vehicle: _vm.vehicle
                },
                on: {
                  change: function change($e) {
                    return (_vm.inspection = $e)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }