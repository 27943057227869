













import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IGroup, Group } from "@/models/group.entity";
import { GroupModule } from "@/store/modules/group.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import GroupTypeDetail from "@/components/group/GroupTypeDetail.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";

@Component({
  components: {
    TheLayoutPortal,
    GroupTypeDetail,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class GroupTypeDetailView extends mixins(PartnerFallbackMixin) {
  isLoading = false;

  group: IGroup | null = null;

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get groupId(): string {
    return this.$route.params.groupId;
  }

  get type(): IGroupType {
    return this.$route.params.type as IGroupType;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const group =
      GroupModule.maps.id.get(this.groupId)[0] ??
      new Group({
        id: this.groupId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await group.fetch();
      this.group = group;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
