








































import RefsEvent from "@/components/utility/RefsEvent.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog, RefsEvent },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class EventAcknowledgeDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop({ default: () => [] })
  value!: IEventUIDto[];

  valueTempCopy: IEventUIDto[] = [];

  isDialogActive = false;

  isLoading = false;

  open() {
    this.valueTempCopy = [...this.value];
    this.isDialogActive = true;
  }

  close() {
    this.isDialogActive = false;
  }

  async completeEvents() {
    this.isLoading = true;

    const failedIds: string[] = [];
    for (const event of this.valueTempCopy) {
      if (!this.isDialogActive || event._isRecurring) {
        failedIds.push(simpleDoubleDigitDate(new Date(event.start).toISOString()));
        this.$emit("acknowledged", event);
        continue;
      }

      this.$log.debug(event);

      try {
        if (!event.ack) {
          await event.acknowledge(new Date().toISOString());
        }

        this.$emit("acknowledged", event);
      } catch (error) {
        this.$log.error(error);
        failedIds.push(simpleDoubleDigitDate(new Date(event.start).toISOString()));
      }
    }

    if (failedIds.length) {
      this.$toast.error(`${$t("failed")}: ${failedIds.map(v => `#${v}`).join(", ")}`);
    } else {
      this.$toast.success("👍");
    }

    this.isLoading = false;
  }
}
