















































import { IDialog } from "@/lib/interfaces/dialog.interface";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Fleet, IFleet } from "@/models/fleet.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import { CompanyModule } from "@/store/modules/company.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    ConfirmActionDialog,
    Debug
  }
})
export default class CreateFleetDialog extends mixins(PartnerFallbackMixin, PermissionMixin) implements IDialog {
  @Prop()
  partnerId!: string;

  fleet: IFleet = new Fleet(this._empty);

  isCreateLoading = false;
  isCompanyLoading = false;
  isDialogActive = false;
  isValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  private get _empty(): Partial<IFleet> {
    return {
      partnerId: this.partnerId,
      title: ""
    };
  }

  get companies() {
    return CompanyModule.filteredAndSorted;
  }

  get showLoadMore() {
    return CompanyModule.totalPages > CompanyModule.currentPage;
  }

  useCompanyName(companyId: string) {
    this.$log.debug(companyId);

    if (!companyId) {
      return;
    }

    const company = CompanyModule.maps.id.get(companyId);

    if (!company || company.length < 0) {
      this.$log.warn("No company received from maps");
    }

    this.$log.debug(company[0]);

    this.fleet.title = company[0].companyName;
  }

  async open() {
    this.isCompanyLoading = true;
    CompanyModule.setFilters([new PageFilterElement({ key: "isFleet", operation: "$eq", value: true as any })]);
    await CompanyModule.fetchFirstPage({ partnerId: this.partnerId }).finally(() => (this.isCompanyLoading = false));

    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.titleTextField as any)?.focus();
    });
  }

  async loadMoreCompanies() {
    this.isCompanyLoading = true;

    await CompanyModule.fetchNextPage({ partnerId: this.partnerId }).finally(() => (this.isCompanyLoading = false));
  }

  close() {
    this.isDialogActive = false;
    this.fleet = new Fleet(this._empty);
    CompanyModule.setFilters([]);
  }

  async create() {
    try {
      this.isCreateLoading = true;
      const created = await this.fleet.create();
      this.$toast("👍");

      this.$router.push({ name: "FleetDetailView", params: { partnerId: created.partnerId, fleetId: created.id } });
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateLoading = false;
      this.isDialogActive = false;
    }
  }
}
