var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: "Designguidelines",
              subtitle:
                "This is our Desinbook. Use subtitle for text or use the chip layout",
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions
            },
            on: {
              actionClicked: _vm.processAction
            }
          }),
          _c(
            "div",
            {
              staticClass: "mx-4"
            },
            [
              _c(
                "div",
                {
                  staticClass: "mb-4"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title"
                    },
                    [_vm._v("Components")]
                  ),
                  _c("div", [
                    _c("ul", [
                      _c("li", [_c("a", [_vm._v("MHeader")])]),
                      _c("li", [_c("a", [_vm._v("MDetailViewGrid")])]),
                      _c("li", [_c("a", [_vm._v("MActionList")])]),
                      _c("li", [_c("a", [_vm._v("MDetailTable")])])
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "mb-4"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title"
                    },
                    [_vm._v("Example Usages")]
                  ),
                  _c("div", [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            on: {
                              click: _vm.goToHeaderExampleWithChips
                            }
                          },
                          [
                            _vm._v(
                              "Example Detail Page tabs and chips as subtitle"
                            )
                          ]
                        )
                      ]),
                      _c("li", [
                        _c(
                          "a",
                          {
                            on: {
                              click: _vm.goToMobileOptimizedPortal
                            }
                          },
                          [_vm._v("Example for mobile optimzed portal")]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }