























































import MDetailTableFormable from "@/components/utility/mmmint/MDetailTableFormable.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { $t } from "@/lib/utility/t";
import { ICompanyAddress } from "@/models/company-address.entity";
import { Company, ICompany } from "@/models/company.entity";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";

@Component({
  components: {
    AssigneePreview,
    MDetailTableFormable
  },
  filters: {
    getFlagEmoji
  }
})
export default class CompanyDetailTable extends mixins(Vue) {
  readonly formable = Company;

  @Prop()
  value!: ICompany;

  @Prop()
  onDetail?: () => void;

  copyAddressToClipboard(address: ICompanyAddress) {
    const value = [];
    if (address.street) {
      value.push(address.street);
    }
    if (address.zip) {
      value.push(address.zip);
    }
    if (address.city) {
      value.push(address.city);
    }
    if (address.countryCode) {
      value.push(address.countryCode);
    }
    for (const type of address.type) {
      value.push($t(`enums.CompanyAddressTypeEnum.${type}`));
    }

    navigator.clipboard.writeText(value.join(" "));
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  getCompanyGroupForId(id: string) {
    return CompanyGroupModule.maps.id.get(id)[0];
  }
}
