var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("paginated-table", {
    attrs: {
      store: _vm.store,
      headers: _vm.headers,
      baseQuery: {
        partnerId: _vm.partnerId
      },
      predefinedFilter: _vm.predefinedFilter
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([
      {
        key: "bodyActions",
        fn: function fn() {
          return [
            _vm.can(_vm.ActionEnum.CREATE, _vm.ResourceEnum.FLEET)
              ? _c("create-fleet-dialog", {
                  attrs: {
                    partnerId: _vm.partnerId
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function fn(_ref) {
                          var on = _ref.on
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "info",
                                  elevation: 0
                                },
                                on: {
                                  click: on
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4126489377
                  )
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "item.isRoot",
        fn: function fn(_ref2) {
          var item = _ref2.item
          return [
            item.isRoot
              ? _c(
                  "v-chip",
                  {
                    attrs: {
                      small: "",
                      color: "primary"
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: "",
                          left: ""
                        }
                      },
                      [_vm._v("mdi-family-tree")]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("objects.fleet.isRoot")) + " ")
                  ],
                  1
                )
              : _c(
                  "v-chip",
                  {
                    attrs: {
                      small: ""
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: "",
                          left: ""
                        }
                      },
                      [_vm._v("mdi-garage-open")]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("common.nouns.fleet")) + " ")
                  ],
                  1
                )
          ]
        }
      },
      {
        key: "item.assignees",
        fn: function fn(_ref3) {
          var item = _ref3.item
          return [
            _c("assignee-preview", {
              attrs: {
                value: item.assignees,
                amount: 3,
                partnerId: _vm.partnerId
              }
            })
          ]
        }
      },
      {
        key: "item.timestamp.created",
        fn: function fn(_ref4) {
          var item = _ref4.item
          return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }