import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IContract } from "@/models/contract.entity";
import contractService from "@/services/mrfiktiv/services/contract-service";
import { MrfiktivContractControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const ContractProviderPageDataProvider = new (class extends AbstractPageDataProvider<
  IContract,
  MrfiktivContractControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivContractControllerGetParamsGen): Promise<IPageViewModel<IContract>> {
    const res = await contractService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IContract[];

    return { meta, data };
  }
})();
