import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Person, IPerson } from "@/models/person.entity";
import { MrfiktivPersonControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PersonDataAccessLayer } from "./access-layers/person.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { PersonPageDataProvider } from "./page-data-provider/person.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "person",
  store
})
export class PersonPaginationStore extends PaginatedBaseStore<IPerson, MrfiktivPersonControllerGetParamsGen> {
  protected _data = PersonDataAccessLayer;
  protected _pageProvider = PersonPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Person.filterables;
}

export const PersonModule = getModule(PersonPaginationStore);
