import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Company, ICompany } from "@/models/company.entity";
import { MrfiktivCompanyControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { CompanyDataAccessLayer } from "./access-layers/company.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { CompanyPageDataProvider } from "./page-data-provider/company.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "company",
  store
})
export class CompanyPaginationStore extends PaginatedBaseStore<ICompany, MrfiktivCompanyControllerGetParamsGen> {
  protected _data = CompanyDataAccessLayer;
  protected _pageProvider = CompanyPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Company.filterables;
}

export const CompanyModule = getModule(CompanyPaginationStore);
