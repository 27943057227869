


























































































import Debug from "@/components/utility/Debug.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { PartnerBankingGoToHelper } from "@/lib/utility/partner-banking.go-to-helper";
import { $t } from "@/lib/utility/t";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import UpdateForm from "../utility/UpdateForm.vue";
import PartnerBankingDetail from "./PartnerBankingDetail.vue";
import PartnerBankingDetailTable from "./PartnerBankingDetailTable.vue";
import MDetailForm from "../utility/mmmint/MDetailForm.vue";
import CustomFieldBoolean from "../report/CustomFieldBoolean.vue";
import { RulesEnum } from "@/lib/rules/rules.map";
import { handleError } from "@/lib/utility/handleError";
import RefsPartnerBanking from "../utility/RefsPartnerBanking.vue";

@Component({
  components: {
    UpdateForm,
    MHeader,
    MDetailViewGrid,
    MActionList,
    ConfirmActionDialog,
    Debug,
    PartnerBankingDetailTable,
    MDetailForm,
    CustomFieldBoolean,
    RefsPartnerBanking
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class PartnerBankingDetailForm extends PartnerBankingDetail {
  panels = [0];

  isLoading = false;

  search = "";

  partnerBankingCopy = this.copyBanking();

  get changesDetectedBank() {
    return this.value.bank !== this.partnerBankingCopy.bank;
  }

  get changesDetectedIban() {
    return this.value.iban !== this.partnerBankingCopy.iban;
  }

  get changesDetectedName() {
    return this.value.name !== this.partnerBankingCopy.name;
  }

  get changesDetectedIsSepaDirectDebitMandate() {
    return this.value.isSepaDirectDebitMandate !== this.partnerBankingCopy.isSepaDirectDebitMandate;
  }

  get changesDetected() {
    return (
      this.changesDetectedBank ||
      this.changesDetectedIban ||
      this.changesDetectedName ||
      this.changesDetectedIsSepaDirectDebitMandate
    );
  }

  get isDisabled() {
    if (!this.partnerBankingCopy.bank || !this.partnerBankingCopy.iban || !this.partnerBankingCopy.name) {
      // dont allow to save an empty form
      return true;
    }

    if (this.changesDetectedIban && this.rules(RulesEnum.IBAN_RULE)[0]?.(this.partnerBankingCopy.iban) !== true) {
      // if the bank changes, the iban must be valid!
      return true;
    }

    return false;
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = PartnerBankingGoToHelper.breadCrumbs;

    const selected = [];

    const partnerId = this.value.partnerId;
    const partnerBankingId = this.value.id;

    selected.push(breadCrumbs.PartnerBankingTable({ partnerId }));
    selected.push(breadCrumbs.PartnerBankingDetail({ partnerId, partnerBankingId }));
    selected.push(breadCrumbs.PartnerBankingDetailForm({ partnerId, partnerBankingId }));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: $t("common.nouns.detail"),
      key: "detail",
      icon: "mdi-open-in-new",
      color: "",
      exec: () => this.value.goTo(this.$router).detail()
    });

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.PARTNER_CLIENT_BANKING)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  beforeMount() {
    this.setAndCopyBanking();
  }

  copyBanking() {
    return {
      bank: this.value.bank,
      iban: this.value.iban,
      name: this.value.name,
      isSepaDirectDebitMandate: this.value.isSepaDirectDebitMandate
    };
  }

  setAndCopyBanking() {
    this.partnerBankingCopy = this.copyBanking();
  }

  searchMatch(values: string[]) {
    if (!this.search) {
      return true;
    }

    return values.some(value => value.toLowerCase().includes(this.search.toLowerCase()));
  }

  async sync() {
    this.isLoading = true;
    await this.value
      .updatePartial({
        bank: this.partnerBankingCopy.bank === this.value.bank ? undefined : this.partnerBankingCopy.bank,
        iban: this.partnerBankingCopy.iban === this.value.iban ? undefined : this.partnerBankingCopy.iban,
        name: this.partnerBankingCopy.name === this.value.name ? undefined : this.partnerBankingCopy.name,
        isSepaDirectDebitMandate:
          this.partnerBankingCopy.isSepaDirectDebitMandate === this.value.isSepaDirectDebitMandate
            ? undefined
            : this.partnerBankingCopy.isSepaDirectDebitMandate
      })
      .catch(handleError);
    this.$toast.success("👍");
    this.setAndCopyBanking();
    this.isLoading = false;
  }

  abort() {
    this.setAndCopyBanking();
  }
}
