




















import MHeader from "@/components/utility/mmmint/MHeader.vue";
import PaginatedTable from "@/components/utility/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IActivityLog } from "@/models/activity-log.entity";
import { Handover, IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: { TheLayoutPortal, PaginatedTable, MHeader },
  filters: { simpleDoubleDigitDate }
})
export default class ActivityTableView extends mixins(Vue, PermissionMixin) {
  title = this.$t("partner.ActivityLogTable.title");

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("partner.ActivityLogTable.title"),
      exact: true,
      disabled: true,
      to: {
        name: "ActivityTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  get activitySource(): MrfiktivReferenceGen {
    return {
      refType: this.ResourceEnum.HANDOVER,
      refId: this.$route.params.handoverId
    };
  }

  getModule() {
    return ActivityLogModule;
  }

  get activityLogs(): IActivityLog[] {
    return ActivityLogModule.entities;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("partner.ActivityLogTable.timestamp").toString(),
        align: "start",
        value: "timestamp.created",
        width: 200
      },
      { text: this.$t("partner.ActivityLogTable.userName").toString(), align: "start", value: "user.userName" },
      {
        text: this.$t("partner.ActivityLogTable.activity").toString(),
        align: "start",
        value: "actionType",
        width: 200
      },
      { text: this.$t("partner.ActivityLogTable.activity").toString(), align: "start", value: "activity" },
      { text: this.$t("partner.ActivityLogTable.comment").toString(), align: "start", value: "comment" }
    ];
  }

  isLoading = false;

  handover: IHandover | null = null;

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async fetchActivity() {
    const partnerId = this.$route.params.partnerId;

    ActivityLogModule.setFilters([
      new PageFilterElement({
        key: "source.refId",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.activitySource.refId
      }),
      new PageFilterElement({
        key: "source.refType",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.activitySource.refType
      })
    ]);

    await ActivityLogModule.fetchFirstPage({ partnerId });
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();
      await this.fetchActivity();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
