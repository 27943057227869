





































































import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { ContractTypeEnum } from "@/lib/enum/contract-type.enum";
import { ContractGoToHelper } from "@/lib/utility/contract.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Group } from "@/models/group.entity";
import { IContract } from "@/models/contract.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { GroupModule } from "@/store/modules/group.store";
import { ContractModule, ContractPaginationStore } from "@/store/modules/contract.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsContract from "../utility/RefsContract.vue";
import RefsGroup from "../utility/RefsGroup.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import ContractCreateDialog from "./ContractCreateDialog.vue";
import ContractSideCard from "./ContractSideCard.vue";
import { IReference } from "@/models/reference.entity";
import AssigneePreview from "../utility/AssigneePreview.vue";
import DueDateChip from "@/components/project/DueDateChip.vue";
import { toArray } from "@/lib/array-helper";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    ContractCreateDialog,
    ContractSideCard,
    AssigneePreview,
    RefsGroup,
    RefsContract,
    DueDateChip,
    Debug
  }
})
export default class ContractTable extends mixins(PermissionMixin) {
  readonly toArray = toArray;

  readonly ContractTypeEnum = ContractTypeEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  ContractStore = ContractModule;

  @Prop()
  partnerId!: string;

  @Prop({ default: () => [] })
  refs!: IReference[];

  @Prop({ default: () => [] })
  clientIds!: IReference[];

  @Prop({ default: () => [] })
  providerIds!: IReference[];

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  @Prop()
  controlElements?: IControlElements[] | undefined;

  @Prop({ default: ContractModule })
  private store!: ContractPaginationStore;

  selectedItem: IContract | null = null;

  selectedItemForDelete: IContract | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get localStore() {
    if (!this.store) {
      return this.ContractStore;
    }

    return this.store;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.createdReadable",
      width: "120px"
    });
    headers.push({
      text: $t("objects.contract.type"),
      value: "type",
      width: "120px",
      align: "center"
    });
    headers.push({
      text: $t("objects.contract.assignees"),
      value: "assignees",
      width: "140px",
      align: "center"
    });
    headers.push({
      text: $t("objects.contract.startDate"),
      value: "startDate",
      width: "120px",
      align: "center"
    });
    headers.push({
      text: $t("objects.contract.endDate"),
      value: "endDate",
      width: "120px",
      align: "center"
    });
    headers.push({
      text: $t("common.nouns.title"),
      value: "title"
    });
    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get _controlElements(): IControlElements[] {
    if (this.controlElements) {
      return this.controlElements;
    }

    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: IContract) =>
          new ContractGoToHelper(this.$router).goToContractDetail({
            contractId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { title: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { title: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  beforeMount() {
    this.store.setFilters(this.filters);
  }

  async getGroup(id: string) {
    if (!id) return;
    let group = GroupModule.maps.id.get(id)[0];
    if (!group) {
      group = new Group({ partnerId: this.partnerId, id });
      group.fetch();
    }

    return group;
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: IContract | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: IContract) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
