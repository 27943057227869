

































































import { detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { BookingBookingViewModelGen } from "@/services/booking/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ServiceModule } from "@/store/modules/service.store";
import { ResourceModule } from "@/store/modules/resource.store";
import { Service } from "@/models/service.entity";

@Component({
  components: {},
  filters: {
    simpleDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class BookingMenuCard extends Vue {
  @Prop()
  selected!: BookingBookingViewModelGen;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: true })
  showActions!: boolean;

  @Prop({ default: true })
  detailed!: boolean;

  get serviceName() {
    if (!this.selected.serviceId) {
      return Service.SERVICE_INTERNAL_NAME;
    }

    const service = ServiceModule.maps.id.get(this.selected.serviceId)[0];
    return service?.name;
  }

  get resourceName() {
    if (!this.selected.resourceId) {
      return "";
    }

    const resource = ResourceModule.maps.id.get(this.selected.resourceId)[0];
    return resource?.name;
  }

  get debug() {
    return ConfigModule.debug;
  }

  click() {
    this.$emit("select", this.selected);
  }
}
