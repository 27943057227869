










import ContractTable from "@/components/contract/ContractTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ContractGoToHelper } from "@/lib/utility/contract.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Contract, IContract } from "@/models/contract.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ContractModule } from "@/store/modules/contract.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import { $t } from "@/lib/utility/t";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    ContractTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ContractTableView extends mixins(PartnerFallbackMixin) {
  @Ref("contractTable")
  contractTable!: ContractTable;

  readonly AMOUNT_COST = "amountContract";

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    const partnerId = this.partnerId;
    crumbs.push(ContractGoToHelper.breadCrumbs.ContractTable({ partnerId }));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${ContractModule.totalItems} ${this.$t("contract.contracts")}`,
      key: this.AMOUNT_COST,
      disabled: true
    });

    return chips;
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("group.contract.table"),
        key: "contractTables",
        exec: () =>
          new GroupGoToHelper(this.$router).goToGroupTypeTable({
            partnerId: this.partnerId,
            type: ResourceEnum.CONTRACT
          })
      }
    ];
  }

  beforeMount() {
    ContractModule.setFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const contractId = this.$route.query.contractId as string;
    if (contractId) {
      const contract = new Contract({ partnerId: this.partnerId, id: contractId });
      this.contractTable?.setSelectedItem(contract);
    }
  }

  openSideCard(contract: IContract) {
    new ContractGoToHelper(this.$router).setUrl(
      ContractGoToHelper.locations.contractTable({
        partnerId: this.partnerId,
        query: contract ? { contractId: contract.id } : {}
      })
    );
  }
}
