













import CompanyDetailForm from "@/components/company/CompanyDetailForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { ICompany, Company } from "@/models/company.entity";
import { CompanyModule } from "@/store/modules/company.store";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";

@Component({
  components: {
    TheLayoutPortal,
    CompanyDetailForm,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyDetailFormView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get companyId(): string {
    return this.$route.params.companyId;
  }

  isLoading = false;

  company: ICompany | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const company =
      CompanyModule.maps.id.get(this.companyId)[0] ??
      new Company({
        id: this.companyId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await company.fetch();
      this.company = company;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToCompanyGroup() {
    if (this.company?.groupId) {
      new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
        partnerId: this.partnerId,
        companyGroupId: this.company?.groupId,
        viewId: "0"
      });
    } else {
      new CompanyGoToHelper(this.$router).goToCompanyTable({
        partnerId: this.partnerId
      });
    }
  }
}
