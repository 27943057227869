













import ContractDetail from "@/components/contract/ContractDetail.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IContract, Contract } from "@/models/contract.entity";
import { ContractModule } from "@/store/modules/contract.store";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { ContractGoToHelper } from "@/lib/utility/contract.go-to-helper";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    TheLayoutPortal,
    ContractDetail,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ContractDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get contractId(): string {
    return this.$route.params.contractId;
  }

  isLoading = false;

  contract: IContract | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const contract =
      ContractModule.maps.id.get(this.contractId)[0] ??
      new Contract({
        id: this.contractId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await contract.fetch();
      this.contract = contract;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToGroup() {
    if (this.contract?.groupId) {
      new GroupGoToHelper(this.$router).goToGroupTypeCustomView({
        partnerId: this.partnerId,
        type: BackendResourceEnum.CONTRACT,
        groupId: this.contract?.groupId,
        viewId: "0"
      });
    } else {
      new ContractGoToHelper(this.$router).goToContractTable({
        partnerId: this.partnerId
      });
    }
  }
}
