var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.value.name,
          breadCrumbs: _vm.breadCrumbs,
          actions: _vm.actions,
          chips: _vm.chips
        },
        on: {
          actionClicked: function actionClicked($event) {
            return $event.exec()
          },
          alertClicked: function alertClicked($event) {
            return $event.exec()
          }
        }
      }),
      Object.values(_vm.PartnerBankingTabEnum).length > 1
        ? _c(
            "v-tabs",
            {
              model: {
                value: _vm.bankingTab,
                callback: function callback($$v) {
                  _vm.bankingTab = $$v
                },
                expression: "bankingTab"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "info"
                }
              }),
              _vm._l(Object.values(_vm.PartnerBankingTabEnum), function(
                bankingTab
              ) {
                return _c(
                  "v-tab",
                  {
                    key: "v-tab" + bankingTab
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "enums.PartnerBankingTabEnum.".concat(bankingTab)
                          )
                        ) +
                        " "
                    )
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.bankingTab,
            callback: function callback($$v) {
              _vm.bankingTab = $$v
            },
            expression: "bankingTab"
          }
        },
        [
          _c(
            "v-tab-item",
            {
              key: _vm.PartnerBankingTabEnum.ACTIVITIES
            },
            [
              _c("partner-banking-detail-table", {
                attrs: {
                  value: _vm.value,
                  onDetail: _vm.goToPartnerBankingDetailForm
                }
              }),
              _c("v-divider", {
                staticClass: "mt-2"
              }),
              _c("activity-card", {
                attrs: {
                  partner: _vm.partner,
                  source: _vm.source,
                  config: {
                    comment: true,
                    mail: false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isDeleteDialogActive && _vm.value
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-partner-banking", {
                attrs: {
                  item: _vm.value
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }