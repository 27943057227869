













import ContractDetailForm from "@/components/contract/ContractDetailForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IContract, Contract } from "@/models/contract.entity";
import { ContractModule } from "@/store/modules/contract.store";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    ContractDetailForm,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ContractDetailFormView extends mixins(PartnerFallbackMixin) {
  isLoading = false;

  contract: IContract | null = null;

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get contractId(): string {
    return this.$route.params.contractId;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const contract =
      ContractModule.maps.id.get(this.contractId)[0] ??
      new Contract({
        id: this.contractId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await contract.fetch();
      this.contract = contract;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToGroup() {
    if (this.contract?.groupId) {
      this.contract.goTo(this.$router).customView();
    } else {
      this.contract?.goTo(this.$router).table();
    }
  }
}
