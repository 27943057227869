










































































import { detailedDate, detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { RRule } from "rrule";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import Tooltip from "../utility/tooltip.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";

@Component({
  components: { Tooltip },
  filters: {
    simpleDate,
    detailedDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class FleetEventMenuCard extends Vue {
  @Prop()
  width?: string;

  @Prop()
  selectedEvent!: IEventUIDto;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: true })
  showActions!: boolean;

  @Prop({ default: true })
  detailed!: boolean;

  /**
   * returns simople date DD.MM.YYYY
   * @param rrule
   */
  simpleDate(date: number) {
    return simpleDate(new Date(date).toISOString());
  }

  /**
   * return recurring info
   * @param rrule
   */
  recurringInfo(rrule: string) {
    return RRule.fromString(rrule).toText();
  }

  getAckUser(by: string) {
    return PartnerUserModule.maps.id.get(by)[0];
  }

  get isAcknowledged() {
    return Boolean(this.selectedEvent.ack);
  }

  get debug() {
    return ConfigModule.debug;
  }

  get location() {
    return this.selectedEvent.location || "";
  }

  get description() {
    return this.selectedEvent.description || "";
  }

  get end() {
    return this.selectedEvent.end ? `- ${simpleDate(this.selectedEvent.end.toString())}` : "";
  }

  get recurrenceDescription() {
    if (this.selectedEvent.rrule) {
      return RRule.fromString(this.selectedEvent.rrule).toText();
    }

    return "";
  }

  get isRecurring() {
    return !!this.selectedEvent.rrule;
  }

  deleteEvent() {
    this.$log.debug("deleteEvent", this.selectedEvent);
    this.$emit("delete", this.selectedEvent);
  }

  updateEvent() {
    this.$log.debug("updateEvent", this.selectedEvent);

    this.$emit("update", this.selectedEvent);
  }

  openEvent() {
    this.$log.debug("openEvent", this.selectedEvent);

    this.$emit("open", this.selectedEvent);
  }
}
