























import { Component, Vue, Prop } from "vue-property-decorator";
import { IListItem } from "@/lib/interfaces/list-item.interface";

@Component({})
export default class LatestEntriesCardList extends Vue {
  @Prop()
  inputs!: IListItem[];

  click(data: IListItem) {
    this.$emit("click", data);
  }
}
