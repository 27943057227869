









































































































































































































import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { RefTypeMap } from "@/store/modules/refs.store";
import { Component, Vue } from "vue-property-decorator";
import RefsAttachmentRequest from "./RefsAttachmentRequest.vue";
import RefsCompany from "./RefsCompany.vue";
import RefsCompanyAddress from "./RefsCompanyAddress.vue";
import RefsCompanyGroup from "./RefsCompanyGroup.vue";
import RefsContract from "./RefsContract.vue";
import RefsCost from "./RefsCost.vue";
import RefsCostGroup from "./RefsCostGroup.vue";
import RefsDocument from "./RefsDocument.vue";
import RefsEvent from "./RefsEvent.vue";
import RefsFleet from "./RefsFleet.vue";
import RefsGroup from "./RefsGroup.vue";
import RefsHandover from "./RefsHandover.vue";
import RefsMessage from "./RefsMessage.vue";
import RefsPerson from "./RefsPerson.vue";
import RefsPersonEmail from "./RefsPersonEmail.vue";
import RefsPersonGroup from "./RefsPersonGroup.vue";
import RefsPersonPhone from "./RefsPersonPhone.vue";
import RefsReport from "./RefsReport.vue";
import RefsSignRequest from "./RefsSignRequest.vue";
import RefsSnapshot from "./RefsSnapshot.vue";
import RefsTicket from "./RefsTicket.vue";
import RefsVehicle from "./RefsVehicle.vue";

@Component({
  components: {
    RefsReport,
    RefsVehicle,
    RefsEvent,
    RefsTicket,
    RefsDocument,
    RefsCost,
    RefsSignRequest,
    RefsAttachmentRequest,
    RefsCostGroup,
    RefsSnapshot,
    RefsHandover,
    RefsMessage,
    RefsCompany,
    RefsCompanyGroup,
    RefsPerson,
    RefsPersonGroup,
    RefsPersonPhone,
    RefsPersonEmail,
    RefsCompanyAddress,
    RefsGroup,
    RefsContract,
    RefsFleet
  }
})
export default class RefsDebugger extends Vue {
  isLoading = false;
  isSmall = false;
  isClick = false;
  isOpenDetail = false;

  isDisplaySubheader = true;

  resources = this.resourceItems;

  get ResourceEnum() {
    return BackendResourceEnum;
  }

  /**
   * Get all refs with store
   */
  get resourceItems() {
    return Array.from(RefTypeMap.keys()).filter(k => !!RefTypeMap.get(k)?.module);
  }

  /**
   * List the first item by RefTypeMap keys, filtered by empty, and by selected.
   */
  get allRefs() {
    return this.resourceItems
      .filter(k => this.resources.includes(k))
      .map(k => {
        const m = RefTypeMap.get(k);

        if (m?.module) {
          return {
            refType: k,
            item: m.module.entities[0]
          };
        } else {
          return {
            refType: "",
            item: {}
          };
        }
      });
  }

  get partner() {
    return PartnerModule.partner;
  }

  /**
   * Fetches from all ref stores the first page to provide data
   */
  async mounted() {
    this.isLoading = true;

    await Promise.all(
      this.resourceItems.map(async k => {
        const v = RefTypeMap.get(k);
        if (v?.module?.fetchFirstPage) {
          await v.module.fetchFirstPage({ partnerId: this.partner.id }).catch(e => this.$log.error(e));
        }
      })
    ).finally(() => (this.isLoading = false));
  }
}
