var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("confirm-action-dialog", {
    attrs: {
      hideClose: true,
      isDialogActive: _vm.isDialogActive,
      rightText: _vm.$t(
        "components.partner.PartnerUserMissingPermissionsDialog.login"
      ),
      leftText: _vm.$t("back"),
      title: _vm.$t(
        "components.partner.PartnerUserMissingPermissionsDialog.title"
      ),
      subtitle: _vm.$t(
        "components.partner.PartnerUserMissingPermissionsDialog.subtitle"
      ),
      rightLoading: _vm.isLogoutLoading
    },
    on: {
      rightClick: _vm.goToLoginPage,
      leftClick: function leftClick() {
        return (_vm.isDialogActive = false)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }