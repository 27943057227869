

















import PartnerBankingDetailForm from "@/components/partner-banking/PartnerBankingDetailForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IPartnerBanking, PartnerBanking } from "@/models/partner-banking.entity";
import { PartnerBankingModule } from "@/store/modules/partner-banking.store";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { PartnerBankingGoToHelper } from "@/lib/utility/partner-banking.go-to-helper";

@Component({
  components: {
    TheLayoutPortal,
    PartnerBankingDetailForm,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerBankingDetailFormView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partnerBankingId(): string {
    return this.$route.params.partnerBankingId;
  }

  isLoading = false;

  partnerBanking: IPartnerBanking | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const partnerBanking =
      PartnerBankingModule.maps.id.get(this.partnerBankingId)[0] ??
      new PartnerBanking({
        id: this.partnerBankingId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await partnerBanking.fetch();
      this.partnerBanking = partnerBanking;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToPartnerBankingGroup() {
    new PartnerBankingGoToHelper(this.$router).goToPartnerBankingTable({
      partnerId: this.partnerId
    });
  }
}
