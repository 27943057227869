













import PartnerUserDetail from "@/components/partner-user/PartnerUserDetail.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IPartnerUser, PartnerUser } from "@/models/user.entity";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    PartnerUserDetail,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerUserDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partnerUserId(): string {
    return this.$route.params.partnerUserId;
  }

  isLoading = false;

  partnerUser: IPartnerUser | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const partnerUser =
      PartnerUserModule.maps.id.get(this.partnerUserId)[0] ??
      new PartnerUser({
        id: this.partnerUserId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await partnerUser.fetch();
      this.partnerUser = partnerUser;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
