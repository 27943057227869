var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("table-wrapper", {
    attrs: {
      allItems: _vm.snapshots,
      headers: _vm.headers,
      hideTableFooter: false
    },
    on: {
      updateSearch: function updateSearch($event) {
        _vm.search = $event
      },
      "click:row": function clickRow($event) {
        return _vm.$emit("click:row", $event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item.status",
        fn: function fn(_ref) {
          var item = _ref.item
          return [
            _c(
              "v-chip",
              {
                staticClass: "mr-1",
                attrs: {
                  small: "",
                  color: _vm.colorByStatus.get(item.status)
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "sign.DigitalSignatureRequestStatus.".concat(
                          item.status
                        )
                      )
                    ) +
                    " "
                )
              ]
            )
          ]
        }
      },
      {
        key: "item.userId",
        fn: function fn(_ref2) {
          var item = _ref2.item
          return [
            _c("assignee-preview", {
              attrs: {
                value: [item.userId]
              }
            })
          ]
        }
      },
      {
        key: "item.timestamp.created",
        fn: function fn(_ref3) {
          var item = _ref3.item
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("simpleDoubleDigitDate")(item.timestamp.created)
                ) +
                " "
            )
          ]
        }
      },
      {
        key: "item.timestamp.lastModified",
        fn: function fn(_ref4) {
          var item = _ref4.item
          return [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("simpleDoubleDigitDate")(item.timestamp.lastModified)
                ) +
                " "
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }