






































import FleetVehicleCrudMixin from "@/components/fleet/FleetVehicleCrudMixin.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailTable from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import UpdateForm from "@/components/utility/UpdateForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { VehicleDetailActions } from "../FleetVehicleDetailView.vue";

@Component({
  components: {
    TheLayoutPortal,
    MHeader: MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    ConfirmActionDialog,
    UpdateForm
  }
})
export default class VehicleDetailView extends mixins(FleetVehicleCrudMixin, PartnerFallbackMixin) {
  readonly formable = Vehicle;
  loading = false;
  isFormShown = true;
  DETAIL = "detail";

  isDeleteDialogActive = false;

  updateKey = 0;

  get vehicleId() {
    return this.$route.params.vehicleId;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get vehicle() {
    if (!this.vehicleAggregation?.vehicle) {
      return undefined;
    }

    return new Vehicle(this.vehicleAggregation.vehicle);
  }

  get chips(): IAction[] {
    return [];
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("project.ticket.actions.toOverview"),
        key: this.DETAIL,
        icon: "mdi-open-in-new"
      },
      {
        text: $t("views.fleet.actions.deleteVehicle"),
        key: VehicleDetailActions.DELETE,
        icon: "mdi-delete-outline",
        color: "red"
      }
    ];
  }

  get breadCrumbs() {
    return [
      {
        text: $t("navCategory.fleet"),
        exact: true,
        disabled: false,
        to: {
          name: "FleetHome",
          params: {
            partnerId: this.partnerId
          }
        }
      },
      {
        text: this.vehicle?.displayName,
        exact: true,
        disabled: false,
        to: {
          name: "FleetVehicleDetail",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicleId
          }
        }
      },
      {
        text: "Details",
        exact: true,
        disabled: true,
        to: {
          name: "VehicleDetailView",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicleId
          }
        }
      }
    ];
  }

  get required() {
    return [requiredRule()];
  }

  async mounted() {
    this.loading = true;

    await this.trySetByRouteOrDefault();

    await this.loadVehicle();

    this.loading = false;
  }

  onInput(vehicle: IVehicle) {
    this.vehicle?.map(vehicle);
    this.updateKey++;
  }

  processAction(action: IAction) {
    switch (action.key) {
      case VehicleDetailActions.DELETE:
        this.isDeleteDialogActive = true;
        break;
      case this.DETAIL:
        new GoToHelper(this.$router).goToVehicleDetail(this.$route.params.vehicleId, this.partnerId);
        break;
    }
  }

  confirmDeletion() {
    this.isDeleteDialogActive = false;
    this.deleteVehicle();
  }
}
