import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { IGroupType } from "./interface/group-type.interface";
import ContractCreateDialog from "@/components/contract/ContractCreateDialog.vue";
import { IGroupTypeEntityCreateDialog } from "@/lib/utility/group/interface/group-type-entity-create-dialog.interface";
import ContractSideCard from "@/components/contract/ContractSideCard.vue";
import { IGroupTypeEntitySideCard } from "./interface/group-type-entity-side-card.interface";

export const GroupTypeComponentMap: Map<
  IGroupType,
  { createDialog: IGroupTypeEntityCreateDialog; sideCard: IGroupTypeEntitySideCard }
> = new Map([
  [
    ResourceEnum.CONTRACT,
    {
      createDialog: (ContractCreateDialog as unknown) as IGroupTypeEntityCreateDialog,
      sideCard: (ContractSideCard as unknown) as IGroupTypeEntitySideCard
    }
  ]
]);
