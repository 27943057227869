













import MDetailTableFormable from "@/components/utility/mmmint/MDetailTableFormable.vue";
import { PartnerBanking, IPartnerBanking } from "@/models/partner-banking.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    MDetailTableFormable
  },
  filters: {}
})
export default class PartnerBankingDetailTable extends mixins(Vue) {
  readonly formable = PartnerBanking;
  readonly ResourceEnum = ResourceEnum;

  @Prop()
  value!: IPartnerBanking;

  @Prop()
  onDetail?: () => void;
}
