import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import fleetService from "@/services/mrfiktiv/services/fleetService";
import {
  MrfiktivCreateFleetDtoGen,
  MrfiktivFleetViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivSimpleFleetViewModelGen,
  MrfiktivUpdateFleetDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { FleetDataAccessLayer } from "@/store/modules/access-layers/fleet.access-layer";
import { CompanyModule } from "@/store/modules/company.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { VehicleReference } from "./vehicle-reference.entity";

export type FleetNode = {
  id: string;
  name: string;
  children: FleetNode[];
};

export function buildFleetTree(fleets: MrfiktivSimpleFleetViewModelGen[]): FleetNode[] {
  const idToNodeMap: Record<string, FleetNode> = {};

  for (const fleet of fleets) {
    idToNodeMap[fleet.id] = {
      id: fleet.id,
      name: fleet.title,
      children: []
    };
  }

  const roots: FleetNode[] = [];

  for (const fleet of fleets) {
    const node = idToNodeMap[fleet.id];

    if (fleet.parentId === undefined) {
      roots.push(node);
    } else {
      const parent = idToNodeMap[fleet.parentId];
      parent.children.push(node);
    }
  }

  return roots;
}

@IsFilterable
export class FleetBase implements MrfiktivSimpleFleetViewModelGen, ICreateDto<IFleet> {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.fleet.id",
    config: {
      itemCallback: () => FleetDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-fleet"
    }
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.fleet.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.fleet.title"
  })
  title: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    displayName: "objects.fleet.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees: string[];

  @FilterConfig({
    type: VehicleReference
  })
  refs?: MrfiktivReferenceGen[] | undefined;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.company",
    config: {
      itemCallback: () => CompanyModule.filteredAndSorted,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-company"
    }
  })
  companyId?: string | undefined;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.fleet.isRoot"
  })
  isRoot: boolean;

  // TODO: IMPLEMENT THIS
  rootId?: string | undefined;
  parentId?: string | undefined;
  ancestorIds: string[];

  /**
   * Construct object
   */
  constructor(obj?: Partial<FleetBase | MrfiktivFleetViewModelGen | MrfiktivSimpleFleetViewModelGen>) {
    this.id = obj?.id ?? "";
    this.partnerId = obj?.partnerId ?? "";
    this.userId = obj?.userId ?? "";

    this.isRoot = obj?.isRoot ?? false;
    this.ancestorIds = obj?.ancestorIds ?? [];
    this.parentId = obj?.parentId;

    this.companyId = obj?.companyId;

    this.title = obj?.title ?? "";

    this.assignees = obj?.assignees || [];
    this.refs = obj?.refs || [];

    this.timestamp = new Timestamp(obj?.timestamp);
  }

  /**
   * fetch object
   */
  async fetch(): Promise<this> {
    const res = await fleetService.findOne(this.partnerId, this.id);

    this.map(res);
    FleetDataAccessLayer.set(this);

    return this;
  }

  /**
   * map props from viewmodel to this
   */
  protected map(obj?: MrfiktivFleetViewModelGen | MrfiktivSimpleFleetViewModelGen) {
    if (!obj) return;
    this.id = obj.id;

    this.partnerId = obj.partnerId;

    this.title = obj.title;

    this.assignees = obj?.assignees || [];

    this.timestamp = new Timestamp(obj.timestamp);
  }

  /**
   * create object
   */
  async create() {
    const data: MrfiktivCreateFleetDtoGen = {
      title: this.title,
      assignees: this.assignees
    };
    const res = await fleetService.create(this.partnerId, data);

    this.map(res);

    FleetDataAccessLayer.set(this);

    return this;
  }

  /**
   * delete object
   */
  async delete() {
    const res = await fleetService.remove(this.partnerId, this.id);

    this.map(res);
    FleetDataAccessLayer.delete(this);
  }

  /**
   * update object
   * @returns
   */
  async update() {
    const data: MrfiktivUpdateFleetDtoGen = {
      title: this.title,
      assignees: this.assignees
    };
    const res = await fleetService.update(this.partnerId, this.id, data);
    this.map(res);
    FleetDataAccessLayer.set(this);

    return this;
  }

  /**
   * update object via dto
   * @param dto
   * @returns
   */
  async updatePartial(dto: MrfiktivUpdateFleetDtoGen) {
    const res = await fleetService.update(this.partnerId, this.id, dto);

    this.map(res);

    FleetDataAccessLayer.set(this);

    return this;
  }
}

type IFleet = FleetBase;
const Fleet = Filter.createForClass(FleetBase);

export { Fleet, IFleet };
