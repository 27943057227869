



















































import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CustomFieldConfigurationForm,
    CustomFieldValueChip
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyGroupCreateDialog extends mixins(PartnerFallbackMixin, PermissionMixin) implements IDialog {
  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  isDialogActive = false;

  isLoading = false;

  companyGroup: ICompanyGroup = new CompanyGroup({});

  get requiredRule() {
    return [requiredRule()];
  }

  open() {
    this.companyGroup = new CompanyGroup({ partnerId: this.partnerId });
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.titleField as HTMLInputElement)?.focus();
    });
  }

  async onRightClick() {
    try {
      this.isDialogActive = true;
      await this.companyGroup.create();
      this.$emit("created", this.companyGroup);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
    }
  }

  onConfirm(item: MrfiktivReferenceGen) {
    this.$log.debug("onConfirm", item);
  }

  close(): void {
    this.isDialogActive = false;
  }
}
