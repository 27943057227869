














































































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IGroup } from "@/models/group.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { GroupModule } from "@/store/modules/group.store";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import { ITableWrapperHeader, IControlElements } from "../utility/TableWrapper.vue";
import GroupTypeCreateDialog from "./GroupTypeCreateDialog.vue";
import RefsGroup from "../utility/RefsGroup.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    GroupTypeCreateDialog,
    RefsGroup
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class GroupTypeTable extends mixins(PermissionMixin) {
  readonly store = GroupModule;

  @Prop()
  partnerId!: string;

  @Prop()
  type!: IGroupType;

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  selectedItem: IGroup | null = null;

  selectedItemForDelete: IGroup | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    if (!GroupModule.entities.length) return [];

    return [
      {
        text: $t("objects.group.title"),
        value: "title",
        align: "start"
      },
      {
        text: $t("project.project.created"),
        value: "timestamp.created",
        width: "110"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.goToGroupCustomView
      },
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: this.setSelectedItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  beforeMount() {
    const typeFilter = new PageFilterElement({
      key: "type",
      operation: PageFilterOperationEnum.EQUAL,
      value: this.type
    });

    this.store.setFilters([...this.filters, typeFilter]);
  }

  beforeDestroy() {
    this.store.setFilters([]);
  }

  goToGroupCustomView(item: IGroup) {
    item.goTo(this.$router).customView();
  }

  setSelectedItem(item: IGroup | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  onDeleteItem(item: IGroup) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }
}
