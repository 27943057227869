





































import BookingSideCard from "@/components/booking/BookingSideCard.vue";
import PartnerBookingCalendar from "@/components/booking/PartnerBookingCalendar.vue";
import { BookingGoToActionEmitData, BookingMDetailActionEnum } from "@/components/booking/portal-detail-card/ui-types";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IBooking } from "@/models/booking.entity";
import { BookingBookingViewModelGen } from "@/services/booking/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { BookingModule } from "@/store/modules/booking.store";
import { UserModule } from "@/store/modules/me-user.store";
import { ResourceModule } from "@/store/modules/resource.store";
import { ServiceModule } from "@/store/modules/service.store";
import { Component } from "vue-property-decorator";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { Service } from "@/models/service.entity";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import { $t } from "@/lib/utility/t";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    TheLayoutPortal,
    PartnerBookingCalendar,
    BookingSideCard,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate,
    formatHoursAndMinutes
  }
})
export default class BookingView extends PartnerFallbackMixin {
  isLoading = false;

  search = "";
  itemsPerPage = 25;
  selected: BookingBookingViewModelGen[] = [];

  selectedBooking: IBooking | null = null;

  tab = 0;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get partner() {
    return PartnerModule.partner;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];
    actions.push({
      text: $t("views.booking.BookingView.goToBookingTable"),
      key: "detail",
      exec: () => new GoToHelper(this.$router).goToBookingTable(this.partnerId, true)
    });

    actions.push({
      text: $t("components.booking.PartnerBookingCalendar.settings"),
      key: "settings",
      exec: () => new GoToHelper(this.$router).goToBookingSetting()
    });

    actions.push({
      text: $t("components.booking.PartnerBookingCalendar.bookingLink"),
      key: "bookingLink",
      exec: () => {
        const url = `https://${this.partner?.settings?.defaultUrl}/booking.html`;
        window.open(url, "_blank");
      }
    });

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    return breadCrumbs;
  }

  get chips() {
    const chips: IAction[] = [];

    // active services
    chips.push({
      text: `${ServiceModule.activeItems.length} ${$t("views.booking.BookingView.service")}`,
      key: "numberOfServices",
      disabled: true
    });

    // active resources
    chips.push({
      text: `${ResourceModule.activeItems.length} ${$t("views.booking.BookingView.resource")}`,
      key: "numberOfResources",
      disabled: true
    });

    return chips;
  }

  onCloseSideCard() {
    this.selectedBooking = null;
  }

  /**
   * After delete clean up
   */
  async onDeleteBooking() {
    this.onCloseSideCard();
  }

  get i18n() {
    return this.$t("views.booking.BookingView");
  }

  get items() {
    const items = ["calendar", "table"];

    if (UserModule.abilities.can(ActionEnum.MANAGE, ResourceEnum.BOOKING)) {
      items.push("settings");
    }

    return items;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get bookings(): IBooking[] {
    return BookingModule.paginationList;
  }

  get bookingDto() {
    return this.selectedBooking;
  }

  get services() {
    return ServiceModule.maps.id;
  }

  get resources() {
    return ResourceModule.maps.id;
  }

  get resourceList() {
    return ResourceModule.entities;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get serviceName() {
    if (!this.selectedBooking || !this.selectedBooking.serviceId) {
      return Service.SERVICE_INTERNAL_NAME;
    }

    const service = ServiceModule.maps.id.get(this.selectedBooking.serviceId)[0];

    return service.name || "";
  }

  get service() {
    if (!this.selectedBooking || !this.selectedBooking.serviceId) {
      return undefined;
    }

    const service = ServiceModule.maps.id.get(this.selectedBooking.serviceId)[0];

    return service;
  }

  get resourceName() {
    const resource = ResourceModule.maps.id.get(this.selectedBooking?.resourceId ?? "")[0];

    return resource.name ?? "";
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      ServiceModule.setHiddenFilters([]);
      const bookingPromise = BookingModule.fetchAll({ partnerId: this.partnerId });
      const servicePromise = ServiceModule.fetchAll({ partnerId: this.partnerId });
      const resourcePromise = ResourceModule.fetchAll({ partnerId: this.partnerId });

      await Promise.all([bookingPromise, servicePromise, resourcePromise]);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async processGoToAction(data: BookingGoToActionEmitData) {
    const action = data.action;

    switch (action) {
      case BookingMDetailActionEnum.GO_TO_EDIT:
        await new GoToHelper(this.$router).goToBookingEdit(this.partnerId, data.booking.id);
        break;
      case BookingMDetailActionEnum.GO_TO_RESOURCE:
        await new GoToHelper(this.$router).goToResourceDetail(this.partnerId, data.booking.resourceId, true);
        break;
      case BookingMDetailActionEnum.GO_TO_SERVICE:
        await new GoToHelper(this.$router).goToServiceDetail(this.partnerId, data.booking.serviceId || "", true);
        break;
      case BookingMDetailActionEnum.GO_TO_OVERVIEW:
      case BookingMDetailActionEnum.OPEN_IN_NEW:
        await new GoToHelper(this.$router).goToBookingDetail(this.partnerId, data.booking.id);
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  }
}
