import { GroupRouteNames } from "@/lib/utility/group.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import GroupTypeCustomViewView from "@/views/group/GroupTypeCustomViewView.vue";
import GroupTypeDetailView from "@/views/group/GroupTypeDetailView.vue";
import GroupTypeTableView from "@/views/group/GroupTypeTableView.vue";

/**
 * Routes for groups and tickets
 */
export const groupRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/type/:type/group",
    component: GroupTypeTableView,
    name: GroupRouteNames.GROUP_RESOURCE_TABLE,
    permission: {
      resource: ResourceEnum.GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/type/:type/group/:groupId",
    component: GroupTypeDetailView,
    name: GroupRouteNames.GROUP_RESOURCE_DETAIL + "Home",
    permission: {
      resource: ResourceEnum.GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/type/:type/group/:groupId/settings",
    component: GroupTypeDetailView,
    name: GroupRouteNames.GROUP_RESOURCE_DETAIL,
    permission: {
      resource: ResourceEnum.GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/type/:type/group/:groupId/view/:viewId",
    component: GroupTypeCustomViewView,
    name: GroupRouteNames.GROUP_RESOURCE_CUSTOM_VIEW,
    permission: {
      resource: ResourceEnum.GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
