






































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomViews from "@/components/cost/CustomViews.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PersonGroup, IPersonGroup } from "@/models/person-group.entity";
import { IPerson, Person } from "@/models/person.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { PersonModule } from "@/store/modules/person.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import PersonSideCard from "@/components/person/PersonSideCard.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import PersonCreateDialog from "@/components/person/PersonCreateDialog.vue";
import { PersonLabelEnum } from "@/lib/enum/person-label.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { simpleDoubleDigitDate, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { MrfiktivPersonControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import RefsPersonGroup from "@/components/utility/RefsPersonGroup.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import { getNestedObjectValues, propertiesToArray } from "@/lib/objectPath-helper";

@Component({
  components: {
    MHeader,
    CustomViews,
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    PersonSideCard,
    RefsVehicle,
    PersonCreateDialog,
    AssigneePreview,
    RefsPersonGroup
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonGroupCustomViewView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Ref("personDialog")
  personDialog!: PersonCreateDialog;

  @Ref("customViews")
  customViews!: CustomViews<IPerson, MrfiktivPersonControllerGetParamsGen>;

  readonly store = PersonModule;

  readonly BackendResourceEnum = BackendResourceEnum;

  readonly PersonLabelEnum = PersonLabelEnum;

  readonly getNestedObjectValues = getNestedObjectValues;

  readonly propertiesToArray = propertiesToArray;

  isLoading = false;

  personGroup: IPersonGroup | null = null;

  viewId = "0";

  partnerId = this.$route.params.partnerId;

  personGroupId = this.$route.params.personGroupId;

  selectedItem: IPerson | null = null;

  tableBaseConfig = {
    values: [
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "personName"
      }
    ],
    filters: []
  };

  calendarBaseConfig = {
    calendarStart: {
      type: ProjectCustomViewFieldEnum.PROPERTY,
      key: "date"
    },
    filters: []
  };

  boardBaseConfig = {
    filters: []
  };

  get viewIdLocal() {
    return this.viewId;
  }

  set viewIdLocal(value: string) {
    this.viewId = value;

    this.closeSideCard();
  }

  get breadCrumbList(): IBreadcrumb[] {
    const breadCrumbs = PersonGoToHelper.breadCrumbs;
    const crumbs: IBreadcrumb[] = [breadCrumbs.PersonGroupTable(this.partnerId)];
    if (this.personGroup?.id) {
      crumbs.push(breadCrumbs.PersonGroupCustomView(this.partnerId, this.personGroup.id));
    }

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    return chips;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.canCreatePerson) {
      actions.push({
        text: $t("person.createPerson"),
        key: "createPerson",
        exec: () => this.personDialog.open()
      });
    }

    if (this.personGroup?.configuration.views[Number(this.viewIdLocal)]?.type === ProjectCustomViewTypeEnum.TABLE) {
      actions.push({
        text: $t("common.verbs.download").toString(),
        key: "download",
        exec: () => this.customViews.downloadToCsv()
      });
    }

    actions.push({
      icon: "mdi-pencil",
      text: $t("project.ticket.settings"),
      key: "edit",
      exec: this.goToSettings
    });

    return actions;
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.personGroupId = this.$route.params.personGroupId;
    this.viewId = this.$route.params.viewId ?? "0";
  }

  async mounted() {
    this.isLoading = true;
    try {
      const partnerAsync = this.trySetByRouteOrDefault();
      const personGroupAsync = (
        PersonGroupModule.maps.id.get(this.personGroupId)[0] ??
        new PersonGroup({
          id: this.personGroupId,
          partnerId: this.partnerId
        })
      )
        .fetch()
        .then(personGroup => (this.personGroup = personGroup));

      await Promise.all([partnerAsync, personGroupAsync]);

      this.store.setHiddenFilter([
        new PageFilterElement({ key: "groupId", operation: "$eq", value: this.personGroupId })
      ]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    const personId = this.$route.query.personId as string;
    if (personId) {
      const person = new Person({ partnerId: this.partnerId, id: personId });
      this.openSideCard(person);
    }
  }

  goToSettings() {
    new PersonGoToHelper(this.$router).goToPersonGroupDetail({
      partnerId: this.partnerId,
      personGroupId: this.personGroupId
    });
  }

  async getPersonGroup(id: string) {
    if (!id) return;
    let personGroup = PersonGroupModule.maps.id.get(id)[0];
    if (!personGroup) {
      personGroup = new PersonGroup({ partnerId: this.partnerId, id });
      personGroup.fetch();
    }

    return personGroup;
  }

  goToPersonGroupDetail(personGroupId: string) {
    new PersonGoToHelper(this.$router).goToPersonGroupDetail({
      partnerId: this.partnerId,
      personGroupId: personGroupId
    });
  }

  createPerson() {
    this.personDialog?.open();
  }

  // identify strings formatted as NNNN-NN-NNTNN:NN
  isDayTimeString(value: string) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
  }

  getDateTime(value: string) {
    return simpleDoubleDigitDate(value) + " " + formatHoursAndMinutes(new Date(value));
  }

  goToVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.HOME, true);
  }

  closeSideCard() {
    this.selectedItem = null;

    new PersonGoToHelper(this.$router).setUrl(
      PersonGoToHelper.locations.personGroupCustomView({
        partnerId: this.partnerId,
        personGroupId: this.personGroupId,
        viewId: this.viewIdLocal || "0"
      })
    );
  }

  openSideCard(person: IPerson) {
    this.selectedItem = null;
    this.$nextTick(() => {
      this.selectedItem = person;
      new PersonGoToHelper(this.$router).setUrl(
        PersonGoToHelper.locations.personGroupCustomView({
          partnerId: this.partnerId,
          personGroupId: this.personGroupId,
          viewId: this.viewIdLocal || "0",
          query: person ? { personId: person.id } : {}
        })
      );
    });
  }

  toArray<T>(value: T | T[]): T[] {
    if (!value) {
      return [];
    }

    if (!Array.isArray(value)) {
      return [value];
    }

    return value;
  }
}
