





























































import { IVehicle } from "@/models/vehicle.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteDialog from "../dialog/DeleteDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import LabelComponent from "../utility/LabelComponent.vue";
import FleetVehicleDetailContextMenu from "./FleetVehicleDetailContextMenu.vue";
import { FleetModule } from "@/store/modules/fleet.store";

@Component({
  components: {
    ContextMenu,
    DeleteDialog,
    FleetVehicleDetailContextMenu,
    LabelComponent
  }
})
export default class FleetVehicleFleetSelect extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "views.fleet.FleetVehicleDriverDetailTable.header" })
  title!: string;

  showActions = false;

  currentMenu = false;
  tmp: string | undefined = "";

  temporaryDriverList: string[] = [];

  goToDrivers() {
    this.$router.push({ name: "FleetTableView", params: { partnerId: this.partnerId } });
  }

  goToFleet() {
    if (!this.vehicle.groupId) {
      return;
    }

    this.$router.push({
      name: "FleetDetailView",
      params: { partnerId: this.partnerId, fleetId: this.vehicle.groupId }
    });
  }

  openCurrentMenu() {
    this.tmp = this.vehicle.groupId;
    this.currentMenu = true;
  }

  closeMenu() {
    this.currentMenu = false;
  }

  get current() {
    return this.vehicle.groupId;
  }

  get currentName() {
    if (!this.current) {
      return "";
    }

    const fleet = FleetModule.maps.id.get(this.current);

    if (fleet[0]) {
      return fleet[0].title;
    }

    return "";
  }

  get fleets() {
    return FleetModule.entities || [];
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleFleetSelect") || {};
  }

  update() {
    this.vehicle.groupId = this.tmp;
    this.$emit("change", this.vehicle);
    this.closeMenu();
  }

  updateEmpty() {
    this.vehicle.groupId = "";
    this.$emit("change", this.vehicle);
    this.closeMenu();
  }

  get partnerId() {
    return PartnerModule?.partner?.id || PartnerModule?.partner?._id;
  }

  refreshFleet() {
    FleetModule.fetchNextPage({ partnerId: this.partnerId });
  }
}
