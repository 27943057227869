













import { IDialog } from "@/lib/interfaces/dialog.interface";
import { GroupTypeCreateDialogs } from "@/lib/utility/group/group-type-components-create-dialogs.const";
import { IGroupTypeEntityCreateDialog } from "@/lib/utility/group/interface/group-type-entity-create-dialog.interface";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: GroupTypeCreateDialogs as any,
  filters: {}
})
export default class GroupTypeEntityCreateDialog extends mixins(PermissionMixin)
  implements IGroupTypeEntityCreateDialog {
  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  @Prop()
  type!: IGroupType;

  @Prop()
  groupId?: string;

  get component() {
    return `${this.type}-create-dialog`;
  }

  open() {
    ((this.$refs[this.component] as any) as IDialog)?.open();
  }

  close() {
    ((this.$refs[this.component] as any) as IDialog)?.close();
  }
}
