var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.loading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            ],
            1
          )
        : _vm.vehicleAggregation
        ? _c(
            "v-container",
            [
              _vm.vehicleAggregation
                ? _c("m-header", {
                    attrs: {
                      title: _vm.vehicleAggregation.vehicle.displayName,
                      subtitle: ""
                        .concat(
                          _vm.vehicleAggregation.vehicle.numberplate,
                          " ("
                        )
                        .concat(
                          _vm.vehicleAggregation.vehicle.identificationnumber,
                          ")"
                        ),
                      chips: _vm.chips,
                      breadCrumbs: _vm.breadCrumbs,
                      actions: _vm.actions
                    },
                    on: {
                      actionClicked: _vm.processAction
                    }
                  })
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _vm.vehicleAggregation && _vm.vehicleAggregation.vehicle
                        ? [
                            _c(
                              "v-tabs",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function callback($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab"
                                }
                              },
                              [
                                _c("v-tabs-slider", {
                                  attrs: {
                                    color: "yellow"
                                  }
                                }),
                                _vm._l(_vm.tabs, function(item) {
                                  return _c(
                                    "v-tab",
                                    {
                                      key: item
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.fleet.FleetVehicleDetailView.".concat(
                                                item
                                              )
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _c("v-divider"),
                            _c(
                              "v-tabs-items",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function callback($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab"
                                }
                              },
                              [
                                _c(
                                  "v-tab-item",
                                  [
                                    _c(
                                      "m-detail-view-grid",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "details",
                                              fn: function fn() {
                                                return [
                                                  _c(
                                                    "Can",
                                                    {
                                                      attrs: {
                                                        I: _vm.ActionEnum.READ,
                                                        a:
                                                          _vm.ResourceEnum
                                                            .DRIVER,
                                                        field: _vm.partnerId
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "fleet-vehicle-driver-detail-table",
                                                        {
                                                          attrs: {
                                                            title: "",
                                                            vehicle:
                                                              _vm
                                                                .vehicleAggregation
                                                                .vehicle,
                                                            loading: _vm.loading
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.updateVehicle
                                                          }
                                                        }
                                                      ),
                                                      _c("v-divider", {})
                                                    ],
                                                    1
                                                  ),
                                                  _c("m-detail-table", {
                                                    attrs: {
                                                      i18nPath:
                                                        "components.fleet.FleetVehicleDetailVehicleCard.table",
                                                      item: _vm.vehicle,
                                                      showMoreAction:
                                                        _vm.tableShowMoreAction,
                                                      config:
                                                        _vm.detailTableConfig,
                                                      customFieldValues:
                                                        _vm.customFieldsForm
                                                    },
                                                    on: {
                                                      actionClicked:
                                                        _vm.processAction
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "actions",
                                              fn: function fn() {
                                                return [
                                                  _c("m-action-list", {
                                                    attrs: {
                                                      divider: true,
                                                      actions: _vm.actions
                                                    },
                                                    on: {
                                                      actionClicked:
                                                        _vm.processAction
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          3732840945
                                        )
                                      },
                                      [
                                        _c("fleet-vehicle-detail-home-card", {
                                          attrs: {
                                            vehicleAggregation:
                                              _vm.vehicleAggregation,
                                            loading: _vm.loadingUpdate
                                          },
                                          on: {
                                            change: _vm.updateVehicle,
                                            goTo: _vm.setTab
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.MILEAGE,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c(
                                          "fleet-vehicle-detail-vehicle-mileage-data-card",
                                          {
                                            attrs: {
                                              vehicle:
                                                _vm.vehicleAggregation.vehicle
                                            },
                                            on: {
                                              change: _vm.updateVehicle
                                            }
                                          }
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.REPORT,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _vm.vehicleAggregation.vehicle
                                          ? _c(
                                              "v-card",
                                              {
                                                attrs: {
                                                  flat: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "fleet-vehicle-detail-damage-card",
                                                  {
                                                    attrs: {
                                                      damages: _vm.damages,
                                                      vehicle:
                                                        _vm.vehicleAggregation
                                                          .vehicle
                                                    },
                                                    on: {
                                                      openReportSideCard:
                                                        _vm.openReportSideCard
                                                    }
                                                  }
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.EVENT,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c("fleet-vehicle-detail-event-card", {
                                          attrs: {
                                            vehicleAggregation:
                                              _vm.vehicleAggregation
                                          },
                                          on: {
                                            openTicketSideCard:
                                              _vm.openTicketSideCard,
                                            openReportSideCard:
                                              _vm.openReportSideCard,
                                            openContractDetail:
                                              _vm.openContractDetail,
                                            openEventSideCard:
                                              _vm.openEventSideCard
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.TICKET,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c("fleet-vehicle-detail-ticket-card", {
                                          attrs: {
                                            vehicle:
                                              _vm.vehicleAggregation.vehicle
                                          },
                                          on: {
                                            change: _vm.updateVehicle,
                                            refresh: _vm.loadVehicle
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.COST,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c("fleet-cost-table", {
                                          attrs: {
                                            filters: [
                                              _vm.vehicleReferenceFilter
                                            ]
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.CONTRACT,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _vm.vehicleAggregation
                                          ? _c(
                                              "fleet-vehicle-detail-contract-table",
                                              {
                                                attrs: {
                                                  vehicle:
                                                    _vm.vehicleAggregation
                                                      .vehicle
                                                },
                                                on: {
                                                  change: _vm.updateVehicle,
                                                  refresh: _vm.loadVehicle
                                                }
                                              }
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.DOCUMENT,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c(
                                          "fleet-vehicle-detail-document-card",
                                          {
                                            attrs: {
                                              vehicle:
                                                _vm.vehicleAggregation.vehicle
                                            },
                                            on: {
                                              change: _vm.updateVehicle,
                                              refresh: _vm.loadVehicle
                                            }
                                          }
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.BLUEPRINT,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c(
                                          "fleet-vehicle-detail-vehicle-blueprint-table-card",
                                          {
                                            attrs: {
                                              vehicle: _vm.vehicle
                                            },
                                            on: {
                                              "update:vehicle": function updateVehicle(
                                                $event
                                              ) {
                                                _vm.vehicle = $event
                                              },
                                              change: _vm.updateVehicle,
                                              createdActivity:
                                                _vm.onCreatedActivity
                                            }
                                          }
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.HANDOVER,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c("handover-table", {
                                          attrs: {
                                            vehicle: _vm.vehicle,
                                            newTab: true
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: _vm.ActionEnum.READ,
                                      a: _vm.ResourceEnum.ACTIVITY,
                                      field: _vm.partnerId
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tab-item",
                                      [
                                        _c(
                                          "fleet-vehicle-detail-activity-card",
                                          {
                                            attrs: {
                                              vehicle:
                                                _vm.vehicleAggregation.vehicle
                                            },
                                            on: {
                                              change: _vm.updateVehicle,
                                              refresh: _vm.loadVehicle
                                            }
                                          }
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isDeleteDialog,
            "max-width": "800",
            title: _vm.$t(
              "components.fleet.FleetVehicleDetailContextMenu.confirmDeletionTitle"
            ),
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            rightLoading: _vm.isLoadingVehicle
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDeleteDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDeleteDialog = $event
            },
            rightClick: _vm.deleteVehicle,
            leftClick: function leftClick($event) {
              _vm.isDeleteDialog = false
            }
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.fleet.FleetVehicleDetailContextMenu.reasonText"
                )
              ) +
              " "
          ),
          _c("v-text-field", {
            staticClass: "mt-2",
            attrs: {
              type: "text",
              label: _vm.$t("components.dialog.DeleteDialog.reasonTextField"),
              rules: _vm.requiredRule,
              required: "",
              outlined: "",
              dense: ""
            },
            model: {
              value: _vm.deleteReason,
              callback: function callback($$v) {
                _vm.deleteReason = $$v
              },
              expression: "deleteReason"
            }
          })
        ],
        1
      ),
      _vm.focusedTicket
        ? _c("fleet-home-vehicle-table-ticket-side-card", {
            key:
              "fleet-home-vehicle-table-ticket-side-card" +
              (_vm.focusedTicket ? _vm.focusedTicket.number : "null"),
            ref: "fleetHomeVehicleTableTicketSideCard",
            attrs: {
              value: _vm.focusedTicket
            }
          })
        : _vm._e(),
      _vm.isReportSideCard
        ? _c("partner-report-detail-side-card", {
            key:
              "partner-report-detail-side-card-damage-list-table" +
              (_vm.focusedReport ? _vm.focusedReport.id : "null"),
            attrs: {
              loading: _vm.isReportSideCardLoading,
              report: _vm.focusedReport
            },
            on: {
              close: function close($event) {
                _vm.isReportSideCard = false
              }
            }
          })
        : _vm._e(),
      _vm.isTicketCreateDialogActive
        ? _c("ticket-create-dialog", {
            attrs: {
              refs: _vm.refs,
              partnerIdProp: _vm.partnerId,
              isDialogActive: _vm.isTicketCreateDialogActive,
              vehicleAggregation: _vm.vehicleAggregation
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isTicketCreateDialogActive = $event
              },
              created: _vm.onTicketCreated,
              close: function close($event) {
                _vm.isTicketCreateDialogActive = false
              }
            }
          })
        : _vm._e(),
      _c("event-create-dialog", {
        ref: "EventCreateDialog",
        attrs: {
          hideButton: true,
          hidden: !_vm.eventCreate.isDialogActive
        },
        on: {
          createEvent: _vm.onCreateEvent
        }
      }),
      _vm.vehicleAggregation
        ? _c("partner-report-initialize-dialog", {
            ref: "PartnerReportInitializeDialog",
            attrs: {
              hideButton: true,
              reportDto: _vm.vehicleAggregation.reportDto
            }
          })
        : _vm._e(),
      _vm.vehicleAggregation
        ? _c("create-mileage-dialog", {
            ref: "CreateMileageDialog",
            attrs: {
              vehicle: _vm.vehicleAggregation.vehicle,
              isDialogActive: _vm.isMileageCreateDialogActive
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isMileageCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isMileageCreateDialogActive = $event
              }
            }
          })
        : _vm._e(),
      _vm.vehicleAggregation
        ? _c("contract-create-dialog", {
            attrs: {
              vehicle: _vm.vehicleAggregation.vehicle,
              isDialogActive: _vm.isCreateContractDialogActive
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isCreateContractDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isCreateContractDialogActive = $event
              }
            }
          })
        : _vm._e(),
      _vm.vehicleAggregation && _vm.isCreateDocumentDialogActive
        ? _c("document-selection-dialog", {
            attrs: {
              activity: _vm.ActivityTypeEnum.CREATE_DOCUMENT,
              folder: "/vehicle/".concat(_vm.vehicleAggregation.vehicle.id),
              isDialogActive: _vm.isCreateDocumentDialogActive,
              reference: _vm.refs[0],
              partnerId: _vm.partnerId
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isCreateDocumentDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isCreateDocumentDialogActive = $event
              }
            }
          })
        : _vm._e(),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.COST
          }
        },
        [
          _c("cost-create-dialog", {
            ref: "costCreateDialog",
            attrs: {
              isDialogActive: _vm.isCostCreateDialogActive,
              hideButton: true,
              partnerId: _vm.partnerId,
              refs: _vm.refs
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isCostCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isCostCreateDialogActive = $event
              },
              createdActivity: _vm.onCreatedActivity
            }
          })
        ],
        1
      ),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.BLUEPRINT
          }
        },
        [
          _c("blueprint-create-dialog", {
            ref: "blueprintDialog",
            attrs: {
              isDialogActive: _vm.isBlueprintCreateDialogActive,
              hideButton: true,
              partnerId: _vm.partnerId,
              vehicle: _vm.vehicle,
              refs: _vm.refs
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isBlueprintCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isBlueprintCreateDialogActive = $event
              },
              createdActivity: _vm.onCreatedActivity
            }
          })
        ],
        1
      ),
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.HANDOVER
          }
        },
        [
          _c("handover-create-dialog", {
            ref: "handoverDialog",
            attrs: {
              isDialogActive: _vm.isBlueprintCreateDialogActive,
              hideButton: true,
              partnerId: _vm.partnerId,
              vehicle: _vm.vehicle,
              refs: _vm.refs
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isBlueprintCreateDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isBlueprintCreateDialogActive = $event
              },
              createdActivity: _vm.onCreatedActivity
            }
          })
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isUpdateVehicleStatusActive,
            rightLoading: _vm.isLoadingVehicle
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isUpdateVehicleStatusActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isUpdateVehicleStatusActive = $event
            },
            rightClick: _vm.changeVehicleStatus,
            leftClick: _vm.cancelChangeVehicleStatus,
            close: _vm.cancelChangeVehicleStatus
          }
        },
        [
          _c("v-select", {
            attrs: {
              loading: _vm.loading,
              items: _vm.vehicleStates,
              solo: "",
              flat: "",
              outlined: ""
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.fleet.FleetVehicleListView.state." + item
                          )
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "selection",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.fleet.FleetVehicleListView.state." + item
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.tempStatus,
              callback: function callback($$v) {
                _vm.tempStatus = $$v
              },
              expression: "tempStatus"
            }
          })
        ],
        1
      ),
      _vm.focusedEvent
        ? _c("event-side-card", {
            attrs: {
              value: _vm.focusedEvent
            },
            on: {
              close: function close($event) {
                _vm.focusedEvent = null
              },
              input: _vm.onUpdateEvent,
              delete: function _delete($event) {
                _vm.onDeleteEvent(_vm.focusedEvent, function() {
                  return (_vm.focusedEvent = null)
                })
              },
              goToDetail: _vm.goToEventDetail,
              goToEdit: _vm.goToEventEdit
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }