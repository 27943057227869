











































import AnonymizationDownloadDialog from "@/components/anonymization/AnonymizationDownloadDialog.vue";
import AnonymizationSideCard from "@/components/anonymization/AnonymizationSideCard.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { AnonymizationStatusEnum } from "@/lib/enum/AnonymizationStatus.enum";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IAnonymization } from "@/models/anonymization.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { AnonymizationModule } from "@/store/modules/anonymization.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";

@Component({
  components: {
    PaginatedTable,
    AssigneePreview,
    AnonymizationSideCard,
    AnonymizationDownloadDialog
  }
})
export default class AnonymizationTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = AnonymizationModule;

  @Prop()
  partnerId!: string;

  @Prop()
  loadingPartnerUsers?: boolean;

  anonymizationStatusEnum = AnonymizationStatusEnum;
  selectedItems: IAnonymization[] = [];
  isReloading = false;
  selectedItem: IAnonymization | null = null;
  isShowDialogActive = false;

  async reload() {
    try {
      this.isReloading = true;
      await this.store.fetchFirstPage({ partnerId: this.partnerId });
    } catch (error) {
      handleError(error);
    } finally {
      this.isReloading = false;
    }
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.anonymization.created"),
      value: "timestamp.created",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: $t("objects.anonymization.status"),
      value: "status",
      width: "100px",
      align: "start"
    });

    headers.push({
      text: $t("objects.anonymization.userId"),
      value: "userId",
      width: "150px",
      align: "start"
    });

    headers.push({
      text: $t("objects.anonymization.filename"),
      value: "fileName",
      align: "start"
    });
    headers.push({
      text: $t("objects.anonymization.totalTime"),
      value: "totalTime"
    });
    headers.push({
      text: $t("objects.anonymization.calculationTime"),
      value: "calculationTime"
    });

    return headers;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  setSelectedItem(item: IAnonymization | null) {
    this.selectedItem = item;
    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  showAnonymization(item: IAnonymization | null) {
    this.setSelectedItem(item);
    this.isShowDialogActive = true;
  }
}
