










import PartnerUserTable from "@/components/partner-user/PartnerUserTable.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PartnerUserGoToHelper } from "@/lib/utility/partner-user.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerUser, IPartnerUser } from "@/models/user.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { PartnerUserModule } from "@/store/modules/partner-user.store";

@Component({
  components: {
    PartnerUserTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerUserTableView extends mixins(PartnerFallbackMixin) {
  @Ref("partnerUserTable")
  partnerUserTable!: PartnerUserTable;

  readonly AMOUNT_COST = "amountPartnerUser";

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get actions(): IAction[] {
    return [];
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(PartnerUserGoToHelper.breadCrumbs.PartnerUserTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${PartnerUserModule.totalItems} ${this.$t("user.users")}`,
      key: this.AMOUNT_COST,
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    PartnerUserModule.setFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const partnerUserId = this.$route.query.partnerUserId as string;
    if (partnerUserId) {
      const partnerUser = new PartnerUser({ partnerId: this.partnerId, id: partnerUserId });
      this.partnerUserTable?.setSelectedItem(partnerUser);
    }
  }

  openSideCard(partnerUser: IPartnerUser) {
    new PartnerUserGoToHelper(this.$router).setUrl(
      PartnerUserGoToHelper.locations.partnerUserTable({
        partnerId: this.partnerId,
        query: partnerUser ? { partnerUserId: partnerUser.id } : {}
      })
    );
  }
}
