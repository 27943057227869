var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.title,
          breadCrumbs: _vm.breadCrumbs,
          actions: _vm.actions
        },
        on: {
          actionClicked: function actionClicked($event) {
            return $event.exec()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function fn() {
              return [
                _vm._v(
                  "Vom " +
                    _vm._s(_vm.startDate) +
                    " bis " +
                    _vm._s(_vm.endDate) +
                    " "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "m-detail-view-grid",
        {
          scopedSlots: _vm._u([
            {
              key: "details",
              fn: function fn() {
                return [
                  _c("inspection-user-table", {
                    attrs: {
                      inspection: _vm.value,
                      handover: _vm.handover
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("debug", [_vm._v("value " + _vm._s(_vm.value))]),
          _c("inspection-card", {
            staticClass: "mb-n7",
            attrs: {
              value: _vm.value
            }
          }),
          _vm.activitySource
            ? _c("activity-card", {
                key: "activity-card" + _vm.partnerId,
                attrs: {
                  partner: _vm.partner,
                  user: _vm.value,
                  source: _vm.activitySource,
                  config: {
                    comment: true,
                    email: _vm.isMail
                  },
                  mail: _vm.mails
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            title: _vm.$t("edit"),
            isDialogActive: _vm.isEditDialog,
            rightLoading: _vm.isLoading,
            rightDisabled: !_vm.isValid,
            leftText: _vm.$t("cancel"),
            rightText: _vm.$t("save"),
            persistent: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isEditDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isEditDialog = $event
            },
            rightClick: _vm.saveEdit,
            leftClick: function leftClick($event) {
              _vm.isEditDialog = false
            }
          }
        },
        [
          _c("inspection-form", {
            on: {
              isValid: function isValid($e) {
                return (_vm.isValid = $e)
              }
            },
            model: {
              value: _vm.tempInspection,
              callback: function callback($$v) {
                _vm.tempInspection = $$v
              },
              expression: "tempInspection"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }