

















































import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { PersonLabelEnum } from "@/lib/enum/person-label.enum";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PersonGroup } from "@/models/person-group.entity";
import { IPerson } from "@/models/person.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { PersonModule } from "@/store/modules/person.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsPerson from "../utility/RefsPerson.vue";
import RefsPersonGroup from "../utility/RefsPersonGroup.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import PersonCreateDialog from "./PersonCreateDialog.vue";
import PersonSideCard from "./PersonSideCard.vue";
import RefsPersonEmail from "../utility/RefsPersonEmail.vue";
import RefsCompanyAddress from "../utility/RefsCompanyAddress.vue";
import RefsPersonPhone from "../utility/RefsPersonPhone.vue";
import { IReference } from "@/models/reference.entity";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    PersonCreateDialog,
    PersonSideCard,
    RefsPersonGroup,
    RefsPerson,
    RefsPersonEmail,
    RefsPersonPhone,
    RefsCompanyAddress,
    Debug
  }
})
export default class PersonTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = PersonModule;

  readonly PersonLabelEnum = PersonLabelEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop({ default: () => [] })
  refs!: IReference[];

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  @Prop()
  controlElements?: IControlElements[] | undefined;

  selectedItem: IPerson | null = null;

  selectedItemForDelete: IPerson | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.createdReadable",
      width: "120px"
    });

    headers.push({
      text: $t("common.nouns.name"),
      value: "titleReadable"
    });

    headers.push({
      text: $t("common.nouns.email"),
      value: "primaryEmail"
    });

    headers.push({
      text: $t("common.nouns.phone"),
      value: "primaryPhone"
    });

    headers.push({
      text: $t("objects.person.groupId"),
      value: "groupId"
    });

    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get _controlElements(): IControlElements[] {
    if (this.controlElements) {
      return this.controlElements;
    }

    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: IPerson) =>
          new PersonGoToHelper(this.$router).goToPersonDetail({
            personId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  beforeMount() {
    PersonModule.setFilters(this.filters);
  }

  async getPersonGroup(id: string) {
    if (!id) return;
    let personGroup = PersonGroupModule.maps.id.get(id)[0];
    if (!personGroup) {
      personGroup = new PersonGroup({ partnerId: this.partnerId, id });
      personGroup.fetch();
    }

    return personGroup;
  }

  personGroupName(id: string) {
    const group = PersonGroupModule.maps.id.get(id);

    return group?.[0]?.title ?? "";
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: IPerson | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: IPerson) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
