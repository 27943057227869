

































import { Component, Prop, Vue } from "vue-property-decorator";
import { IAction } from "./MHeader.vue";
import MenuButton from "../MenuButton.vue";

@Component({ components: { MenuButton } })
export default class MQuickLinkList extends Vue {
  @Prop()
  actions!: IAction[];

  @Prop({ default: "" })
  title!: string;

  a: IAction | null = null;

  get activeActions() {
    return this.actions;
  }

  actionClicked(action: IAction | undefined) {
    if (!action) {
      return;
    }
    if (action.exec) {
      action.exec();
      return;
    }
    this.$emit("actionClicked", action);
  }
}
