















import PersonGroupTable from "@/components/person/group/PersonGroupTable.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { IPersonGroup, PersonGroup } from "@/models/person-group.entity";

@Component({
  components: {
    PersonGroupTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonGroupTableView extends mixins(PartnerFallbackMixin) {
  @Ref("personGroupTable")
  personGroupTable!: PersonGroupTable;

  readonly AMOUNT_COMPANY_GROUP = "amountPersonGroup";

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(PersonGoToHelper.breadCrumbs.PersonGroupTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${PersonGroupModule.totalItems} ${this.$t("person.personGroups")}`,
        key: this.AMOUNT_COMPANY_GROUP,
        disabled: true
      }
    ];
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("person.allPersons"),
        key: "allPersons",
        exec: () => new PersonGoToHelper(this.$router).goToPersonTable({ partnerId: this.partnerId })
      }
    ];
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const personGroupId = this.$route.query.personGroupId as string;
    if (personGroupId) {
      const personGroup = new PersonGroup({ partnerId: this.partnerId, id: personGroupId });
      this.personGroupTable?.setSelectedItem(personGroup);
    }
  }

  openSideCard(personGroup: IPersonGroup) {
    new PersonGoToHelper(this.$router).setUrl(
      PersonGoToHelper.locations.personGroupTable({
        partnerId: this.partnerId,
        query: personGroup ? { personGroupId: personGroup.id } : {}
      })
    );
  }
}
