










































import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Address } from "@/models/address.entity";
import { PartnerBanking, IPartnerBanking } from "@/models/partner-banking.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IChips } from "../utility/mmmint/MHeader.vue";
import RefsSelect from "../utility/RefsSelect.vue";
import PartnerBankingDetailTable from "./PartnerBankingDetailTable.vue";
import { PartnerBankingGoToHelper, PartnerBankingRouteNames } from "@/lib/utility/partner-banking.go-to-helper";
import { ICompany } from "@/models/company.entity";
import { IPerson } from "@/models/person.entity";
import { RefsCompanyModule, RefsPersonModule } from "@/store/modules/refs.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import RulesMixin from "@/mixins/RulesMixin.vue";

enum PartnerBankingTabEnum {
  ACTIVITIES = "activities"
}

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    ActivityCard: () => import("@/components/thg/ActivityCard.vue"),
    MActionList,
    ConfirmActionDialog,
    PartnerBankingDetailTable,
    RefsSelect,
    LatestEntriesCardEmpty
  },
  filters: {
    getFlagEmoji
  }
})
export default class PartnerBankingDetail extends mixins(PermissionMixin, RulesMixin) {
  readonly PartnerBanking = PartnerBanking;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;
  readonly formable = PartnerBanking;
  readonly PartnerBankingTabEnum = PartnerBankingTabEnum;
  readonly DefaultTab = 0;

  @Prop()
  value!: IPartnerBanking;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  bankingTab = 0;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  referringCompanyEntity: ICompany | null = null;

  referringPersonEntity: IPerson | null = null;

  get chips(): IChips[] {
    const chips: IChips[] = [];

    if (this.value.isSepaDirectDebitMandate) {
      chips.push({
        text: $t("objects.partnerBanking.isSepaDirectDebitMandate"),
        key: "isSepaDirectDebitMandate",
        icon: "mdi-check",
        color: "success"
      });
    } else {
      chips.push({
        text: $t("objects.partnerBanking.isSepaDirectDebitMandate"),
        key: "isSepaDirectDebitMandate",
        icon: "mdi-close",
        color: "error"
      });
    }

    return chips;
  }

  get showMoreAction(): IAction {
    return {
      text: $t("common.nouns.detail"),
      key: "detail",
      description: $t("common.nouns.detail"),
      exec: () => this.value.goTo(this.$router).form(),
      disabled: !this.can(ActionEnum.UPDATE, ResourceEnum.PARTNER_CLIENT_BANKING)
    };
  }

  get subtitle() {
    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const breadCrumbs = PartnerBankingGoToHelper.breadCrumbs;

    const partnerId = this.value.partnerId;
    const partnerBankingId = this.value.id;

    const selected = [];

    selected.push(breadCrumbs.PartnerBankingTable({ partnerId }));
    selected.push(breadCrumbs.PartnerBankingDetail({ partnerId, partnerBankingId }));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).detail()
      });
    }

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.verbs.edit"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).form()
      });
    }

    if (this.referringCompanyEntity) {
      actions.push({
        text: $t("partnerBanking.viewCompany"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.referringCompanyEntity?.goTo(this.$router).detail()
      });
    }

    if (this.referringPersonEntity) {
      actions.push({
        text: $t("partnerBanking.viewPerson"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.referringPersonEntity?.goTo(this.$router).detail()
      });
    }

    return actions;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.PARTNER_CLIENT_BANKING
    };
  }

  async mounted() {
    if (this.$route.query.bankingTab) {
      this.bankingTab = Number(this.$route.query.bankingTab as any);
    }

    await Promise.all([this.findRefferingCompanyEntity(), this.findRefferingPersonEntity()]);
  }

  async findRefferingCompanyEntity() {
    if (!this.can(ActionEnum.READ, BackendResourceEnum.COMPANY)) {
      return;
    }

    RefsCompanyModule.setFilters([
      new PageFilterElement({
        key: "bankingIds",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.value.id
      })
    ]);

    await RefsCompanyModule.fetchFirstPage({ partnerId: this.value.partnerId });

    const company = RefsCompanyModule.filteredAndSorted[0];
    if (company?.bankingIds?.includes(this.value.id)) {
      this.referringCompanyEntity = company;
    }
  }

  async findRefferingPersonEntity() {
    if (!this.can(ActionEnum.READ, BackendResourceEnum.PERSON)) {
      return;
    }

    RefsPersonModule.setFilters([
      new PageFilterElement({
        key: "bankingIds",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.value.id
      })
    ]);

    await RefsPersonModule.fetchFirstPage({ partnerId: this.value.partnerId });

    const person = RefsPersonModule.filteredAndSorted[0];
    if (person?.bankingIds?.includes(this.value.id)) {
      this.referringPersonEntity = person;
    }
  }

  @Watch("bankingTab")
  onTabChange() {
    if (this.$route.name === PartnerBankingRouteNames.PARTNER_BANKING_DETAIL) {
      new GoToHelper(this.$router).setUrl({
        ...PartnerBankingGoToHelper.locations.partnerBankingDetail({
          partnerId: this.value.partnerId,
          partnerBankingId: this.value.id,
          query: { bankingTab: `${this.bankingTab ?? this.DefaultTab}` }
        })
      });
    }
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  goToPartnerBankingDetailForm() {
    new PartnerBankingGoToHelper(this.$router).goToPartnerBankingDetailForm({
      partnerId: this.value.partnerId,
      partnerBankingId: this.value.id,
      newTab: false
    });
  }
}
