var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "paginated-table",
        {
          attrs: {
            store: _vm.store,
            headers: _vm.headers,
            baseQuery: {
              partnerId: _vm.partnerId
            },
            controlElements: _vm.controlElements,
            predefinedFilter: _vm.predefinedFilter
          },
          on: {
            "click:row": _vm.goToCompanyGroupCustomView
          },
          scopedSlots: _vm._u([
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.COMPANY_GROUP,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _c("company-group-create-dialog", {
                        ref: "companyGroupCreateDialog",
                        attrs: {
                          hideButton: false,
                          partnerId: _vm.partnerId,
                          refs: []
                        },
                        on: {
                          created: _vm.goToCompanyGroupCustomView
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "item.title",
              fn: function fn(_ref) {
                var item = _ref.item
                return [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pl-0",
                      attrs: {
                        "two-line": ""
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "pa-0 text-truncate"
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                          !_vm.isMobile
                            ? _c(
                                "v-list-item-subtitle",
                                {
                                  staticClass: "pa-0 text-truncate",
                                  staticStyle: {
                                    "max-width": "450px"
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.description) + " ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.timestamp.created",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return [
                  _vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")
                ]
              }
            }
          ])
        },
        [
          _c(
            "template",
            {
              slot: "onboarding"
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-auto",
                  staticStyle: {
                    "max-width": "750px"
                  },
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c("v-spacer"),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("company.noCompanyGroupsTitle")
                          )
                        }
                      }),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _c("v-card-subtitle", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("company.noCompanyGroupDescriptionI")
                        )
                      }
                    }),
                    _c(
                      "a",
                      {
                        on: {
                          click: _vm.goToCustomFields
                        }
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("company.noCompanyGroupDescriptionII")
                            )
                          }
                        })
                      ]
                    ),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("company.noCompanyGroupDescriptionIII")
                        )
                      }
                    })
                  ]),
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.COMPANY_GROUP,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: 0,
                                color: "info"
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.$refs.companyGroupCreateDialog.open()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("getStarted")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.selectedItem
        ? _c("company-group-side-card", {
            attrs: {
              value: _vm.selectedItem
            },
            on: {
              close: function close() {
                return _vm.setSelectedItem(null)
              }
            }
          })
        : _vm._e(),
      _vm.isDeleteDialogActive && _vm.selectedItemForDelete
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error",
                rightLoading: _vm.isDeleteLoading
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-company-group", {
                attrs: {
                  item: _vm.selectedItemForDelete
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }