














































































































































































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import FleetEventMenuCard from "@/components/fleet/FleetEventMenuCard.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/PaginatedTable.vue";
import SideCard from "@/components/utility/SideCard.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { detailedDate, detailedDateWithDay, simpleDate } from "@/lib/utility/date-helper";
import { BaseGoToHelper, GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { EventModule } from "@/store/modules/event.store";
import { OverdueEventListModule } from "@/store/modules/overdue-event.store";
import { AllEventListModule } from "@/store/modules/all-event.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { RecurringEventModule } from "@/store/modules/recurring-event.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { RRule } from "rrule";
import { Component, Watch } from "vue-property-decorator";
import { PredefinedFilterType, myEventFilter } from "../event/filter/event.filter";
import EventSideCard from "@/components/event/EventSideCard.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { mixins } from "vue-class-component";
import FleetVehicleEventCrudMixin from "@/components/fleet/FleetVehicleEventCrudMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import EventAcknowledgeDialog from "@/components/fleet/EventAcknowledgeDialog.vue";

export function rruleToText(rrule: string): string {
  return RRule.fromString(rrule).toText();
}

type IItemType = { event: IEventUIDto; index: number };

@Component({
  components: {
    EventAcknowledgeDialog,
    TheLayoutPortal,
    PaginatedTable,
    TableWrapper,
    MHeader,
    AnalyticsDateRangeSelectorDialog,
    SideCard,
    FleetEventMenuCard,
    Tooltip,
    Debug,
    EventSideCard,
    FilterCardPagination
  },
  filters: {
    detailedDateWithDay,
    detailedDate,
    simpleDate,
    rruleToText
  }
})
export default class PartnerEvents extends mixins(PartnerFallbackMixin, FleetVehicleEventCrudMixin) {
  readonly ActionEnum = ActionEnum;
  readonly ResourceEnum = ResourceEnum;

  isDetailLoading = false;
  loading = true;
  tab = 0;

  selectedOverdue: IItemType[] = [];

  selectedEvent: IEventUIDto | null = null;

  get selectedOverdueLocal(): IItemType[] {
    // supress allow selection of recurring events. they can't be acknowledged
    return this.selectedOverdue.filter(s => !s.event.isRecurring);
  }

  set selectedOverdueLocal(selectedOverdueLocal: IItemType[]) {
    this.selectedOverdue.splice(0, this.selectedOverdue.length, ...selectedOverdueLocal);
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get overdueEventsSearch() {
    return OverdueEventListModule.search;
  }
  set overdueEventsSearch(search: string | undefined) {
    OverdueEventListModule.setSearch(search);
  }

  get overdueEventsFilters() {
    return OverdueEventListModule.filters;
  }
  set overdueEventsFilters(filters: IPageFilterElement[]) {
    OverdueEventListModule.setFilters(filters);
  }

  get overdueEventsFilterList() {
    return OverdueEventListModule.filterOptions;
  }

  get allEventsSearch() {
    return AllEventListModule.search;
  }

  set allEventsSearch(search: string | undefined) {
    AllEventListModule.setSearch(search);
  }

  get allEventsFilters() {
    return AllEventListModule.filters;
  }

  set allEventsFilters(filters: IPageFilterElement[]) {
    AllEventListModule.setFilters(filters);
  }

  get allEventsFilterList() {
    return AllEventListModule.filterOptions;
  }

  get dates() {
    return AllEventListModule.dateRange;
  }

  onAcknowledged(event: IEventUIDto) {
    const index = this.selectedOverdue.findIndex(e => e.event.id === event.id);
    if (index > -1) {
      this.selectedOverdue.splice(index, 1);
    }
  }

  onConfirmAllEventsFilter() {
    this.allEventsFilters = AllEventListModule.filters;
    this.load();
  }

  onConfirmOverdueEventsFilter() {
    this.overdueEventsFilters = OverdueEventListModule.filters;
    this.load();
  }

  get tabs(): IAction[] {
    return [
      { text: this.$t("overdue").toString(), key: "overdue", icon: "mdi-calendar-alert" },
      { text: this.$t("components.fleet.FleetHomeCalendar.event").toString(), key: "list", icon: "mdi-table" },
      { text: this.$t("components.fleet.FleetHomeCalendar.recurring").toString(), key: "recurring", icon: "mdi-sync" }
    ];
  }

  get headersOverdue(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("components.fleet.FleetHomeCalendar.start").toString(),
        value: "event.start",
        type: "date",
        align: "start"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.name").toString(),
        value: "event.summary",
        align: "start"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.recurring").toString(),
        value: "event.isVirtual",
        align: "start"
      },
      {
        text: this.$t("common.nouns.vehicle").toString(),
        value: "event.vehicleId",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get headersList(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("components.fleet.FleetHomeCalendar.start").toString(),
        value: "event.start",
        type: "date",
        align: "start"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.name").toString(),
        value: "event.summary",
        align: "start"
      },
      //  {
      //   text: this.$t("components.fleet.FleetHomeCalendar.overdue").toString(),
      //   value: "event.ack",
      //   align: "start"
      // },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.recurring").toString(),
        value: "event.isVirtual",
        align: "start"
      },
      {
        text: this.$t("common.nouns.vehicle").toString(),
        value: "event.vehicleId",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get headersRecurring(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("project.project.created").toString(),
        value: "timestamp.created",
        align: "start",
        type: "date"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.deleted").toString(),
        value: "isDeleted",
        align: "start"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.name").toString(),
        value: "summary",
        align: "start"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.start").toString(),
        value: "start",
        align: "start",
        type: "date"
      },
      {
        text: this.$t("views.fleet.EventCreateDialog.recurring").toString(),
        value: "rrule",
        align: "start"
      },
      {
        text: this.$t("common.nouns.vehicle").toString(),
        value: "vehicleId",
        align: "start"
      },
      {
        text: this.$t("project.project.userId").toString(),
        value: "userId",
        align: "start"
      },

      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: () => undefined,
        disabled: this.loading
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.DocumentTable.delete").toString(),
        action: () => undefined,
        disabled: this.loading
      }
    ];
  }

  get predefinedFilter(): PredefinedFilterType[] {
    const predefinedFilter = myEventFilter.slice();

    return predefinedFilter;
  }

  get recurringEvents() {
    return RecurringEventModule.events;
  }

  get listEvents() {
    return AllEventListModule.filtered.map((event, index) => ({
      event,
      index
    }));
  }

  get overdueEvents(): IItemType[] {
    return OverdueEventListModule.filtered.map((event, index) => ({
      event,
      index
    }));
  }

  get partner() {
    return PartnerModule.partner;
  }

  @Watch("tab")
  syncRoute() {
    this.setRoute(this.tab);
  }

  isDue(event: IEventUIDto) {
    if (!event) {
      return false;
    }

    return event.isOverdue;
  }

  setIsLoadingDetail(value: boolean) {
    this.isDetailLoading = value;
  }

  get tabIndexFromRoute() {
    const routeName = this.$route.name ?? "";
    const index = this.routeNames.findIndex(c => c === routeName);
    if (index > -1) return index;
    return 0;
  }

  async mounted() {
    const vehicleId = this.$route.query["vehicleId"] as string | undefined;

    this.tab = this.tabIndexFromRoute;

    await this.trySetByRouteOrDefault();

    // Filter by set vehicleId from query param. Only after this load the da
    if (vehicleId) {
      const vehicleIdFilter = new PageFilterElement({
        key: "vehicleId",
        operation: "$eq",
        value: vehicleId
      });
      RecurringEventModule.setFilter([vehicleIdFilter]);
      AllEventListModule.setFilter([vehicleIdFilter]);
      OverdueEventListModule.setFilter([vehicleIdFilter]);
    } else {
      RecurringEventModule.setFilter([]);
      AllEventListModule.setFilter([]);
      OverdueEventListModule.setFilter([]);
    }

    await this.load();
  }

  async refresh() {
    await this.load();
  }

  getModule() {
    return RecurringEventModule;
  }

  getVehicle(id: string) {
    return VehicleModule.maps.id.get(id)[0];
  }

  getUser(id: string) {
    return PartnerUserModule.maps.id.get(id)[0];
  }
  /**
   * map index of tab to route name
   */
  readonly routeNames = ["PartnerEventsOverdue", "PartnerEventsList", "PartnerEventsRecurring"];

  setRoute(tabIndex: number, eventId?: string) {
    let query = {};
    if (eventId) {
      query = { eventId };
    }

    const name = this.routeNames[tabIndex];

    const location = this.$router.resolve({
      name,
      params: {
        partnerId: this.$route.params.partnerId,
        tab: name
      },
      query
    });

    if (location) {
      new BaseGoToHelper(this.$router).setUrl(location.location);
    }
  }

  async ack(selected: IItemType[]) {
    this.$log.debug(selected);

    this.loading = true;

    try {
      for (const event of selected) {
        await event.event.acknowledge(new Date().toISOString());
      }

      await OverdueEventListModule.fetchAll({
        partnerId: PartnerModule.partner._id
      });

      this.selectedOverdueLocal = [];
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async load() {
    this.loading = true;
    this.isDetailLoading = true;

    try {
      const promises: Promise<any>[] = [];

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.USER, PartnerModule.partner._id)) {
        promises.push(
          PartnerUserModule.fetchFirstPage({
            partnerId: PartnerModule.partner._id
          })
        );
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, PartnerModule.partner._id)) {
        promises.push(VehicleModule.fetchAll({ partnerId: PartnerModule.partner._id }));
      }

      promises.push(
        RecurringEventModule.fetchAllFromBeginning({
          partnerId: PartnerModule.partner._id
        })
      );

      promises.push(
        AllEventListModule.fetchAll({
          partnerId: PartnerModule.partner._id,
          from: new Date(this.dates[0]).getTime(),
          to: new Date(this.dates[1]).getTime()
        })
      );

      promises.push(
        OverdueEventListModule.fetchAll({
          partnerId: PartnerModule.partner._id
        })
      );

      if (this.$route.query.eventId) {
        promises.push(
          EventModule.getOne({
            partnerId: this.$route.params.partnerId,
            eventId: this.$route.query.eventId.toString()
          }).then(event => {
            this.setRoute(this.tab, event.id);

            this.selectedEvent = event;
          })
        );
      }

      await Promise.all(promises);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }

  async onRowClick(event: IEventUIDto) {
    this.selectedEvent = null;
    await event.createVirtual();
    this.$nextTick(() => {
      this.setRoute(this.tab, event.id);
      this.selectedEvent = event;
    });
  }

  async onUpdateEvent(eventUIDto: IEventUIDto) {
    try {
      this.loadingUpdate = true;
      await this.updateEvent(eventUIDto, eventUIDto.vehicleId ?? "", new Date(eventUIDto.start));
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }

  async goToDetail(event: IEventUIDto) {
    await event.createVirtual();
    await new GoToHelper(this.$router).goToEventDetail(this.partner.id, event.id ?? "error", true);
  }

  async goToEdit(event: IEventUIDto) {
    await event.createVirtual();
    await new GoToHelper(this.$router).goToEventDetailEdit(this.partner.id, event.id ?? "error", false);
  }

  async onDeleteEvent() {
    try {
      if (!this.selectedEvent) return;
      this.loadingUpdate = true;
      await this.deleteEvent(this.selectedEvent, this.partner.id, new Date(this.selectedEvent.start));
      this.selectedEvent = null;
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }
}
