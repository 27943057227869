













































import Debug from "@/components/utility/Debug.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { ContractGoToHelper } from "@/lib/utility/contract.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { Group } from "@/models/group.entity";
import { ICustomField } from "@/models/custom-field.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { GroupModule } from "@/store/modules/group.store";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import UpdateForm from "../utility/UpdateForm.vue";
import ContractDetail from "./ContractDetail.vue";
import ContractDetailTable from "./ContractDetailTable.vue";

@Component({
  components: {
    UpdateForm,
    MHeader,
    MDetailViewGrid,
    MActionList,
    ConfirmActionDialog,
    Debug,
    ContractDetailTable
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class ContractDetailForm extends ContractDetail {
  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = ContractGoToHelper.breadCrumbs;

    const selected = [];

    const partnerId = this.value.partnerId;
    const contractId = this.value.id;

    selected.push(breadCrumbs.ContractTable({ partnerId }));
    selected.push(breadCrumbs.ContractDetail({ partnerId, contractId }));
    selected.push(breadCrumbs.ContractDetailForm({ partnerId, contractId }));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).detail()
      });
    }

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).detail()
      });
    }

    const groupId = this.value.groupId;
    if (groupId) {
      actions.push({
        text: $t("contract.toGroup"),
        key: "toGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).customView()
      });
    }

    if (this.value.id) {
      actions.push({
        text: $t("contract.toContract"),
        key: "toContract",
        icon: "mdi-open-in-new",
        color: "",

        exec: () => this.value.goTo(this.$router).detail()
      });
    }

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.PERSON)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  async loadCustomConfig(value: string): Promise<ICustomField[]> {
    if (!value) return [];

    const group =
      GroupModule.maps.id.get(value)[0] ||
      (await new Group({ partnerId: this.partner.id, id: value }).fetch().catch(handleError));

    return group?.configuration.customFieldConfig?.map(c => c.customField) || [];
  }
}
