













import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ICompanyGroup, CompanyGroup } from "@/models/company-group.entity";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import CompanyGroupDetail from "@/components/company/group/CompanyGroupDetail.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    CompanyGroupDetail,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyGroupDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get companyGroupId(): string {
    return this.$route.params.companyGroupId;
  }

  isLoading = false;

  companyGroup: ICompanyGroup | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const companyGroup =
      CompanyGroupModule.maps.id.get(this.companyGroupId)[0] ??
      new CompanyGroup({
        id: this.companyGroupId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await companyGroup.fetch();
      this.companyGroup = companyGroup;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
