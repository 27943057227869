























































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IControlElements } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import {
  displayAvailableWeekDaysAsShortString,
  displayMsAsMinutesAndHours,
  simpleDoubleDigitDate
} from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ServiceModule } from "@/store/modules/service.store";
import { Component, Prop } from "vue-property-decorator";
import ServiceForm from "./ServiceForm.vue";
import { ResourceModule } from "@/store/modules/resource.store";
import { dateRule } from "@/lib/rules/dateRule";
import { PartnerModule } from "@/store/modules/partner";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { IService, Service } from "@/models/service.entity";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    TheLayoutPortal,
    PaginatedTable,
    ServiceForm,
    ConfirmActionDialog
  }
})
export default class ServiceTable extends PartnerFallbackMixin {
  @Prop({ default: false })
  load!: boolean;

  store = ServiceModule;

  isLoading = false;
  isValid = false;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;

  /**
   * Opening delete all confirmation
   */
  isDeleteAllDialogActive = false;
  isDeleteAllDialogLoading = false;

  /**
   * Create dialog
   */
  isCreateDialogActive = false;
  isCreateDialogLoading = false;
  /**
   * Update dialog
   */
  isUpdateDialogActive = false;
  isUpdateDialogLoading = false;
  isDialogLoading = false;
  updateServiceId = "";

  search = "";

  itemsPerPage = 25;

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];
  selectedItems: IService[] = [];

  serviceToCreate: IService = new Service();
  serviceToUpdate: IService | null = null;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  headers = [
    { text: this.$t("views.booking.ServiceTable.isActive"), align: "start", value: "isActive", width: 100 },
    {
      text: this.$t("views.booking.ServiceTable.durationInSeconds"),
      align: "start",
      value: "duration",
      width: 100
    },
    { text: this.$t("views.booking.ServiceTable.availability"), align: "start", value: "availability", width: 250 },
    { text: this.$t("views.booking.ServiceTable.displayName"), align: "start", value: "name" },
    { text: this.$t("views.booking.ServiceTable.slug"), align: "start", value: "slug" },

    {
      text: this.$t("views.booking.ServiceTable.date"),
      align: "end",
      value: "timestamp.created",
      rules: [dateRule()],
      type: "date"
    },
    { text: "", align: "end", value: "controls", width: 150, sortable: false }
  ];

  async mounted() {
    this.isLoading = true;
    try {
      ServiceModule.setHiddenFilters([
        new PageFilterElement({
          key: "isDeleted",
          value: false as any
        })
      ]);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async loadAll() {
    this.isLoading = true;
    try {
      await Promise.all([ResourceModule.fetchAll({ partnerId: this.partnerId })]);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  displayAvailableWeekDaysAsShortString(val: BookingAvailabilityGen[]) {
    return displayAvailableWeekDaysAsShortString(val);
  }

  displayMsAsMinutesAndHours(val: number) {
    return displayMsAsMinutesAndHours(val);
  }

  simpleDoubleDigitDate(val: string) {
    return simpleDoubleDigitDate(val);
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get services() {
    return ServiceModule.paginationList;
  }

  get resources() {
    return ResourceModule.paginationList;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-pencil",
        text: this.$t("views.booking.ServiceTable.edit").toString(),
        action: this.openServiceFormInDialog
      },
      {
        icon: "mdi-open-in-new",
        text: this.$t("views.booking.ServiceTable.open").toString(),
        action: this.open
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("views.booking.ServiceTable.delete").toString(),
        action: (item: IService) => {
          this.selectedItems = [item];
          this.isDeleteDialogActive = true;
        }
      }
    ];
  }

  async open(service: IService) {
    await new GoToHelper(this.$router).goToServiceDetail(this.partnerId, service.id);
  }

  openServiceFormInDialog(service: IService) {
    this.serviceToUpdate = new Service(service);
    this.isUpdateDialogActive = true;
    this.updateServiceId = service.id;
  }

  async updateService() {
    if (!this.serviceToUpdate) {
      this.$toast.error("No Service to update");
      throw new Error("Not defined");
    }

    if (this.updateServiceId !== this.serviceToUpdate.id || this.partnerId !== this.serviceToUpdate.partnerId) {
      this.$toast.error("Service to update is wrong");
      throw new Error("Wrong Service");
    }

    this.isUpdateDialogLoading = true;

    try {
      await this.serviceToUpdate.replaceOneByPartnerId();
      this.$toast.success("👍");
      this.isUpdateDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isUpdateDialogLoading = false;
    }
  }

  closeUpdateServiceDialog() {
    this.isUpdateDialogActive = false;
    // Restore correct service from store
    this.serviceToUpdate = ServiceModule.maps.id.get(this.updateServiceId)[0];
    this.updateServiceId = "";
  }

  async createService() {
    this.isCreateDialogLoading = true;

    try {
      await new Service({ ...this.serviceToCreate, partnerId: this.partnerId }).create();
      this.$toast.success("👍");
      this.isCreateDialogActive = false;
      await this.loadAll();
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateDialogLoading = false;
    }
  }

  /**
   * Open delete all dialog and save the passed services
   * @param selected list of services
   */
  openDeleteAllDialog() {
    this.isDeleteAllDialogActive = true;
  }

  /**
   * Deletes all selected services.
   */
  async deleteServices() {
    this.isDeleteAllDialogLoading = true;

    if (!this.selectedItems || this.selectedItems.length <= 0) {
      this.isDeleteAllDialogLoading = false;
      this.isDeleteAllDialogActive = false;
      this.$toast.error(this.$t("views.booking.ServiceTable.noDocumentsSelected"));
      return;
    }

    if (this.selectedItems.length >= 10) {
      const error = new Error(this.$t("views.booking.ServiceTable.tooManyError").toString());
      this.$toast.error(error.message);

      throw error;
    }

    const promises: Promise<void>[] = [];
    let counter = 0;

    for (const service of this.selectedItems) {
      promises.push(service.delete());
      counter++;
    }

    try {
      await Promise.all(promises);
      await this.loadAll();
      this.$toast.success(this.$t("views.booking.ServiceTable.deleted", { counter: counter }));
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.isDeleteAllDialogActive = false;
      this.isDeleteAllDialogLoading = false;
      /** Reset the selected items */
      this.selectedItems = [];
    }
  }

  async deleteService() {
    this.isDeleteDialogLoading = true;

    const serviceToRemove = this.selectedItems[0];

    if (!serviceToRemove) {
      this.$toast.error(this.$t("views.booking.ServiceTable.noDocumentsSelected"));
    }

    await serviceToRemove.delete();
    this.$toast.success("👍");

    this.isDeleteDialogLoading = false;
    this.isDeleteDialogActive = false;
    this.selectedItems = [];
  }

  getStateColor(item: IService) {
    if (item.isActive) {
      return "success";
    } else {
      return "grey";
    }
  }
}
