var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          label: _vm.$t("objects.handover.company.name"),
          outlined: "",
          dense: ""
        },
        model: {
          value: _vm.value.companyName,
          callback: function callback($$v) {
            _vm.$set(_vm.value, "companyName", $$v)
          },
          expression: "value.companyName"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: _vm.$t("objects.handover.company.email"),
          rules: _vm.rules(_vm.RulesEnum.EMAIL_RULE_OPTIONAL),
          outlined: "",
          dense: ""
        },
        model: {
          value: _vm.value.email,
          callback: function callback($$v) {
            _vm.$set(_vm.value, "email", $$v)
          },
          expression: "value.email"
        }
      }),
      _c("v-text-field", {
        attrs: {
          label: _vm.$t("objects.handover.company.phone"),
          rules: _vm.rules(_vm.RulesEnum.PHONE_RULE_OPTIONAL),
          outlined: "",
          dense: ""
        },
        model: {
          value: _vm.value.phone,
          callback: function callback($$v) {
            _vm.$set(_vm.value, "phone", $$v)
          },
          expression: "value.phone"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }