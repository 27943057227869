













































































































































































import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import { ContractTypeEnum } from "@/lib/enum/contract-type.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { rules, RulesEnum } from "@/lib/rules/rules.map";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { Address } from "@/models/address.entity";
import { CompanyAddress } from "@/models/company-address.entity";
import { Contract, IContract } from "@/models/contract.entity";
import { Group, IGroup } from "@/models/group.entity";
import { IReference } from "@/models/reference.entity";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ConfigModule } from "@/store/modules/config";
import { GroupModule } from "@/store/modules/group.store";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import VueRouter from "vue-router";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IRefSuggestion } from "@/store/modules/fleet-aggregation.store";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import CostCardDocuments from "../cost/CostCardDocuments.vue";
import RefsSelect from "../utility/RefsSelect.vue";
import RefsSelectForm from "../utility/RefsSelectForm.vue";
import { mixins } from "vue-class-component";
import FileInputMixin from "../utility/FileInputMixin.vue";
import { IGroupTypeEntityCreateDialog } from "@/lib/utility/group/interface/group-type-entity-create-dialog.interface";
import TemplateEditor from "@/components/template/TemplateEditor.vue";

@Component({
  components: {
    ConfirmActionDialog,
    CustomFieldListForm,
    CostCardDocuments,
    RefsSelect,
    Debug,
    RefsSelectForm,
    TemplateEditor
  },
  filters: {}
})
export default class ContractCreateDialog extends mixins(FileInputMixin) implements IGroupTypeEntityCreateDialog {
  readonly ContractTypeEnum = ContractTypeEnum;
  readonly Contract = Contract;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;
  readonly CompanyAddress = CompanyAddress;
  readonly ResourceEnum = ResourceEnum;

  @Ref("costCardDocuments")
  costCardDocuments!: CostCardDocuments;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  /**
   * ID of contract group to preselect
   */
  @Prop()
  groupId?: string;

  @Prop({ default: false })
  fullscreen?: boolean;

  @Prop({ default: () => [] })
  refs!: IReference[];

  @Prop({ default: () => [] })
  clientIds!: IReference[];

  @Prop({ default: () => [] })
  providerIds!: IReference[];

  isDialogActive = false;

  isLoading = false;

  isFormValid = false;

  contract: IContract = new Contract();

  group: IGroup | null = null;

  isLoadingCustomFieldValues = false;

  isLoadingGroups = false;

  get suggested(): IRefSuggestion[] {
    return [];
  }

  get categories(): BackendResourceEnum[] | undefined {
    return undefined;
  }

  get groups() {
    return GroupModule.filteredAndSorted;
  }

  get requiredRule() {
    return rules(RulesEnum.REQUIRED_RULE);
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  async onCreatedDocument(...documents: ISignDocument[]) {
    this.$log.debug(documents);

    if (!this.contract.title && documents.length) {
      const title = documents
        .map(d => {
          if (d.title?.endsWith(".pdf")) {
            return d.title.substring(0, d.title.length - 4);
          }
          return d.title;
        })
        .join("; ") as string;
      this.contract.title = title;
    }
  }

  async handleFileHook(file: File) {
    const signDocument = new SignDocument({
      name: file.name,
      title: file.name,
      isTemplate: false,
      folder: "/cost/",
      refs: [],
      file: file,
      partnerId: this.partnerId
    });

    this.costCardDocuments.isLoadingCreation = true;
    await signDocument.create().catch(handleError);
    this.costCardDocuments.isLoadingCreation = false;
    this.costCardDocuments.onCreated(signDocument);

    (this.$refs.radioExpense as any)?.focus();
  }

  startDrag() {
    this.isDrag = true;
  }

  endDrag() {
    this.isDrag = false;
  }

  open() {
    this.contract = new Contract({
      type: ContractTypeEnum.INSURANCE,
      groupId: this.groupId,
      partnerId: this.partnerId,
      refs: this.refs,
      clientIds: this.clientIds,
      providerIds: this.providerIds
    });
    this.setGroup();
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.startField as HTMLInputElement)?.focus();
    });
  }

  close() {
    this.isDialogActive = false;
  }

  async onRightClick() {
    try {
      this.isLoading = true;
      this.isDialogActive = true;
      await this.contract.create();
      this.$emit("created", this.contract);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
      this.isLoading = false;
    }
  }

  @Watch("contract.groupId")
  async setGroup() {
    this.group = null;

    const groupId = this.contract.groupId;
    if (groupId) {
      this.isLoadingCustomFieldValues = true;
      const group =
        GroupModule.maps.id.get(groupId)[0] ||
        (await new Group({ partnerId: this.contract.partnerId, id: groupId }).fetch().catch(handleError));
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.group = group;
      });
    }
  }

  async refreshGroups() {
    this.isLoadingGroups = true;
    try {
      GroupModule.setFilter([]);
      await GroupModule.fetchAll({ partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingGroups = false;
  }

  goToGroupCustomView(router: VueRouter) {
    if (!this.groupId) return;
    new GroupGoToHelper(router).goToGroupTypeCustomView({
      partnerId: this.partnerId,
      type: BackendResourceEnum.CONTRACT,
      groupId: this.groupId,
      viewId: "0"
    });
  }
}
