









































































































































































































import ActivityCard from "@/components/thg/ActivityCard.vue";
import RefsUser from "@/components/utility/RefsUser.vue";

import { setNestedObjectValues } from "@/lib/objectPath-helper";
import {
  displayMsAsMinutesAndHours,
  formatAsFullDateAndFullHour,
  formatHoursAndMinutes,
  simpleDoubleDigitDate
} from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailForm from "@/components/utility/mmmint/MDetailForm.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IAlert } from "@/components/utility/mmmint/MHeader.vue";
import BookingAttachmentList from "@/components/booking/shared/BookingAttachmentList.vue";
import BookingCustomerInformationTimeLineElement from "./BookingCustomerInformationTimeLineElement.vue";
import BookingBookingInformationTimeLineElement from "./BookingBookingInformationTimeLineElement.vue";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import {
  BookingMDetailActionEnum,
  BookingActionRecordIconEnum,
  BookingFormConfigCategoryEnum,
  BookingGoToActionEmitData,
  BookingPortalFormEmitInputData
} from "./ui-types";
import { IMDetailFormConfig, MDetailFormConfig } from "@/lib/formable";
import { IBooking } from "@/models/booking.entity";
import { IResource } from "@/models/resource.entity";
import { IService } from "@/models/service.entity";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import TimelineCard from "@/components/utility/TimelineItem.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    MDetailTable,
    MDetailForm,
    MActionList,
    CustomFieldListForm,
    TimelineCard,
    ActivityCard,
    RefsUser,
    ConfirmActionDialog,
    BookingAttachmentList,
    BookingCustomerInformationTimeLineElement,
    BookingBookingInformationTimeLineElement
  },
  filters: { simpleDoubleDigitDate, formatAsFullDateAndFullHour, formatHoursAndMinutes, displayMsAsMinutesAndHours }
})
export default class BookingDetailCard extends Vue {
  @Prop()
  value!: IBooking;

  @Prop()
  loading!: boolean;

  @Prop()
  resourceName!: string;

  @Prop()
  serviceName!: string;

  @Prop()
  service!: IService;

  @Prop()
  resources!: IResource[];

  @Prop({ default: false })
  hideBreadcrumbs!: boolean;

  @Prop({ default: false })
  hideInNewTabButton!: boolean;

  @Prop()
  fromCalendar!: boolean;

  @Prop()
  isEdit!: boolean;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;
  notifyUserForDelete = false;

  /**
   * Opening update confirmation
   */
  isUpdateDialogActive = false;
  isUpdateDialogLoading = false;
  notifyUserForUpdate = false;

  readonly actionRecord: Record<BookingMDetailActionEnum, IAction> = {
    [BookingMDetailActionEnum.GO_TO_OVERVIEW]: {
      text: String(this.$t("project.ticket.actions.toOverview")),
      key: BookingMDetailActionEnum.GO_TO_OVERVIEW,
      icon: BookingActionRecordIconEnum.OPEN_IN_NEW
    },
    [BookingMDetailActionEnum.GO_TO_EDIT]: {
      text: String(this.$t("common.verbs.edit")),
      key: BookingMDetailActionEnum.GO_TO_EDIT,
      icon: BookingActionRecordIconEnum.EDIT
    },
    [BookingMDetailActionEnum.GO_TO_RESOURCE]: {
      text: String(this.$t("views.booking.BookingPortalDetailView.goToResource")),
      key: BookingMDetailActionEnum.GO_TO_RESOURCE,
      icon: BookingActionRecordIconEnum.OPEN_IN_NEW
    },
    [BookingMDetailActionEnum.DELETE_BOOKING]: {
      text: String(this.$t("common.verbs.delete")),
      key: BookingMDetailActionEnum.DELETE_BOOKING,
      icon: BookingActionRecordIconEnum.DELETE,
      color: "red"
    },
    [BookingMDetailActionEnum.OPEN_IN_NEW]: {
      text: String(this.$t("project.ticket.actions.openInNewTab")),
      key: BookingMDetailActionEnum.OPEN_IN_NEW,
      icon: BookingActionRecordIconEnum.OPEN_IN_NEW
    },
    [BookingMDetailActionEnum.GO_TO_SERVICE]: {
      text: String(this.$t("views.booking.BookingPortalDetailView.goToService")),
      key: BookingMDetailActionEnum.GO_TO_SERVICE,
      icon: BookingActionRecordIconEnum.OPEN_IN_NEW
    }
  };

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  formatHoursAndMinutes(date: Date) {
    return formatHoursAndMinutes(date);
  }

  get mail() {
    return this.value.customerInformation?.email;
  }

  // #region data, non-ui
  get partnerId() {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.BOOKING
    };
  }
  // #endregion data, non-ui

  get breadCrumbs(): IBreadcrumb[] | undefined {
    if (this.hideBreadcrumbs) {
      return undefined;
    }

    const breadCrumbs: IBreadcrumb[] = [
      {
        text: this.$t("navigation.TheOnlineBookingList.bookings"),
        exact: true,
        to: {
          name: "BookingView",
          params: {
            partnerId: this.partnerId
          }
        }
      },
      {
        text: this.$t("components.booking.BookingDetailCard.booking"),
        exact: true,
        disabled: !this.isEdit,
        to: {
          name: "BookingPortalDetailView",
          params: {
            partnerId: this.partnerId,
            bookingId: this.value.id
          }
        }
      }
    ];

    if (this.isEdit) {
      breadCrumbs.push({
        text: "Detail",
        exact: true,
        disabled: true, // last page, so breadcrumb is not clickable
        // last page, always disabled, don't provide router data
        to: {} as any
      });
    }

    return breadCrumbs;
  }

  get chips() {
    const chips = [];

    if (this.value.isDeleted) {
      chips.push({
        text: this.$t("components.booking.PartnerBookingCalendar.cancelled"),
        color: "orange",
        icon: "mdi-trash-can"
      });
    }

    return chips;
  }

  get alerts(): IAlert[] {
    const alerts: IAlert[] = [];

    if (this.value.isDeleted) {
      alerts.push({
        text: String(this.$t("components.booking.BookingDetailCard.bookingCancelledAlertText")),
        type: "warning"
      });
    }

    return alerts;
  }

  // #region actions
  get actions() {
    return this.isEdit ? this.editActions : this.baseActions;
  }

  get detailTableMoreAction() {
    return this.isEdit ? null : this.actionRecord.goToEdit;
  }

  get baseActions() {
    const actions: IAction[] = [];

    if (!this.hideInNewTabButton) {
      actions.push(this.actionRecord.openInNew);
    }

    actions.push(this.actionRecord.goToResource);

    if (this.value.serviceId) {
      actions.push(this.actionRecord.goToService);
    }

    actions.push(this.actionRecord.goToEdit);

    if (!this.value.isDeleted) {
      actions.push(this.actionRecord.deleteBooking);
    }

    return actions;
  }

  get editActions() {
    const actions: IAction[] = [];

    actions.push(this.actionRecord.goToOverview);

    actions.push(this.actionRecord.goToResource);

    if (this.value.serviceId) {
      actions.push(this.actionRecord.goToService);
    }

    if (!this.value.isDeleted) {
      actions.push(this.actionRecord.deleteBooking);
    }

    return actions;
  }
  // #endregion actions

  get avatarAuthorName() {
    return this.$t("createdOnBy", {
      date: simpleDoubleDigitDate(this.value.timestamp.created),
      name: this.value.getUserIdentifierString()
    });
  }

  /**
   * MHeader title.
   * Display customer names(first, last), the service booked and the resource used.
   * For "blocked" bookings, don't show any user credentials, just display the service name and resource name
   */
  get title() {
    if (!this.value || !this.serviceName || !this.resourceName) {
      return undefined;
    }

    const userIdentifierTitleString = `${this.value.getUserIdentifierString()} - `;

    return `${this.value.serviceId ? userIdentifierTitleString : ""}${this.serviceName} (${this.resourceName})`;
  }

  // #region configuration
  get config(): IMDetailFormConfig[] {
    if (!this.value) {
      return [];
    }

    const config: IMDetailFormConfig<IBooking>[] = [
      {
        category: BookingFormConfigCategoryEnum.BOOKING,
        key: "resourceId",
        type: DetailFormComponentsEnum.SELECT_FIELD,
        model: this.value.resourceId,
        searchKeywords: [],
        props: {
          label: this.$t("objects.booking.resourceId"),
          items: this.resources.map(resource => ({
            text: resource.name,
            value: resource.id
          }))
        }
      },
      {
        category: BookingFormConfigCategoryEnum.BOOKING,
        key: "startDate",
        type: DetailFormComponentsEnum.TEXT_FIELD,
        model: this.value.startDate,
        searchKeywords: [],
        props: {
          type: "date",
          label: this.$t("objects.booking.date")
        }
      },
      {
        category: BookingFormConfigCategoryEnum.BOOKING,
        key: "startTime",
        type: DetailFormComponentsEnum.TEXT_FIELD,
        model: this.value.startTime,
        searchKeywords: [],
        props: {
          type: "time",
          label: this.$t("objects.booking.from")
        }
      },
      {
        category: BookingFormConfigCategoryEnum.BOOKING,
        key: "endTime",
        type: DetailFormComponentsEnum.TEXT_FIELD,
        model: this.value.endTime,
        searchKeywords: [],
        props: {
          type: "time",
          label: this.$t("objects.booking.to")
        }
      },
      {
        category: BookingFormConfigCategoryEnum.BOOKING,
        key: "bookingInformation.notes" as any,
        type: DetailFormComponentsEnum.TEXT_AREA,
        model: this.value.bookingInformation?.notes,
        searchKeywords: [],
        props: {
          label: this.$t("objects.booking.notes")
        }
      }
    ];

    return config;
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    const fields: IMDetailTableConfig<IBooking>[] = [
      {
        key: "start",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "end",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "resourceId",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      }
    ];

    if (this.value.isDeleted) {
      fields.push({
        key: "deletedAt",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    }

    // Booking is not of type blocker
    if (this.value.serviceId) {
      fields.push({
        key: "serviceId",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });

      fields.push({
        key: "customerInformation",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      });
    }

    /**
     * Add custom fields if any
     * Show them in the table only in overview page.
     * In edit mode(detail page), they should show in the form
     */
    if (this.value.values && this.value.values.length > 0 && !this.isEdit) {
      for (const value of this.value?.values) {
        fields.push({
          key: value.id as any,
          type: DetailTableTypeEnum.CUSTOM
        });
      }
    }

    return fields;
  }
  // #endregion configuration

  /**
   * Actual custom fields part of the service
   */
  get serviceCustomFields() {
    return this.service.customFieldConfig.map(customFieldConfig => customFieldConfig.customField);
  }

  /**
   * Update the booking notes inline.
   * Set the notes to the model and call update booking.
   * @param notes booking information notes
   */
  updateBookingInformation(notes: string) {
    if (!this.value.bookingInformation) {
      this.value.bookingInformation = { notes: "" };
    }
    this.value.bookingInformation.notes = notes;
    this.onUpdateBookingClicked();
  }

  processAction(action: IAction) {
    switch (action.key) {
      case BookingMDetailActionEnum.DELETE_BOOKING: {
        this.isDeleteDialogActive = true;
        break;
      }
      default:
        /** Actions not covered are just emitted */
        this.$emit("goTo", { action: action.key, booking: this.value } as BookingGoToActionEmitData);
    }
  }

  async deleteBooking() {
    this.isDeleteDialogLoading = true;

    try {
      await this.value.delete(this.notifyUserForDelete);
      this.$toast.success("👍");

      this.$emit("delete");
    } catch (error) {
      handleError(error);
    } finally {
      this.isDeleteDialogLoading = false;
      this.isDeleteDialogActive = false;
      this.notifyUserForDelete = false;
    }
  }

  /**
   * Syncs the changes from the form to the value
   * and show the update confirmation dialog.
   */
  async syncChanges() {
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      setNestedObjectValues(this.value, key, config.getConfigValueByKey(key));
    }

    this.isUpdateDialogActive = true;
  }

  /**
   * Emits data to parent component and closes the update confirmation dialog.
   */
  onUpdateBookingClicked() {
    const eventData: BookingPortalFormEmitInputData = {
      booking: this.value,
      notifyUserForUpdate: this.notifyUserForUpdate
    };

    this.$emit("input", eventData);
    this.isUpdateDialogActive = false;
  }

  async abortChanges() {
    this.isUpdateDialogActive = false;
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      config.configValueByKey = { key: key, value: this.value[key] };
    }
  }
}
