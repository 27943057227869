import { PersonRouteNames } from "@/lib/utility/person.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import PersonDetailView from "@/views/person/PersonDetailView.vue";
import PersonDetailFormView from "@/views/person/PersonDetailFormView.vue";
import PersonTableView from "@/views/person/PersonTableView.vue";
import PersonGroupDetailView from "@/views/person/group/PersonGroupDetailView.vue";
import PersonGroupTableView from "@/views/person/group/PersonGroupTableView.vue";
import PersonGroupCustomViewView from "@/views/person/group/PersonGroupCustomViewView.vue";
import PersonImportView from "@/views/person/PersonImportView.vue";

/**
 * Routes for companies and tickets
 */
export const personRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/person/",
    component: PersonTableView,
    name: PersonRouteNames.PERSON_TABLE,
    permission: {
      resource: ResourceEnum.PERSON,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.CRM,
      icon: "mdi-card-account-mail-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 43
    }
  },
  {
    path: "/partner/:partnerId/person/import",
    component: PersonImportView,
    name: PersonRouteNames.PERSON_IMPORT,
    permission: {
      resource: ResourceEnum.PERSON,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/person/:personId",
    component: PersonDetailView,
    name: PersonRouteNames.PERSON_DETAIL,
    permission: {
      resource: ResourceEnum.PERSON,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/person/:personId/form",
    component: PersonDetailFormView,
    name: PersonRouteNames.PERSON_DETAIL_FORM,
    permission: {
      resource: ResourceEnum.PERSON,
      action: ActionEnum.UPDATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/person-group/",
    component: PersonGroupTableView,
    name: PersonRouteNames.PERSON_GROUP_TABLE,
    permission: {
      resource: ResourceEnum.PERSON_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.CRM,
      icon: "mdi-account-group-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 43
    }
  },
  {
    path: "/partner/:partnerId/person-group/:personGroupId/",
    component: PersonGroupCustomViewView,
    name: "PERSON_GROUP_CUSTOM_VIEW_HOME",
    permission: {
      resource: ResourceEnum.PERSON_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/person-group/:personGroupId/settings",
    component: PersonGroupDetailView,
    name: PersonRouteNames.PERSON_GROUP_DETAIL,
    permission: {
      resource: ResourceEnum.PERSON_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/person-group/:personGroupId/view/:viewId",
    component: PersonGroupCustomViewView,
    name: PersonRouteNames.PERSON_GROUP_CUSTOM_VIEW,
    permission: {
      resource: ResourceEnum.PERSON_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
