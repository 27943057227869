













import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { TicketModule } from "@/store/modules/ticket.store";
import ProjectInitializeListsMixin from "@/views/project/mixins/ProjectInitializeListsMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TicketTableCard from "../project/TicketTableCard.vue";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: { TicketTableCard }
})
export default class FleetVehicleDetailTicketCard extends mixins(ProjectInitializeListsMixin) {
  @Prop()
  vehicle!: IVehicle;

  get refs(): MrfiktivReferenceGen[] {
    return [
      {
        refId: this.vehicle.id,
        refType: BackendResourceEnum.VEHICLE
      }
    ];
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
  }

  async mounted() {
    const partnerId = this.partnerId;
    const promises = [];

    TicketModule.setFilter([new PageFilterElement({ key: "state", value: TicketStatusEnum.OPEN, operation: "$eq" })]);
    TicketModule.setHiddenFilter([
      new PageFilterElement({ key: "refs.refId", value: this.vehicle.id, operation: "$eq" })
    ]);
    promises.push(this.getTickets(partnerId));
    promises.push(this.getProjects());
    promises.push(this.getVehicles());
    await Promise.all(promises);
  }
}
