




















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import EventCard from "@/components/event/EventCard.vue";
import FleetVehicleEventCrudMixin from "@/components/fleet/FleetVehicleEventCrudMixin.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IVehicle } from "@/models/vehicle.entity";
import { EventModule } from "@/store/modules/event.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: { EventCard, TheLayoutPortal, LatestEntriesCardEmpty }
})
export default class EventDetailView extends mixins(FleetVehicleEventCrudMixin, PartnerFallbackMixin) {
  loading = true;

  value: IEventUIDto | null = null;

  vehicle: IVehicle | null = null;

  get eventId() {
    return this.$route.params.eventId;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  async mounted() {
    this.loading = true;
    await this.trySetByRouteOrDefault();
    await this.getEvent()
      .then(this.getVehicle)
      .catch(handleError)
      .finally(() => {
        this.loading = false;
      });
  }

  async getEvent() {
    const partnerId = this.partnerId;
    const eventId = this.eventId;
    const event = await EventModule.getOne({ partnerId, eventId });
    this.value = event;
  }

  async getVehicle() {
    if (!this.value) return;
    if (!this.value.vehicleId) return;

    try {
      this.vehicle = await VehicleModule.getOne({
        partnerId: this.partnerId,
        vehicleId: this.value.vehicleId
      });
    } catch (error) {
      this.$log.error(error);
    }
  }

  async onUpdateEvent(eventUIDto: IEventUIDto) {
    try {
      this.loadingUpdate = true;
      await this.updateEvent(eventUIDto, eventUIDto.vehicleId ?? "", new Date(eventUIDto.start));
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }

  async onDeleteEvent() {
    try {
      if (!this.value) return;
      this.loadingUpdate = true;
      await this.deleteEvent(this.value, this.vehicle?.id ?? this.value.vehicleId ?? "", new Date(this.value.start));

      await new GoToHelper(this.$router).goToVehicleDetail(
        this.value.vehicleId ?? this.vehicle?.id ?? "",
        this.partnerId,
        VehicleTabs.EVENT,
        false
      );
      this.value = null;
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
    }
  }

  async goToDetail(event: IEventUIDto) {
    await new GoToHelper(this.$router).goToEventDetail(this.partnerId, event.id ?? "", false);
  }

  async goToEdit(event: IEventUIDto) {
    await new GoToHelper(this.$router).goToEventDetailEdit(this.partnerId, event.id ?? "", false);
  }
}
