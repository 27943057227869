





























import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";
import RefsList from "../utility/RefsList.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CreateFleetDialog from "./CreateFleetDialog.vue";
import { FleetModule } from "@/store/modules/fleet.store";
import { IFleet } from "@/models/fleet.entity";

@Component({
  components: {
    PaginatedTable,
    CreateFleetDialog,
    RefsVehicle,
    RefsList,
    AssigneePreview
  },
  filters: {
    simpleDate
  }
})
export default class FleetTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = FleetModule;

  get userId() {
    return UserModule.userId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.partner.id;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.fleet.isRoot"),
      value: "isRoot",
      sortable: false
    });

    headers.push({
      text: $t("objects.fleet.title"),
      value: "title",
      sortable: false
    });

    headers.push({
      text: $t("objects.fleet.assignees"),
      value: "assignees",
      width: "150px"
    });

    headers.push({
      text: $t("objects.fleet.timestamp.created"),
      value: "timestamp.created"
    });

    return headers;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    predefinedFilter.push({
      name: "objects.fleet.mine",
      filter: [
        new PageFilterElement({
          key: "assignees",
          value: this.userId,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    predefinedFilter.push({
      name: "objects.fleet.isRoot",
      filter: [
        new PageFilterElement({
          key: "isRoot",
          value: true as any,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  setSelectedItem(item: IFleet) {
    this.$log.debug(item);
    this.$router.push({ name: "FleetDetailView", params: { partnerId: item.partnerId, fleetId: item.id } });
  }

  beforeMount() {
    this.store.setFilters([
      new PageFilterElement({
        key: "isRoot",
        value: true as any,
        operation: PageFilterOperationEnum.EQUAL
      })
    ]);
  }
}
