import { IGroupTypeEntityCreateDialog } from "@/lib/utility/group/interface/group-type-entity-create-dialog.interface";
import { GroupTypeComponentMap } from "./group-type-components.map";

export const GroupTypeCreateDialogs = (() => {
  let dialogs: Record<string, IGroupTypeEntityCreateDialog> = {};

  Array.from(GroupTypeComponentMap.keys()).forEach(value => {
    const createDialog = GroupTypeComponentMap.get(value)?.createDialog;
    if (!createDialog) {
      return;
    }

    dialogs = {
      [`${value}-create-dialog`]: createDialog,
      ...dialogs
    };
  });

  return dialogs;
})();
