var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            : !_vm.value
            ? _c("latest-entries-card-empty")
            : _c("snapshot-detail", {
                attrs: {
                  hideBreadCrumbs: false,
                  hideToDetailButton: true
                },
                model: {
                  value: _vm.value,
                  callback: function callback($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }