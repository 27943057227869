
























































































































import CompanyGroupCreateDialog from "@/components/company/group/CompanyGroupCreateDialog.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import RefsCompany from "@/components/utility/RefsCompany.vue";
import RefsCompanyGroup from "@/components/utility/RefsCompanyGroup.vue";
import { getFlagEmoji, getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { emailRule, phoneRuleOptional } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Address } from "@/models/address.entity";
import { CompanyGroup, ICompanyGroup } from "@/models/company-group.entity";
import { Company, ICompany } from "@/models/company.entity";
import { Contact } from "@/models/contact.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IChips } from "../utility/mmmint/MHeader.vue";
import SelectAssignees from "../utility/SelectAssignees.vue";
import { CustomFieldValue } from "@/models/custom-field-value.entity";
import { debounce } from "debounce";
import CompanyDetailTable from "./CompanyDetailTable.vue";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { VSkeletonLoader } from "vuetify/lib";

const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    ActivityCard: () => import("@/components/thg/ActivityCard.vue"),
    RefsCompanyGroup,
    RefsCompany,
    CustomFieldListForm,
    MActionList,
    ConfirmActionDialog,
    CompanyGroupCreateDialog,
    Debug,
    SelectAssignees,
    CopyClipboardText,
    AssigneePreview,
    CompanyDetailTable,
    PartnerMap
  },
  filters: {
    getFlagEmojiByLanguage,
    getFlagEmoji
  }
})
export default class CompanyDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly CompanyServiceEnum = CompanyServiceEnum;
  readonly Contact = Contact;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;
  readonly formable = Company;

  @Prop()
  value!: ICompany;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  companyGroup: ICompanyGroup | null = null;

  isLoadingCompanyGroup = false;

  isLoadingCompanyGroups = false;

  isLoadingCustomFieldValues = false;

  get showMoreAction(): IAction {
    return {
      text: $t("common.nouns.detail"),
      key: "detail",
      description: $t("common.nouns.detail"),
      exec: () =>
        new CompanyGoToHelper(this.$router).goToCompanyDetailForm({
          partnerId: this.value.partnerId,
          companyId: this.value.id,
          newTab: false
        }),
      disabled: !this.can(ActionEnum.UPDATE, ResourceEnum.COMPANY)
    };
  }

  get color() {
    return ConfigModule.color.primary;
  }

  get coordinates() {
    return this.value.addresses?.[0]?.geo || undefined;
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [requiredRule(), emailRule()];
  }

  get phoneRule() {
    return [phoneRuleOptional()];
  }

  get subtitle() {
    if (this.value.userId && this.getUserNameForId(this.value.userId)) {
      return $t("createdOnBy", {
        date: simpleDoubleDigitDate(this.value.timestamp.created),
        name: this.getUserNameForId(this.value.userId)
      });
    }

    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get chips(): IChips[] {
    return (
      this.value.services?.map(s => {
        return {
          key: s.toString(),
          text: $t(`enums.CompanyServiceEnum.${s}`).toString()
        };
      }) ?? []
    );
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const breadCrumbs = CompanyGoToHelper.breadCrumbs;

    const selected = [];
    if (this.value.groupId) {
      selected.push(breadCrumbs.CompanyGroupTable(this.value.partnerId));
      selected.push(breadCrumbs.CompanyGroupCustomView(this.value.partnerId, this.value.groupId));
    } else {
      selected.push(breadCrumbs.CompanyTable(this.value.partnerId));
    }
    selected.push(breadCrumbs.CompanyDetail(this.value.partnerId, this.value.id));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    const companyGroupId = this.value.groupId;
    if (companyGroupId) {
      actions.push({
        text: $t("company.toCompanyGroup"),
        key: "toCompanyGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
            partnerId: this.value.partnerId,
            companyGroupId: companyGroupId,
            viewId: "0",
            newTab: false
          })
      });
    }

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.COMPANY)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  get groups() {
    return CompanyGroupModule.entities;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.COMPANY
    };
  }

  debounceSaveCustomFields = debounce(this.saveCustomFieldValues, 1000);

  mounted() {
    this.setCompanyGroup();
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  goToCompanyGroupCustomView() {
    if (!this.value.groupId) return;

    new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
      partnerId: this.value.partnerId,
      companyGroupId: this.value.groupId,
      viewId: "0",
      newTab: false
    });
  }

  @Watch("value.groupId")
  async setCompanyGroup() {
    this.companyGroup = null;

    const companyGroupId = this.value.groupId;
    if (companyGroupId) {
      this.isLoadingCompanyGroup = true;
      this.isLoadingCustomFieldValues = true;

      const companyGroup =
        CompanyGroupModule.maps.id.get(companyGroupId)[0] ||
        (await new CompanyGroup({ partnerId: this.partner.id, id: companyGroupId }).fetch().catch(handleError));
      this.isLoadingCompanyGroup = false;
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.companyGroup = companyGroup;
      });
    }
  }

  async saveGroup() {
    this.isLoadingCompanyGroup = true;
    await this.value
      .updatePartial({
        groupId: this.value.groupId
      })
      .catch(handleError);
    this.isLoadingCompanyGroup = false;
  }

  async saveCustomFieldValues() {
    this.isLoadingCustomFieldValues = true;
    await this.value
      .updatePartial({
        values: CustomFieldValue.buildCustomFieldValuesDto(
          this.value.values.map(v => ({ id: v.id, value: v.value, timezone: v.timezone }))
        )
      })
      .catch(handleError);
    this.isLoadingCustomFieldValues = false;
  }

  async refreshCompanyGroups() {
    this.isLoadingCompanyGroups = true;
    try {
      CompanyGroupModule.setFilter([]);
      await CompanyGroupModule.fetchAll({ partnerId: PartnerModule.partner.id });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingCompanyGroups = false;
  }

  goToCompanyDetailForm() {
    new CompanyGoToHelper(this.$router).goToCompanyDetailForm({
      partnerId: this.value.partnerId,
      companyId: this.value.id,
      newTab: false
    });
  }

  async onAssigneesUpdate(assignees: []) {
    if (!this.value) return;

    await this.value.updatePartial({ assignees });
  }

  async onAssigneesAdded(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, assignees);
  }

  async onAssigneesRemoved(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, assignees);
  }
}
