var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c("import-overview-card", {
        attrs: {
          importConfig: _vm.importConfig,
          title: _vm.$t("components.fleet.FleetEventImportCard.title"),
          goBack: "FleetHome"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function fn(slotProps) {
              return [
                _c(slotProps.component, {
                  tag: "span",
                  attrs: {
                    outlined: false,
                    small: true,
                    partnerId: _vm.partnerId
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }