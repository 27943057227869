var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("partner-report-card-images", {
            ref: "images",
            staticClass: "ma-1",
            attrs: {
              isCaptureEnvironment: true,
              hideSelect: true,
              dto: _vm.report
            },
            on: {
              "update:dto": function updateDto($event) {
                _vm.report = $event
              }
            }
          }),
          _c("partner-report-card-images", {
            ref: "images",
            staticClass: "ma-1",
            attrs: {
              isCaptureEnvironment: true,
              hideSelect: true,
              dto: _vm.report
            },
            on: {
              "update:dto": function updateDto($event) {
                _vm.report = $event
              }
            }
          }),
          _c("br"),
          _c("br"),
          _c("v-divider"),
          _c("br"),
          _c("br"),
          _c("refs-selection-by-type", {
            attrs: {
              partnerId: _vm.partnerId,
              refType: _vm.refType,
              showAsMenu: true,
              selected: _vm.selected
                ? [
                    {
                      refId: _vm.selected.id,
                      refType: "ticket"
                    }
                  ]
                : []
            },
            on: {
              select: _vm.select
            }
          }),
          _vm._v(" selected: " + _vm._s(_vm.selected) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }