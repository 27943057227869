






import CompanyImportCard from "@/components/company/CompanyImportCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    CompanyImportCard
  }
})
export default class CompanyImportView extends mixins(PartnerFallbackMixin, PermissionMixin) {}
