






































































import MDetailTableFormable from "@/components/utility/mmmint/MDetailTableFormable.vue";
import { copyToClipboard } from "@/lib/utility/copy-to-clipboard.helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { Contract, IContract } from "@/models/contract.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsSelected from "../utility/RefsSelected.vue";

@Component({
  components: {
    MDetailTableFormable,
    RefsSelected
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ContractDetailTable extends mixins(Vue) {
  readonly formable = Contract;
  readonly ResourceEnum = ResourceEnum;
  readonly simpleDoubleDigitDate = simpleDoubleDigitDate;
  readonly copyToClipboard = copyToClipboard;

  @Prop()
  value!: IContract;

  @Prop()
  onDetail?: () => void;
}
