var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("excel-import-create", {
    attrs: {
      dto: _vm.createDto,
      baseData: {
        partnerId: _vm.partnerId
      },
      outlined: _vm.outlined,
      small: _vm.small,
      text: _vm.$t("common.nouns.import")
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }