













import { CompanyRouteNames } from "@/lib/utility/company.go-to-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ImportOverviewCard, { IImportOverviewConfig } from "../utility/ImportOverviewCard.vue";
import CompanyImportDialog from "./CompanyImportDialog.vue";

@Component({
  components: { ImportOverviewCard, CompanyImportDialog }
})
export default class CompanyImportCard extends mixins(PermissionMixin) {
  CompanyRouteNames = CompanyRouteNames;
  get importConfig(): IImportOverviewConfig[] {
    return [
      {
        category: this.$t("components.CompanyImportCard.category").toString(),
        items: [
          {
            title: this.$t("components.CompanyImportCard.itemTitle").toString(),
            description: this.$t("components.CompanyImportCard.itemDescription").toString(),
            component: "company-import-dialog",
            icon: "mdi-office-building",
            hasPermission: this.canCreateCompany
          }
        ]
      }
    ];
  }
}
