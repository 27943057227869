var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            ],
            1
          )
        : _vm.handover
        ? _c(
            "v-container",
            [
              _c("m-header", {
                attrs: {
                  title: _vm.title,
                  breadCrumbs: _vm.breadCrumbs,
                  actions: _vm.actions
                },
                on: {
                  actionClicked: function actionClicked($event) {
                    return $event.exec()
                  }
                }
              }),
              _c("handover-report-table", {
                ref: "handoverReportTable",
                attrs: {
                  partnerId: _vm.partnerId,
                  handoverId: _vm.handoverId
                }
              }),
              _c("create-handover-finding-dialog", {
                ref: "createHandoverFindingDialog",
                attrs: {
                  vehicles: _vm.vehicles,
                  handover: _vm.handover
                },
                on: {
                  "create:inspection": _vm.openCreateInspectionDialog
                }
              }),
              _c("inspection-create-dialog", {
                ref: "inspectionCreateDialog",
                attrs: {
                  handover: _vm.handover
                }
              })
            ],
            1
          )
        : _c("latest-entries-card-empty")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }