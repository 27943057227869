




import { Component, Prop, Vue } from "vue-property-decorator";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import { IGroupTypeEntity } from "@/lib/utility/group/interface/group-type-entity.interface";
import { GroupTypeSideCards } from "@/lib/utility/group/group-type-components-side-cards.const";

@Component({
  components: GroupTypeSideCards as any,
  filters: {}
})
export default class GroupTypeEntitySideCard extends Vue {
  @Prop()
  value!: IGroupTypeEntity;

  @Prop()
  type!: IGroupType;

  get component() {
    return `${this.type}-side-card`;
  }
}
