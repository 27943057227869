import { PartnerUserRouteNames } from "@/lib/utility/partner-user.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import PartnerUserDetailView from "@/views/partner-user/PartnerUserDetailView.vue";
import PartnerUserTableView from "@/views/partner-user/PartnerUserTableView.vue";

/**
 * Routes for companies and tickets
 */
export const partnerUserRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/user/",
    component: PartnerUserTableView,
    name: PartnerUserRouteNames.PARTNER_USER_TABLE,
    permission: {
      resource: ResourceEnum.USER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.CRM,
      icon: "mdi-account-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/user/:partnerUserId",
    component: PartnerUserDetailView,
    name: PartnerUserRouteNames.PARTNER_USER_DETAIL,
    permission: {
      resource: ResourceEnum.USER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
