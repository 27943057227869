








































import Debug from "@/components/utility/Debug.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IContract } from "@/models/contract.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IReference, Reference } from "@/models/reference.entity";
import { RefsContractModule } from "@/store/modules/refs.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsSelectionByType from "../utility/RefsSelectionByType.vue";
import RefsList from "../utility/RefsList.vue";

/**
 * Dialog to pick a an entity and add refs to it
 */
@Component({
  components: {
    RefsSelectionByType,
    RefsList,
    ConfirmActionDialog,
    Debug
  },
  filters: {}
})
export default class ContractSelectByClientOrProviderUpdateDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  @Prop({ default: undefined })
  predefinedFilter!: { name: string; filter: IPageFilterElement[] }[];

  @Prop()
  refs!: IReference[];

  @Prop({ default: "contract.addContract" })
  title!: string;

  @Prop({ default: false })
  isProvider!: boolean; // otherwise its client

  isLoading = false;

  isDialogActive = false;

  selectedEntity: IContract | null = null;

  get leftText() {
    if (this.selectedEntity) {
      return this.$t("back");
    }

    return this.$t("close");
  }

  leftClick() {
    if (this.selectedEntity) {
      this.selectedEntity = null;
      return;
    }

    this.close();
  }

  open() {
    this.selectedEntity = null;
    const filters = [];
    for (const ref of this.refs) {
      filters.push(
        new PageFilterElement({
          key: this.isProvider ? "providerIds.refId" : "clientIds.refId",
          operation: PageFilterOperationEnum.NOT_EQUAL,
          value: ref.refId
        })
      );
    }
    RefsContractModule.setFilters(filters);
    this.isDialogActive = true;
  }

  close() {
    this.isDialogActive = false;
    this.selectedEntity = null;
    RefsContractModule.setFilters([]);
  }

  async addRefsToContract() {
    if (!this.selectedEntity || !this.selectedEntity.updatePartial) {
      return;
    }

    this.isLoading = true;

    try {
      const updateDto = this.isProvider
        ? {
            providerIds: Reference.filterDuplicates([...this.selectedEntity.providerIds, ...this.refs])
          }
        : {
            clientIds: Reference.filterDuplicates([...this.selectedEntity.clientIds, ...this.refs])
          };
      await this.selectedEntity.updatePartial(updateDto);
      this.close();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
