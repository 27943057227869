













































import Debug from "@/components/utility/Debug.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { CompanyGroup } from "@/models/company-group.entity";
import { ICustomField } from "@/models/custom-field.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import UpdateForm from "../utility/UpdateForm.vue";
import CompanyDetail from "./CompanyDetail.vue";
import CompanyDetailTable from "./CompanyDetailTable.vue";

@Component({
  components: {
    UpdateForm,
    MHeader,
    MDetailViewGrid,
    MActionList,
    ConfirmActionDialog,
    Debug,
    CompanyDetailTable
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class CompanyDetailForm extends CompanyDetail {
  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = CompanyGoToHelper.breadCrumbs;

    const selected = [];

    if (this.value.groupId) {
      selected.push(breadCrumbs.CompanyGroupTable(this.value.partnerId));
      selected.push(breadCrumbs.CompanyGroupCustomView(this.value.partnerId, this.value.groupId));
    } else {
      selected.push(breadCrumbs.CompanyTable(this.value.partnerId));
    }
    selected.push(breadCrumbs.CompanyDetail(this.value.partnerId, this.value.id));
    selected.push(breadCrumbs.CompanyDetailForm(this.value.partnerId, this.value.id));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetailForm({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    const companyGroupId = this.value.groupId;
    if (companyGroupId) {
      actions.push({
        text: $t("company.toCompanyGroup"),
        key: "toCompanyGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
            partnerId: this.value.partnerId,
            companyGroupId: companyGroupId,
            viewId: "0",
            newTab: false
          })
      });
    }

    if (this.value.id) {
      actions.push({
        text: $t("company.toCompany"),
        key: "toCompany",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            partnerId: this.value.partnerId,
            companyId: this.value.id,
            newTab: false
          })
      });
    }

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.COMPANY)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  async loadCustomConfig(value: string): Promise<ICustomField[]> {
    if (!value) return [];

    const companyGroup =
      CompanyGroupModule.maps.id.get(value)[0] ||
      (await new CompanyGroup({ partnerId: this.partner.id, id: value }).fetch().catch(handleError));

    return companyGroup?.configuration.customFieldConfig?.map(c => c.customField) || [];
  }
}
