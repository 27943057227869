















import PartnerBankingTable from "@/components/partner-banking/PartnerBankingTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PartnerBankingGoToHelper } from "@/lib/utility/partner-banking.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerBanking, IPartnerBanking } from "@/models/partner-banking.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerBankingModule } from "@/store/modules/partner-banking.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    PartnerBankingTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerBankingTableView extends mixins(PartnerFallbackMixin) {
  @Ref("partnerBankingTable")
  partnerBankingTable!: PartnerBankingTable;

  readonly AMOUNT_PARTNER_BANKING = "amountPartnerBanking";

  /**
   * refs that will be set in new partnerBankings and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  selectedItems: IPartnerBanking[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(PartnerBankingGoToHelper.breadCrumbs.PartnerBankingTable({ partnerId: this.partnerId }));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${PartnerBankingModule.filtered.length} ${this.$t("partnerBanking.partnerBankings")}`,
      key: this.AMOUNT_PARTNER_BANKING,
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    PartnerBankingModule.setHiddenFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const partnerBankingId = this.$route.query.partnerBankingId as string;
    if (partnerBankingId) {
      const partnerBanking = new PartnerBanking({ partnerId: this.partnerId, id: partnerBankingId });
      this.partnerBankingTable?.setSelectedItem(partnerBanking);
    }
  }

  openSideCard(partnerBanking: IPartnerBanking) {
    new PartnerBankingGoToHelper(this.$router).setUrl(
      PartnerBankingGoToHelper.locations.partnerBankingTable({
        partnerId: this.partnerId,
        query: partnerBanking ? { partnerBankingId: partnerBanking.id } : {}
      })
    );
  }
}
