var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _vm.title
        ? _c("v-card-subtitle", [_vm._v(_vm._s(_vm.$t(_vm.title)))])
        : _vm._e(),
      _c("v-simple-table", [
        _c("tbody", [
          _c(
            "tr",
            {
              staticStyle: {
                cursor: "pointer"
              },
              on: {
                click: _vm.goToFleet
              }
            },
            [
              _c("td", [_vm._v(_vm._s(_vm.$t("common.nouns.fleet")))]),
              _c("td", [_vm._v(" " + _vm._s(_vm.currentName) + " ")]),
              _c("td", [
                _c(
                  "div",
                  {
                    staticClass: "actionButtons  text-right"
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          "max-width": "300"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function fn(_ref) {
                              var on = _ref.on,
                                attrs = _ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          loading: _vm.loading,
                                          icon: "",
                                          "x-small": "",
                                          color: "info"
                                        },
                                        on: {
                                          click: function click($event) {
                                            $event.stopPropagation()
                                            return _vm.openCurrentMenu.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-pencil")])],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.currentMenu,
                          callback: function callback($$v) {
                            _vm.currentMenu = $$v
                          },
                          expression: "currentMenu"
                        }
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                outlined: "",
                                "hide-details": "",
                                clearable: "",
                                solo: "",
                                flat: "",
                                dense: "",
                                items: _vm.fleets,
                                "item-value": "id",
                                "item-text": "title",
                                "append-outer-icon": "mdi-refresh",
                                loading: _vm.loading
                              },
                              on: {
                                "click:append-outer": _vm.refreshFleet
                              },
                              model: {
                                value: _vm.tmp,
                                callback: function callback($$v) {
                                  _vm.tmp = $$v
                                },
                                expression: "tmp"
                              }
                            }),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "info",
                                      outlined: "",
                                      small: ""
                                    },
                                    on: {
                                      click: _vm.closeMenu
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.fleet.EventCreateDialog.close"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "info",
                                      elevation: "0",
                                      small: ""
                                    },
                                    on: {
                                      click: _vm.update
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.fleet.EventCreateDialog.save"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }