











































































import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { IHasBankingInformation } from "@/lib/utility/data/has-banking-information.interface";
import { IHasUpdatePartial } from "@/lib/utility/data/has-update-partial.interface";
import PartnerBankingSideCard from "./PartnerBankingSideCard.vue";
import { IPartnerBanking, PartnerBanking } from "@/models/partner-banking.entity";
import { ITableWrapperHeader, IControlElements } from "@/lib/types/tableWrapper";
import { PartnerBankingModule } from "@/store/modules/partner-banking.store";
import { handleError } from "@/lib/utility/handleError";
import { IHasPartnerIdOptional } from "@/lib/interfaces/has-partner-id.interface";
import { $t } from "@/lib/utility/t";
import TableWrapper from "../utility/TableWrapper.vue";
import PartnerBankingCreateDialog from "./PartnerBankingCreateDialog.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MDetailTableFormable from "../utility/mmmint/MDetailTableFormable.vue";
import { BillingProfile } from "@/models/banking-information.entity";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsPartnerBanking from "../utility/RefsPartnerBanking.vue";
import UpdateForm from "../utility/UpdateForm.vue";

@Component({
  components: {
    ConfirmActionDialog,
    TableWrapper,
    PartnerBankingSideCard,
    PartnerBankingCreateDialog,
    MDetailViewGrid,
    MDetailTableFormable,
    RefsPartnerBanking,
    UpdateForm
  },
  filters: {}
})
export default class PartnerHasPartnerBankingIdsTable<
  T extends IHasBankingInformation & IHasPartnerIdOptional,
  UpdateDto extends Partial<IHasBankingInformation>
> extends mixins(PermissionMixin) {
  readonly formable = BillingProfile;

  @Prop()
  value!: T & IHasUpdatePartial<T, UpdateDto>;

  isDeleteDialogActive = false;

  isUpdateDialogActive = false;

  isDeleteLoading = false;

  partnerBankings: IPartnerBanking[] = [];

  selectedItem: IPartnerBanking | null = null;

  selectedItemForDeletion: IPartnerBanking | null = null;

  isLoadingEntities = false;

  isLoadingDescription = false;

  isEditDescription = false;

  descriptionCopy = this.value.billingProfile.note ?? "";

  isLoadingPaymentTerm = false;

  paymentTermCopy = this.value.billingProfile.paymentTerm ?? 0;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.createdReadable",
      width: "90px",
      align: "start"
    });
    headers.push({
      text: $t("objects.partnerBanking.isSepaDirectDebitMandate"),
      value: "isSepaDirectDebitMandate",
      width: "60px",
      align: "center"
    });
    headers.push({
      text: $t("objects.partnerBanking.name"),
      value: "name",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: $t("objects.partnerBanking.bank"),
      value: "bank",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: $t("objects.partnerBanking.iban"),
      value: "iban",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: "",
      value: "controls",
      align: "end",
      sortable: false
    });

    return headers;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("common.verbs.open"),
        action: this.setSelectedItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.startDeleteDialog
      }
    ];

    return controlElements;
  }

  async mounted() {
    this.isLoadingEntities = true;
    this.partnerBankings = await Promise.all(this.value.bankingIds?.map(id => this.fetchBanking(id)) ?? []);
    this.isLoadingEntities = false;
  }

  startEditDescription() {
    this.descriptionCopy = this.value.billingProfile.note ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.billingProfile.note ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        billingProfile: {
          note: this.descriptionCopy,
          paymentTerm: this.value.billingProfile.paymentTerm
        }
      } as UpdateDto)
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }

  async saveEditPaymentTerm() {
    this.isLoadingPaymentTerm = true;
    await this.value
      .updatePartial({
        billingProfile: {
          note: this.value.billingProfile.note,
          paymentTerm: Number(this.paymentTermCopy)
        }
      } as UpdateDto)
      .catch(handleError);
    this.isLoadingPaymentTerm = false;
    this.isUpdateDialogActive = false;
  }

  async fetchBanking(id: string) {
    let banking = PartnerBankingModule.maps.id.get(id)[0];
    if (!banking) {
      const entity = new PartnerBanking({
        id: id,
        partnerId: this.value.partnerId
      });
      await entity.fetch().catch(handleError);
      banking = entity;
    }

    return banking;
  }

  setSelectedItem(banking: IPartnerBanking | null) {
    this.selectedItem = null;
    this.$nextTick(() => {
      this.selectedItem = banking;
    });
  }

  async onCreated(banking: IPartnerBanking) {
    this.isLoadingEntities = true;
    const bankingIds: string[] = [...(this.value.bankingIds ?? []), banking.id];
    const updateDto = { bankingIds } as UpdateDto;
    await this.value
      .updatePartial(updateDto)
      .then(() => this.partnerBankings.push(banking))
      .catch(this.$log.error);
    this.isLoadingEntities = false;
  }

  startDeleteDialog(banking: IPartnerBanking) {
    this.isDeleteDialogActive = true;
    this.selectedItemForDeletion = banking;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;
      this.isLoadingEntities = true;

      await this.selectedItemForDeletion?.delete();
      const bankingIds = this.value.bankingIds?.filter(id => id !== this.selectedItemForDeletion?.id);
      await this.value.updatePartial({ bankingIds } as UpdateDto);
      const partnerBankings = this.partnerBankings.filter(
        partnerBanking => partnerBanking.id !== this.selectedItemForDeletion?.id
      );
      this.partnerBankings.splice(0, this.partnerBankings.length, ...partnerBankings);

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
      this.isLoadingEntities = false;
      this.selectedItemForDeletion = null;
    }
  }

  openUpdateDialog() {
    this.isUpdateDialogActive = true;
  }
}
