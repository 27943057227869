






























































import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { CompanyGroup } from "@/models/company-group.entity";
import { ICompany } from "@/models/company.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { CompanyModule } from "@/store/modules/company.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsCompany from "../utility/RefsCompany.vue";
import RefsCompanyGroup from "../utility/RefsCompanyGroup.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CompanyCreateDialog from "./CompanyCreateDialog.vue";
import CompanySideCard from "./CompanySideCard.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import RefsCompanyAddress from "../utility/RefsCompanyAddress.vue";
import { PredefinedFilterType } from "@/views/event/filter/event.filter";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { UserModule } from "@/store/modules/me-user.store";
import { IReference } from "@/models/reference.entity";
import Tooltip from "@/components/utility/tooltip.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CompanyCreateDialog,
    CompanySideCard,
    RefsCompanyGroup,
    RefsCompany,
    Debug,
    RefsCompanyAddress,
    AssigneePreview,
    Tooltip
  }
})
export default class CompanyTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = CompanyModule;

  readonly CompanyServiceEnum = CompanyServiceEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop({ default: () => [] })
  refs!: IReference[];

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  @Prop({ default: true })
  showIsFleetColumn!: boolean;

  @Prop()
  controlElements?: IControlElements[] | undefined;

  selectedItem: ICompany | null = null;

  selectedItemForDelete: ICompany | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.createdReadable",
      width: "120px"
    });

    if (this.showIsFleetColumn) {
      headers.push({
        text: $t("objects.company.isFleet"),
        value: "isFleet",
        width: "100px"
      });
    }

    headers.push({
      text: $t("objects.company.services"),
      value: "services",
      width: "100px"
    });

    headers.push({
      text: $t("objects.company.assignees"),
      align: "start",
      value: "assignees",
      width: "150px"
    });

    headers.push({
      text: $t("objects.company.companyName"),
      value: "companyName"
    });

    headers.push({
      text: $t("common.nouns.email"),
      value: "contact.email"
    });

    headers.push({
      text: $t("common.nouns.phone"),
      value: "contact.phone"
    });

    headers.push({
      text: $t("objects.company.groupId"),
      value: "groupId",
      width: "150px"
    });

    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get _controlElements(): IControlElements[] {
    if (this.controlElements) {
      return this.controlElements;
    }

    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: ICompany) =>
          new CompanyGoToHelper(this.$router).goToCompanyDetail({
            companyId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get userId() {
    return UserModule.userId;
  }

  get predefinedFilter(): PredefinedFilterType[] {
    const predefinedFilter: PredefinedFilterType[] = [];

    predefinedFilter.push({
      name: "project.ticket.mine",
      filter: [
        new PageFilterElement({
          key: "assignees",
          value: this.userId,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  async getCompanyGroup(id: string) {
    if (!id) return;
    let companyGroup = CompanyGroupModule.maps.id.get(id)[0];
    if (!companyGroup) {
      companyGroup = new CompanyGroup({ partnerId: this.partnerId, id });
      companyGroup.fetch();
    }

    return companyGroup;
  }

  groupName(id: string) {
    const group = CompanyGroupModule.maps.id.get(id);

    return group?.[0]?.title ?? "";
  }

  beforeMount() {
    CompanyModule.setFilters(this.filters);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: ICompany | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: ICompany) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
