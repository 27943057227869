var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.partner
        ? _c(
            "v-container",
            [
              _c("m-header", {
                attrs: {
                  breadCrumbs: _vm.breadCrumbList,
                  title: _vm.$t("company.companyTable"),
                  chips: _vm.chips
                }
              }),
              _c("company-table", {
                ref: "companyTable",
                attrs: {
                  partnerId: _vm.partnerId
                },
                on: {
                  openSideCard: _vm.openSideCard
                }
              })
            ],
            1
          )
        : _c("v-container", [_c("v-skeleton-loader")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }