













import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IPersonGroup, PersonGroup } from "@/models/person-group.entity";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import PersonGroupDetail from "@/components/person/group/PersonGroupDetail.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    PersonGroupDetail,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonGroupDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get personGroupId(): string {
    return this.$route.params.personGroupId;
  }

  isLoading = false;

  personGroup: IPersonGroup | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const personGroup =
      PersonGroupModule.maps.id.get(this.personGroupId)[0] ??
      new PersonGroup({
        id: this.personGroupId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await personGroup.fetch();
      this.personGroup = personGroup;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
