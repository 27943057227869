var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.FLEET,
            field: _vm.partnerId
          }
        },
        [
          _vm._t("activator", null, {
            on: _vm.open
          })
        ],
        2
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            title: _vm.$t("views.fleet.fleet.create"),
            isDialogActive: _vm.isDialogActive,
            rightLoading: _vm.isCreateLoading || _vm.isCompanyLoading,
            rightDisabled: !_vm.isValid,
            width: "1050"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            rightClick: _vm.create,
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            }
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isValid,
                callback: function callback($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c(
                "v-row",
                [
                  _vm.isDebug
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.parentId,
                              outlined: "",
                              label: _vm.$t("objects.fleet.parentId")
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t("objects.fleet.company")
                        },
                        model: {
                          value: _vm.isCompany,
                          callback: function callback($$v) {
                            _vm.isCompany = $$v
                          },
                          expression: "isCompany"
                        }
                      }),
                      _vm.isCompany
                        ? _c("v-autocomplete", {
                            ref: "companySelectField",
                            attrs: {
                              loading: _vm.isCompanyLoading,
                              disabled: _vm.isCreateLoading,
                              items: _vm.companies,
                              outlined: "",
                              label: _vm.$t("objects.fleet.company"),
                              "item-text": "companyName",
                              "item-value": "id",
                              "append-outer-icon": _vm.showLoadMore
                                ? "mdi-refresh"
                                : "",
                              clearable: ""
                            },
                            on: {
                              "click:append-outer": _vm.loadMoreCompanies,
                              input: _vm.useCompanyName
                            },
                            model: {
                              value: _vm.fleet.companyId,
                              callback: function callback($$v) {
                                _vm.$set(_vm.fleet, "companyId", $$v)
                              },
                              expression: "fleet.companyId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c("v-text-field", {
                        ref: "titleTextField",
                        attrs: {
                          rules: _vm.requiredRule,
                          outlined: "",
                          label: _vm.$t("objects.fleet.title")
                        },
                        model: {
                          value: _vm.fleet.title,
                          callback: function callback($$v) {
                            _vm.$set(_vm.fleet, "title", $$v)
                          },
                          expression: "fleet.title"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }