var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        absolute: true,
        loading: _vm.isLoading,
        width: !_vm.fullscreenBreakpoint ? "1200px" : "100%",
        hideTitle: true,
        fullscreen: _vm.fullscreenBreakpoint
      },
      on: {
        close: _vm.close
      }
    },
    [
      _c(
        "v-container",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line"
                }
              })
            : _vm.value
            ? _c("person-detail", {
                staticClass: "mt-1",
                attrs: {
                  value: _vm.value,
                  hideBreadCrumbs: true,
                  showDetailButton: true
                },
                on: {
                  deleted: _vm.close
                }
              })
            : _c("latest-entries-card-empty")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }