import { Contract } from "@/models/contract.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ContractModule } from "@/store/modules/contract.store";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { IGroupTypeEntity } from "./interface/group-type-entity.interface";
import { IGroupType } from "./interface/group-type.interface";

export const GroupTypeResourceMap: Map<
  IGroupType,
  { entity: IGroupTypeEntity; module: PaginatedBaseStore<any, any> }
> = new Map([[ResourceEnum.CONTRACT, { entity: Contract as IGroupTypeEntity, module: ContractModule }]]);
