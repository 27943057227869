














































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPersonGroup } from "@/models/person-group.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../../utility/ConfirmActionDialog.vue";
import { IControlElements, ITableWrapperHeader } from "../../utility/TableWrapper.vue";
import PersonGroupCreateDialog from "@/components/person/group/PersonGroupCreateDialog.vue";
import PersonGroupSideCard from "./PersonGroupSideCard.vue";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import RefsPersonGroup from "@/components/utility/RefsPersonGroup.vue";
import { handleError } from "@/lib/utility/handleError";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    PersonGroupCreateDialog,
    PersonGroupSideCard,
    RefsPersonGroup
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonGroupTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = PersonGroupModule;

  @Prop()
  partnerId!: string;

  selectedItem: IPersonGroup | null = null;

  selectedItemForDelete: IPersonGroup | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    if (!PersonGroupModule.entities.length) return [];

    return [
      {
        text: $t("project.project.created"),
        value: "timestamp.created",
        width: "110"
      },
      {
        text: $t("objects.personGroup.title"),
        value: "title",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.goToPersonGroupCustomView
      },
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: this.setSelectedItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  goToPersonGroupCustomView(item: IPersonGroup) {
    new PersonGoToHelper(this.$router).goToPersonGroupCustomView({
      partnerId: this.partnerId,
      personGroupId: item.id,
      viewId: "0"
    });
  }

  setSelectedItem(item: IPersonGroup | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  onDeleteItem(item: IPersonGroup) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }
}
