
import { Component, Prop, Vue } from "vue-property-decorator";
import { IFormableClass } from "@/lib/formable";
import { downloadAsXlsx, getExcelImportConfig } from "@/lib/download-as-xlsx";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";
import { unflattenObject } from "@/lib/flatten-object";

@Component({})
export default class ExcelImportMixin<T> extends Vue {
  /**
   * The dto class to create a new item
   */
  @Prop()
  dto!: IFormableClass;

  @Prop({ default: () => ({}) })
  baseData!: Record<string, any>;

  @Prop({ default: () => [] })
  excludeKeys!: string[];

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: "" })
  text!: boolean;

  get config(): { [key: string]: IExcelImportConfig } {
    const configs = getExcelImportConfig(this.dto.formables);

    const filteredConfig: Record<string, IExcelImportConfig> = {};
    for (const [key, config] of Object.entries(configs)) {
      if (this.excludeKeys.includes(key)) continue;
      filteredConfig[key] = config;
    }

    return filteredConfig;
  }

  loadingImport = false;
  dataImports: T[] = [];
  failedImports: { reason: string; dto?: T }[] = [];

  async processItem(item: T): Promise<T | undefined> {
    throw new Error(`Method not implemented for ${item}`);
  }

  /**
   *
   * @param items
   */
  async importData(items: { data: T[]; headers: any }) {
    this.loadingImport = true;
    this.dataImports = [];
    this.failedImports = [];

    for (const item of items.data) {
      try {
        const unflattened = (unflattenObject({ ...item, ...this.baseData }) ?? {}) as T;
        const res = await this.processItem(unflattened);
        if (res) {
          this.dataImports.push(res);
        }
      } catch (error) {
        const errorMessage = (error as Error).message;
        this.$log.error(error);
        this.failedImports.push({ reason: errorMessage, dto: item });
        continue;
      }
    }

    this.loadingImport = false;
  }

  createErrorReport() {
    downloadAsXlsx(this.failedImports);
  }
}
