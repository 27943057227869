
















































import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PersonGroup, IPersonGroup } from "@/models/person-group.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog,
    CustomFieldConfigurationForm
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonGroupCreateDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  isDialogActive = false;

  isLoading = false;

  personGroup: IPersonGroup = new PersonGroup({});

  get requiredRule() {
    return [requiredRule()];
  }

  open() {
    this.personGroup = new PersonGroup({ partnerId: this.partnerId });
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.titleField as HTMLInputElement)?.focus();
    });
  }

  async onRightClick() {
    try {
      this.isDialogActive = true;
      await this.personGroup.create();
      this.$emit("created", this.personGroup);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
    }
  }

  onConfirm(item: MrfiktivReferenceGen) {
    this.$log.debug("onConfirm", item);
  }

  close(): void {
    this.isDialogActive = false;
  }
}
