








































import RefsTicket from "@/components/utility/RefsTicket.vue";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { TYPE } from "vue-toastification";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog, RefsTicket },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketAcknowledgeDialog extends mixins(PermissionMixin) implements IDialog {
  readonly TicketStatusEnum = TicketStatusEnum;

  @Prop({ default: () => [] })
  value!: ITicket[];

  isDialogActive = false;

  isLoading = false;

  open() {
    this.isDialogActive = true;
  }

  close() {
    this.isDialogActive = false;
  }

  async completeTickets() {
    this.isLoading = true;

    let completed = 0;
    const toastId = this.$toast($t("project.ticket.updated", { number: completed }), {
      type: TYPE.DEFAULT,
      timeout: false,
      showCloseButtonOnHover: true
    });

    const failedIds: number[] = [];
    for (const ticket of this.value) {
      if (!this.isDialogActive) {
        failedIds.push(ticket.number);
        continue;
      }

      this.$log.debug(ticket);

      try {
        if (ticket.state !== TicketStatusEnum.CLOSED) {
          await ticket.updateState(TicketStatusEnum.CLOSED);
        }

        completed++;
        this.$toast.update(toastId, { content: `${completed}/${this.value.length}` });
      } catch (error) {
        this.$log.error(error);
        failedIds.push(ticket.number);
      }
    }

    if (failedIds.length) {
      this.$toast.update(toastId, {
        content: `${$t("failed")}: ${failedIds.map(v => `#${v}`).join(", ")}`,
        options: {
          type: TYPE.ERROR,
          timeout: 5000
        }
      });
    } else {
      this.$toast.update(toastId, {
        content: "👍",
        options: {
          type: TYPE.SUCCESS,
          timeout: 5000
        }
      });
    }

    this.isLoading = false;

    this.close();
  }
}
