var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.UPDATE,
            a: _vm.ResourceEnum.HANDOVER,
            field: _vm.value.partnerId
          }
        },
        [
          _vm._t("activator", null, {
            on: _vm.open
          })
        ],
        2
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            title: _vm.$t("handover.agreement.create.title"),
            isDialogActive: _vm.isDialogActive,
            rightLoading: _vm.isCreateLoading,
            rightDisabled: !_vm.isFormValid,
            width: "1050"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            rightClick: _vm.create,
            leftClick: function leftClick($event) {
              _vm.isDialogActive = false
            }
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function callback($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _vm._l(_vm.agreements, function(agreement, index) {
                return _c(
                  "v-row",
                  {
                    key: "newAgreement" + index
                  },
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mb-n1",
                            staticStyle: {
                              display: "flex"
                            }
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-small": "",
                                  text: "",
                                  elevation: 0
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.removeAgreement(index)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("remove")))]
                            )
                          ],
                          1
                        ),
                        _c("v-textarea", {
                          ref: "descriptionTextField",
                          refInFor: true,
                          attrs: {
                            rules: _vm.requiredRule,
                            outlined: "",
                            label: _vm.$t("objects.agreement.description")
                          },
                          model: {
                            value: agreement.description,
                            callback: function callback($$v) {
                              _vm.$set(agreement, "description", $$v)
                            },
                            expression: "agreement.description"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    elevation: 0
                  },
                  on: {
                    click: _vm.addNewEmptyAgreement
                  }
                },
                [_vm._v(_vm._s(_vm.$t("add")))]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }