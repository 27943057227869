var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("the-layout-portal-list", {
              attrs: {
                title: _vm.$t("nav.PartnerUserList.title"),
                getModule: _vm.getModule,
                isLoadingMore: _vm.isLoadingMore
              },
              on: {
                loadMore: _vm.loadMore,
                refresh: function refresh() {
                  return _vm.refresh({
                    partnerId: _vm.partnerId
                  })
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "options",
                  fn: function fn() {
                    return [
                      _c("partner-user-create-dialog", {
                        attrs: {
                          partnerId: _vm.partnerId
                        },
                        on: {
                          created: _vm.set
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function fn(_ref) {
                              var on = _ref.on
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      elevation: 0
                                    },
                                    on: {
                                      click: on
                                    }
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-plus ")])],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "listItem",
                  fn: function fn(_ref2) {
                    var listItem = _ref2.listItem
                    return [
                      _vm.isLoading || !listItem
                        ? _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type:
                                "divider, card-heading, divider, list-item-two-line,list-item-two-line, divider"
                            }
                          })
                        : _c(
                            "v-list-item",
                            {
                              key: listItem.id,
                              staticClass: "item",
                              class: _vm.isActive(listItem),
                              attrs: {
                                "two-line": ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.set(listItem)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(listItem.firstName) +
                                        " " +
                                        _vm._s(listItem.lastName) +
                                        " "
                                    )
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(listItem.userName))
                                  ])
                                ],
                                1
                              ),
                              listItem.isArchived
                                ? _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: "grey lighten-1"
                                          }
                                        },
                                        [_vm._v("mdi-archive-outline")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                    ]
                  }
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _c(
              "v-container",
              {
                staticStyle: {
                  "overflow-y": "auto",
                  height: "calc(100vh - 80px)"
                }
              },
              [
                _c("partner-user-detail", {
                  attrs: {
                    partnerId: _vm.partnerId,
                    loading: _vm.isLoadingDetail,
                    showDetailButton: true
                  },
                  model: {
                    value: _vm.user,
                    callback: function callback($$v) {
                      _vm.user = $$v
                    },
                    expression: "user"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }