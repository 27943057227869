
























































import MDetailTableFormable from "@/components/utility/mmmint/MDetailTableFormable.vue";
import RefsCompanyAddress from "@/components/utility/RefsCompanyAddress.vue";
import RefsPersonEmail from "@/components/utility/RefsPersonEmail.vue";
import RefsPersonPhone from "@/components/utility/RefsPersonPhone.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { IPerson, Person } from "@/models/person.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    MDetailTableFormable,
    RefsCompanyAddress,
    RefsPersonEmail,
    RefsPersonPhone
  },
  filters: {
    getFlagEmoji
  }
})
export default class PersonDetailTable extends mixins(Vue) {
  readonly formable = Person;

  @Prop()
  value!: IPerson;

  @Prop()
  onDetail?: () => void;
}
