














































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CompanySideCard from "@/components/company/CompanySideCard.vue";
import CustomViews from "@/components/cost/CustomViews.vue";
import GroupTypeEntityCreateDialog from "@/components/group/GroupTypeEntityCreateDialog.vue";
import GroupTypeEntitySideCard from "@/components/group/GroupTypeEntitySideCard.vue";
import PersonSideCard from "@/components/person/PersonSideCard.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import RefsGroup from "@/components/utility/RefsGroup.vue";
import RefsSelected from "@/components/utility/RefsSelected.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { toArray } from "@/lib/array-helper";
import { getNestedObjectValues, propertiesToArray } from "@/lib/objectPath-helper";
import { formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { IGroupTypeEntity } from "@/lib/utility/group/interface/group-type-entity.interface";
import { GroupTypeResourceMap } from "@/lib/utility/group/group-type-resource.map";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import { GroupTypes } from "@/lib/utility/group/group-types.const";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Group, IGroup } from "@/models/group.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { MrfiktivGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { GroupModule } from "@/store/modules/group.store";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    MHeader,
    CustomViews,
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    PersonSideCard,
    CompanySideCard,
    GroupTypeEntityCreateDialog,
    GroupTypeEntitySideCard,
    AssigneePreview,
    RefsGroup,
    RefsSelected
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class GroupTypeCustomViewView<Type extends IGroupTypeEntity> extends mixins(
  PartnerFallbackMixin,
  PermissionMixin
) {
  readonly simpleDoubleDigitDate = simpleDoubleDigitDate;

  readonly GroupTypes = GroupTypes;

  readonly BackendResourceEnum = BackendResourceEnum;

  readonly getNestedObjectValues = getNestedObjectValues;

  readonly propertiesToArray = propertiesToArray;

  readonly toArray = toArray;

  @Ref("groupTypeEntityCreateDialog")
  groupTypeEntityCreateDialog!: GroupTypeEntityCreateDialog;

  @Ref("customViews")
  customViews!: CustomViews<Type, MrfiktivGroupControllerGetParamsGen>;

  isLoading = false;

  group: IGroup | null = null;

  viewId = this.$route.params.viewId || "0";

  partnerId = this.$route.params.partnerId;

  selectedItem: Type | null = null;

  tableBaseConfig = {
    values: [
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "id"
      }
    ],
    filters: []
  };

  calendarBaseConfig = {
    calendarStart: {
      type: ProjectCustomViewFieldEnum.PROPERTY,
      key: "date"
    },
    filters: []
  };

  boardBaseConfig = {
    filters: [
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "id"
      }
    ]
  };

  get store() {
    return GroupTypeResourceMap.get(this.type)?.module;
  }

  get groupId() {
    return this.$route.params.groupId;
  }

  get type(): IGroupType {
    return this.$route.params.type as IGroupType;
  }

  get viewIdLocal() {
    return this.viewId;
  }

  set viewIdLocal(value: string) {
    this.viewId = value;

    this.closeSideCard();
  }

  get breadCrumbList(): IBreadcrumb[] {
    const breadCrumbs = GroupGoToHelper.breadCrumbs;
    const partnerId = this.partnerId;
    const type = this.type;
    const viewId = this.viewId;
    const groupId = this.groupId;

    const crumbs: IBreadcrumb[] = [
      breadCrumbs.groupTypeTable({
        partnerId,
        type
      })
    ];
    if (this.group?.id) {
      crumbs.push(
        breadCrumbs.groupTypeCustomView({
          partnerId,
          type,
          groupId,
          viewId
        })
      );
    }

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    return chips;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.can(ActionEnum.CREATE, this.type)) {
      actions.push({
        text: $t(`group.${this.type}.create`),
        key: "createGroup",
        exec: this.createGroupEntity
      });
    }

    if (
      this.customViews &&
      this.group?.configuration.views[Number(this.viewIdLocal)]?.type === ProjectCustomViewTypeEnum.TABLE
    ) {
      actions.push({
        text: $t("common.verbs.download").toString(),
        key: "download",
        exec: this.customViews?.downloadToCsv
      });
    }

    actions.push({
      icon: "mdi-pencil",
      text: $t("project.ticket.settings"),
      key: "edit",
      exec: this.goToSettings
    });

    return actions;
  }

  async mounted() {
    this.isLoading = true;
    try {
      const partnerAsync = this.trySetByRouteOrDefault();
      const groupAsync = (
        GroupModule.maps.id.get(this.groupId)[0] ??
        new Group({
          id: this.groupId,
          partnerId: this.partnerId
        })
      )
        .fetch()
        .then(group => (this.group = group));

      await Promise.all([partnerAsync, groupAsync]);

      this.store?.setHiddenFilters([new PageFilterElement({ key: "groupId", operation: "$eq", value: this.groupId })]);
      this.store?.fetchFirstPage({ partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    const id = this.$route.query.id as string;
    if (id) {
      const ResourceEntity = GroupTypeResourceMap.get(this.type)?.entity;
      if (ResourceEntity) {
        const group = new ResourceEntity({ partnerId: this.partnerId, id: id }) as Type;

        this.openSideCard(group);
      }
    }
  }

  goToSettings() {
    new GroupGoToHelper(this.$router).goToGroupTypeDetail({
      partnerId: this.partnerId,
      type: this.type,
      groupId: this.groupId
    });
  }

  async getGroup(id: string) {
    if (!id) return;
    let group = GroupModule.maps.id.get(id)[0];
    if (!group) {
      group = new Group({ partnerId: this.partnerId, id });
      group.fetch();
    }

    return group;
  }

  goToGroupDetail(groupId: string) {
    new GroupGoToHelper(this.$router).goToGroupTypeDetail({
      partnerId: this.partnerId,
      type: this.type,
      groupId: groupId
    });
  }

  createGroupEntity() {
    this.groupTypeEntityCreateDialog?.open();
  }

  // identify strings formatted as NNNN-NN-NNTNN:NN
  isDayTimeString(value: string) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
  }

  getDateTime(value: string) {
    return simpleDoubleDigitDate(value) + " " + formatHoursAndMinutes(new Date(value));
  }

  closeSideCard() {
    this.selectedItem = null;

    new GroupGoToHelper(this.$router).setUrl(
      GroupGoToHelper.locations.groupTypeCustomView({
        partnerId: this.partnerId,
        type: this.type,
        groupId: this.groupId,
        viewId: this.viewIdLocal || "0"
      })
    );
  }

  openSideCard(value: Type) {
    this.selectedItem = null;
    this.$nextTick(() => {
      this.selectedItem = value;
      new GroupGoToHelper(this.$router).setUrl(
        GroupGoToHelper.locations.groupTypeCustomView({
          partnerId: this.partnerId,
          type: this.type,
          groupId: this.groupId,
          viewId: this.viewIdLocal || "0",
          query: value.id ? { id: value.id } : {}
        })
      );
    });
  }
}
