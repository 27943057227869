import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { MrfiktivEventControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import partnerBankingService from "@/services/mrfiktiv/services/partner-banking.service";
import { IPartnerBanking } from "@/models/partner-banking.entity";

export const PartnerBankingPageDataProvider = new (class extends AbstractPageDataProvider<
  IPartnerBanking,
  MrfiktivEventControllerGetParamsGen
> {
  itemsPerPage = 50;

  async getPage(query: MrfiktivEventControllerGetParamsGen): Promise<IPageViewModel<IPartnerBanking>> {
    const res = await partnerBankingService.findAll({ ...query });

    const meta = res.meta;
    const data = (res.data || []) as IPartnerBanking[];

    return { meta, data };
  }
})();
