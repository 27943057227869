var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        isGoHomeOnBack: _vm.isGoHomeOnBack
      }
    },
    [
      _c(
        "card",
        [
          _c("partner-report-initialize-card", {
            attrs: {
              initReport: _vm.initReport
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }