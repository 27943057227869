









import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import ServiceTable from "./ServiceTable.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import { ServiceModule } from "@/store/modules/service.store";

@Component({
  components: {
    TheLayoutPortal,
    ServiceTable,
    MHeader
  }
})
export default class ServiceTableView extends PartnerFallbackMixin {
  loading = false;

  async mounted() {
    this.loading = true;
    await this.trySetByRouteOrDefault();
    this.loading = false;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${ServiceModule.totalItems} ${this.$t("project.project.projects")}`,
        key: "amount",
        disabled: true
      }
    ];
  }
}
