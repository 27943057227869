var render = function() {
  var _vm$created

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.HANDOVER
          }
        },
        [
          _c(
            "Can",
            {
              attrs: {
                I: _vm.ActionEnum.CREATE,
                a: _vm.ResourceEnum.REPORT
              }
            },
            [
              _vm._t("activator", null, {
                on: _vm.open
              })
            ],
            2
          )
        ],
        1
      ),
      _vm.isDialogActive
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                width: "1200px",
                isDialogActive: _vm.isDialogActive,
                title: _vm.title,
                absolute: true,
                persistent: true,
                fullscreen: true,
                rightLoading: _vm.isRightLoading || _vm.isImageLoading,
                rightDisabled: _vm.rightDisabled,
                leftText: _vm.leftText,
                rightText: _vm.rightText,
                hideLeft: _vm.hideLeft,
                loading: _vm.isDialogLoading
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDialogActive = $event
                },
                rightClick: _vm.rightClick,
                leftClick: _vm.leftClick,
                close: _vm.close
              }
            },
            [
              _c(
                "v-window",
                {
                  attrs: {
                    touchless: ""
                  },
                  model: {
                    value: _vm.step,
                    callback: function callback($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.StepEnum.INSPECTION
                      }
                    },
                    [
                      _vm.handover.inspections.length
                        ? _c(
                            "v-card",
                            [
                              _c("v-card-text", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.CreateHandoverFindingsDialog.steps.inspection.description"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.handover.inspections,
                                  label: _vm.$t("common.nouns.inspection"),
                                  "item-value": "id",
                                  "item-text": "title",
                                  outlined: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function fn(_ref) {
                                        var item = _ref.item,
                                          attrs = _ref.attrs,
                                          on = _ref.on
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center"
                                                          }
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    item.start
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-xs"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "simpleDoubleDigitDate"
                                                                  )(item.start)
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.start && item.address
                                                      ? _c("span", [
                                                          _vm._v(" - ")
                                                        ])
                                                      : _vm._e(),
                                                    item.address
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-xs"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.address
                                                                    .street
                                                                ) +
                                                                ", " +
                                                                _vm._s(
                                                                  item.address
                                                                    .zip
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  item.address
                                                                    .city
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  item.address
                                                                    .state
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item",
                                      fn: function fn(_ref2) {
                                        var item = _ref2.item,
                                          attrs = _ref2.attrs,
                                          on = _ref2.on
                                        return [
                                          _c(
                                            "v-list-item",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-list-item",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            align: "center"
                                                          }
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-subtitle", [
                                                    item.start
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-xs"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "simpleDoubleDigitDate"
                                                                  )(item.start)
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    item.start && item.address
                                                      ? _c("span", [
                                                          _vm._v(" - ")
                                                        ])
                                                      : _vm._e(),
                                                    item.address
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-xs"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.address
                                                                    .street
                                                                ) +
                                                                ", " +
                                                                _vm._s(
                                                                  item.address
                                                                    .zip
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  item.address
                                                                    .city
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  item.address
                                                                    .state
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  870600252
                                ),
                                model: {
                                  value: _vm.selectedInspectionId,
                                  callback: function callback($$v) {
                                    _vm.selectedInspectionId = $$v
                                  },
                                  expression: "selectedInspectionId"
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "mx-auto",
                              staticStyle: {
                                "max-width": "600px",
                                display: "grid",
                                "place-items": "center"
                              },
                              style: "height: ".concat(
                                _vm.$vuetify.breakpoint.height - 240,
                                "px"
                              )
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    flat: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticStyle: {
                                        display: "flex"
                                      }
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t(
                                              "components.CreateHandoverFindingsDialog.steps.inspection.createInspection"
                                            )
                                          )
                                        }
                                      }),
                                      _c("v-spacer")
                                    ],
                                    1
                                  ),
                                  _c("v-card-subtitle", [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "components.CreateHandoverFindingsDialog.steps.inspection.noInspection"
                                          )
                                        )
                                      }
                                    })
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            elevation: 0,
                                            color: "info"
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.$emit(
                                                "create:inspection"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("getStarted")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.StepEnum.LOCATOR
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.vehicles,
                              "item-value": "id",
                              "return-object": "",
                              outlined: "",
                              placeholder: _vm.$t(
                                "components.CreateHandoverFindingsDialog.steps.locator.descriptionVehicle"
                              )
                            },
                            on: {
                              change: _vm.initialize
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function fn(_ref3) {
                                    var item = _ref3.item
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.displayName
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.identificationnumber
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function fn(_ref4) {
                                    var item = _ref4.item
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.displayName
                                                        ) +
                                                        " "
                                                    )
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.identificationnumber
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1458998044
                            ),
                            model: {
                              value: _vm.selectedVehicle,
                              callback: function callback($$v) {
                                _vm.selectedVehicle = $$v
                              },
                              expression: "selectedVehicle"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.blueprints.length
                        ? _c("v-card-text", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.CreateHandoverFindingsDialog.steps.locator.description"
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _c("v-card-text", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.CreateHandoverFindingsDialog.steps.locator.noBlueprint"
                                  )
                                ) +
                                " "
                            )
                          ]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.blueprints,
                              "item-value": "id",
                              "return-object": "",
                              outlined: "",
                              placeholder: _vm.$t(
                                "handover.report.noBlueprintOption"
                              )
                            },
                            on: {
                              change: function change($event) {
                                return _vm.markers.splice(0)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function fn(_ref5) {
                                    var item = _ref5.item
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(item.name))
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    )
                                                  ]),
                                                  item.type
                                                    ? _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "enums.BlueprintTypeEnum." +
                                                                    item.type
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.location
                                                    ? _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.location.join(
                                                                ", "
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function fn(_ref6) {
                                    var item = _ref6.item
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(item.name))
                                                  ]),
                                                  _c("v-list-item-subtitle", [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    )
                                                  ]),
                                                  item.type
                                                    ? _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "enums.BlueprintTypeEnum." +
                                                                    item.type
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.location
                                                    ? _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.location.join(
                                                                ", "
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1588313692
                            ),
                            model: {
                              value: _vm.selectedBlueprint,
                              callback: function callback($$v) {
                                _vm.selectedBlueprint = $$v
                              },
                              expression: "selectedBlueprint"
                            }
                          }),
                          _vm.selectedBlueprint
                            ? _c(
                                "div",
                                [
                                  _c("m-image-marker", {
                                    ref: "imageMarkerCreateDialog",
                                    attrs: {
                                      maxHeight: 300,
                                      src: _vm.bluePrintImageUrl,
                                      markers: _vm.markers
                                    },
                                    on: {
                                      markerCreated: _vm.addMarker
                                    }
                                  }),
                                  _vm.markers.length
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex"
                                          }
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c("tooltip", {
                                            attrs: {
                                              left: "",
                                              "nudge-left": "",
                                              text: _vm.$t("sign.Sign.clear")
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function fn(_ref7) {
                                                    var on = _ref7.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              icon: ""
                                                            },
                                                            on: {
                                                              click: function click(
                                                                $event
                                                              ) {
                                                                return _vm.markers.splice(
                                                                  0
                                                                )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              " mdi-redo-variant "
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2331540740
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.bluePrintLocations &&
                                  _vm.bluePrintLocations.length >= 1
                                    ? _c("v-select", {
                                        staticClass: "pt-4",
                                        attrs: {
                                          items: _vm.bluePrintLocations,
                                          outlined: ""
                                        },
                                        model: {
                                          value: _vm.meta,
                                          callback: function callback($$v) {
                                            _vm.meta = $$v
                                          },
                                          expression: "meta"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.StepEnum.DETAILS
                      }
                    },
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.CreateHandoverFindingsDialog.steps.details.description"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "6"
                              }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  model: {
                                    value: _vm.isValid,
                                    callback: function callback($$v) {
                                      _vm.isValid = $$v
                                    },
                                    expression: "isValid"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      required: "",
                                      rules: _vm.requiredRule,
                                      outlined: "",
                                      label:
                                        _vm.$t("objects.report.title") + " *"
                                    },
                                    model: {
                                      value: _vm.report.title,
                                      callback: function callback($$v) {
                                        _vm.$set(_vm.report, "title", $$v)
                                      },
                                      expression: "report.title"
                                    }
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      label: _vm.$t(
                                        "objects.handover.description"
                                      )
                                    },
                                    model: {
                                      value: _vm.report.message,
                                      callback: function callback($$v) {
                                        _vm.$set(_vm.report, "message", $$v)
                                      },
                                      expression: "report.message"
                                    }
                                  }),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.handover.attendees.filter(
                                        function(a) {
                                          return !!a.companyName
                                        }
                                      ),
                                      "return-object": "",
                                      "item-value": function itemValue(item) {
                                        return ""
                                          .concat(item.companyName)
                                          .concat(item.firstName)
                                          .concat(item.lastName)
                                      },
                                      outlined: "",
                                      clearable: "",
                                      label: _vm.$t("finding.contact")
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function fn(_ref8) {
                                            var item = _ref8.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.companyName) +
                                                  " (" +
                                                  _vm._s(item.firstName) +
                                                  " " +
                                                  _vm._s(item.lastName) +
                                                  ") "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "selection",
                                          fn: function fn(_ref9) {
                                            var item = _ref9.item
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.companyName) +
                                                  " (" +
                                                  _vm._s(item.firstName) +
                                                  " " +
                                                  _vm._s(item.lastName) +
                                                  ") "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      442504540
                                    ),
                                    model: {
                                      value: _vm.contactTemp,
                                      callback: function callback($$v) {
                                        _vm.contactTemp = $$v
                                      },
                                      expression: "contactTemp"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "6"
                              }
                            },
                            [
                              _c("partner-report-card-images", {
                                ref: "images",
                                staticClass: "ma-1",
                                attrs: {
                                  isCaptureEnvironment: true,
                                  hideSelect: true,
                                  dto: _vm.report
                                },
                                on: {
                                  "update:dto": function updateDto($event) {
                                    _vm.report = $event
                                  },
                                  setIsLoading: _vm.setIsLoadingImage
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.StepEnum.SUCCESS
                      }
                    },
                    [
                      _vm.selectedBlueprint
                        ? _c("create-handover-finding-dialog-report-table", {
                            attrs: {
                              vehicle: _vm.selectedVehicle,
                              highlightedReportId:
                                (_vm$created = _vm.created) === null ||
                                _vm$created === void 0
                                  ? void 0
                                  : _vm$created.id,
                              blueprint: _vm.selectedBlueprint,
                              reports: _vm.createdReports
                            },
                            on: {
                              selectedMarker: function selectedMarker($event) {
                                _vm.isDialogActive = true
                              },
                              goToDetailFormForMarker:
                                _vm.goToDetailFormForMarker
                            }
                          })
                        : _c("create-handover-finding-dialog-report-table", {
                            attrs: {
                              vehicle: _vm.selectedVehicle,
                              reports: _vm.createdReports
                            },
                            on: {
                              selectedMarker: function selectedMarker($event) {
                                _vm.isDialogActive = true
                              },
                              goToDetailFormForMarker:
                                _vm.goToDetailFormForMarker
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("debug", [_vm._v(_vm._s(_vm.report))])
            ],
            1
          )
        : _vm._e(),
      _vm.confirmProceedNoImagesDialog
        ? _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.confirmProceedNoImagesDialog,
              title: _vm.$t("handover.report.noImages"),
              fullscreen: false,
              leftText: _vm.$t("back"),
              rightText: _vm.$t("next")
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.confirmProceedNoImagesDialog = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.confirmProceedNoImagesDialog = $event
              },
              rightClick: _vm.rightClick,
              leftClick: function leftClick($event) {
                _vm.confirmProceedNoImagesDialog = false
              },
              close: function close($event) {
                _vm.confirmProceedNoImagesDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }