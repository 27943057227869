
















































import { IDialog } from "@/lib/interfaces/dialog.interface";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IGroup, Group } from "@/models/group.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CustomFieldConfigurationForm from "../report/CustomFieldConfigurationForm.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import { GroupTypes } from "@/lib/utility/group/group-types.const";

@Component({
  components: {
    ConfirmActionDialog,
    CustomFieldConfigurationForm
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class GroupTypeCreateDialog extends mixins(PermissionMixin) implements IDialog {
  readonly GroupTypes = GroupTypes;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  @Prop()
  type!: IGroupType;

  isDialogActive = false;

  isLoading = false;

  group: IGroup = new Group();

  get requiredRule() {
    return [requiredRule()];
  }

  open() {
    this.group = new Group({ partnerId: this.partnerId, type: this.type });
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.titleField as HTMLInputElement)?.focus();
    });
  }

  async onRightClick() {
    try {
      this.isDialogActive = true;
      await this.group.create();
      this.$emit("created", this.group);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
    }
  }

  onConfirm(item: MrfiktivReferenceGen) {
    this.$log.debug("onConfirm", item);
  }

  close(): void {
    this.isDialogActive = false;
  }
}
