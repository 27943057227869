


















































import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { PartnerBankingGoToHelper } from "@/lib/utility/partner-banking.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerBanking } from "@/models/partner-banking.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerBankingModule } from "@/store/modules/partner-banking.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsPartnerBanking from "../utility/RefsPartnerBanking.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import PartnerBankingCreateDialog from "./PartnerBankingCreateDialog.vue";
import PartnerBankingSideCard from "./PartnerBankingSideCard.vue";
import AssigneePreview from "../utility/AssigneePreview.vue";
import DueDateChip from "@/components/project/DueDateChip.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    PartnerBankingCreateDialog,
    PartnerBankingSideCard,
    AssigneePreview,
    RefsPartnerBanking,
    DueDateChip,
    Debug
  }
})
export default class PartnerBankingTable extends mixins(PermissionMixin) {
  readonly BackendResourceEnum = BackendResourceEnum;

  readonly store = PartnerBankingModule;

  @Prop()
  partnerId!: string;

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  @Prop()
  controlElements?: IControlElements[] | undefined;

  selectedItem: IPartnerBanking | null = null;

  selectedItemForDelete: IPartnerBanking | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.createdReadable",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: $t("objects.partnerBanking.isSepaDirectDebitMandate"),
      value: "isSepaDirectDebitMandate",
      width: "120px",
      align: "center"
    });
    headers.push({
      text: $t("objects.partnerBanking.name"),
      value: "name",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: $t("objects.partnerBanking.bank"),
      value: "bank",
      width: "120px",
      align: "start"
    });
    headers.push({
      text: $t("objects.partnerBanking.iban"),
      value: "iban",
      width: "120px",
      align: "start"
    });

    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get _controlElements(): IControlElements[] {
    if (this.controlElements) {
      return this.controlElements;
    }

    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: IPartnerBanking) =>
          new PartnerBankingGoToHelper(this.$router).goToPartnerBankingDetail({
            partnerBankingId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { title: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { title: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  beforeMount() {
    this.store.setFilters(this.filters);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: IPartnerBanking | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: IPartnerBanking) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
