












import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FleetTable from "@/components/fleet/FleetTable.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { FleetActions } from "./FleetHomeView.vue";

@Component({
  components: { TheLayoutPortal, FleetTable, MHeader, LatestEntriesCardEmpty }
})
export default class FleetTableView extends mixins(Vue, PermissionMixin, PartnerFallbackMixin) {
  isLoading = false;

  title = this.$t("views.fleet.fleet.title");
  get breadCrumbs() {
    return [
      {
        text: this.$t("views.fleet.fleet.title"),
        exact: true,
        disabled: true
      }
    ];
  }

  get actions(): IAction[] {
    return [
      {
        icon: "mdi-car-2-plus",
        text: this.$t("views.fleet.FleetHomeVehicleTable.import").toString(),
        key: FleetActions.IMPORT_VEHICLE,
        color: ""
      }
    ];
  }

  goToFleetImport() {
    this.$router.push({ name: "FleetImportView" });
  }

  async processAction(action: IAction) {
    switch (action.key) {
      case FleetActions.IMPORT_VEHICLE:
        await this.goToFleetImport();
        break;
    }
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    this.isLoading = true;
    try {
      await this.trySetByRouteOrDefault();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
