






































import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { VehicleAggregation } from "@/store/modules/fleet-aggregation.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import EventCreateDialog from "../event/EventCreateDialog.vue";
import PartnerReportInitializeDialog from "../partner/PartnerReportInitializeDialog.vue";
import TicketCreateDialog from "../project/TicketCreateDialog.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import FleetVehicleEventCrudMixin from "./FleetVehicleEventCrudMixin.vue";
import { ITicket } from "@/models/ticket.entity";

@Component({
  components: {
    ConfirmActionDialog,
    TicketCreateDialog,
    PartnerReportInitializeDialog,
    EventCreateDialog
  }
})
export default class FleetHomeVehicleTableActions extends mixins(FleetVehicleEventCrudMixin, DarkModeHighlightMixin) {
  @Prop()
  vehicleAggregation!: VehicleAggregation;

  @Prop({ default: false })
  hideOpenInNew!: boolean;

  @Prop({ default: false })
  hideFile!: boolean;

  @Prop({ default: () => new Date() })
  dateFocus!: Date;

  @Prop()
  partnerId!: string;

  isTicketCreateDialogActive = false;

  refs: MrfiktivReferenceGen[] = [
    {
      refId: this.vehicleAggregation.vehicle.id,
      refType: ResourceEnum.VEHICLE
    }
  ];

  /**
   * Opening create confirmation
   */
  eventCreate = {
    isDialogActive: false,
    isDialogLoading: false,
    isValid: false
  };

  get actions() {
    const actions = [];

    actions.push(
      {
        icon: "mdi-open-in-new",
        text: "Zum Fahrzeug",
        color: "",
        action: this.openVehicleSideCard,
        condition:
          !this.hideOpenInNew &&
          UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, PartnerModule.partner._id)
      },
      {
        icon: "mdi-ticket-outline",
        text: this.$t("project.ticket.createTicket").toString(),
        color: "",
        action: () => {
          this.isTicketCreateDialogActive = true;
        },
        condition: UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.TICKET, PartnerModule.partner._id)
      },
      {
        icon: "mdi-calendar",
        text: this.$t("views.booking.BookingTable.confirmCreateTitle").toString(),
        color: "",
        action: () => {
          this.eventCreate.isDialogActive = true;
          (this.$refs.EventCreateDialog as EventCreateDialog).dialog = true;
        },
        condition: UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.EVENT, PartnerModule.partner._id)
      },
      {
        icon: "mdi-car-wrench",
        text: this.$t("report.navigation.report").toString(),
        color: "",
        action: () => (this.$refs.PartnerReportInitializeDialog as PartnerReportInitializeDialog).initializeDialog(),
        condition: UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.REPORT, PartnerModule.partner._id)
      }
    );

    return actions.filter(a => a.condition);
  }

  /**
   * Create Calender Event
   */
  async onCreateEvent(event: IEventUIDto) {
    await this.createEvent(event, this.vehicleAggregation.vehicle.id, this.dateFocus);
  }

  async onTicketCreated(ticket: ITicket) {
    this.$emit("openTicketSideCard", ticket);
  }

  openVehicleSideCard() {
    const vehicle: IVehicle = this.vehicleAggregation.vehicle;
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, true);
  }
}
