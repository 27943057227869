var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.HANDOVER,
            field: _vm.handover.partnerId
          }
        },
        [
          _vm._t("activator", null, {
            on: _vm.open
          })
        ],
        2
      ),
      _vm.handover
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                title: _vm.$t("inspection.create.title"),
                isDialogActive: _vm.isDialogActive,
                rightLoading: _vm.isCreateLoading,
                rightDisabled: !_vm.isValid,
                width: "1050"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDialogActive = $event
                },
                rightClick: _vm.create,
                leftClick: _vm.close,
                close: _vm.close
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: "7"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title mb-4"
                        },
                        [_vm._v(_vm._s(_vm.$t("inspection.create.general")))]
                      ),
                      _c("inspection-form", {
                        on: {
                          isValid: function isValid($e) {
                            return (_vm.isInspectionValid = $e)
                          }
                        },
                        model: {
                          value: _vm.inspection,
                          callback: function callback($$v) {
                            _vm.inspection = $$v
                          },
                          expression: "inspection"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: "5"
                      }
                    },
                    [
                      _vm._l(_vm.inspection.attendees, function(
                        attendee,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "blueprint-form my-4"
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mx-1 mb-1",
                                attrs: {
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "title mb-2"
                                  },
                                  [_vm._v("Teilnehmer*in " + _vm._s(index + 1))]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      outlined: "",
                                      color: "error",
                                      small: ""
                                    },
                                    on: {
                                      click: function click($event) {
                                        return _vm.removeItem(index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("remove")))]
                                )
                              ],
                              1
                            ),
                            _c("attendee-form", {
                              attrs: {
                                value: attendee,
                                suggestions: _vm.handover.parties
                              },
                              on: {
                                isValid: _vm.$e
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: "success"
                          },
                          on: {
                            click: _vm.addUser
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("objects.inspection.addAttendee"))
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }