var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          headers: _vm.headers,
          baseQuery: {
            partnerId: _vm.partnerId
          },
          controlElements: _vm.controlElements,
          predefinedFilter: _vm.predefinedFilter,
          groupBy: "date",
          groupDesc: true,
          showSelect: true,
          selectedItems: _vm.selectedItemsLocal
        },
        on: {
          "update:selectedItems": function updateSelectedItems($event) {
            _vm.selectedItemsLocal = $event
          },
          "update:selected-items": function updateSelectedItems($event) {
            _vm.selectedItemsLocal = $event
          },
          "click:row": _vm.setSelectedItem
        },
        scopedSlots: _vm._u(
          [
            {
              key: "group.header",
              fn: function fn(_ref) {
                var group = _ref.group,
                  toggle = _ref.toggle,
                  isOpen = _ref.isOpen,
                  items = _ref.items
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center"
                      },
                      on: {
                        click: toggle
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.simpleDoubleDigitDate(group)) + " "
                      ),
                      _c("v-spacer"),
                      isOpen
                        ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                        : _c("v-icon", [_vm._v("mdi-chevron-right")]),
                      _c("cost-chip", {
                        attrs: {
                          value: {
                            type:
                              _vm.getTotalForItems(items) < 0
                                ? _vm.CostTypeEnum.EXPENSE
                                : _vm.CostTypeEnum.INCOME,
                            total: _vm.getTotalForItems(items) || 0,
                            totalReadable: (
                              _vm.getTotalForItems(items) || 0
                            ).toLocaleString("de-DE", {
                              style: "currency",
                              currency: "EUR"
                            })
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.COST,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _c("cost-create-dialog", {
                        attrs: {
                          isDialogActive: _vm.isCostCreateDialogActive,
                          hideButton: false,
                          partnerId: _vm.partnerId,
                          refs: _vm.refs
                        },
                        on: {
                          "update:isDialogActive": function updateIsDialogActive(
                            $event
                          ) {
                            _vm.isCostCreateDialogActive = $event
                          },
                          "update:is-dialog-active": function updateIsDialogActive(
                            $event
                          ) {
                            _vm.isCostCreateDialogActive = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._t("bodyActions")
                ]
              },
              proxy: true
            },
            {
              key: "item.total",
              fn: function fn(_ref2) {
                var item = _ref2.item
                return [
                  _c("debug", [
                    _vm._v(" item.total " + _vm._s(item.total) + " "),
                    _c("br"),
                    _vm._v(
                      " item.absoluteTotal " + _vm._s(item.absoluteTotal) + " "
                    ),
                    _c("br"),
                    _vm._v(
                      " item.expenseOrIncome " +
                        _vm._s(item.expenseOrIncome) +
                        " "
                    ),
                    _c("br")
                  ]),
                  _c("cost-chip", {
                    attrs: {
                      value: item
                    }
                  })
                ]
              }
            },
            {
              key: "item.title",
              fn: function fn(_ref3) {
                var item = _ref3.item
                return [
                  _c("refs-cost", {
                    attrs: {
                      hideDate: true,
                      hideCost: true,
                      item: item
                    },
                    on: {
                      openDetail: _vm.setSelectedItem
                    }
                  })
                ]
              }
            },
            {
              key: "item.group",
              fn: function fn(_ref4) {
                var item = _ref4.item
                return [
                  item.group
                    ? _c("refs-cost-group", {
                        attrs: {
                          itemAsync: _vm.getCostGroup(item.group)
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.timestamp.created",
              fn: function fn(_ref5) {
                var item = _ref5.item
                return [
                  _vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")
                ]
              }
            },
            {
              key: "item.refs.refId",
              fn: function fn(_ref6) {
                var item = _ref6.item
                return _vm._l(item.refs, function(ref) {
                  return _c(
                    "div",
                    {
                      key: "item-refs-" + item.id + ref.refId + ref.refType
                    },
                    [
                      ref.refType === _vm.BackendResourceEnum.VEHICLE
                        ? _c("refs-vehicle", {
                            attrs: {
                              item: _vm.getVehicleById(ref.refId),
                              small: true
                            },
                            on: {
                              openDetail: _vm.goToVehicleDetail
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                })
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.selectedItem
        ? _c("cost-side-card", {
            attrs: {
              value: _vm.selectedItem
            },
            on: {
              close: function close() {
                return _vm.setSelectedItem(null)
              }
            }
          })
        : _vm._e(),
      _vm.isDeleteDialogActive && _vm.selectedItemForDelete
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error",
                rightLoading: _vm.isDeleteLoading
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-cost", {
                attrs: {
                  item: _vm.selectedItemForDelete
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }