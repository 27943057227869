





























import { Component, Prop, Vue } from "vue-property-decorator";
import { IAction } from "./mmmint/MHeader.vue";

@Component({})
export default class MenuButton extends Vue {
  @Prop({ default: "400px" })
  maxWidth!: string;
  @Prop()
  actions!: IAction[];

  selected: IAction = this.actions[0];

  actionClicked(action: IAction | undefined) {
    if (!action) {
      return;
    }
    if (action.exec) {
      action.exec();
      return;
    }
    this.$emit("click", action);
  }
}
