















































































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import TimeSeriesLineChartCard from "@/components/analytics/TimeSeriesLineChartCard.vue";
import StatisticsCard from "@/components/cards/StatisticsCard.vue";
import DashboardStatisticsList from "@/components/dashboard/DashboardStatisticsList.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { AnalyticsModule } from "@/store/modules/analytics.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import StatisticsCardRow, { IStatisticRowElement } from "../utility/StatisticsCardRow.vue";

type DataHandlerItem = {
  isLoading: boolean;
  getData: () => Promise<void>;
};

type DataHandler = Record<string, DataHandlerItem>;

@Component({
  components: {
    AnalyticsDateRangeSelectorDialog,
    TheLayoutPortal,
    StatisticsCard,
    DashboardStatisticsList,
    TimeSeriesLineChartCard,
    BarChartCard,
    PieChartCard,
    StatisticsCardRow
  }
})
export default class PartnerAnalyticsDashboard extends PartnerFallbackMixin {
  loading = false;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  dataHandler: DataHandler = {
    reportTrendAnalysis: {
      getData: async () => {
        await AnalyticsModule.getTrendAnalysisDataPerPartner();
      },
      isLoading: false
    },
    signTrendAnalysis: {
      getData: async () => {
        await AnalyticsModule.getTrendDataPerPartner("signRequest");
      },
      isLoading: false
    },
    bookingTrendAnalysis: {
      getData: async () => {
        await AnalyticsModule.getTrendDataPerPartner("booking");
      },
      isLoading: false
    },
    statusDistribution: {
      getData: async () => {
        await AnalyticsModule.getStatusDistributionData();
      },
      isLoading: false
    },
    hourDistribution: {
      getData: async () => {
        await AnalyticsModule.getHourDistributionData();
      },
      isLoading: false
    },
    weekdayDistribution: {
      getData: async () => {
        await AnalyticsModule.getWeekdayDistributionDataPerPartner();
      },
      isLoading: false
    },
    reportsCount: {
      getData: async () => {
        await AnalyticsModule.getReportsCountDataPerPartner();
      },
      isLoading: false
    },
    formsCount: {
      getData: async () => {
        await AnalyticsModule.getFormsCountDataPerPartner();
      },
      isLoading: false
    },
    signRequestCount: {
      getData: async () => {
        await AnalyticsModule.getCountDataPerPartner("signRequest");
      },
      isLoading: false
    },
    bookingCount: {
      getData: async () => {
        await AnalyticsModule.getCountDataPerPartner("booking");
      },
      isLoading: false
    }
  };

  get statusDistributionData() {
    return AnalyticsModule.statusDistributionData.data;
  }

  get hourDistributionData() {
    return AnalyticsModule.hourDistributionData.data;
  }

  get weekdayDistributionData() {
    return AnalyticsModule.weekdayDistributionData.data;
  }

  get reportsCountData() {
    return AnalyticsModule.reportsCountData.count;
  }

  get signCountData() {
    return AnalyticsModule.signCountData.count;
  }

  get bookingCountData() {
    return AnalyticsModule.bookingCountData.count;
  }

  get formsCountData() {
    return AnalyticsModule.formsCountData.count;
  }

  get dates() {
    return AnalyticsModule.dateRange;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get rowData(): IStatisticRowElement[] {
    const row: IStatisticRowElement[] = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, this.partnerId)) {
      row.push({
        icon: "mdi-car-wrench",
        title: "components.dashboard.DashboardStatisticsList.reports",
        color: "primary",
        data: this.reportsCountData.toString()
      });
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.FORM, this.partnerId)) {
      row.push({
        icon: "mdi-car",
        title: "components.dashboard.DashboardStatisticsList.forms",
        color: "primary",
        data: this.formsCountData.toString()
      });
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN, this.partnerId)) {
      row.push({
        icon: "mdi-file-sign",
        title: "sign.DigitalSignatureHomeView.showAllSignatures",
        color: "primary",
        data: this.signCountData.toString()
      });
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.BOOKING, this.partnerId)) {
      row.push({
        icon: "mdi-calendar",
        title: "common.nouns.appointments",
        color: "primary",
        data: this.bookingCountData.toString()
      });
    }

    return row;
  }

  async mounted() {
    this.loading = true;
    try {
      await this.getData();
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
    }
  }

  async getData() {
    Object.keys(this.dataHandler).forEach(async key => {
      this.dataHandler[key].isLoading = true;

      try {
        await this.dataHandler[key].getData();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    });
  }

  async setDateRange(dateRange: string[]) {
    AnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  // Charts
  get reportTrendData() {
    const trendData = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.thirtyDayTrend
      }
    ];
    return trendData;
  }

  get signTrendData() {
    const trendData = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisDataSign.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisDataSign.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisDataSign.data.thirtyDayTrend
      }
    ];
    return trendData;
  }

  get bookingTrendData() {
    const trendData = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisDataBooking.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisDataBooking.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisDataBooking.data.thirtyDayTrend
      }
    ];
    return trendData;
  }
}
