










import PersonTable from "@/components/person/PersonTable.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Person, IPerson } from "@/models/person.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PersonModule } from "@/store/modules/person.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { $t } from "@/lib/utility/t";

@Component({
  components: {
    PersonTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonTableView extends mixins(PartnerFallbackMixin) {
  @Ref("personTable")
  personTable!: PersonTable;

  readonly AMOUNT_COST = "amountPerson";

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get actions(): IAction[] {
    return [
      {
        text: $t("common.verbs.import"),
        key: "import",
        exec: () => new PersonGoToHelper(this.$router).goToPersonImport({ partnerId: this.partnerId })
      }
    ];
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(PersonGoToHelper.breadCrumbs.PersonTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${PersonModule.totalItems} ${this.$t("person.people")}`,
      key: this.AMOUNT_COST,
      disabled: true
    });

    return chips;
  }

  beforeMount() {
    PersonModule.setFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const personId = this.$route.query.personId as string;
    if (personId) {
      const person = new Person({ partnerId: this.partnerId, id: personId });
      this.personTable?.setSelectedItem(person);
    }
  }

  openSideCard(person: IPerson) {
    new PersonGoToHelper(this.$router).setUrl(
      PersonGoToHelper.locations.personTable({
        partnerId: this.partnerId,
        query: person ? { personId: person.id } : {}
      })
    );
  }
}
