
















































import { detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { IVehicle } from "@/models/vehicle.entity";

import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { vehicleClassMap } from "@/lib/utility/vehicleClassMap";
import { IThgVehicleClass } from "@/lib/interfaces/thg/thg-vehicle-class.interface";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";

@Component({
  components: {},
  filters: {
    simpleDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class FleetVehicleMenuCard extends Vue {
  @Prop()
  selected!: IVehicle;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: true })
  showActions!: boolean;

  @Prop({ default: true })
  detailed!: boolean;

  get debug() {
    return ConfigModule.debug;
  }

  getIconForVehicleClass(vehicleClass: VehicleTypes | undefined): IThgVehicleClass {
    if (!vehicleClass) {
      return { text: "Unbekannt", icon: "mdi-help" };
    }

    return vehicleClassMap.get(vehicleClass) || { text: "Unbekannt", icon: "mdi-help" };
  }
}
