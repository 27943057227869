import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Group, IGroup } from "@/models/group.entity";
import { MrfiktivGroupControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, getModule } from "vuex-module-decorators";
import { ActionEnum } from "../enum/authActionEnum";
import { ResourceEnum } from "../enum/authResourceEnum";
import { PaginatedBaseStore } from "../paginated-base.store";
import { GroupDataAccessLayer } from "./access-layers/group.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { UserModule } from "./me-user.store";
import { GroupPageDataProvider } from "./page-data-provider/group.page-data-provider";
import { PartnerModule } from "./partner";

@Module({
  dynamic: true,
  namespaced: true,
  name: "group",
  store
})
export class GroupPaginationStore extends PaginatedBaseStore<IGroup, MrfiktivGroupControllerGetParamsGen> {
  protected _data = GroupDataAccessLayer;
  protected _pageProvider = GroupPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Group.filterables;

  @Action
  async reset(): Promise<void> {
    super.reset();
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.GROUP, PartnerModule.partner.id)) {
      this.setFilters([]);
      await this.fetchFirstPage({ partnerId: PartnerModule.partner.id }).catch(e => Vue.$log.error(e));
    }
  }
}

export const GroupModule = getModule(GroupPaginationStore);
