import { ThirdPartySystemRouteNames } from "@/lib/utility/third-party-system.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import ThirdPartySystemDetailView from "@/views/third-party-system/ThirdPartySystemDetailView.vue";
import ThirdPartySystemTableView from "@/views/third-party-system/ThirdPartySystemTableView.vue";

/**
 * Routes for third party system
 */
export const thirdPartySystemRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/third-party-system/",
    component: ThirdPartySystemTableView,
    name: ThirdPartySystemRouteNames.THIRD_PARTY_SYSTEM_TABLE,
    permission: {
      resource: ResourceEnum.TPS,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-application-export",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 60
    }
  },
  {
    path: "/partner/:partnerId/third-party-system/:thirdPartySystemId",
    component: ThirdPartySystemDetailView,
    name: ThirdPartySystemRouteNames.THIRD_PARTY_SYSTEM_DETAIL,
    permission: {
      resource: ResourceEnum.TPS,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
