



















































import MDetailTableFormable from "@/components/utility/mmmint/MDetailTableFormable.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { Company, ICompany } from "@/models/company.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsCompanyAddress from "../utility/RefsCompanyAddress.vue";

@Component({
  components: {
    MDetailTableFormable,
    RefsCompanyAddress
  },
  filters: {
    getFlagEmoji
  }
})
export default class CompanyDetailTable extends mixins(Vue) {
  readonly formable = Company;

  @Prop()
  value!: ICompany;

  @Prop()
  onDetail?: () => void;
}
