import { ContractRouteNames } from "@/lib/utility/contract.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import ContractDetailFormView from "@/views/contract/ContractDetailFormView.vue";
import ContractDetailView from "@/views/contract/ContractDetailView.vue";
import ContractTableView from "@/views/contract/ContractTableView.vue";

/**
 * Routes for contracts and tickets
 */
export const contractRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/contract/",
    component: ContractTableView,
    name: ContractRouteNames.CONTRACT_TABLE,
    permission: {
      resource: ResourceEnum.CONTRACT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.CRM,
      icon: "mdi-file-document-multiple-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 41
    }
  },
  {
    path: "/partner/:partnerId/contract/:contractId",
    component: ContractDetailView,
    name: ContractRouteNames.CONTRACT_DETAIL,
    permission: {
      resource: ResourceEnum.CONTRACT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/contract/:contractId/form",
    component: ContractDetailFormView,
    name: ContractRouteNames.CONTRACT_DETAIL_FORM,
    permission: {
      resource: ResourceEnum.CONTRACT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
