import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { Filter, IsFilterable } from "@/lib/filterable";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { RulesEnum } from "@/lib/rules/rules.map";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { IUpdateDto } from "@/lib/utility/data/update-dto.interface";
import { $t } from "@/lib/utility/t";
import { MrfiktivCreatePersonDtoGen, MrfiktivPersonViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { CompanyAddress, ICompanyAddress } from "./company-address.entity";
import { IPersonEmail, PersonEmail, PersonEmailBase } from "./person-email.entity";
import { IPersonPhone, PersonPhone, PersonPhoneBase } from "./person-phone.entity";
import { IPerson, Person } from "./person.entity";

type CreateDtoType = Omit<
  MrfiktivPersonViewModelGen,
  "id" | "values" | "groupId" | "addresses" | "emails" | "phones" | "timestamp" | "bankingIds"
>;
@IsFormable
@IsFilterable
class ImportPersonDtoBase implements CreateDtoType, ICreateDto<IPerson>, IUpdateDto<IPerson> {
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.description", "person.person"],
    type: DetailFormComponentsEnum.TEXT_AREA,
    props: {
      required: false,
      label: "objects.person.description"
    },
    clearable: true
  })
  description?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.firstName", "person.person"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.person.firstName"
    },
    clearable: true
  })
  firstName?: string;

  /**
   * @inheritdoc
   */
  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.lastName", "person.person"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.person.lastName"
    },
    clearable: true
  })
  lastName?: string;

  @FormConfig({
    category: "person.person",
    searchKeywords: ["objects.person.language", "person.person"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      items: ConfigModule.availableLanguages.map(v => {
        return {
          text: $t(`enums.LanguageCodeEnum.${v}`),
          value: v
        } as IVSelectItem;
      }),
      itemValue: "value",
      label: "objects.person.language"
    },
    rules: [RulesEnum.REQUIRED_RULE]
  })
  language: LanguageCodeEnum;

  @FormConfig({
    category: "common.nouns.address",
    searchKeywords: ["common.nouns.address"],
    type: CompanyAddress,
    props: { label: "common.nouns.address" }
  })
  address: ICompanyAddress;

  @FormConfig({
    category: "common.nouns.email",
    searchKeywords: ["common.nouns.email"],
    type: PersonEmail,
    props: { label: "common.nouns.email" }
  })
  email: IPersonEmail;

  @FormConfig({
    category: "common.nouns.phone",
    searchKeywords: ["common.nouns.phone"],
    type: PersonPhone,
    props: { label: "common.nouns.phone" }
  })
  phone: IPersonPhone;

  isUpdateable = true;

  /**
   * Construct person
   */
  constructor(obj?: Partial<ImportPersonDtoBase>) {
    this.partnerId = obj?.partnerId ?? "";
    this.description = obj?.description;
    this.firstName = obj?.firstName;
    this.lastName = obj?.lastName;
    this.language = (obj?.language as LanguageCodeEnum) ?? LanguageCodeEnum.DE;
    this.address = new CompanyAddress(obj?.address);
    this.email = new PersonEmailBase(obj?.email);
    this.phone = new PersonPhoneBase(obj?.phone);
  }

  /**
   * create fetch person
   */
  async create() {
    const data: MrfiktivCreatePersonDtoGen = {
      description: this.description || undefined,
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      language: this.language || undefined,
      addresses: this.address ? [this.address] : undefined,
      emails: this.email?.email ? [this.email] : undefined,
      phones: this.phone?.phone ? [this.phone] : undefined
    };

    const person = new Person({ ...data, partnerId: this.partnerId } as IPerson);
    await person.create();

    return person;
  }

  /**
   * update person
   * @returns
   */
  async update() {
    const data: MrfiktivCreatePersonDtoGen = {
      description: this.description || undefined,
      firstName: this.firstName || undefined,
      lastName: this.lastName || undefined,
      language: this.language || undefined,
      addresses: this.address ? [this.address] : [],
      emails: this.email?.email ? [this.email] : [],
      phones: this.phone?.phone ? [this.phone] : []
    };

    const person = new Person({ ...data, partnerId: this.partnerId } as IPerson);
    await person.create();

    return person;
  }
}

type IImportPersonDto = ImportPersonDtoBase;
const ImportPersonDto = Form.createForClass(Filter.createForClass(ImportPersonDtoBase));

export { IImportPersonDto, ImportPersonDto };
