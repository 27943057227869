

























import RulesMixin from "@/mixins/RulesMixin.vue";
import { ICompanyInformation } from "@/models/company-information.entity";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

@Component({
  components: {}
})
export default class CompanyForm extends mixins(RulesMixin) {
  @Prop()
  value!: ICompanyInformation;

  isValid = false;

  @Watch("isValid")
  watchIsValid(value: boolean) {
    this.$emit("isValid", value);
  }
}
