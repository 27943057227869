var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mx-2",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              staticStyle: {
                display: "flex"
              },
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    "x-small": "",
                    outlined: "",
                    text: ""
                  },
                  on: {
                    click: _vm.openEditAddressMenu
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
              ),
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    title: _vm.$t("objects.inspection.address"),
                    subtitle: _vm.$t("objects.inspection.addressHint"),
                    isDialogActive: _vm.isEditAddressMenu,
                    rightLoading: _vm.isLoadingSaveAddress,
                    rightDisabled: !_vm.isAddressValid,
                    leftText: _vm.$t("cancel"),
                    rightText: _vm.$t("save")
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isEditAddressMenu = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isEditAddressMenu = $event
                    },
                    leftClick: _vm.abortEditAddress,
                    rightClick: _vm.saveEditAddress
                  }
                },
                [
                  _c("address-form", {
                    attrs: {
                      isHeader: false,
                      subheader: _vm.$t("objects.inspection.address")
                    },
                    on: {
                      isValid: function isValid($e) {
                        return (_vm.isAddressValid = $e)
                      }
                    },
                    model: {
                      value: _vm.addressCopy,
                      callback: function callback($$v) {
                        _vm.addressCopy = $$v
                      },
                      expression: "addressCopy"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.coordinates
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("partner-map", {
                    staticClass: "z-index: 0; !important;",
                    attrs: {
                      coordinates: _vm.coordinates,
                      height: 265,
                      colour: _vm.color
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-space-between align-items-center",
              attrs: {
                cols: "12"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column"
                },
                [
                  _vm.value.address.street
                    ? _c(
                        "div",
                        {
                          staticClass: "title"
                        },
                        [_vm._v(" " + _vm._s(_vm.value.address.street) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "subtitle"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.value.address.zip) +
                          " " +
                          _vm._s(_vm.value.address.city)
                      )
                    ]
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    fab: "",
                    icon: "",
                    small: "",
                    outlined: "",
                    color: "info"
                  },
                  on: {
                    click: _vm.navigateTo
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "info"
                      }
                    },
                    [_vm._v("mdi-directions")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "timeline-card",
                {
                  attrs: {
                    outlined: "",
                    title: _vm.$t("objects.inspection.description")
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "menu",
                      fn: function fn() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-small": "",
                                text: ""
                              },
                              on: {
                                click: _vm.openEditDescriptionMenu
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                          ),
                          _c(
                            "confirm-action-dialog",
                            {
                              attrs: {
                                title: _vm.$t("objects.inspection.description"),
                                isDialogActive: _vm.isEditDescriptionMenu,
                                rightLoading: _vm.isLoadingSaveDescription,
                                leftText: _vm.$t("cancel"),
                                rightText: _vm.$t("save"),
                                supressKeyboardConfirm: true
                              },
                              on: {
                                "update:isDialogActive": function updateIsDialogActive(
                                  $event
                                ) {
                                  _vm.isEditDescriptionMenu = $event
                                },
                                "update:is-dialog-active": function updateIsDialogActive(
                                  $event
                                ) {
                                  _vm.isEditDescriptionMenu = $event
                                },
                                leftClick: _vm.abortEditDescription,
                                rightClick: _vm.saveEditDescription
                              }
                            },
                            [
                              _c("template-editor", {
                                ref: "templateEditor",
                                staticStyle: {
                                  height: "350px"
                                },
                                attrs: {
                                  readOnly: false,
                                  label: _vm.$t(
                                    "objects.inspection.description"
                                  )
                                },
                                model: {
                                  value: _vm.descriptionCopy,
                                  callback: function callback($$v) {
                                    _vm.descriptionCopy = $$v
                                  },
                                  expression: "descriptionCopy"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("template-editor", {
                    key: _vm.value.description,
                    staticClass: "ma-1",
                    attrs: {
                      value: _vm.value.description,
                      readOnly: true,
                      outlined: false
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }