































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FleetDetail from "@/components/fleet/FleetDetail.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Fleet, FleetNode } from "@/models/fleet.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { FleetModule } from "@/store/modules/fleet.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: { TheLayoutPortalSplit, MHeader, FleetDetail, LatestEntriesCardEmpty, Debug }
})
export default class FleetDetailView extends mixins(Vue, PermissionMixin, PartnerFallbackMixin) {
  isLoading = false;

  vehicles: IVehicle[] = [];

  search: string | null = null;

  isEditTitle = false;
  isUpdateTitle = false;
  tempTitle = "";

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get tree(): FleetNode[] {
    return FleetModule.tree;
  }

  get active() {
    return FleetModule.active;
  }

  get activeIds() {
    return FleetModule.activeIds;
  }

  set activeIds(active: string[]) {
    if (!active?.length) {
      return;
    }
    FleetModule.setActive(active);
  }

  get open() {
    return FleetModule.openIds;
  }

  set open(open: string[]) {
    FleetModule.setOpen(open);
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];

    breadCrumbs.push({
      text: this.$t("views.fleet.fleet.title"),
      exact: true,
      to: { name: "FleetTableView", params: { partnerId: this.$route.params.partnerId } }
    });

    breadCrumbs.push(...FleetModule.breadcrumbs);

    return breadCrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: this.$t("project.ticket.editTitle").toString(),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startUpdateFleetTitle
    });

    return actions;
  }

  get loading() {
    return FleetModule.loading;
  }

  get fleet() {
    return FleetModule.root;
  }

  get title() {
    return FleetModule?.active?.title;
  }

  get partner() {
    return PartnerModule.partner;
  }

  startUpdateFleetTitle() {
    if (!this.active) {
      return;
    }

    this.tempTitle = this.active.title;
    this.isEditTitle = true;
  }

  selectFirst() {
    if (!FleetModule.root?.id) {
      return;
    }

    this.activeIds = [FleetModule.root.id];
  }

  async fetchVehicles() {
    const fleetId = this.fleetId;
    const partnerId = this.partnerId;

    VehicleModule.setFilter([new PageFilterElement({ key: "groupId", operation: "$eq", value: fleetId })]);
    await VehicleModule.fetchAll({ partnerId });
  }

  async fetchFleet() {
    await FleetModule.set({ fleet: new Fleet({ id: this.fleetId, partnerId: this.partnerId }) });
  }

  async updateTitle() {
    if (!this.active) {
      return;
    }

    this.isUpdateTitle = true;
    this.isLoading = true;
    await this.active.updatePartial({ title: this.tempTitle }).finally(() => (this.isLoading = false));
    this.isEditTitle = false;
    this.isUpdateTitle = false;
  }

  cancelUpdateTitle() {
    if (!this.active) {
      return;
    }

    this.tempTitle = this.active.title;
    this.isEditTitle = false;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get fleetId() {
    return this.$route.params.fleetId;
  }

  async mounted() {
    try {
      this.isLoading = true;
      await this.trySetByRouteOrDefault();

      await this.fetchFleet();
      this.selectFirst();

      this.active?.fetchCompany();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
