








import { Component, Vue } from "vue-property-decorator";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import PartnerReportInitializeCard from "@/components/partner/PartnerReportInitializeCard.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import Card from "@/components/utility/Card.vue";

@Component({
  components: {
    LayoutSimple,
    PartnerReportInitializeCard,
    Card
  }
})
export default class PartnerReportInitialize extends Vue {
  isGoHomeOnBack = false;

  report: CreateReportAsPartnerDto = new CreateReportAsPartnerDto();
  initReport = new CreateReportUrlFactory().setFromCreateDialog(this.report);

  async mounted() {
    if (this.$route.query.isGoHomeOnBack === "true") {
      this.isGoHomeOnBack = true;
    }

    this.report = new CreateReportAsPartnerDto();
  }
}
