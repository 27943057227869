
































































import FilterCard from "@/components/filter/FilterCard.vue";
import PartnerManageInitializeDialog from "@/components/partner/PartnerManageInitializeDialog.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivSettingPartnerInternalViewModelGen as SettingPartnerInternalViewModel } from "@/services/mrfiktiv/v1/data-contracts";
import { ExportModule } from "@/store/modules/export.store";
import { SettingModule } from "@/store/modules/setting.store";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { FilterCard, PartnerManageInitializeDialog }
})
export default class PartnerManageList extends PermissionMixin {
  @Prop()
  partnerId!: string;

  search = "";

  get setupList() {
    return [
      {
        onClick: this.goToDAWizard,
        text: "components.partner.PartnerManagerList.goToDAWizard",
        isSetup: ExportModule.isDaSetup,
        isActive: true
      },
      {
        onClick: this.goToKSRWizard,
        text: "components.partner.PartnerManagerList.goToKSRWizard",
        isSetup: ExportModule.isKsrSetup,
        isActive: true
      },
      {
        onClick: this.goToPDRWizard,
        text: "components.partner.PartnerManagerList.goToPDRWizard",
        isSetup: ExportModule.isPdrSetup,
        isActive: true
      },
      {
        onClick: this.goToDealerDeskWizard,
        text: "components.partner.PartnerManagerList.goToDealerDeskWizard",
        isActive: true
      }
    ];
  }

  get allPartnerSettings() {
    return SettingModule.allPartnerSettings;
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  async mounted() {
    if (SettingModule.allPartnerSettings.length > 0) {
      SettingModule.setPartnerSetting(SettingModule.allPartnerSettings[0]);
    } else {
      SettingModule.setPartnerSetting({} as SettingPartnerInternalViewModel);
    }
  }

  async set(item: any) {
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "PartnerManageDetailView", params: { settingId: item.id } });
      return;
    }
    SettingModule.setPartnerSetting(item);
  }

  date(item: any): string {
    return simpleDate(item.timestamp?.created);
  }

  isActive(item: any) {
    return item.id === SettingModule.partnerSetting.id;
  }

  /**
   * The available categories visible in the filter menu
   */
  filterList: IFilterListElement[] = getDateFilterList("timestamp");

  items = SettingModule.allPartnerSettings.slice();

  get filteredItems() {
    return this.items;
  }

  /**
   * opens stepper for DA Setup
   */
  goToDAWizard() {
    this.$router.push({ name: "PartnerManageStepperDAView" });
  }

  /**
   * opens stepper for KSR Setup
   */
  goToKSRWizard() {
    this.$router.push({ name: "PartnerManageStepperKSRView" });
  }

  /**
   * opens stepper for PDR Setup
   */
  goToPDRWizard() {
    this.$router.push({ name: "PartnerManageStepperPDRView" });
  }

  /**
   * opens stepper for PDR Setup
   */
  goToDealerDeskWizard() {
    this.$router.push({ name: "PartnerManageStepperDealerDeskView" });
  }
}
