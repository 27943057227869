


























































































































































import Card from "@/components/utility/Card.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { $t } from "@/lib/utility/t";
import { FleetNode, IFleet } from "@/models/fleet.entity";
import { FleetModule } from "@/store/modules/fleet.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import { IAction } from "../utility/mmmint/MHeader.vue";
import MNavigationList from "../utility/mmmint/MNavigationList.vue";
import MQuickLinkList from "../utility/mmmint/MQuickLinkList.vue";
import RefsCompany from "../utility/RefsCompany.vue";
import CreateFleetDialog from "./CreateFleetDialog.vue";
import SelectAssignees from "../utility/SelectAssignees.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { IControlElements } from "@/lib/types/tableWrapper";
import { IPerson } from "@/models/person.entity";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { IReference, Reference } from "@/models/reference.entity";
import { handleError } from "@/lib/utility/handleError";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import PersonTable from "../person/PersonTable.vue";
import { CompanyModule } from "@/store/modules/company.store";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import FleetVehicleTable from "@/components/fleet/FleetVehicleTable.vue";
import CompanyTable from "../company/CompanyTable.vue";
import ContractTable from "../contract/ContractTable.vue";
import { CompanyServiceEnum } from "@/lib/enum/company-service.enum";
import SelectByRefsUpdateDialog from "../utility/SelectByRefsUpdateDialog.vue";
import HasDescription from "@/components/utility/HasDescription.vue";

enum FleetTabEnum {
  OVERVIEW = "overview",
  VEHICLES = "vehicle",
  PEOPLE_TABLE = "people-table",
  COMPANY_TABLE = "company-table",
  CONTRACT_TABLE = "contract-table"
}

@Component({
  components: {
    Card,
    Debug,
    CreateFleetDialog,
    MActionList,
    MDetailViewGrid,
    MNavigationList,
    MQuickLinkList,
    RefsCompany,
    SelectAssignees,
    PersonTable,
    ConfirmActionDialog,
    FleetVehicleTable,
    CompanyTable,
    ContractTable,
    SelectByRefsUpdateDialog,
    HasDescription
  }
})
export default class FleetDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly FleetTabEnum = FleetTabEnum;
  readonly DefaultTab = 0;

  isLoadingFleets = false;
  isLoadingDelete = false;
  isCompanyLoading = false;
  isLoadingRemoveRef = false;

  isChangeDialogActive = false;
  isCompanyDialogActive = false;
  isDeleteDialogActive = false;

  tab = 0;

  newCompanyId = "";

  get tree(): FleetNode[] {
    return FleetModule.tree;
  }

  get root(): IFleet | null {
    return FleetModule.root;
  }

  get active(): IFleet | undefined {
    return FleetModule.active;
  }

  get vehicles() {
    return VehicleModule.entities;
  }

  get companies() {
    return CompanyModule.entities;
  }

  get companySelectPredefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    return [
      {
        name: "enums.CompanyServiceEnum.WORKSHOP",
        filter: [
          new PageFilterElement({
            key: "services",
            operation: "$eq",
            value: CompanyServiceEnum.WORKSHOP
          })
        ]
      },
      {
        name: "enums.CompanyServiceEnum.INSURANCE",
        filter: [
          new PageFilterElement({
            key: "services",
            operation: "$eq",
            value: CompanyServiceEnum.INSURANCE
          })
        ]
      },
      {
        name: "enums.CompanyServiceEnum.LEASING",
        filter: [
          new PageFilterElement({
            key: "services",
            operation: "$eq",
            value: CompanyServiceEnum.LEASING
          })
        ]
      },
      {
        name: "enums.CompanyServiceEnum.RENTAL",
        filter: [
          new PageFilterElement({
            key: "services",
            operation: "$eq",
            value: CompanyServiceEnum.RENTAL
          })
        ]
      }
    ];
  }

  get showLoadMore() {
    return CompanyModule.totalPages > CompanyModule.currentPage;
  }

  get contractTableFilters(): IPageFilterElement[] | undefined {
    if (!this.active) {
      return undefined;
    }
    return [
      new PageFilterElement({
        value: this.active.id,
        operation: PageFilterOperationEnum.EQUAL,
        key: "refs.refId"
      })
    ];
  }

  get referenceTableFilters(): IPageFilterElement[] | undefined {
    if (!this.active) {
      return undefined;
    }
    return [
      new PageFilterElement({
        value: this.active.id,
        operation: PageFilterOperationEnum.EQUAL,
        key: "refs.refId"
      })
    ];
  }

  get fleetTableFilters(): IPageFilterElement[] | undefined {
    if (!this.active) {
      return undefined;
    }
    return [
      new PageFilterElement({
        value: this.active.id,
        operation: PageFilterOperationEnum.EQUAL,
        key: "groupId"
      })
    ];
  }

  get createRefs(): IReference[] | undefined {
    if (!this.active) {
      return undefined;
    }
    return [
      {
        refId: this.active.id,
        refType: BackendResourceEnum.FLEET
      }
    ];
  }

  get personTableControlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (person: IPerson) =>
          new PersonGoToHelper(this.$router).goToPersonDetail({
            personId: person.id,
            partnerId: person.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-close",
        text: $t("remove"),
        loading: this.isLoadingRemoveRef,
        action: async (person: IPerson) => {
          this.isLoadingRemoveRef = true;
          const filteredRefs = person.refs.filter(r => r.refId !== this.active?.id);
          await person.updatePartial({ refs: Reference.filterDuplicates(filteredRefs) }).catch(handleError);
          this.isLoadingRemoveRef = false;
        }
      }
    ];
  }

  get headers() {
    return [
      {
        text: this.$t("objects.vehicle.registration.numberplate").toString(),
        align: "start",
        value: "numberplate"
      }
    ];
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: `${this.$t("common.nouns.fleet")} ${this.$t("create")}`,
      key: "create",
      icon: "mdi-plus",
      exec: () => (this.$refs.createFleetDialog as CreateFleetDialog)?.open()
    });

    actions.push({
      text: `${this.$t("common.nouns.fleet")} ${this.$t("delete")}`,
      key: "delete",
      color: "red",
      icon: "mdi-trash-can",
      exec: () => (this.isDeleteDialogActive = true)
    });

    return actions;
  }

  mounted() {
    if (this.$route.query.tab) {
      this.tab = Number(this.$route.query.tab as string);
    }
  }

  @Watch("tab")
  onTabChange() {
    this.$log.debug(this.tab);
  }

  @Watch("active")
  async fetchDetails() {
    if (!this.active) {
      return;
    }

    await this.active.fetchCompany();
  }

  addFleet(fleet: IFleet) {
    this.$emit("addFleet", fleet);
  }
  addCompany(fleet: IFleet) {
    this.$emit("addCompany", fleet);
  }

  async saveCompany() {
    if (this.newCompanyId && this.active) {
      this.active.companyId = this.newCompanyId;
      this.isCompanyLoading = true;

      try {
        await this.active.update();
        this.addCompany(this.active);
        await this.active.fetchCompany();

        this.isCompanyDialogActive = false;
      } catch (error) {
        handleError(error);
      } finally {
        this.isCompanyLoading = false;
      }
    } else {
      this.$log.error("Can not save company");
    }
  }

  async deleteFleet() {
    this.isLoadingDelete = true;
    try {
      await this.active?.delete();

      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.deleteSuccessToast"));
      this.$emit("delete");
      this.isDeleteDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingDelete = false;
    }
  }

  async onAssigneesUpdate(assignees: []) {
    if (!this.active) return;

    await this.active.updatePartial({ assignees });
  }

  async openCompanyDialog() {
    this.isCompanyDialogActive = true;
    this.isCompanyLoading = true;
    CompanyModule.setFilters([new PageFilterElement({ key: "isFleet", operation: "$eq", value: true as any })]);
    await CompanyModule.fetchFirstPage({ partnerId: this.partnerId }).finally(() => (this.isCompanyLoading = false));

    this.$nextTick(() => {
      (this.$refs.titleTextField as any)?.focus();
    });
  }

  async loadMoreCompanies() {
    this.isCompanyLoading = true;

    await CompanyModule.fetchNextPage({ partnerId: this.partnerId }).finally(() => (this.isCompanyLoading = false));
  }
}
