import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";

import analyticsService from "@/services/mrfiktiv/services/analyticsService";
import { PartnerModule } from "./partner";
import {
  MrfiktivAnalyticsCountGen,
  MrfiktivBaseTrendAnalysisViewmodelGen,
  MrfiktivReportHourAnalysisPerPartnerViewmodelGen,
  MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen,
  MrfiktivStatusDistributionAnalyticsViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "analytics",
  store
})
export class AnalyticsStore extends VuexModule {
  private _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()
  ];

  get dateRange(): string[] {
    return this._dateRange;
  }

  get from(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return tempo;
  }

  get to(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(20, 59, 59, 999)).toISOString();
    return tempo;
  }

  trendAnalysisData: MrfiktivBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  private _statusDistributionData: MrfiktivStatusDistributionAnalyticsViewModelGen = {
    data: []
  };

  private _hourDistributionData: MrfiktivReportHourAnalysisPerPartnerViewmodelGen = {
    data: []
  };

  private _weekdayDistributionData: MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen = {
    data: []
  };

  private _reportsCountData: MrfiktivAnalyticsCountGen = {
    count: 0
  };

  private _formsCountData: MrfiktivAnalyticsCountGen = {
    count: 0
  };

  bookingCountData: MrfiktivAnalyticsCountGen = {
    count: 0
  };

  signCountData: MrfiktivAnalyticsCountGen = {
    count: 0
  };

  trendAnalysisDataSign: MrfiktivBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  trendAnalysisDataBooking: MrfiktivBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get statusDistributionData(): MrfiktivStatusDistributionAnalyticsViewModelGen {
    return this._statusDistributionData;
  }

  get hourDistributionData(): MrfiktivReportHourAnalysisPerPartnerViewmodelGen {
    return this._hourDistributionData;
  }

  get weekdayDistributionData(): MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen {
    return this._weekdayDistributionData;
  }

  get reportsCountData() {
    return this._reportsCountData;
  }

  get formsCountData() {
    return this._formsCountData;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  // Trend
  @Action
  async getTrendAnalysisDataPerPartner() {
    const trendAnalysisData: MrfiktivBaseTrendAnalysisViewmodelGen = await analyticsService.getTrendAnalysisDataPerPartner(
      this.from,
      this.to
    );
    this.context.commit("_mutateTrendAnalysisData", trendAnalysisData);
  }

  @Mutation
  _mutateTrendAnalysisData(trendAnalysisData: MrfiktivBaseTrendAnalysisViewmodelGen) {
    this.trendAnalysisData = trendAnalysisData;
  }

  // Status Distribution

  @Action
  async getStatusDistributionData() {
    const data: MrfiktivStatusDistributionAnalyticsViewModelGen = await analyticsService.getStatusDistributionPerPartner(
      this.from,
      this.to
    );

    this.context.commit("_mutateStatusDistributionData", data);
  }

  @Mutation
  _mutateStatusDistributionData(data: MrfiktivStatusDistributionAnalyticsViewModelGen) {
    this._statusDistributionData = data;
  }

  // Hour distribution

  @Action
  async getHourDistributionData() {
    const data: MrfiktivReportHourAnalysisPerPartnerViewmodelGen = await analyticsService.getHourAnalysisPerPartner(
      this.from,
      this.to
    );

    this.context.commit("_mutateHourDistributionData", data);
  }

  @Mutation
  _mutateHourDistributionData(data: MrfiktivReportHourAnalysisPerPartnerViewmodelGen) {
    this._hourDistributionData = data;
  }

  // Weekly distribution
  @Action
  async getWeekdayDistributionDataPerPartner() {
    const data: MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen = await analyticsService.getWeekdayAnalysisPerPartner(
      this.from,
      this.to
    );
    this.context.commit("_mutateWeekdayDistributionData", data);
  }

  @Mutation
  _mutateWeekdayDistributionData(data: MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen) {
    this._weekdayDistributionData = data;
  }

  @Action
  async getCountDataPerPartner(
    document:
      | "form"
      | "report"
      | "image"
      | "user"
      | "partner"
      | "newsletter"
      | "appraisal"
      | "vehicle"
      | "ticket"
      | "cost"
      | "project"
      | "document"
      | "booking"
      | "customerAccount"
      | "signRequest"
  ) {
    const data: MrfiktivAnalyticsCountGen = await analyticsService.getCountPerPartner({
      from: this.from,
      to: this.to,
      partnerId: PartnerModule.partner.id,
      document: document
    });

    this.context.commit("_mutateCountData", { data: data, document: document });

    return data;
  }

  @Mutation
  _mutateCountData(obj: { data: MrfiktivAnalyticsCountGen; document: string }) {
    switch (obj.document) {
      case "booking":
        this.bookingCountData = obj.data;
        break;

      case "signRequest":
        this.signCountData = obj.data;
        break;

      case "report":
        this._reportsCountData = obj.data;
        break;

      case "form":
        this._formsCountData = obj.data;
        break;

      default:
        break;
    }
  }

  @Action
  async getTrendDataPerPartner(
    document:
      | "form"
      | "report"
      | "image"
      | "user"
      | "partner"
      | "newsletter"
      | "appraisal"
      | "vehicle"
      | "ticket"
      | "cost"
      | "project"
      | "document"
      | "booking"
      | "customerAccount"
      | "signRequest"
  ) {
    const data: MrfiktivBaseTrendAnalysisViewmodelGen = await analyticsService.getTrendPerPartner({
      from: this.from,
      to: this.to,
      partnerId: PartnerModule.partner.id,
      document: document
    });

    this.context.commit("_mutateTrendData", { data: data, document: document });

    return data;
  }

  @Mutation
  _mutateTrendData(obj: { data: MrfiktivBaseTrendAnalysisViewmodelGen; document: string }) {
    switch (obj.document) {
      case "booking":
        this.trendAnalysisDataBooking = obj.data;
        break;

      case "signRequest":
        this.trendAnalysisDataSign = obj.data;
        break;

      case "report":
        this.trendAnalysisData = obj.data;
        break;

      default:
        break;
    }
  }

  // Reports count
  @Action
  async getReportsCountDataPerPartner() {
    const data: MrfiktivAnalyticsCountGen = await analyticsService.getReportsCountPerPartner(this.from, this.to);

    this.context.commit("_mutateReportsCountData", data);
  }

  @Mutation
  _mutateReportsCountData(data: MrfiktivAnalyticsCountGen) {
    this._reportsCountData = data;
  }

  // Forms count
  @Action
  async getFormsCountDataPerPartner() {
    const data: MrfiktivAnalyticsCountGen = await analyticsService.getFormsCountPerPartner(this.from, this.to);

    this.context.commit("_mutateFormsCountData", data);
  }

  @Mutation
  _mutateFormsCountData(data: MrfiktivAnalyticsCountGen) {
    this._formsCountData = data;
  }
}

export const AnalyticsModule = getModule(AnalyticsStore);
