var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      !_vm.vehicle.identificationnumber
        ? _c(
            "v-alert",
            {
              staticClass: "ma-2",
              attrs: {
                value: true,
                type: "warning",
                border: "left"
              }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "grow"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.i18n["missingIdentificationnumber"]) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-expansion-panels",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header"),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "8",
                            sm: "10"
                          }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "mt-4",
                            attrs: {
                              outlined: "",
                              readonly: "",
                              value: _vm.url,
                              label: _vm.$t(
                                "components.partner.PartnerReportInitializeCard.result"
                              )
                            },
                            on: {
                              click: _vm.copyLinkToClipboard
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center justify-center",
                          attrs: {
                            cols: "4",
                            sm: "2"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              ref: "download",
                              attrs: {
                                download: _vm.qrCodeName
                              },
                              on: {
                                click: _vm.downloadQrLink
                              }
                            },
                            [
                              _c("qrcode-vue", {
                                ref: "qrcode",
                                staticClass: "mx-auto",
                                attrs: {
                                  value: _vm.url,
                                  size: "120",
                                  level: "L",
                                  "data-test-qrcode": "",
                                  id: "qr",
                                  background: "transparent"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("table-wrapper", {
        attrs: {
          controlElements: _vm.controlElements,
          headers: _vm.headers,
          allItems: _vm.damages
        },
        on: {
          "click:row": function clickRow(v) {
            return _vm.$emit("openReportSideCard", v)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c("partner-report-initialize-dialog", {
                  key: "partner-report-initialize-dialog" + _vm.forceUpdate,
                  attrs: {
                    icon: false,
                    reportDto: _vm.createReportAsPartnerDto
                  },
                  on: {
                    close: _vm.resetCreateReportDto
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "item.timestamp.created",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("simpleDoubleDigitDate")(item.timestamp.created)
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.progressStatus",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      small: "",
                      color: _vm.colorByStatus.get(item.progressStatus)
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "views.report.my.MyDamageView.status.".concat(
                              item.progressStatus
                            )
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.damages",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold"
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
                _vm._v(" "),
                item.title ? _c("br") : _vm._e(),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.damage
                          .map(function(damage) {
                            return _vm.$t(
                              "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damages.".concat(
                                damage
                              )
                            )
                          })
                          .join(", ")
                      ) +
                      " "
                  )
                ]),
                item.damage.length >= 1 ? _c("br") : _vm._e(),
                _c("span", [_vm._v(_vm._s(item.message))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }