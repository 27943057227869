


















import CompanySideCard from "@/components/company/CompanySideCard.vue";
import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { parseGeoCodeFromGoogleMapsUrl } from "@/lib/utility/google-maps-geo.helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { CompanyWithGeoAndDistanceBase } from "@/models/company.entity";
import { GeoEntity } from "@/models/geoEntity";
import { CompanyModule } from "@/store/modules/company.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";

const CompanyMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/company/CompanyMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: {
    Debug,
    TheLayoutPortal,
    CompanyMap,
    CompanySideCard
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyMapView extends mixins(PartnerFallbackMixin) {
  selectedCompany: CompanyWithGeoAndDistanceBase | null = null;

  get coordinates() {
    const coordinates: GeoEntity[] = [];
    CompanyModule.filtered.forEach(company => {
      if (company.addresses && Array.isArray(company.addresses)) {
        company.addresses.forEach(address => {
          if (address.geo) {
            coordinates.push(address.geo);
          }
        });
      }
    });
    return coordinates;
  }

  get color() {
    return ConfigModule.color.primary;
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get search() {
    return this.$route.query.s;
  }

  get filter() {
    return this.$route.query.f?.toString().toLocaleUpperCase();
  }

  get query() {
    return this.$route.query.q;
  }

  get parsed() {
    if (!this.$route.params.url) {
      return "";
    }

    const parsed = parseGeoCodeFromGoogleMapsUrl(this.$route.params.url);

    this.$log.debug(parsed);

    if (!parsed) {
      return "";
    }

    return `${parsed.lat}, ${parsed.lng}`;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  beforeMount() {
    CompanyModule.setFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
  }

  onClick(company: CompanyWithGeoAndDistanceBase | null) {
    if (!company) {
      return;
    }

    this.$log.debug(company);

    if (this.isMobile) {
      return new CompanyGoToHelper(this.$router).goToCompanyDetail({
        partnerId: this.partnerId,
        companyId: company?.id
      });
    }

    this.selectedCompany = company;
  }
}
