
























































































































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { IGroup } from "@/models/group.entity";
import {
  MrfiktivCustomFieldListElementDtoGen,
  MrfiktivCustomFieldListElementViewModelGen,
  MrfiktivProjectConfigDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomFieldConfigurationForm from "../report/CustomFieldConfigurationForm.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import RefsGroup from "../utility/RefsGroup.vue";

@Component({
  components: {
    CustomFieldConfigurationForm,
    MHeader,
    RefsGroup,
    ConfirmActionDialog,
    MDetailViewGrid,
    MActionList
  }
})
export default class GroupTypeDetail extends mixins(Vue) {
  readonly VIEW_PERSONS = "viewGroups";
  readonly VIEW_PERSON_GROUPS = "viewGroups";
  readonly EDIT_ACTION = "editAction";
  readonly DELETE_ACTION = "deleteAction";

  @Prop()
  value!: IGroup;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  @Prop()
  type!: IGroupType;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  isLoadingDescription = false;

  isLoadingTitle = false;

  isLoadingCustomFields = false;

  isEditingTitle = false;

  isEditCustomFields = false;

  editTitleCopy = this.value.title;

  isEditDescription = false;

  descriptionCopy = this.value.description ?? "";

  customFieldConfigCopy: MrfiktivCustomFieldListElementViewModelGen[] = [];

  get subtitle() {
    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get createdDate() {
    return simpleDoubleDigitDate(this.value.timestamp.created);
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = GroupGoToHelper.breadCrumbs;

    const partnerId = this.value.partnerId;
    const type = this.type;
    const groupId = this.value.id;
    const viewId = "0";

    return [
      breadCrumbs.groupTypeTable({ partnerId, type }),
      breadCrumbs.groupTypeCustomView({ partnerId, type, groupId, viewId }),
      breadCrumbs.groupTypeDetail({ partnerId, type, groupId })
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("project.ticket.actions.openInNewTab"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new GroupGoToHelper(this.$router).goToGroupTypeDetail({
            partnerId: this.value.partnerId,
            groupId: this.value.id,
            type: this.type,
            newTab: true
          })
      });
    }

    actions.push({
      text: $t("project.ticket.editTitle"),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startEditingTitle
    });

    return actions;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.PERSON_GROUP
    };
  }

  get detailActions() {
    return [
      {
        text: this.$t("group.viewGroups").toString(),
        key: "" + Math.random(),
        icon: "mdi-open-in-new",
        exec: () => this.value.goTo(this.$router).table()
      },
      {
        text: this.$t("delete").toString(),
        key: "" + Math.random(),
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      }
    ];
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  startEditingTitle() {
    this.editTitleCopy = this.value.title;
    this.isEditingTitle = true;
  }

  cancelEditTitle() {
    this.isEditingTitle = false;
    this.editTitleCopy = this.value.title;
  }

  async saveEditTitle() {
    this.isLoadingTitle = true;
    await this.value
      .updatePartial({
        title: this.editTitleCopy
      })
      .catch(handleError);
    this.editTitleCopy = this.value.title;

    this.isLoadingTitle = false;
    this.isEditingTitle = false;
  }

  startEditDescription() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        description: this.descriptionCopy
      })
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }

  startEditCustomFields() {
    if (!this.value) return;
    this.customFieldConfigCopy.splice(0);
    this.customFieldConfigCopy.push(...this.value.configuration.customFieldConfig);
    this.isEditCustomFields = true;
  }

  abortEditCustomFields() {
    if (!this.value) return;
    this.customFieldConfigCopy.splice(0);
    this.customFieldConfigCopy.push(...this.value.configuration.customFieldConfig);
    this.isEditCustomFields = false;
  }

  async saveCustomFields() {
    if (!this.value) return;
    this.isEditCustomFields = true;

    const customFieldConfig: MrfiktivCustomFieldListElementDtoGen[] = this.customFieldConfigCopy.map(c => ({
      ...c,
      customField: c.customField.id
    }));

    const configuration: MrfiktivProjectConfigDtoGen = {
      ...this.value.configuration,
      views: this.value.configuration.views.map(v => v.dto),
      isTemplate: this.value.configuration.isTemplate,
      customFieldConfig
    };

    this.isLoadingCustomFields = true;
    await this.value
      .updatePartial({
        configuration
      })
      .catch(handleError);

    this.isLoadingCustomFields = false;

    this.isEditCustomFields = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.value.partnerId);
  }
}
