










import GroupTypeTable from "@/components/group/GroupTypeTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { GroupGoToHelper } from "@/lib/utility/group.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Group, IGroup } from "@/models/group.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { GroupModule } from "@/store/modules/group.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { IGroupType } from "@/lib/utility/group/interface/group-type.interface";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import { $t } from "@/lib/utility/t";

@Component({
  components: {
    GroupTypeTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class GroupTypeTableView extends mixins(PartnerFallbackMixin) {
  @Ref("table")
  table!: GroupTypeTable;

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get type(): IGroupType {
    return this.$route.params.type as IGroupType;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(GroupGoToHelper.breadCrumbs.groupTypeTable({ partnerId: this.partnerId, type: this.type }));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${GroupModule.totalItems} ${this.$t("common.nouns.documents")}`,
      key: "amount",
      disabled: true
    });

    return chips;
  }

  get actions(): IAction[] {
    return [
      {
        text: $t(`group.${this.type}.all`),
        key: "allDocuments",
        exec: () =>
          new GroupGoToHelper(this.$router).goToGroupTypeEntities({ partnerId: this.partnerId, type: this.type })
      }
    ];
  }

  beforeMount() {
    GroupModule.setFilters([]);
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const groupId = this.$route.query.groupId as string;
    if (groupId) {
      const group = new Group({ partnerId: this.partnerId, id: groupId });
      this.table?.setSelectedItem(group);
    }
  }

  goToCustomView(group: IGroup) {
    group.goTo(this.$router).customView();
  }
}
