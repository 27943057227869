



import { Component } from "vue-property-decorator";
import PartnerManageCard from "@/components/partner/PartnerManageCard.vue";
import { SettingModule } from "@/store/modules/setting.store";
import { PartnerModule } from "@/store/modules/partner";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";

@Component({ components: { PartnerManageCard } })
export default class PartnerManager extends PartnerFallbackMixin {
  get partnerId() {
    return PartnerModule.partner._id;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    if (this.$route.params.partnerId) {
      await SettingModule.getSettingsByPartnerId(this.partnerId);
    }
  }
}
