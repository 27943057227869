
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { handleError } from "@/lib/utility/handleError";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivUpdateVehicleStateDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { FleetAggregationModule, VehicleAggregation } from "@/store/modules/fleet-aggregation.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Vue } from "vue-property-decorator";

/**
 * Mixin with stuff shared by views that display details of a vehicle
 */
@Component({
  components: {},
  filters: {}
})
export default class FleetVehicleCrudMixin extends Vue {
  vehicleAggregation: VehicleAggregation | null = null;

  loadingUpdate = false;
  loadingDeletion = false;
  isLoadingVehicle = false;

  async loadVehicle() {
    try {
      this.isLoadingVehicle = true;
      const vehicleId = this.$route.params.vehicleId;
      const partnerId = PartnerModule.partner._id;
      const vehicle = await VehicleModule.getOne({
        partnerId,
        vehicleId
      });
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth();
      let vehicleAggregation = await FleetAggregationModule.getVehicleAggregation(vehicle.id);

      if (!vehicleAggregation) {
        vehicleAggregation = await FleetAggregationModule.createVehicleAggregation(vehicle);
      } else {
        vehicleAggregation.vehicle = vehicle;
      }
      await vehicleAggregation.initializeAsync({ year, month });
      this.vehicleAggregation = vehicleAggregation;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingVehicle = false;
    }
  }

  async updateVehicleState(vehicle: IVehicle, refresh = false) {
    this.$log.debug("updateVehicleState", vehicle.state);

    try {
      const updateDto: MrfiktivUpdateVehicleStateDtoGen = {
        state: vehicle.state ?? VehicleStateEnum.ACTIVE
      };

      this.loadingUpdate = true;
      const updated = await VehicleModule.updateState({
        partnerId: PartnerModule.partner._id,
        vehicleId: vehicle.id,
        data: updateDto
      });

      if (this.vehicleAggregation) {
        this.vehicleAggregation.vehicle = updated;
      }

      this.$toast.success("👍");
    } catch (error) {
      this.loadingUpdate = false;
      if (refresh) {
        await this.loadVehicle();
      }
    }
  }

  async updateVehicle(vehicle: IVehicle, refresh = false) {
    this.$log.debug("updateVehicle", vehicle);
    this.loadingUpdate = true;
    try {
      const updated = await vehicle.update();

      if (this.vehicleAggregation) {
        this.vehicleAggregation.vehicle = updated;
      }

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingUpdate = false;
      if (refresh) {
        await this.loadVehicle();
      }
    }
  }

  async deleteVehicle() {
    try {
      this.loadingDeletion = true;
      await VehicleModule.delete({
        partnerId: PartnerModule.partner._id,
        vehicleId: this.$route.params.vehicleId
      });

      await VehicleModule.fetchAll({ partnerId: PartnerModule.partner._id });

      this.$toast.success(
        this.$t("components.fleet.FleetVehicleDetailVehicleCard.deleted", {
          id: this.vehicleAggregation?.vehicle?.numberplate || this.$route.params.vehicleId
        })
      );

      this.vehicleAggregation = null;
      FleetAggregationModule.removeAggregation(this.$route.params.vehicleId);

      this.$router.push({
        name: "FleetHome",
        params: {
          partnerId: this.$route.params.partnerId
        }
      });
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loadingDeletion = false;
    }
  }
}
