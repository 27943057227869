





































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TrashCanIcon from "@/components/utility/TrashCanIcon.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { DocumentModule } from "@/store/modules/document.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import DocumentSelectionDialog from "../sign/DocumentSelectionDialog.vue";
import SideCard from "@/components/utility/SideCard.vue";
import PdfViewer from "@/components/files/v2/PdfViewer.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import { ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    ConfirmActionDialog,
    TheLayoutPortal,
    MHeader,
    LatestEntriesCardEmpty,
    ContextMenu,
    TrashCanIcon,
    DocumentSelectionDialog,
    SideCard,
    PdfViewer,
    TableWrapper
  },
  filters: { simpleDoubleDigitDate }
})
export default class HandoverAttachmentsTableView extends mixins(Vue, PermissionMixin) {
  title = $t("common.nouns.attachments");

  isLoading = true;

  handover: IHandover | null = null;

  isRemovingAttachmentLoading = false;

  removeAttachmentIndex = 0;

  isAttachmentRemoveDialog = false;

  attachments: { document: ISignDocument | null; loading: boolean }[] = [];

  isAddingAttachmentLoading = false;

  isDocumentDialogActive = false;

  selectedAttachment: ISignDocument | null = null;

  selectingAttachment = false;

  containerWidth = 0;

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: this.title,
        value: "index",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 125, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        disabled: this.isRemovingAttachmentLoading || this.isAddingAttachmentLoading,
        icon: "mdi-trash-can",
        text: $t("remove"),
        action: (item: { index: number }) => this.openRemoveAttachmentDialog(item.index)
      }
    ];
  }

  get indexedAttachments() {
    return this.attachments.map((attachment, index) => ({ ...attachment, index }));
  }

  get isMobilePdfViewer() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) return breadcrumbs;

    breadcrumbs.push({
      text: $t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: $t("common.nouns.attachments"),
      exact: true,
      disabled: true,
      to: {
        name: "HandoverAttachmentsTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: $t("handover.attachments.create"),
      key: "newAttachment",
      disabled: this.isRemovingAttachmentLoading || this.isAddingAttachmentLoading,
      exec: () => (this.isDocumentDialogActive = true)
    });

    return actions;
  }

  get fileActions(): IAction[] {
    const actions: IAction[] = [];

    if (this.selectedAttachment) {
      actions.push({
        text: $t("project.ticket.actions.openInNewTab"),
        key: "newAttachment",
        exec: () => this.openAttachment(this.selectedAttachment)
      });
    }

    return actions;
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();

      if (!this.handover) return;

      this.attachments.splice(0);

      for (const attachmentId of this.handover.attachmentIds) {
        this.handleDocumentId(attachmentId);
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  measureWidth() {
    const client = (this.$refs.container as Element)?.getBoundingClientRect();

    this.containerWidth = client.width;
  }

  async selectAttachment(attachment: ISignDocument | null) {
    this.selectingAttachment = true;
    this.selectedAttachment = null;
    await attachment?.fetch().catch(handleError);
    this.selectingAttachment = false;

    this.$nextTick(() => {
      this.selectedAttachment = attachment;
    });
  }

  openRemoveAttachmentDialog(i: number) {
    this.removeAttachmentIndex = i;
    this.isAttachmentRemoveDialog = true;
  }

  async saveAttachmentRemoveDialog() {
    if (!this.handover) return;

    try {
      this.isRemovingAttachmentLoading = true;

      const document = this.attachments[this.removeAttachmentIndex];

      // remove reference to handover from document
      if (document.document) {
        const handoverReferenceIndex = document.document.refs.findIndex(r => r.refId === this.handover?.id);
        if (handoverReferenceIndex > -1) {
          document.document.refs.splice(handoverReferenceIndex, 1);
        }
        await document.document.update();
      }
      const attachmentIds = [...this.handover.attachmentIds];
      attachmentIds.splice(this.removeAttachmentIndex, 1);
      await this.handover.updatePartial({ attachmentIds }).catch(handleError);
      this.attachments.splice(this.removeAttachmentIndex, 1);

      this.removeAttachmentIndex = 0;
      this.isAttachmentRemoveDialog = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isRemovingAttachmentLoading = false;
    }
  }

  async getDocumentForId(documentId: string) {
    let document: ISignDocument | null = DocumentModule.maps.id.get(documentId)[0] ?? null;

    if (!document) {
      try {
        document = await new SignDocument({ id: documentId, partnerId: this.handover?.partnerId }).fetch();
      } catch (e) {
        this.$log.error(e);
        document = null;
      }
    }

    return document;
  }

  async handleDocumentId(documentId: string) {
    this.attachments.push({
      document: null,
      loading: true
    });

    const index = this.attachments.length - 1;

    const document = await this.getDocumentForId(documentId);

    this.attachments.splice(index, 1, {
      document,
      loading: false
    });
  }

  abortAttachmentRemoveDialog() {
    this.removeAttachmentIndex = 0;
    this.isAttachmentRemoveDialog = false;
  }

  async onAttachmentCreated(attachment: ISignDocument) {
    if (!this.handover) return;

    const attachmentIds = [...this.handover.attachmentIds];
    attachmentIds.push(attachment.id);

    this.isAddingAttachmentLoading = true;
    try {
      this.attachments.push({ document: null, loading: true });
      const index = this.attachments.length - 1;
      await this.handover.updatePartial({ attachmentIds }).catch(handleError);

      // add reference to handover to document
      if (this.handover) {
        attachment.refs.push({
          refId: this.handover.id,
          refType: BackendResourceEnum.HANDOVER
        });
        await attachment.update();
      }

      this.isDocumentDialogActive = false;
      this.attachments.splice(index, 1, { document: attachment, loading: false });
    } catch (e) {
      handleError(e);
    } finally {
      this.isAddingAttachmentLoading = false;
    }
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  openAttachment(attachment?: ISignDocument | null) {
    if (attachment) new GoToHelper(this.$router).goToDocumentDetail(attachment.id, attachment.partnerId, true);
  }
}
