var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row",
          staticStyle: {
            display: "inline-block"
          }
        },
        [
          _c(
            "v-tabs",
            {
              class: _vm.isMobile ? "" : "pl-2",
              model: {
                value: _vm.viewIdLocal,
                callback: function callback($$v) {
                  _vm.viewIdLocal = $$v
                },
                expression: "viewIdLocal"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "yellow"
                }
              }),
              _vm._l(_vm.tabs, function(item, index) {
                return _c(
                  "v-tab",
                  {
                    key: item.id,
                    staticClass: "v-tabs__container",
                    attrs: {
                      disabled: _vm.loading
                    }
                  },
                  [
                    _vm.tabIcon(item)
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              left: ""
                            }
                          },
                          [_vm._v(_vm._s(_vm.tabIcon(item)))]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.tabTitleTransformer(item)) + " "),
                    index === _vm.viewIdLocal
                      ? _c("project-custom-view-context-menu", {
                          attrs: {
                            customView: _vm.activeTabConfig,
                            filterOptions: _vm.store.filterOptions
                          },
                          on: {
                            delete: _vm.deleteCustomView,
                            createDuplicate: _vm.createDuplicateCustomView,
                            update: _vm.openUpdateDialog,
                            reorder: _vm.openReorderDialog
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c("project-custom-view-form", {
                staticClass: "align-self-center",
                attrs: {
                  isDialogActive: _vm.customViewFormDialog,
                  project: _vm.entity,
                  predefinedFilter: _vm.predefinedFilter,
                  filterOptions: _vm.store.filterOptions
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialog = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialog = $event
                  },
                  save: _vm.onAddedNewBoard
                }
              }),
              _c("project-custom-view-form", {
                staticClass: "align-self-center",
                attrs: {
                  isDialogActive: _vm.customViewFormDialogUpdate,
                  isEditForm: true,
                  project: _vm.entity,
                  customViewToUpdate: _vm.activeTabConfig,
                  hideDefaultActivator: true,
                  predefinedFilter: _vm.predefinedFilter,
                  filterOptions: _vm.store.filterOptions
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialogUpdate = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialogUpdate = $event
                  },
                  update: _vm.loadForCustomView
                }
              }),
              _vm.customViewFormDialogOrder
                ? _c("project-custom-view-order-dialog", {
                    staticClass: "align-self-center",
                    attrs: {
                      isDialogActive: _vm.customViewFormDialogOrder,
                      project: _vm.entity,
                      filterOptions: _vm.store.filterOptions
                    },
                    on: {
                      "update:isDialogActive": function updateIsDialogActive(
                        $event
                      ) {
                        _vm.customViewFormDialogOrder = $event
                      },
                      "update:is-dialog-active": function updateIsDialogActive(
                        $event
                      ) {
                        _vm.customViewFormDialogOrder = $event
                      },
                      update: _vm.loadForCustomView
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("v-divider"),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: {
              height: "3",
              indeterminate: "",
              color: "info"
            }
          })
        : _c("div", {
            staticStyle: {
              height: "3px"
            }
          }),
      !_vm.tabs.length
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-actions",
                {
                  staticClass: "justify-center"
                },
                [
                  _c("custom-view-create-helper", {
                    staticClass: "mt-3",
                    attrs: {
                      partnerId: _vm.entity.partnerId,
                      tableBaseConfig: _vm.tableBaseConfig,
                      calendarBaseConfig: _vm.calendarBaseConfig,
                      boardBaseConfig: _vm.boardBaseConfig
                    },
                    model: {
                      value: _vm.entity,
                      callback: function callback($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.viewIdLocal === 0 || _vm.viewIdLocal
                ? _c(
                    "v-card",
                    {
                      staticClass: "flex-container",
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("filter-card-pagination", {
                        attrs: {
                          filterList: _vm.store.filterOptions,
                          filter: _vm.paginationFilter,
                          filterOptions: _vm.store.filterOptions,
                          predefinedFilter: _vm.predefinedFilter,
                          search: _vm.search,
                          hideDivider: true
                        },
                        on: {
                          "update:filter": function updateFilter($event) {
                            _vm.paginationFilter = $event
                          },
                          "update:search": function updateSearch($event) {
                            _vm.search = $event
                          },
                          confirmFilter: function confirmFilter($event) {
                            return _vm.store.fetchAll({
                              partnerId: _vm.entity.partnerId
                            })
                          },
                          confirmSearch: function confirmSearch($event) {
                            return _vm.store.fetchAll({
                              partnerId: _vm.entity.partnerId
                            })
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.viewIdLocal !== undefined
                ? _c(
                    "div",
                    [
                      _vm.loading
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "table"
                            }
                          })
                        : _c(
                            "v-tabs-items",
                            {
                              attrs: {
                                touchless: ""
                              },
                              model: {
                                value: _vm.viewIdLocal,
                                callback: function callback($$v) {
                                  _vm.viewIdLocal = $$v
                                },
                                expression: "viewIdLocal"
                              }
                            },
                            _vm._l(_vm.tabs, function(item) {
                              var _vm$activeTabConfig2

                              return _c(
                                "v-tab-item",
                                {
                                  key: item.id
                                },
                                [
                                  item.type ===
                                  _vm.ProjectCustomViewTypeEnum.BOARD
                                    ? _c(
                                        "div",
                                        [
                                          _c("kanban-board", {
                                            attrs: {
                                              loading:
                                                _vm.loading ||
                                                _vm.customViewsLoading,
                                              columns: _vm.boardColumns,
                                              cards: _vm.tickets,
                                              identifierFunction:
                                                _vm.identifierFunction,
                                              changeValueFunction:
                                                _vm.changeValueFunction,
                                              filterOptions:
                                                _vm.store.filterOptions
                                            },
                                            on: {
                                              change: _vm.change
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "chip",
                                                  fn: function fn(_ref) {
                                                    var items = _ref.items
                                                    return [
                                                      _vm._t("chip", null, {
                                                        items: items
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "default",
                                                  fn: function fn(_ref2) {
                                                    var _vm$activeTabConfig

                                                    var item = _ref2.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          on: {
                                                            click: function click() {
                                                              return _vm.openSideCard(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            {
                                                              staticClass:
                                                                "py-0"
                                                            },
                                                            [
                                                              _c("refs-list", {
                                                                attrs: {
                                                                  refType:
                                                                    _vm.refType,
                                                                  item: item,
                                                                  small: false
                                                                },
                                                                on: {
                                                                  openDetail: function openDetail(
                                                                    item
                                                                  ) {
                                                                    return _vm.openSideCard(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm.activeTabConfig &&
                                                              _vm
                                                                .activeTabConfig
                                                                .values
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pb-1 pl-2"
                                                                    },
                                                                    _vm._l(
                                                                      _vm.collectFieldValues(
                                                                        item,
                                                                        (_vm$activeTabConfig =
                                                                          _vm.activeTabConfig) ===
                                                                          null ||
                                                                          _vm$activeTabConfig ===
                                                                            void 0
                                                                          ? void 0
                                                                          : _vm$activeTabConfig.values
                                                                      ),
                                                                      function(
                                                                        field
                                                                      ) {
                                                                        return _c(
                                                                          "custom-field-value-chip",
                                                                          {
                                                                            key:
                                                                              field.id,
                                                                            attrs: {
                                                                              customFieldClass:
                                                                                "mr-1 mb-1",
                                                                              color:
                                                                                field.color,
                                                                              dark:
                                                                                field.dark,
                                                                              value: field,
                                                                              lightenBackground: false
                                                                            }
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : item.type ===
                                      _vm.ProjectCustomViewTypeEnum.TABLE
                                    ? _c(
                                        "div",
                                        [
                                          _c("custom-project-table", {
                                            ref: "customProjectTable".concat(
                                              _vm.viewIdLocal
                                            ),
                                            refInFor: true,
                                            attrs: {
                                              loading: _vm.loading,
                                              customConfig:
                                                (_vm$activeTabConfig2 =
                                                  _vm.activeTabConfig) ===
                                                  null ||
                                                _vm$activeTabConfig2 === void 0
                                                  ? void 0
                                                  : _vm$activeTabConfig2.values,
                                              groupBy: _vm.groupTableBy,
                                              sortBy: _vm.sortBy,
                                              sortDesc: _vm.sortDesc,
                                              items: _vm.tickets,
                                              entity: _vm.entity,
                                              partnerId: _vm.entity.partnerId,
                                              filterOptions:
                                                _vm.store.filterOptions
                                            },
                                            on: {
                                              openDetail: _vm.openSideCard
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "group.header",
                                                  fn: function fn(_ref3) {
                                                    var groupBy = _ref3.groupBy,
                                                      group = _ref3.group,
                                                      toggle = _ref3.toggle,
                                                      isOpen = _ref3.isOpen,
                                                      items = _ref3.items,
                                                      headers = _ref3.headers,
                                                      remove = _ref3.remove,
                                                      isMobile = _ref3.isMobile,
                                                      hover = _ref3.hover
                                                    return [
                                                      _vm._t(
                                                        "group.header",
                                                        null,
                                                        {
                                                          group: group,
                                                          groupBy: groupBy,
                                                          toggle: toggle,
                                                          isOpen: isOpen,
                                                          items: items,
                                                          headers: headers,
                                                          remove: remove,
                                                          isMobile: isMobile,
                                                          hover: hover
                                                        }
                                                      )
                                                    ]
                                                  }
                                                },
                                                _vm._l(_vm.headers, function(
                                                  header
                                                ) {
                                                  return {
                                                    key: "item.".concat(
                                                      header.value
                                                    ),
                                                    fn: function fn(_ref4) {
                                                      var item = _ref4.item,
                                                        value = _ref4.value
                                                      return [
                                                        _vm._t(
                                                          "item.".concat(
                                                            header.value
                                                          ),
                                                          null,
                                                          {
                                                            item: item,
                                                            value: value
                                                          }
                                                        )
                                                      ]
                                                    }
                                                  }
                                                })
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : item.type ===
                                      _vm.ProjectCustomViewTypeEnum.CALENDAR
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              attrs: {
                                                fluid: ""
                                              }
                                            },
                                            [
                                              _c("calendar", {
                                                attrs: {
                                                  loading:
                                                    _vm.loading ||
                                                    _vm.customViewsLoading,
                                                  events: _vm.events,
                                                  type: _vm.calendarType
                                                },
                                                on: {
                                                  "update:type": function updateType(
                                                    $event
                                                  ) {
                                                    _vm.calendarType = $event
                                                  },
                                                  "click:detail":
                                                    _vm.openCalendarDetail
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "preview",
                                                      fn: function fn(_ref5) {
                                                        var _vm$activeTabConfig3

                                                        var slotScope =
                                                          _ref5.slotScope
                                                        return [
                                                          _c(
                                                            "v-card",
                                                            [
                                                              _c("refs-list", {
                                                                attrs: {
                                                                  refType:
                                                                    _vm.refType,
                                                                  item: _vm.firstEntityFromEvent(
                                                                    slotScope.item
                                                                  ),
                                                                  customConfig:
                                                                    (_vm$activeTabConfig3 =
                                                                      _vm.activeTabConfig) ===
                                                                      null ||
                                                                    _vm$activeTabConfig3 ===
                                                                      void 0
                                                                      ? void 0
                                                                      : _vm$activeTabConfig3.values,
                                                                  small: true
                                                                },
                                                                on: {
                                                                  openDetail: function openDetail(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openTicketSideCardFromCalendar(
                                                                      slotScope
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _vm._v(
                                                  " Ansicht vom Typ " +
                                                    _vm._s(item.type) +
                                                    " wird noch nicht unterstützt. "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            }),
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _c("debug", [
        _vm._v(
          " " + _vm._s(_vm.activeTabConfig) + " " + _vm._s(_vm.events) + " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }