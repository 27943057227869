var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            !_vm.inbox
              ? _c("latest-entries-card-empty")
              : _c(
                  "div",
                  [
                    _c("partner-message-list-paginated", {
                      attrs: {
                        store: _vm.inbox.module
                      },
                      on: {
                        set: _vm.setMessage
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function fn() {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass: "pt-2 pb-0"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.inbox.name) + " "
                                      ),
                                      _c("v-spacer")
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-subtitle",
                                    {
                                      staticClass: "py-0 my-n2"
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          value: _vm.folder,
                                          loading: _vm.isNewFolderLoading,
                                          dense: "",
                                          items: _vm.folderOptions
                                        },
                                        on: {
                                          change: _vm.setNewFolder
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function fn(_ref) {
                                              var item = _ref.item
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.messageFolderIconMap.get(
                                                          item
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MessageFolderEnum.".concat(
                                                          item
                                                        )
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "selection",
                                            fn: function fn(_ref2) {
                                              var item = _ref2.item
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.messageFolderIconMap.get(
                                                            item
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "MessageFolderEnum.".concat(
                                                          item
                                                        )
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            _vm.isDetailLoading
              ? _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: {
                    type: "card@1"
                  }
                })
              : !_vm.message
              ? _c(
                  "v-card",
                  {
                    staticClass: "justify-center align-center",
                    attrs: {
                      flat: ""
                    }
                  },
                  [
                    _c(
                      "v-card-text",
                      [
                        _c("v-img", {
                          staticClass: "my-8",
                          attrs: {
                            contain: "",
                            src: require("@/assets/undraw/undraw_envelope_re_f5j4.svg"),
                            height: "240",
                            justify: "center"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-card-title",
                      {
                        staticClass: "justify-center"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.PartnerMessageListPaginated.empty"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-card-subtitle",
                      {
                        staticClass: "justify-center",
                        attrs: {
                          align: "center"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.PartnerMessageListPaginated.selectToRead"
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "v-card-actions",
                      {
                        staticClass: "justify-center"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "PartnerMessageInboxList"
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: ""
                                }
                              },
                              [_vm._v("Inboxen")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c("partner-message-detail", {
                  attrs: {
                    value: _vm.message
                  }
                })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }