var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("m-header", {
        attrs: {
          title: "Dashboard",
          breadCrumbs: _vm.breadCrumbs
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function fn() {
              return [
                _c("analytics-date-range-selector-dialog", {
                  staticClass: "mx-4",
                  attrs: {
                    dates: _vm.dates
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function callback($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "yellow"
                }
              }),
              _vm._l(_vm.tabs, function(item) {
                return _c(
                  "v-tab",
                  {
                    key: item.key,
                    staticClass: "v-tabs__container"
                  },
                  [
                    item.icon
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              left: ""
                            }
                          },
                          [_vm._v(_vm._s(item.icon))]
                        )
                      : _vm._e(),
                    _vm._v(_vm._s(item.text) + " ")
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c("v-divider")
        ],
        1
      ),
      _vm.activeTab === 0
        ? _c(
            "v-row",
            {
              staticClass: "mt-4 mx-4"
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("pie-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      kpiGroup: _vm.kpiChart1,
                      data: _vm.dataChart1,
                      title: "Schadenarten (Ø Schadenhöhe)",
                      reverseAxis: true,
                      sort: false
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("bar-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      data: _vm.dataChart2,
                      kpiGroup: _vm.kpiChart2,
                      sort: false,
                      title: "Schadenstatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("pie-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      data: _vm.dataChart3,
                      kpiGroup: _vm.kpiChart3,
                      title: "Anteil Privatfahrt in %"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("bar-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      data: _vm.dataChart8,
                      title: "Kosten pro Fahrer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    lg: "8",
                    md: "6",
                    cols: "12"
                  }
                },
                [
                  _c("time-series-line-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      data: _vm.trendData,
                      title: "Schadensmeldungen im Trend"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("pie-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      data: _vm.dataChart4,
                      title: "Ursachen (Ø Schadenhöhe)"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("bar-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      reverseAxis: true,
                      data: _vm.dataChart6,
                      sort: false,
                      title: "Wochentage"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6",
                    lg: "4"
                  }
                },
                [
                  _c("bar-chart-card", {
                    attrs: {
                      colorSchema: _vm.analyticsColors,
                      loading: false,
                      data: _vm.dataChart5,
                      title: "Top Kreditoren"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.activeTab === 1
        ? _c(
            "v-row",
            {
              staticClass: "mt-4 mx-4",
              attrs: {
                align: "center"
              }
            },
            [_vm._v(" Dashboard nr 2 ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }