










import { ImportPersonDto } from "@/models/import-person.dto";
import { Component, Prop, Vue } from "vue-property-decorator";
import ExcelImportCreate from "../utility/ExcelImportCreate.vue";

@Component({
  components: { ExcelImportCreate }
})
export default class PersonImportDialog extends Vue {
  readonly createDto = ImportPersonDto;

  @Prop()
  outlined!: boolean;

  @Prop()
  small!: boolean;

  @Prop()
  partnerId!: string;
}
