import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Contract, IContract } from "@/models/contract.entity";
import { MrfiktivContractControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ContractDataAccessLayer } from "./access-layers/contract.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ContractPageDataProvider } from "./page-data-provider/contract.page-data-provider";
import { ContractProviderPageDataProvider } from "./page-data-provider/contract-provider.page-data-provider";
import { ContractClientPageDataProvider } from "./page-data-provider/contract-client.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "contract",
  store
})
export class ContractPaginationStore extends PaginatedBaseStore<IContract, MrfiktivContractControllerGetParamsGen> {
  protected _data = ContractDataAccessLayer;
  protected _pageProvider = ContractPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Contract.filterables;
}

/**
 * To filter for receivers when both recevier and client are in the same view (need different filters)
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "contract-provider",
  store
})
class ContractProviderPaginationStore extends PaginatedBaseStore<IContract, MrfiktivContractControllerGetParamsGen> {
  protected _data = ContractDataAccessLayer;
  protected _pageProvider = ContractProviderPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Contract.filterables;
}

/**
 * To filter for clients when both recevier and client are in the same view (need different filters)
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "contract-client",
  store
})
class ContractClientPaginationStore extends PaginatedBaseStore<IContract, MrfiktivContractControllerGetParamsGen> {
  protected _data = ContractDataAccessLayer;
  protected _pageProvider = ContractClientPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Contract.filterables;
}

export const ContractModule = getModule(ContractPaginationStore);
export const ContractProviderModule = getModule(ContractProviderPaginationStore);
export const ContractClientModule = getModule(ContractClientPaginationStore);
