var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.relevantReports.length || _vm.blueprint
    ? _c(
        "div",
        [
          _vm.blueprint
            ? _c(
                "div",
                [
                  _c("m-image-marker", {
                    key:
                      "blueprint" +
                      _vm.blueprint.id +
                      "markers" +
                      _vm.markers.length,
                    attrs: {
                      src: _vm.blueprint.image.url,
                      markers: _vm.markers
                    },
                    on: {
                      markerCreated: function markerCreated($event) {
                        return _vm.goToDetailFormForMarker(
                          _vm.blueprint,
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            : _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.CreateHandoverFindingsDialog.steps.success.findingWithoutBlueprint"
                      )
                    ) +
                    " "
                )
              ]),
          _vm._l(_vm.relevantReports, function(r) {
            return _c(
              "v-list-item",
              {
                key: "report" + r.id,
                attrs: {
                  "three-line": ""
                },
                on: {
                  click: function click($event) {
                    return _vm.goToReportDetail(r)
                  }
                }
              },
              [
                r.handover
                  ? _c(
                      "v-list-item-avatar",
                      {
                        staticClass: "font-weight-bold"
                      },
                      [_vm._v(" #" + _vm._s(r.handover.number) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(" " + _vm._s(r.title) + " "),
                      r.companyName
                        ? _c(
                            "span",
                            {
                              staticClass: "grey--text text--lighten-1"
                            },
                            [
                              _vm._v(
                                " - " +
                                  _vm._s(r.companyName) +
                                  " (" +
                                  _vm._s(r.firstName) +
                                  " " +
                                  _vm._s(r.lastName) +
                                  ") "
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c("v-list-item-subtitle", [
                      _vm._v(" " + _vm._s(r.message) + " ")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }