













































import Debug from "@/components/utility/Debug.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import { PersonGroup } from "@/models/person-group.entity";
import { ICustomField } from "@/models/custom-field.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import UpdateForm from "../utility/UpdateForm.vue";
import PersonDetail from "./PersonDetail.vue";
import PersonDetailTable from "./PersonDetailTable.vue";

@Component({
  components: {
    UpdateForm,
    MHeader,
    MDetailViewGrid,
    MActionList,
    ConfirmActionDialog,
    Debug,
    PersonDetailTable
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class PersonDetailForm extends PersonDetail {
  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }

    const breadCrumbs = PersonGoToHelper.breadCrumbs;

    const selected = [];

    selected.push(breadCrumbs.PersonTable(this.value.partnerId));
    selected.push(breadCrumbs.PersonDetail(this.value.partnerId, this.value.id));
    selected.push(breadCrumbs.PersonDetailForm(this.value.partnerId, this.value.id));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonDetailForm({
            partnerId: this.value.partnerId,
            personId: this.value.id,
            newTab: false
          })
      });
    }

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonDetail({
            partnerId: this.value.partnerId,
            personId: this.value.id,
            newTab: false
          })
      });
    }

    const personGroupId = this.value.groupId;
    if (personGroupId) {
      actions.push({
        text: $t("person.toPersonGroup"),
        key: "toPersonGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonGroupCustomView({
            partnerId: this.value.partnerId,
            personGroupId: personGroupId,
            viewId: "0",
            newTab: false
          })
      });
    }

    if (this.value.id) {
      actions.push({
        text: $t("person.toPerson"),
        key: "toPerson",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonDetail({
            partnerId: this.value.partnerId,
            personId: this.value.id,
            newTab: false
          })
      });
    }

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.PERSON)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  async loadCustomConfig(value: string): Promise<ICustomField[]> {
    if (!value) return [];

    const personGroup =
      PersonGroupModule.maps.id.get(value)[0] ||
      (await new PersonGroup({ partnerId: this.partner.id, id: value }).fetch().catch(handleError));

    return personGroup?.configuration.customFieldConfig?.map(c => c.customField) || [];
  }
}
