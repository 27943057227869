



































import PartnerReportDetail from "@/components/partner/PartnerReportDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { FeatureModule } from "@/store/modules/feature.store";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { RefsMessagesModule } from "@/store/modules/refs.store";
import { UserModule } from "@/store/modules/me-user.store";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    LayoutSimple,
    TheLayoutPortal,
    PartnerReportDetail
  }
})
export default class PartnerReportsDetailView extends PartnerFallbackMixin {
  loading = false;
  isError = false;
  isGoHomeOnBack = false;

  async mounted() {
    this.loading = true;

    this.trySetByRouteOrDefault();

    if (this.$route.query.isGoHomeOnBack === "true") {
      this.isGoHomeOnBack = true;
    }

    try {
      await PartnerModule.getReportByPartnerIdAndReportId({
        partnerId: this.$route.params.partnerId,
        documentId: this.$route.params.reportId
      });

      const promises: Promise<any>[] = [];

      ActivityLogModule.setFilters([
        new PageFilterElement({
          key: "source.refId",
          operation: PageFilterOperationEnum.EQUAL,
          value: PartnerModule.report._id
        })
      ]);
      promises.push(
        ActivityLogModule.fetchFirstPage({ partnerId: PartnerModule.report.partnerId }).catch(error =>
          this.$log.error(error)
        )
      );

      if (
        FeatureModule.isTpsActive &&
        UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TPS, PartnerModule.report.partnerId)
      ) {
        ThirdPartySystemModule.setFilters([
          new PageFilterElement({
            key: "refs.refId",
            operation: PageFilterOperationEnum.EQUAL,
            value: PartnerModule.report._id
          })
        ]);
        promises.push(
          ThirdPartySystemModule.fetchFirstPage({ partnerId: PartnerModule.report.partnerId }).catch(error =>
            this.$log.error(error)
          )
        );
      } else {
        promises.push(
          ExportModule.getExportReportMetaByPartnerIdAndReportId({
            partnerId: PartnerModule.report.partnerId,
            reportId: PartnerModule.report._id
          }).catch(error => this.$log.error(error))
        );
      }

      RefsMessagesModule.generateFrontendTimeLineElementsForMessagesAssociatedWithReference({
        refId: PartnerModule.report._id,
        partnerId: PartnerModule.report.partnerId
      });

      await Promise.all(promises);
    } catch (error) {
      this.isError = true;
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden der Schadensmeldung");
    } finally {
      this.loading = false;
    }
  }

  goToList() {
    new GoToHelper(this.$router).goToReportList(this.$route.params.partnerId);
  }
}
