












import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FleetTable from "@/components/fleet/FleetTable.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, FleetTable, MHeader, LatestEntriesCardEmpty }
})
export default class FleetTableView extends mixins(Vue, PermissionMixin, PartnerFallbackMixin) {
  isLoading = false;

  title = this.$t("views.fleet.fleet.title");
  get breadCrumbs() {
    return [
      {
        text: this.$t("objects.fleet.title"),
        exact: true,
        disabled: true
      }
    ];
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    this.isLoading = true;
    try {
      await this.trySetByRouteOrDefault();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
