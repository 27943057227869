













import PersonDetailForm from "@/components/person/PersonDetailForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IPerson, Person } from "@/models/person.entity";
import { PersonModule } from "@/store/modules/person.store";
import { handleError } from "@/lib/utility/handleError";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";

@Component({
  components: {
    TheLayoutPortal,
    PersonDetailForm,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PersonDetailFormView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get personId(): string {
    return this.$route.params.personId;
  }

  isLoading = false;

  person: IPerson | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const person =
      PersonModule.maps.id.get(this.personId)[0] ??
      new Person({
        id: this.personId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await person.fetch();
      this.person = person;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToPersonGroup() {
    if (this.person?.groupId) {
      new PersonGoToHelper(this.$router).goToPersonGroupCustomView({
        partnerId: this.partnerId,
        personGroupId: this.person?.groupId,
        viewId: "0"
      });
    } else {
      new PersonGoToHelper(this.$router).goToPersonTable({
        partnerId: this.partnerId
      });
    }
  }
}
