import { PartnerModule } from "@/store/modules/partner";
import { MrfiktivHttpClientProvider } from "../mrfiktiv-http-client.provider";
import { Analytics } from "../v1/Analytics";
import {
  MrfiktivAnalyticsControllerCountPerPartnerParamsGen,
  MrfiktivAnalyticsCountGen,
  MrfiktivBaseTrendAnalysisViewmodelGen,
  MrfiktivPartnerAnalysisViewmodelGen,
  MrfiktivReportHourAnalysisPerPartnerViewmodelGen,
  MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen,
  MrfiktivStatusDistributionAnalyticsViewModelGen
} from "../v1/data-contracts";

import { HttpClient } from "../v1/http-client";
import { ServiceEnum } from "@/lib/enum/service.enum";
/**
 * Communicates with the thg module (implementet in thg api)
 */
class AnalyticsService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Analytics;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Analytics(this.client);
  }

  /**
   *
   * Gets the TrendAnalytics Data
   *
   * @returns
   */
  async getTrendanalysisDataPerPartner(from: string, to: string): Promise<MrfiktivBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.analyticsControllerTrendAnalysisForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  /**
   *
   * Gets the Status distribution of reports
   *
   * @returns
   */
  async getStatusDistributionPerPartner(
    from: string,
    to: string
  ): Promise<MrfiktivStatusDistributionAnalyticsViewModelGen> {
    const response = await this.proxy.analyticsControllerStatusDistributionForPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  /**
   *
   * Gets the week day distribution for a specific partner
   *
   * @returns
   */
  async getHourAnalysisPerPartner(from: string, to: string): Promise<MrfiktivReportHourAnalysisPerPartnerViewmodelGen> {
    const response = await this.proxy.analyticsControllerReportHourAnalysisPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  /**
   * Gets the weekly distribution for a specific partner
   *
   * @returns
   */
  async getWeekdayAnalysisPerPartner(
    from: string,
    to: string
  ): Promise<MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen> {
    const response = await this.proxy.analyticsControllerReportWeekdayAnalysisPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  /**
   * Gets count for a specific partner
   *
   * @returns
   */
  async getCountPerPartner(
    query: MrfiktivAnalyticsControllerCountPerPartnerParamsGen
  ): Promise<MrfiktivAnalyticsCountGen> {
    const response = await this.proxy.analyticsControllerCountPerPartner(query);

    return response.data;
  }

  /**
   * Gets report documents count for a specific partner
   *
   * @returns
   */
  async getReportsCountPerPartner(from: string, to: string): Promise<MrfiktivAnalyticsCountGen> {
    const response = await this.proxy.analyticsControllerCountPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      document: "report",
      from,
      to
    });

    return response.data;
  }

  /**
   * Gets form documents count for a specific partner
   *
   * @returns
   */
  async getFormsCountPerPartner(from: string, to: string): Promise<MrfiktivAnalyticsCountGen> {
    const response = await this.proxy.analyticsControllerCountPerPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      document: "form",
      from,
      to
    });

    return response.data;
  }

  async getTrendAnalysisDataForReportControl(from: string, to: string): Promise<MrfiktivBaseTrendAnalysisViewmodelGen> {
    const response = await this.proxy.analyticsControllerTrendAnalysisForForwardedReports({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  async getForwardedReportCountsByPartner(from: string, to: string): Promise<MrfiktivPartnerAnalysisViewmodelGen> {
    const response = await this.proxy.analyticsControllerGetForwardedReportCountsByPartner({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  async getTotalForwardedReportCount(from: string, to: string) {
    const response = await this.proxy.analyticsControllerGetTotalForwardedReportCount({
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
      from,
      to
    });

    return response.data;
  }

  async getTrendAnalysisDataForMultiplePartners(from: string, to: string, partnerIds: string[]) {
    const response = await this.proxy.analyticsControllerMultiplePartnersTrendAnalytics({
      ids: partnerIds,
      from,
      to
    });

    return response.data;
  }

  async getAverageTrendAnalysisDataForMultiplePartners(from: string, to: string, partnerIds: string[]) {
    const response = await this.proxy.analyticsControllerMultiplePartnersAverageTrendAnalytics({
      ids: partnerIds,
      from,
      to
    });

    return response.data;
  }

  async getReportsCountDataForMultiplePartners(from: string, to: string, partnerIds: string[]) {
    const response = await this.proxy.analyticsControllerMultiplePartnersCountReports({
      ids: partnerIds,
      from,
      to
    });

    return response.data;
  }

  async getStatusDistributionDataForMultiplePartners(from: string, to: string, partnerIds: string[]) {
    const response = await this.proxy.analyticsControllerMultiplePartnersStatusDistribution({
      ids: partnerIds,
      from,
      to
    });

    return response.data;
  }

  async count(serviceEnum: ServiceEnum, from: string, to: string) {
    const response = await this.proxy.analyticsControllerCount({
      document: serviceEnum as any,
      from,
      to
    });

    return response.data;
  }

  async countAndGroupByPartner(serviceEnum: ServiceEnum, from?: string, to?: string) {
    const response = await this.proxy.analyticsControllerCoundAndGroupByPartner({
      document: serviceEnum as any,
      from,
      to
    });

    return response.data;
  }

  async countPerPartner(serviceEnum: ServiceEnum, from: string, to: string, partnerId: string) {
    const response = await this.proxy.analyticsControllerCountPerPartner({
      document: serviceEnum as any,
      from,
      to,
      partnerId
    });

    return response.data;
  }
}

export default new AnalyticsService(new MrfiktivHttpClientProvider());
