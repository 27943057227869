









import Card from "@/components/utility/Card.vue";
import { FleetNode, IFleet } from "@/models/fleet.entity";
import { FleetModule } from "@/store/modules/fleet.store";
import { Component, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { ICompany } from "@/models/company.entity";
import { VehicleModule } from "@/store/modules/vehicle.store";

@Component({
  components: { Card, Debug }
})
export default class FleetDetail extends Vue {
  get tree(): FleetNode[] {
    return FleetModule.activeTree;
  }

  get fleet(): IFleet | null {
    return FleetModule.active;
  }

  get company(): ICompany | null {
    return FleetModule.activeCompany;
  }

  get companyAddress() {
    return this.company?.addresses?.[0].street;
  }

  get vehicles() {
    return VehicleModule.filteredAndSorted;
  }
}
