
















































































































































































































import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import ProjectCustomViewContextMenu from "@/components/project/ProjectCustomViewContextMenu.vue";
import ProjectCustomViewForm from "@/components/project/ProjectCustomViewForm.vue";
import ProjectCustomViewOrderDialog from "@/components/project/ProjectCustomViewOrderDialog.vue";

import TicketCreateDialog from "@/components/project/TicketCreateDialog.vue";
import TicketSideCard from "@/components/project/TicketSideCard.vue";
import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import Calendar from "@/components/utility/Calendar.vue";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import Debug from "@/components/utility/Debug.vue";
import RefsList from "@/components/utility/RefsList.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { downloadAsXlsx } from "@/lib/download-as-xlsx";
import { getNestedObjectValues } from "@/lib/objectPath-helper";
import { CalendarEvent, CalendarTypeEnum } from "@/lib/utility/calendarEvent";
import { deepCopy } from "@/lib/utility/deep-copy";
import { handleError } from "@/lib/utility/handleError";
import { CustomProjectTableHelper, CustomViewExtendedDocument } from "@/lib/utility/project/customProjectTableHelper";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import {
  MrfiktivProjectCustomViewFieldDtoGen,
  MrfiktivProjectCustomViewViewmodelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { IPaginationParams, PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { CustomFieldEnum } from "@/store/modules/custom-field.store";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import CustomProjectTable from "@/views/project/CustomProjectTable.vue";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import KanbanBoard, { IColumn } from "@/views/project/KanbanBoard.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IsCustomViewable } from "@/lib/interfaces/is-custom-viewable.interface";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";
import { ICustomViewableEntity } from "@/lib/interfaces/custom-viewable-entity.interface";
import { ProjectCustomView as ProjectCustomViewClass } from "@/models/project-custom-view.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { dottedSubString } from "@/lib/utility/string-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { IProjectCustomView } from "@/models/project-custom-view.entity";
import CustomViewCreateHelper from "@/components/cost/CustomViewCreateHelper.vue";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";

@Component({
  components: {
    Calendar,
    KanbanBoard,
    FilterCardPagination,
    TicketSideCard,
    RefsList,
    ProjectCustomViewForm,
    TheLayoutPortal,
    CustomProjectTable,
    ProjectCustomViewContextMenu,
    TicketCreateDialog,
    Debug,
    CustomFieldValueChip,
    ContextMenu,
    ProjectCustomViewOrderDialog,
    CustomViewCreateHelper
  }
})
export default class CustomViews<
  Type extends IsCustomViewable,
  PaginationParamsType extends IPaginationParams & { partnerId: string }
> extends mixins(PermissionMixin) {
  /**
   * Store that contains the paginated data
   */
  @Prop()
  store!: PaginatedBaseStore<Type, PaginationParamsType>;

  /**
   * Specifies the Active Tab and serves as v-model for the v-tab
   * Specifies the active tab config. if e.g. its 3, the active tab is the third one of the views array
   */
  @Prop({ default: "0" })
  readonly viewId!: string;

  /**
   * The entity containing custo view configuration
   */
  @Prop({ default: () => ({}) })
  entity!: CustomViewEntity<Type>;

  @Prop()
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  /**
   * to customize which fields should be shown in the table
   */
  @Prop({})
  tableBaseConfig!: IProjectCustomView;

  /**
   * to customize calendar
   */
  @Prop({})
  calendarBaseConfig!: IProjectCustomView;

  /**
   * to customize board
   */
  @Prop({})
  boardBaseConfig!: IProjectCustomView;

  isAddViewDialogActive = false;

  /**
   * Defines which refs-component will be shown as preview for entities
   */
  @Prop()
  refType!: BackendResourceEnum;

  get viewIdLocal() {
    return Number(this.viewId);
  }

  set viewIdLocal(value: number) {
    this.$emit("update:viewId", (value ?? 0).toString());
  }

  loading = false;

  /**
   * Dialog v-model for custom view creation
   */
  customViewFormDialog = false;

  /**
   * Dialog v-model for custom view update
   */
  customViewFormDialogUpdate = false;

  /**
   * Dialog v-model for custom view order update
   */
  customViewFormDialogOrder = false;

  /**
   * Dialog for creation of Tickets;
   */
  isCreateDialogActive = false;

  /**
   * Loading prop for deletion of a project
   */
  deletingProject = false;

  /**
   * General loading prop for this view
   */
  customViewsLoading = false;

  ProjectCustomViewTypeEnum = ProjectCustomViewTypeEnum;

  calendarType = this.isMobile ? CalendarTypeEnum.DAY : CalendarTypeEnum.MONTH;

  /**
   * Returns all custom views
   */
  get tabs(): MrfiktivProjectCustomViewViewmodelGen[] {
    return this.entity?.configuration?.views || [];
  }

  /**
   * Returns the active tab config
   */
  get activeTabConfig() {
    if (!this.tabs.length) {
      // TODO: Show dialog to create custom view or smth
      return undefined;
    }

    if (this.viewIdLocal === undefined || this.viewIdLocal > this.tabs.length) {
      this.viewIdLocal = 0;
    }

    if (!this.tabs[this.viewIdLocal]) {
      return undefined;
    }

    return deepCopy(this.tabs[this.viewIdLocal]);
  }

  get sortBy() {
    if (!this.activeTabConfig?.sortBy) {
      return null;
    }

    return this.activeTabConfig.sortBy.key;
  }

  get sortDesc() {
    if (!this.activeTabConfig?.sortBy) {
      return null;
    }

    return this.activeTabConfig.sortBy.order === PageOrderEnum.DESCENDING;
  }

  /**
   * Group Table by
   */
  get groupTableBy() {
    if (!this.activeTabConfig?.groupBy) {
      return null;
    }

    return this.activeTabConfig.groupBy.key;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  /**
   * Defines the attribute which is responsible for the columns of the board.
   * E.g it could be {type: "customFields", key: "123...mongoid"}
   *
   * For now it can only be a customField of type singleSelect
   *
   * That means that the columns are all possibles values of the customFields 123...mongoid (the getter "BoarrdColumns" serarches for the different values that this customField can have)
   *
   */
  get boardColumnKey() {
    return this.tabs[this.viewIdLocal]?.boardColumn?.key?.split("?")[1] ?? "";
  }

  get boardColumnOrder(): string[] {
    return this.tabs[this.viewIdLocal]?.boardColumnOrder ?? [];
  }

  /**
   * Searches all values that the boarColumnKey can have.
   * E.g. is the boarColumKey is "Werkstattstatus", the Boardcolums are e.g. "Für Abholung bereit", "In Werkstatt", "Werkstattauftrag beednet"
   */
  get boardColumns() {
    const columns: IColumn[] = [];

    const boardColumnKey = this.boardColumnKey;
    if (boardColumnKey) {
      const config = this.entity?.configuration?.customFieldConfig.find(
        config => config.customField.id === boardColumnKey
      );
      if (config?.customField?.configuration?.values) {
        const boardColumnOrder = this.boardColumnOrder;

        if (!boardColumnOrder.length) {
          for (const key of config.customField?.configuration?.values || []) {
            columns.push({
              key: key.value,
              label: key.value,
              color: key.color
            });
          }
        } else {
          for (const orderedValue of boardColumnOrder) {
            const foundKey = config.customField?.configuration?.values.find(f => f.value === orderedValue);
            if (foundKey) {
              columns.push({
                key: foundKey.value,
                label: foundKey.value,
                color: foundKey.color
              });
            }
          }
        }
      }
    }
    return columns;
  }

  get projectCustomFieldIdMap() {
    const projectCustomFields = this.entity?.configuration?.customFieldConfig || [];
    const idToKeyMap = new Map();

    projectCustomFields.forEach(item => {
      idToKeyMap.set(item.customField.id, item.customField.key);
    });

    return idToKeyMap;
  }

  get tickets(): Type[] {
    return this.store.paginationList;
  }

  get events(): CalendarEvent<Type>[] {
    const calenderEvents = [];
    if (!this.activeTabConfig) {
      return [];
    }
    for (const event of this.store.filtered) {
      let start: Date | undefined = undefined;
      let end: Date | undefined = undefined;
      /**
       * Type of the custom field of the either `calendarStart` or `calendarEnd` config.
       * In the calendar config type we only store the type as `customField`, not the underlying type
       * of custom field(date, date_time, single_select, etc)
       */
      let customFieldType: CustomFieldEnum | undefined = undefined;

      /**
       * Find correct event start
       */
      if (this.activeTabConfig?.calendarStart?.type === ProjectCustomViewFieldEnum.PROPERTY) {
        const key = this.activeTabConfig?.calendarStart?.key;

        const found = getNestedObjectValues(event, key) as string;

        if (found) {
          start = new Date(found);
        }
      }
      if (this.activeTabConfig?.calendarStart?.type === ProjectCustomViewFieldEnum.CUSTOM_FIELD) {
        const found = event.values?.find(v => v.id === this.activeTabConfig?.calendarStart?.key.split("?")[1]);
        if (found && found?.value) {
          const foundCustomFieldConfig = this.entity?.configuration?.customFieldConfig.find(
            customFieldConfigItem => customFieldConfigItem.customField.id === found.id
          );
          customFieldType = foundCustomFieldConfig?.customField.type as CustomFieldEnum;

          start = new Date(found.value as string);
        }
      }

      /**
       * Find correct event end
       */

      if (this.activeTabConfig?.calendarEnd?.type === ProjectCustomViewFieldEnum.PROPERTY) {
        const found = event.values?.find(v => v.id === this.activeTabConfig?.calendarStart?.key);
        if (found && found?.value) {
          const foundCustomFieldConfig = this.entity?.configuration?.customFieldConfig.find(
            customFieldConfigItem => customFieldConfigItem.customField.id === found.id
          );
          customFieldType = foundCustomFieldConfig?.customField.type as CustomFieldEnum;

          end = new Date(found.value as string);
        }
      }

      if (this.activeTabConfig?.calendarEnd?.type === ProjectCustomViewFieldEnum.CUSTOM_FIELD) {
        const found = event.values?.find(v => v.id === this.activeTabConfig?.calendarEnd?.key.split("?")[1]);
        if (found && found?.value) {
          const foundCustomFieldConfig = this.entity?.configuration?.customFieldConfig.find(
            customFieldConfigItem => customFieldConfigItem.customField.id === found.id
          );
          customFieldType = foundCustomFieldConfig?.customField.type as CustomFieldEnum;

          end = new Date(found.value as string);
        }
      }

      if (start || end) {
        // Determine the actual start and end dates
        let actualStart = start || end;
        let actualEnd = end;

        // Check and swap if necessary
        if (start && end && start > end) {
          actualStart = end;
          actualEnd = start;
        }

        calenderEvents.push({
          name: event.titleReadable,
          start: actualStart,
          end: actualEnd,
          timed: customFieldType === CustomFieldEnum.DATE_TIME,
          data: [event]
        } as CalendarEvent<Type>);
      }
    }

    return calenderEvents;
  }

  /**
   * Custom Field value lookup
   * @param customConfig
   */
  collectFieldValues(
    item: ICustomViewableEntity<Type, any>,
    customConfig: MrfiktivProjectCustomViewFieldDtoGen[]
  ): CustomViewExtendedDocument[] {
    if (!item.projectId) {
      return [];
    }
    return new CustomProjectTableHelper(
      customConfig,
      this.store.filterOptions,
      this.entity.configuration.customFieldConfig,
      item
    ).getExtendedDocument();
  }

  get headers() {
    return new CustomProjectTableHelper(
      this.activeTabConfig?.values ?? [],
      this.store.filterOptions,
      this.entity.configuration.customFieldConfig
    ).headers;
  }

  firstEntityFromEvent(event: CalendarEvent<Type>) {
    if (event && event.data && event.data.length) {
      return event.data[0];
    }
  }

  openTicketSideCardFromCalendar(slotScope: { item: CalendarEvent<Type>; close: () => void }) {
    const ticket = this.firstEntityFromEvent(slotScope.item);
    if (ticket) {
      this.openSideCard(ticket);
      slotScope.close();
    }
  }

  @Watch("viewId")
  async loadForCustomView() {
    this.loading = true;

    try {
      this.store.setFilters(this.activeTabConfig?.filters.map(f => new PageFilterElement(f)) || []);

      await this.store.fetchAll({ partnerId: this.entity.partnerId } as PaginationParamsType);
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  downloadToCsv() {
    /**
     * Get the correct table - the one we're viewing at the moment.
     * Let the table map the data to the correct format and
     * then download it.
     */
    const refName = `customProjectTable${this.viewIdLocal}`;
    const ref = this.$refs[refName] as (Vue | Element)[];
    const customProjectTableComponent = ref[0] as CustomProjectTable<Type>;

    let elementsToDownload: Record<string, any>[] = [];

    if (customProjectTableComponent) {
      elementsToDownload = customProjectTableComponent.mapTicketsToTableData();
    }

    downloadAsXlsx(elementsToDownload, undefined, false);
  }

  /**
   * Adds new Board an set new tab
   * @param config
   */
  async onAddedNewBoard(newConfig: MrfiktivProjectCustomViewViewmodelGen) {
    try {
      this.loading = true;
      const tabIndex = this.tabs.findIndex(config => config.id === newConfig.id);
      this.viewIdLocal = tabIndex;
    } catch (error) {
      handleError(error);
    }

    this.loading = false;
  }

  openUpdateDialog() {
    this.customViewFormDialogUpdate = true;
  }

  openReorderDialog() {
    this.customViewFormDialogOrder = true;
  }

  async createDuplicateCustomView(customView: MrfiktivProjectCustomViewViewmodelGen) {
    if (!this.entity?.configuration?.views) {
      this.$toast("Project configuration not set");
      return;
    }
    this.loading = true;
    try {
      const newCustomView = new ProjectCustomViewClass(deepCopy(customView));
      newCustomView.title = newCustomView.title + " (copy)";
      this.entity?.configuration.views.push(new ProjectCustomViewClass(newCustomView));
      await this.entity.update();
      this.onAddedNewBoard(this.entity.configuration.views.slice(-1)[0]);
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  tabIcon(item: MrfiktivProjectCustomViewViewmodelGen) {
    if (item.type === ProjectCustomViewTypeEnum.BOARD) {
      return "mdi-view-column-outline";
    }
    if (item.type === ProjectCustomViewTypeEnum.TABLE) {
      return "mdi-table";
    }
    if (item.type === ProjectCustomViewTypeEnum.CALENDAR) {
      return "mdi-calendar-month-outline";
    }
  }

  tabTitleTransformer(item: MrfiktivProjectCustomViewViewmodelGen) {
    const title = deepCopy(item.title ?? "");

    // Returns full Label if tab is active or title is empty
    if (!title || item.id === this.activeTabConfig?.id) {
      return title;
    }

    // Trim the string to 17 characters if it's longer than 20
    return dottedSubString(deepCopy(item.title), 20);
  }

  /**
   * Custom function to sort Ticktes for columns for the Board view
   * @param ticket
   */
  identifierFunction(ticket: Type) {
    if (this.boardColumnKey) {
      const value = ticket.values?.find(value => value.id === this.boardColumnKey);
      if (value) {
        return value.value;
      }
    }
  }

  /**
   * Custom function to change tickets that are drag an droped on the board
   * @param ticket
   */
  changeValueFunction(ticket: Type, newValue: string, oldValue: string) {
    if (this.boardColumnKey) {
      const value = ticket.values?.find(value => value.id === this.boardColumnKey);
      if (value?.value) {
        if (typeof value.value === "string") {
          value.value = newValue;
        } else if (!value.value.includes(newValue)) {
          const oldIndex = value.value.indexOf(oldValue);
          if (oldIndex > -1) value.value.splice(oldIndex, 1);
          value.value.push(newValue);
        }
      }
    }

    return ticket;
  }

  get search() {
    return this.store.search;
  }

  set search(search: string | undefined) {
    this.store.setSearch(search);
  }

  get paginationFilter(): IPageFilterElement[] {
    return this.store.filters;
  }

  set paginationFilter(filter: IPageFilterElement[]) {
    this.store.setFilters(filter);
  }

  openCalendarDetail(e: CalendarEvent<Type>) {
    this.openSideCard(e.data[0]);
  }

  /**
   * On mount init project an load tickets for custom view
   */
  async mounted() {
    this.customViewsLoading = true;
    try {
      await this.loadForCustomView();
    } catch (error) {
      handleError(error);
    } finally {
      this.customViewsLoading = false;
    }

    const customValueKey = "values.value";
    // add filter options for project specific custom fields
    let filterOptions = [...this.store.filterOptions];
    filterOptions = filterOptions.filter(f => f.key !== customValueKey);
    this.store.filterOptions.splice(0), this.store.filterOptions.push(...filterOptions);
    for (const customFieldConfig of this.entity?.configuration?.customFieldConfig ?? []) {
      this.store.filterOptions.push(
        new PaginationFilterListElement({
          key: customValueKey,
          displayName: customFieldConfig.customField.name,
          type: PageFilterTypes.STRING,
          config: {
            items: customFieldConfig.customField.configuration?.values,
            mapItemToComponent(item) {
              return {
                value: item
              };
            },
            component: customFieldConfig.customField.configuration?.values ? "custom-field-value-chip" : ""
          }
        })
      );
    }
  }

  openSideCard(element: Type | null) {
    this.$emit("openSideCard", element);
  }

  async deleteCustomView(customViewId: string) {
    try {
      this.deletingProject = true;
      const index = this.entity.configuration.views.findIndex(view => view.id === customViewId);
      this.viewIdLocal = 0;

      if (index !== -1) {
        // The element was found in the array
        this.entity.configuration.views.splice(index, 1);

        await this.entity.update();
      } else {
        // Handle the case where the element was not found
        this.$log.error("Custom view not found in the array");
      }
      if (this.tabs.length) {
        this.viewIdLocal = 0;
        await this.loadForCustomView();
      }
    } catch (error) {
      handleError(error);
    }

    this.deletingProject = false;
  }

  /**
   * Function to update the entity after droping it to a new column on the board
   *
   * @param ticket
   */
  async change(entity: ICustomViewableEntity<Type, any>) {
    try {
      entity.loading = true;
      await entity.update();
      this.openSideCard(null);
    } catch (error) {
      handleError(error);
    } finally {
      entity.loading = false;
    }
  }
}
