import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { ICompany } from "@/models/company.entity";
import { buildFleetTree, findPathInForest, Fleet, FleetNode, IFleet } from "@/models/fleet.entity";
import fleetService from "@/services/mrfiktiv/services/fleetService";
import { MrfiktivFleetControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { FleetDataAccessLayer, FleetIndexDbAccessLayer } from "./access-layers/fleet.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { FleetPageDataProvider } from "./page-data-provider/fleet.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "fleet",
  store
})
class FleetStore extends PaginatedBaseStore<IFleet, MrfiktivFleetControllerFindAllParamsGen> {
  protected _data = FleetDataAccessLayer;
  protected _db = FleetIndexDbAccessLayer;

  protected _pageProvider = FleetPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider, this._db);

  loading = false;

  root: IFleet | null = null;
  fleets: IFleet[] | null = null;
  map: Map<string, IFleet> = new Map();
  company: ICompany | null = null;
  tree: FleetNode[] = [];

  activeIds: string[] = [];
  openIds: string[] = [];

  filterOptions: PaginationFilterListElement[] = Fleet.filterables;

  get active(): IFleet | undefined {
    return this.map.get(this.activeIds[0]);
  }

  @Action
  reset() {
    this.context.commit("setRootFleet", null);
    this.context.commit("setFleets", []);
    this.context.commit("setTree", []);
    this._data.clear();
  }

  @Action
  async set(data: { fleet: IFleet }) {
    this.context.commit("setLoading", true);

    try {
      await fleetService.findOne(data.fleet.partnerId, data.fleet.id).then(response => {
        const fleet = new Fleet(response);
        this.context.commit("setRootFleet", fleet);
        this.context.commit("mutateActive", [fleet.id]);
        this.context.commit("mutateOpen", [fleet.id]);
      });
      await fleetService.findAllRelatedForFleet(data.fleet.partnerId, data.fleet.id).then(response => {
        this.context.commit("setTree", response);
        this.context.commit(
          "setFleets",
          response.map(r => new Fleet(r))
        );
      });
    } finally {
      this.context.commit("setLoading", false);
    }
  }

  @Action
  setActive(active: string[]) {
    this.context.commit("mutateActive", active);
  }

  @Action
  setOpen(open: string[]) {
    this.context.commit("mutateOpen", open);
  }

  @Mutation
  mutateActive(active: string[]) {
    this.activeIds = active;
  }

  @Mutation
  mutateOpen(open: string[]) {
    this.openIds = open;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setRootFleet(fleet: IFleet) {
    this.root = fleet;
  }

  @Mutation
  setFleets(fleets: IFleet[]) {
    this.map = new Map(
      fleets.map(f => {
        return [f.id, f];
      })
    );
    this.fleets = fleets;
  }

  @Mutation
  setCompany(company: ICompany) {
    this.company = company;
  }

  @Mutation
  setTree(fleet: IFleet[]) {
    const tree = buildFleetTree(fleet);

    this.tree = tree;
  }

  get breadcrumbs(): IBreadcrumb[] {
    if (!this.root) {
      return [];
    }

    const path = findPathInForest(this.tree, this.activeIds[0]);
    if (!path) {
      return [];
    }

    const routeName = "FleetDetailView";

    return path.map((node, idx) => {
      const isLast = idx === path.length - 1;
      return {
        text: node.name,
        exact: isLast,
        disabled: true,
        to: {
          name: routeName,
          params: { partnerId: node.obj?.partnerId ?? "", fleetId: node.id }
        }
      };
    });
  }
}

export const FleetModule = getModule(FleetStore);
