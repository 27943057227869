var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("paginated-table", {
    attrs: {
      store: _vm.store,
      headers: _vm.headers,
      baseQuery: {
        partnerId: _vm.partnerId
      },
      controlElements: _vm._controlElements,
      predefinedFilter: _vm.predefinedFilter,
      showSelect: false
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.isArchived",
          fn: function fn(_ref) {
            var value = _ref.value
            return [
              value
                ? _c(
                    "v-chip",
                    {
                      attrs: {
                        small: ""
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("objects.user.isArchived")) + " "
                      )
                    ]
                  )
                : _c(
                    "v-chip",
                    {
                      attrs: {
                        small: ""
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("objects.user.isActive")) + " "
                      )
                    ]
                  )
            ]
          }
        },
        {
          key: "bodyActions",
          fn: function fn() {
            return [
              _c(
                "Can",
                {
                  attrs: {
                    I: _vm.ActionEnum.CREATE,
                    a: _vm.ResourceEnum.USER,
                    field: _vm.partnerId
                  }
                },
                [
                  _c("partner-user-create-dialog", {
                    attrs: {
                      hideButton: false,
                      partnerId: _vm.partnerId
                    },
                    on: {
                      created: function created(v) {
                        _vm.$toast.success("👍")

                        _vm.setSelectedItem(v)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._t("bodyActions"),
              _vm.selectedItem
                ? _c("partner-user-side-card", {
                    attrs: {
                      value: _vm.selectedItem
                    },
                    on: {
                      close: function close() {
                        return _vm.setSelectedItem(null)
                      }
                    }
                  })
                : _vm._e(),
              _vm.isDeleteDialogActive && _vm.selectedItemForDelete
                ? _c(
                    "confirm-action-dialog",
                    {
                      attrs: {
                        isDialogActive: _vm.isDeleteDialogActive,
                        fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                        title: _vm.$t("user.deactivate.title"),
                        subtitle: _vm.$t("user.deactivate.subtitle"),
                        rightText: _vm.$t("user.deactivate.action"),
                        rightColor: "error",
                        rightLoading: _vm.isDeleteLoading,
                        rightDisabled:
                          _vm.selectedItemForDelete._id !==
                            _vm.confirmUserIdToDelete ||
                          _vm.selectedItemForDelete.userName !==
                            _vm.confirmUserNameToDelete
                      },
                      on: {
                        "update:isDialogActive": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isDeleteDialogActive = $event
                        },
                        "update:is-dialog-active": function updateIsDialogActive(
                          $event
                        ) {
                          _vm.isDeleteDialogActive = $event
                        },
                        close: function close($event) {
                          _vm.isDeleteDialogActive = false
                        },
                        leftClick: function leftClick($event) {
                          _vm.isDeleteDialogActive = false
                        },
                        rightClick: _vm.onDelete
                      }
                    },
                    [
                      _c("copy-clipboard-text", {
                        staticClass: "mb-1",
                        attrs: {
                          text: _vm.selectedItemForDelete._id,
                          icon: "mdi-content-copy"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("objects.id"),
                          rules: _vm.rules(_vm.RulesEnum.REQUIRED_RULE)
                        },
                        model: {
                          value: _vm.confirmUserIdToDelete,
                          callback: function callback($$v) {
                            _vm.confirmUserIdToDelete = $$v
                          },
                          expression: "confirmUserIdToDelete"
                        }
                      }),
                      _c("copy-clipboard-text", {
                        staticClass: "mb-1",
                        attrs: {
                          text: _vm.selectedItemForDelete.userName,
                          icon: "mdi-content-copy"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("objects.user.userName"),
                          rules: _vm.rules(_vm.RulesEnum.REQUIRED_RULE)
                        },
                        model: {
                          value: _vm.confirmUserNameToDelete,
                          callback: function callback($$v) {
                            _vm.confirmUserNameToDelete = $$v
                          },
                          expression: "confirmUserNameToDelete"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }