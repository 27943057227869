var render = function() {
  var _vm$value$contact, _vm$value$contact2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.loading
    ? _c("v-skeleton-loader", {
        staticClass: "mx-auto",
        attrs: {
          type:
            "card-heading, divider, list-item-two-line,list-item-two-line, divider"
        }
      })
    : _vm.value
    ? _c(
        "div",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("debug", {
            attrs: {
              debug: _vm.value
            }
          }),
          _c("m-header", {
            attrs: {
              title: _vm.title,
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions
            },
            scopedSlots: _vm._u([
              {
                key: "subtitle",
                fn: function fn() {
                  return [
                    _c(
                      "v-chip",
                      {
                        staticClass: "mr-2 text-truncate",
                        attrs: {
                          small: ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.copyToClipboard(_vm.value.id)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              small: ""
                            }
                          },
                          [_vm._v("mdi-account")]
                        ),
                        _vm._v(" " + _vm._s(_vm.value.id) + " ")
                      ],
                      1
                    ),
                    _vm.value.externalId
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "mr-2 text-truncate",
                            attrs: {
                              small: ""
                            },
                            on: {
                              click: function click($event) {
                                return _vm.copyToClipboard(_vm.value.externalId)
                              }
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: ""
                                }
                              },
                              [_vm._v("mdi-connection")]
                            ),
                            _vm._v(" " + _vm._s(_vm.value.externalId) + " ")
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("user-administration-detail-identity-status", {
                      attrs: {
                        value: _vm.value
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "info"
                }
              }),
              _vm._l(_vm.tabs, function(tab) {
                return _c(
                  "v-tab",
                  {
                    key: "v-tab" + tab
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("enums.UserTabEnum.".concat(tab))) +
                        " "
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                {
                  key: _vm.UserTabEnum.OVERVIEW
                },
                [
                  _c("customer-contact-card", {
                    staticClass: "mt-2",
                    attrs: {
                      customerName:
                        _vm.value.firstName + " " + _vm.value.lastName,
                      address: _vm.value.address,
                      email:
                        (_vm$value$contact = _vm.value.contact) === null ||
                        _vm$value$contact === void 0
                          ? void 0
                          : _vm$value$contact.email,
                      phone:
                        (_vm$value$contact2 = _vm.value.contact) === null ||
                        _vm$value$contact2 === void 0
                          ? void 0
                          : _vm$value$contact2.phone,
                      isCompany: _vm.value.isCompany,
                      companyName: _vm.value.company,
                      isTaxDeductible: _vm.value.isTaxDeductible,
                      taxnumber: _vm.value.taxnumber
                    }
                  }),
                  _vm.activitySource
                    ? _c("activity-card", {
                        key: "activity-card" + _vm.partnerId,
                        staticClass: "mt-2",
                        attrs: {
                          partner: _vm.partner,
                          user: _vm.value,
                          source: _vm.activitySource,
                          config: {
                            comment: true,
                            mail: true
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  key: _vm.UserTabEnum.ADMINISTRATION
                },
                [
                  !_vm.value.isArchived
                    ? _c("partner-user-danger-zone-card", {
                        attrs: {
                          partnerId: _vm.partnerId,
                          selectedUser: _vm.value
                        }
                      })
                    : _vm._e(),
                  _c("user-administration-detail-permissions", {
                    attrs: {
                      selectedUser: _vm.value,
                      permissions: _vm.value.permission,
                      partnerId: _vm.partnerId
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("latest-entries-card-empty")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }