var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("m-header", {
        attrs: {
          breadCrumbs: _vm.breadCrumbList,
          title: _vm.title,
          actions: _vm.actions
        },
        on: {
          actionClicked: _vm.processAction
        }
      }),
      _c(
        "div",
        {
          staticClass: "d-flex flex-row",
          staticStyle: {
            display: "inline-block"
          }
        },
        [
          _c(
            "v-tabs",
            {
              class: _vm.isMobile ? "" : "pl-2",
              on: {
                change: _vm.loadTicketsForCustomView
              },
              model: {
                value: _vm.viewId,
                callback: function callback($$v) {
                  _vm.viewId = $$v
                },
                expression: "viewId"
              }
            },
            [
              _c("v-tabs-slider", {
                attrs: {
                  color: "yellow"
                }
              }),
              _vm._l(_vm.tabs, function(item, index) {
                return _c(
                  "v-tab",
                  {
                    key: item.id,
                    staticClass: "v-tabs__container",
                    attrs: {
                      disabled: _vm.loadingTickets
                    }
                  },
                  [
                    _vm.tabIcon(item)
                      ? _c(
                          "v-icon",
                          {
                            attrs: {
                              left: ""
                            }
                          },
                          [_vm._v(_vm._s(_vm.tabIcon(item)))]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.tabTitleTransformer(item)) + " "),
                    index === _vm.viewId
                      ? _c("project-custom-view-context-menu", {
                          attrs: {
                            customView: _vm.activeTabConfig,
                            project: _vm.project
                          },
                          on: {
                            delete: _vm.deleteCustomView,
                            createDuplicate: _vm.createDuplicateCustomView,
                            update: _vm.openUpdateDialog,
                            reorder: _vm.openReorderDialog
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c("project-custom-view-form", {
                staticClass: "align-self-center",
                attrs: {
                  isDialogActive: _vm.customViewFormDialog,
                  project: _vm.project,
                  filterOptions: _vm.getModule().filterOptions,
                  predefinedFilter: _vm.predefinedFilter
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialog = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialog = $event
                  },
                  save: _vm.addNewBoard
                }
              }),
              _c("project-custom-view-form", {
                staticClass: "align-self-center",
                attrs: {
                  isDialogActive: _vm.customViewFormDialogUpdate,
                  project: _vm.project,
                  customViewToUpdate: _vm.activeTabConfig,
                  filterOptions: _vm.getModule().filterOptions,
                  predefinedFilter: _vm.predefinedFilter,
                  hideDefaultActivator: true,
                  isEditForm: true
                },
                on: {
                  "update:isDialogActive": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialogUpdate = $event
                  },
                  "update:is-dialog-active": function updateIsDialogActive(
                    $event
                  ) {
                    _vm.customViewFormDialogUpdate = $event
                  },
                  update: _vm.loadTicketsForCustomView
                }
              }),
              _vm.customViewFormDialogOrder
                ? _c("project-custom-view-order-dialog", {
                    staticClass: "align-self-center",
                    attrs: {
                      isDialogActive: _vm.customViewFormDialogOrder,
                      project: _vm.project
                    },
                    on: {
                      "update:isDialogActive": function updateIsDialogActive(
                        $event
                      ) {
                        _vm.customViewFormDialogOrder = $event
                      },
                      "update:is-dialog-active": function updateIsDialogActive(
                        $event
                      ) {
                        _vm.customViewFormDialogOrder = $event
                      },
                      update: _vm.loadTicketsForCustomView
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("v-divider"),
      _vm.loadingTickets
        ? _c("v-progress-linear", {
            attrs: {
              height: "3",
              indeterminate: "",
              color: "info"
            }
          })
        : _c("div", {
            staticStyle: {
              height: "3px"
            }
          }),
      !_vm.tabs.length && _vm.project
        ? _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c(
                "v-card-actions",
                {
                  staticClass: "justify-center"
                },
                [
                  _c("custom-view-create-helper", {
                    staticClass: "mt-3",
                    attrs: {
                      partnerId: _vm.partnerId,
                      tableBaseConfig: _vm.tableBaseConfig,
                      calendarBaseConfig: _vm.calendarBaseConfig,
                      boardBaseConfig: _vm.boardBaseConfig
                    },
                    model: {
                      value: _vm.project,
                      callback: function callback($$v) {
                        _vm.project = $$v
                      },
                      expression: "project"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.viewId === 0 || _vm.viewId
                ? _c(
                    "v-card",
                    {
                      staticClass: "flex-container",
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("filter-card-pagination", {
                        attrs: {
                          filterList: _vm.paginationFilterList,
                          filter: _vm.paginationFilter,
                          predefinedFilter: _vm.predefinedFilter,
                          search: _vm.search,
                          hideDivider: true
                        },
                        on: {
                          "update:filter": function updateFilter($event) {
                            _vm.paginationFilter = $event
                          },
                          "update:search": function updateSearch($event) {
                            _vm.search = $event
                          },
                          confirmFilter: _vm.getTickets,
                          confirmSearch: _vm.getTickets
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.viewId !== undefined
                ? _c(
                    "div",
                    [
                      _vm.loadingTickets
                        ? _c("v-skeleton-loader", {
                            attrs: {
                              type: "table"
                            }
                          })
                        : _c(
                            "v-tabs-items",
                            {
                              attrs: {
                                touchless: ""
                              },
                              model: {
                                value: _vm.viewId,
                                callback: function callback($$v) {
                                  _vm.viewId = $$v
                                },
                                expression: "viewId"
                              }
                            },
                            _vm._l(_vm.tabs, function(item) {
                              var _vm$activeTabConfig2

                              return _c(
                                "v-tab-item",
                                {
                                  key: item.id
                                },
                                [
                                  item.type ===
                                  _vm.ProjectCustomViewTypeEnum.BOARD
                                    ? _c(
                                        "div",
                                        [
                                          _c("kanban-board", {
                                            attrs: {
                                              loading:
                                                _vm.loadingTickets ||
                                                _vm.customViewsLoading,
                                              columns: _vm.boardColumns,
                                              cards: _vm.tickets,
                                              identifierFunction:
                                                _vm.identifierFunction,
                                              changeValueFunction:
                                                _vm.changeValueFunction
                                            },
                                            on: {
                                              change: _vm.change
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function fn(_ref) {
                                                    var _vm$activeTabConfig

                                                    var item = _ref.item
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.openTicketSideCard(
                                                                item.number
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            {
                                                              staticClass:
                                                                "py-0"
                                                            },
                                                            [
                                                              _c(
                                                                "refs-ticket",
                                                                {
                                                                  attrs: {
                                                                    item: item,
                                                                    small: false
                                                                  }
                                                                }
                                                              ),
                                                              _vm.activeTabConfig &&
                                                              _vm
                                                                .activeTabConfig
                                                                .values
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "pb-1 pl-2"
                                                                    },
                                                                    _vm._l(
                                                                      _vm.collectFieldValues(
                                                                        item,
                                                                        (_vm$activeTabConfig =
                                                                          _vm.activeTabConfig) ===
                                                                          null ||
                                                                          _vm$activeTabConfig ===
                                                                            void 0
                                                                          ? void 0
                                                                          : _vm$activeTabConfig.values
                                                                      ),
                                                                      function(
                                                                        field
                                                                      ) {
                                                                        return _c(
                                                                          "custom-field-value-chip",
                                                                          {
                                                                            key:
                                                                              field.id,
                                                                            attrs: {
                                                                              customFieldClass:
                                                                                "mr-1 mb-1",
                                                                              color:
                                                                                field.color,
                                                                              dark:
                                                                                field.dark,
                                                                              value: field,
                                                                              lightenBackground: false
                                                                            }
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : item.type ===
                                      _vm.ProjectCustomViewTypeEnum.TABLE
                                    ? _c(
                                        "div",
                                        [
                                          _c("custom-project-table", {
                                            ref: "customProjectTable".concat(
                                              _vm.viewId
                                            ),
                                            refInFor: true,
                                            attrs: {
                                              loading: _vm.loadingTickets,
                                              customConfig:
                                                (_vm$activeTabConfig2 =
                                                  _vm.activeTabConfig) ===
                                                  null ||
                                                _vm$activeTabConfig2 === void 0
                                                  ? void 0
                                                  : _vm$activeTabConfig2.values,
                                              groupBy: _vm.groupTableBy,
                                              sortBy: _vm.sortBy,
                                              sortDesc: _vm.sortDesc,
                                              items: _vm.tickets,
                                              entity: _vm.project,
                                              partnerId: _vm.partnerId,
                                              filterOptions: _vm.getModule()
                                                .filterOptions
                                            },
                                            on: {
                                              openDetail: function openDetail(
                                                $event
                                              ) {
                                                return _vm.openTicketSideCard(
                                                  $event.number
                                                )
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "group.header",
                                                  fn: function fn(_ref2) {
                                                    var groupBy = _ref2.groupBy,
                                                      group = _ref2.group,
                                                      toggle = _ref2.toggle,
                                                      isOpen = _ref2.isOpen,
                                                      items = _ref2.items
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center"
                                                          },
                                                          on: {
                                                            click: toggle
                                                          }
                                                        },
                                                        [
                                                          groupBy[0].includes(
                                                            "refs"
                                                          ) &&
                                                          groupBy[0].includes(
                                                            _vm
                                                              .BackendResourceEnum
                                                              .VEHICLE
                                                          )
                                                            ? _c(
                                                                "refs-vehicle",
                                                                {
                                                                  attrs: {
                                                                    item: _vm.getVehicleById(
                                                                      group
                                                                    )
                                                                  },
                                                                  on: {
                                                                    openDetail:
                                                                      _vm.goToVehicleDetail
                                                                  }
                                                                }
                                                              )
                                                            : _vm.isDayTimeString(
                                                                group
                                                              )
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getDateTime(
                                                                      group
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            : group
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(group)
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("v-spacer"),
                                                          isOpen
                                                            ? _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-chevron-down"
                                                                )
                                                              ])
                                                            : _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-chevron-right"
                                                                )
                                                              ]),
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    items.length
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.state",
                                                  fn: function fn(_ref3) {
                                                    var item = _ref3.item
                                                    return [
                                                      _c(
                                                        "tooltip",
                                                        {
                                                          attrs: {
                                                            text: _vm.$t(
                                                              "enums.TicketStatusEnum." +
                                                                item.state
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _c("v-avatar", {
                                                            attrs: {
                                                              color: _vm.ticketColor(
                                                                item
                                                              ),
                                                              size: "10px"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.number",
                                                  fn: function fn(_ref4) {
                                                    var item = _ref4.item
                                                    return [
                                                      _vm._v(
                                                        " #" +
                                                          _vm._s(item.number) +
                                                          " "
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.title",
                                                  fn: function fn(_ref5) {
                                                    var item = _ref5.item
                                                    return [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.openTicketSideCard(
                                                                item.number
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-inline-block text-truncate",
                                                              staticStyle: {
                                                                "max-width":
                                                                  "350px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.due",
                                                  fn: function fn(_ref6) {
                                                    var item = _ref6.item
                                                    return [
                                                      _c("due-date-chip", {
                                                        attrs: {
                                                          chip: false,
                                                          ticket: item
                                                        }
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.assignees",
                                                  fn: function fn(_ref7) {
                                                    var item = _ref7.item
                                                    return [
                                                      _c("assignee-preview", {
                                                        attrs: {
                                                          value: item.assignees,
                                                          amount: 3,
                                                          partnerId:
                                                            _vm.partnerId
                                                        }
                                                      })
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.total",
                                                  fn: function fn(_ref8) {
                                                    var item = _ref8.item
                                                    return [
                                                      _c("cost-chip", {
                                                        attrs: {
                                                          value: item
                                                        }
                                                      })
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : item.type ===
                                      _vm.ProjectCustomViewTypeEnum.CALENDAR
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-container",
                                            {
                                              attrs: {
                                                fluid: ""
                                              }
                                            },
                                            [
                                              _c("calendar", {
                                                attrs: {
                                                  loading:
                                                    _vm.loadingTickets ||
                                                    _vm.customViewsLoading,
                                                  events: _vm.events,
                                                  type: _vm.calendarType
                                                },
                                                on: {
                                                  "update:type": function updateType(
                                                    $event
                                                  ) {
                                                    _vm.calendarType = $event
                                                  },
                                                  "click:detail":
                                                    _vm.openCalendarDetail
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "preview",
                                                      fn: function fn(_ref9) {
                                                        var _vm$activeTabConfig3

                                                        var slotScope =
                                                          _ref9.slotScope
                                                        return [
                                                          _c(
                                                            "v-card",
                                                            [
                                                              _c(
                                                                "refs-ticket",
                                                                {
                                                                  attrs: {
                                                                    item: _vm.firstTicketFromEvent(
                                                                      slotScope.item
                                                                    ),
                                                                    customConfig:
                                                                      (_vm$activeTabConfig3 =
                                                                        _vm.activeTabConfig) ===
                                                                        null ||
                                                                      _vm$activeTabConfig3 ===
                                                                        void 0
                                                                        ? void 0
                                                                        : _vm$activeTabConfig3.values,
                                                                    small: true
                                                                  },
                                                                  on: {
                                                                    openDetail: function openDetail(
                                                                      $event
                                                                    ) {
                                                                      return _vm.openTicketSideCardFromCalendar(
                                                                        slotScope
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _vm._v(
                                                  " Ansicht vom Typ " +
                                                    _vm._s(item.type) +
                                                    " wird noch nicht unterstützt. "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            }),
                            1
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _c("debug", [
        _vm._v(
          " " + _vm._s(_vm.activeTabConfig) + " " + _vm._s(_vm.events) + " "
        )
      ]),
      _vm.isSideCard
        ? _c("ticket-side-card", {
            attrs: {
              loading: _vm.loadingTicket || _vm.customViewsLoading,
              loadingUpdate: _vm.loadingUpdate,
              partnerId: _vm.partnerId,
              projectId: _vm.projectId,
              viewId: _vm.viewId
            },
            on: {
              close: _vm.closeTicketSideCard,
              update: _vm.updateTicket,
              updateTicketStatus: _vm.updateTicketStatus,
              updateCustomFieldValues: _vm.updateCustomFieldValues,
              updateReferences: _vm.updateTicket,
              deleteTicket: _vm.onDelete,
              "delete:assignees": _vm.createDeleteAssigneeActivity,
              "add:assignees": _vm.createAddAssigneeActivity
            },
            model: {
              value: _vm.ticket,
              callback: function callback($$v) {
                _vm.ticket = $$v
              },
              expression: "ticket"
            }
          })
        : _vm._e(),
      _vm.isMobile
        ? _c(
            "v-btn",
            {
              attrs: {
                color: "info",
                fab: "",
                dark: "",
                bottom: "",
                right: "",
                fixed: ""
              },
              on: {
                click: function click($event) {
                  _vm.isCreateDialogActive = true
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-plus")])],
            1
          )
        : _vm._e(),
      _c("ticket-create-dialog", {
        attrs: {
          hideButton: true,
          projectId: _vm.projectId,
          partnerIdProp: _vm.partnerId,
          smallBtn: false,
          isDialogActive: _vm.isCreateDialogActive,
          refs: []
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isCreateDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isCreateDialogActive = $event
          },
          created: _vm.loadTicketsForCustomView
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }