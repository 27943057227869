








































import Debug from "@/components/utility/Debug.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IReference, Reference } from "@/models/reference.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "./ConfirmActionDialog.vue";
import RefsSelectionByType from "./RefsSelectionByType.vue";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { IHasRefs } from "@/lib/interfaces/has-refs.interface";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import RefsList from "./RefsList.vue";
import { RefTypeMap } from "@/store/modules/refs.store";

/**
 * Dialog to pick a an entity and add refs to it
 */
@Component({
  components: {
    RefsSelectionByType,
    RefsList,
    ConfirmActionDialog,
    Debug
  },
  filters: {}
})
export default class SelectByRefsUpdateDialog<T extends IHasRefs> extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  @Prop({ default: undefined })
  predefinedFilter!: { name: string; filter: IPageFilterElement[] }[];

  @Prop()
  refs!: IReference[];

  @Prop()
  refType!: BackendResourceEnum;

  @Prop({ default: "company.addCompany" })
  title!: string;

  isLoading = false;

  isDialogActive = false;

  selectedEntity: (IEntity<T, IHasRefs> & IHasRefs) | null = null;

  get leftText() {
    if (this.selectedEntity) {
      return this.$t("back");
    }

    return this.$t("close");
  }

  leftClick() {
    if (this.selectedEntity) {
      this.selectedEntity = null;
      return;
    }

    this.close();
  }

  open() {
    this.selectedEntity = null;
    const filters = [];
    for (const ref of this.refs) {
      filters.push(
        new PageFilterElement({
          key: "refs.refId",
          operation: PageFilterOperationEnum.NOT_EQUAL,
          value: ref.refId
        })
      );
    }
    RefTypeMap.get(this.refType)?.module?.setFilters(filters);
    this.isDialogActive = true;
  }

  close() {
    this.isDialogActive = false;
    this.selectedEntity = null;
    RefTypeMap.get(this.refType)?.module?.setFilters([]);
  }

  async addRefsToCompany() {
    if (!this.selectedEntity || !this.selectedEntity.updatePartial) {
      return;
    }

    this.isLoading = true;

    try {
      await this.selectedEntity.updatePartial({
        refs: Reference.filterDuplicates([...this.selectedEntity.refs, ...this.refs])
      });
      this.close();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
