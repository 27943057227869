

































































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { PersonLabelEnum } from "@/lib/enum/person-label.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { phoneRule, emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PersonGoToHelper } from "@/lib/utility/person.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Address } from "@/models/address.entity";
import { Person, IPerson } from "@/models/person.entity";
import { ConfigModule } from "@/store/modules/config";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsPersonGroup from "../utility/RefsPersonGroup.vue";
import CustomFieldBoolean from "../report/CustomFieldBoolean.vue";
import { CompanyAddress } from "@/models/company-address.entity";
import { IPersonGroup, PersonGroup } from "@/models/person-group.entity";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { PersonEmail } from "@/models/person-email.entity";
import { PersonPhone } from "@/models/person-phone.entity";
import { IReference } from "@/models/reference.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import TemplateEditor from "@/components/template/TemplateEditor.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    RefsPersonGroup,
    CustomFieldListForm,
    LatestEntriesCardEmpty,
    Debug,
    CustomFieldBoolean,
    TemplateEditor
  },
  filters: {
    simpleDoubleDigitDate,
    getFlagEmojiByLanguage
  }
})
export default class PersonCreateDialog extends mixins(PartnerFallbackMixin) implements IDialog {
  readonly PersonLabelEnum = PersonLabelEnum;
  readonly Person = Person;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;
  readonly CompanyAddress = CompanyAddress;
  readonly PersonEmail = PersonEmail;
  readonly PersonPhone = PersonPhone;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  /**
   * ID of person group to preselect
   */
  @Prop()
  groupId?: string;

  @Prop({ default: false })
  fullscreen?: boolean;

  @Prop({ default: () => [] })
  refs!: IReference[];

  isDialogActive = false;

  isLoading = false;

  isFormValid = false;

  person: IPerson = new Person({
    groupId: this.groupId,
    partnerId: this.partnerId,
    addresses: [],
    emails: [],
    phones: [],
    refs: this.refs
  });

  personGroup: IPersonGroup | null = null;

  isLoadingCustomFieldValues = false;

  isLoadingPersonGroups = false;

  get groups() {
    return PersonGroupModule.entities;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [emailRule()];
  }

  get phoneRule() {
    return [phoneRule()];
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  open() {
    this.person = new Person({
      groupId: this.groupId,
      partnerId: this.partnerId,
      addresses: [],
      emails: [],
      phones: [],
      refs: this.refs
    });
    this.setPersonGroup();
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.personNameField as HTMLInputElement)?.focus();
    });
  }

  close() {
    this.isDialogActive = false;
  }

  async onRightClick() {
    try {
      this.isLoading = true;
      this.isDialogActive = true;
      await this.person.create();
      this.$emit("created", this.person);
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
      this.isLoading = false;
    }
  }

  addAddress() {
    if (!this.person.addresses) {
      this.person.addresses = [];
    }

    this.person.addresses.push(new CompanyAddress());
  }

  popAddress(index: number) {
    if (!this.person.addresses || this.person.addresses.length < 1) {
      return;
    }

    this.person.addresses.splice(index, 1);
  }

  @Watch("person.groupId")
  async setPersonGroup() {
    this.personGroup = null;

    const personGroupId = this.person.groupId;
    if (personGroupId) {
      this.isLoadingCustomFieldValues = true;
      const personGroup =
        PersonGroupModule.maps.id.get(personGroupId)[0] ||
        (await new PersonGroup({ partnerId: this.person.partnerId, id: personGroupId }).fetch().catch(handleError));
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.personGroup = personGroup;
      });
    }
  }

  goToPersonGroupCustomView() {
    if (!this.person.groupId) return;

    new PersonGoToHelper(this.$router).goToPersonGroupCustomView({
      partnerId: this.partnerId,
      personGroupId: this.person.groupId,
      viewId: "0",
      newTab: true
    });
  }

  async refreshPersonGroups() {
    this.isLoadingPersonGroups = true;
    try {
      PersonGroupModule.setFilters([
        new PageFilterElement({
          key: "type",
          operation: PageFilterOperationEnum.EQUAL,
          value: ResourceEnum.PERSON
        })
      ]);
      await PersonGroupModule.fetchFirstPage({ partnerId: this.partnerId });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingPersonGroups = false;
  }
}
