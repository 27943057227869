




























































import Debug from "@/components/utility/Debug.vue";
import { IGroupTypeEntityCreateDialog } from "@/lib/utility/group/interface/group-type-entity-create-dialog.interface";
import { handleError } from "@/lib/utility/handleError";
import { IPartnerBanking, PartnerBanking } from "@/models/partner-banking.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import CustomFieldBoolean from "../report/CustomFieldBoolean.vue";

@Component({
  components: {
    ConfirmActionDialog,
    Debug,
    CustomFieldBoolean
  },
  filters: {}
})
export default class PartnerBankingCreateDialog extends mixins(Vue, RulesMixin)
  implements IGroupTypeEntityCreateDialog {
  readonly ResourceEnum = ResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: boolean;

  @Prop({ default: false })
  fullscreen?: boolean;

  isDialogActive = false;

  isLoading = false;

  isFormValid = false;

  partnerBanking: IPartnerBanking = new PartnerBanking();

  open() {
    this.partnerBanking = new PartnerBanking({
      partnerId: this.partnerId
    });
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.startField as HTMLInputElement)?.focus();
    });
  }

  close() {
    this.isDialogActive = false;
  }

  async onRightClick() {
    try {
      this.isLoading = true;
      this.isDialogActive = true;
      await this.partnerBanking.create();
      this.$emit("created", this.partnerBanking);
    } catch (e) {
      handleError(e);
    } finally {
      this.close();
      this.isLoading = false;
    }
  }
}
