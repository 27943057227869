var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        attrs: {
          headers: _vm.headers,
          store: _vm.handoverReportStore,
          baseQuery: {
            partnerId: _vm.partnerId
          },
          predefinedFilter: _vm.predefinedFilter
        },
        on: {
          "click:row": _vm.openReportSideCard
        },
        scopedSlots: _vm._u([
          {
            key: "item.title",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold"
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                ),
                item.title ? _c("br") : _vm._e(),
                _c("span", [_vm._v(" " + _vm._s(item.message) + " ")])
              ]
            }
          },
          {
            key: "item.handover.number",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold"
                  },
                  [_vm._v(" #" + _vm._s(item.handover.number) + " ")]
                )
              ]
            }
          },
          {
            key: "item.assignees",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                _c("assignee-preview", {
                  attrs: {
                    value: item.assignees,
                    amount: 3
                  }
                })
              ]
            }
          },
          {
            key: "item.handover.foundInInspection",
            fn: function fn(_ref4) {
              var item = _ref4.item
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.inspectionTitle(item.handover.foundInInspection)
                      )
                  )
                ])
              ]
            }
          },
          {
            key: "item.progressStatus",
            fn: function fn(_ref5) {
              var item = _ref5.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      small: "",
                      color: _vm.color(item.progressStatus),
                      elevation: 0,
                      dark: ""
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "partner.PartnerReportDetailChangeProgress.".concat(
                              item.progressStatus
                            )
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm.selectedReport
        ? _c("partner-report-detail-side-card", {
            attrs: {
              loading: _vm.isLoadingReport,
              report: _vm.selectedReport
            },
            on: {
              close: _vm.closeReportSideCard
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }