












import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FleetDetail from "@/components/fleet/FleetDetail.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Fleet } from "@/models/fleet.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { FleetModule } from "@/store/modules/fleet.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, MHeader, FleetDetail, LatestEntriesCardEmpty }
})
export default class FleetDetailView extends mixins(Vue, PermissionMixin, PartnerFallbackMixin) {
  isLoading = false;

  vehicles: IVehicle[] = [];

  get breadCrumbs() {
    return [
      {
        text: this.$t("views.fleet.fleet.title"),
        exact: true,
        to: { name: "FleetTableView", params: { partnerId: this.$route.params.partnerId } }
      },
      {
        text: this.title,
        exact: true,
        disabled: true
      }
    ];
  }

  get loading() {
    return FleetModule.loading;
  }

  get fleet() {
    return FleetModule.active;
  }

  get title() {
    return this.fleet?.title;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async fetchVehicles(partnerId: string, fleetId: string) {
    VehicleModule.setFilter([new PageFilterElement({ key: "groupId", operation: "$eq", value: fleetId })]);
    await VehicleModule.fetchAll({ partnerId });
  }

  async mounted() {
    const partnerId = this.$route.params.partnerId;
    const fleetId = this.$route.params.fleetId;

    try {
      this.isLoading = true;
      await this.trySetByRouteOrDefault();

      FleetModule.set({ fleet: new Fleet({ id: fleetId, partnerId: partnerId }) });

      await this.fetchVehicles(partnerId, fleetId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
