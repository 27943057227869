















































import ProjectSideCard from "@/components/project/ProjectSideCard.vue";
import ProjectTable from "@/components/project/ProjectTable.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ProjectModule } from "@/store/modules/project.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ProjectCrudMixin from "./mixins/ProjectCrudMixin.vue";
import ProjectGoToMixin from "./mixins/ProjectGoToMixin.vue";
import ProjectInitializeListsMixin from "./mixins/ProjectInitializeListsMixin.vue";
import { IProject } from "@/models/project.entity";

@Component({
  components: {
    Debug,
    MHeader,
    ProjectTable,
    ProjectSideCard,
    TheLayoutPortal
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ProjectTableView extends mixins(
  ProjectInitializeListsMixin,
  PermissionMixin,
  ProjectGoToMixin,
  ProjectCrudMixin
) {
  readonly VIEW_TICKETS = "viewTickets";
  readonly AMOUNT_PROJECTS = "amountTicket";

  project: IProject | null = null;

  loadingSideCard = false;
  isSideCard = false;

  isValid = false;

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [this.breadCrumbs.ProjectTable(this.partnerId)];
    if (this.projectId) {
      crumbs.push(this.breadCrumbs.ProjectDetail(this.partnerId, this.projectId));
    }

    return crumbs;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${ProjectModule.totalItems} ${this.$t("project.project.projects")}`,
        key: this.AMOUNT_PROJECTS,
        disabled: true
      }
    ];
  }

  get actions() {
    return [
      {
        text: this.$t("project.ticket.allTickets"),
        key: this.VIEW_TICKETS,
        icon: "mdi-ticket-outline"
      }
    ];
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.projectId = this.$route.query.projectId as string;
    this.ticketNumber = this.$route.params.ticketNumber;
  }

  processAction(action: IAction) {
    switch (action.key) {
      case this.VIEW_TICKETS:
        this.goTo.ticketTable({ partnerId: this.partnerId });
    }
  }

  async mounted() {
    await this.initializeProjectLists(this.partnerId);

    const projectId = this.$route.query.projectId as string;
    if (projectId) {
      this.isSideCard = true;
      await this.getProject(projectId);
    }
  }
}
