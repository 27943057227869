















import CompanyGroupTable from "@/components/company/group/CompanyGroupTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { ICompanyGroup, CompanyGroup } from "@/models/company-group.entity";

@Component({
  components: {
    CompanyGroupTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyGroupTableView extends mixins(PartnerFallbackMixin) {
  @Ref("companyGroupTable")
  companyGroupTable!: CompanyGroupTable;

  readonly AMOUNT_COMPANY_GROUP = "amountCompanyGroup";

  /**
   * refs that will be set in new companies and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(CompanyGoToHelper.breadCrumbs.CompanyGroupTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${CompanyGroupModule.totalItems} ${this.$t("company.companyGroupTable")}`,
        key: this.AMOUNT_COMPANY_GROUP,
        disabled: true
      }
    ];
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("company.allCompanys"),
        key: "allCompanys",
        exec: () => new CompanyGoToHelper(this.$router).goToCompanyTable({ partnerId: this.partnerId })
      }
    ];
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    const companyGroupId = this.$route.query.companyGroupId as string;
    if (companyGroupId) {
      const companyGroup = new CompanyGroup({ partnerId: this.partnerId, id: companyGroupId });
      this.companyGroupTable?.setSelectedItem(companyGroup);
    }
  }

  openSideCard(companyGroup: ICompanyGroup) {
    new CompanyGoToHelper(this.$router).setUrl(
      CompanyGoToHelper.locations.companyGroupTable({
        partnerId: this.partnerId,
        query: companyGroup ? { companyGroupId: companyGroup.id } : {}
      })
    );
  }
}
