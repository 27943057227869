import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { ICompany } from "@/models/company.entity";
import { buildFleetTree, Fleet, FleetNode, IFleet } from "@/models/fleet.entity";
import fleetService from "@/services/mrfiktiv/services/fleetService";
import { MrfiktivFleetControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { FleetDataAccessLayer } from "./access-layers/fleet.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { FleetPageDataProvider } from "./page-data-provider/fleet.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "fleet",
  store
})
class FleetStore extends PaginatedBaseStore<IFleet, MrfiktivFleetControllerFindAllParamsGen> {
  protected _data = FleetDataAccessLayer;
  protected _pageProvider = FleetPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  loading = false;

  active: IFleet | null = null;
  activeCompany: ICompany | null = null;
  activeTree: FleetNode[] = [];

  filterOptions: PaginationFilterListElement[] = Fleet.filterables;

  @Action
  reset() {
    this.context.commit("setFleet", null);
    this.context.commit("activeTree", []);
  }

  @Action
  async set(data: { fleet: IFleet }) {
    this.context.commit("setLoading", true);

    try {
      fleetService.findOne(data.fleet.partnerId, data.fleet.id).then(response => {
        this.context.commit("setFleet", response);
      });
      fleetService.findAllRelatedForFleet(data.fleet.partnerId, data.fleet.id).then(response => {
        this.context.commit("setFleetTree", response);
      });
    } finally {
      this.context.commit("setLoading", false);
    }
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setFleet(fleet: IFleet) {
    this.active = fleet;
  }

  @Mutation
  setCompany(company: ICompany) {
    this.activeCompany = company;
  }

  @Mutation
  setFleetTree(fleet: IFleet[]) {
    this.activeTree = buildFleetTree(fleet);
  }
}

export const FleetModule = getModule(FleetStore);
