















































import PartnerReportInitializeDialog from "@/components/partner/PartnerReportInitializeDialog.vue";
import { ExportReportMetaTypeMap } from "@/lib/enum/export-report-meta-type.enum";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStatusIconMap } from "@/store/mapper/report-icon.map";
import { ExportModule } from "@/store/modules/export.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import ProjectInitializeListsMixin from "@/views/project/mixins/ProjectInitializeListsMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheLayoutPortalList from "../../layouts/TheLayoutPortalList.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { $t } from "@/lib/utility/t";
import { handleError } from "@/lib/utility/handleError";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    PartnerReportInitializeDialog,
    Tooltip,
    TheLayoutPortalList,
    AssigneePreview
  }
})
export default class PartnerReportListPaginated extends mixins(
  DarkModeHighlightMixin,
  PaginationListMixin,
  PartnerFallbackMixin,
  ProjectInitializeListsMixin,
  PermissionMixin
) {
  isLoadingAll = false;

  ProgressStatusEnum = ProgressStatusEnum;

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    predefinedFilter.push({
      name: "components.FilterCardPagination.predefinedFilter.ticket.open",
      filter: [
        new PageFilterElement({
          key: "progressStatus",
          value: ProgressStatusEnum.NEW,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    predefinedFilter.push({
      name: "components.FilterCardPagination.predefinedFilter.ticket.drafts",
      filter: [
        new PageFilterElement({
          key: "progressStatus",
          value: ProgressStatusEnum.DRAFT,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    predefinedFilter.push({
      name: "project.ticket.mine",
      filter: [
        new PageFilterElement({
          key: "assignees",
          value: this.userId,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  get userId() {
    return UserModule.userId;
  }

  get paginationList() {
    return this.getModule().paginationList;
  }

  getModule() {
    return ReportPaginationModule;
  }

  async mounted() {
    ReportPaginationModule.setSearch("");

    if (!PartnerModule.partner._id) {
      throw new Error("No Partner Set");
    }

    this.refresh();
  }

  async set(item: IReport) {
    this.$emit("set", item);
  }

  async refresh() {
    this.isLoadingAll = true;

    try {
      await ReportPaginationModule.fetchFirstPage({
        partnerId: PartnerModule.partner._id
      });
    } catch (error) {
      this.$log.error("Error fetching reports");
      this.$toast.error(this.$t("components.partner.PartnerReportList.error"));
    } finally {
      this.isLoadingAll = false;
    }

    try {
      await ExportModule.getExportReportMetasByPartnerId(PartnerModule.partner._id);
    } catch (error) {
      this.$log.error(error);
    }
  }

  async fetchNextPage() {
    const promises: Promise<any>[] = [];
    if (this.paginationList.length === 0) {
      promises.push(
        ReportPaginationModule.fetchFirstPage({
          partnerId: PartnerModule.partner._id
        })
      );
    } else {
      promises.push(ReportPaginationModule.fetchNextPage({ partnerId: PartnerModule.partner._id }));

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TPS, PartnerModule.partner._id)) {
        promises.push(
          ThirdPartySystemModule.fetchNextPage({
            partnerId: PartnerModule.partner._id
          })
        );
      }
    }

    await Promise.all(promises);
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.fetchNextPage();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingMore = false;
    }
  }

  setIsLoadingDetail(value: boolean) {
    this.$emit("isLoadingDetail", value);
  }

  getThirdPartySystems(report: IReport) {
    const tpss = ThirdPartySystemModule.maps.report?.get(report.id) ?? [];

    return tpss;
  }

  /**
   * Some methods for the presentation of items in the list
   */
  isUpdateProgressStatusEnabled() {
    return FeatureModule.isUpdateProgressStatusEnabled;
  }
  getProgressIcon(item: IReport) {
    if (!this.isUpdateProgressStatusEnabled()) return "";
    return reportStatusIconMap.get(item.progressStatus);
  }
  getProgressStatus(item: IReport) {
    return this.$t("partner.PartnerReportDetailChangeProgress." + item.progressStatus);
  }

  getExportReportMetaIcon(item: IReport) {
    if (FeatureModule.isTpsActive) {
      const tpss = this.getThirdPartySystems(item);

      return tpss.length ? "mdi-file-send" : "";
    }

    const found = ExportModule.exportReportMetas.get(item._id);

    return found ? "mdi-file-send" : "";
  }

  getExportReportMetaType(item: IReport) {
    if (FeatureModule.isTpsActive) {
      const tpss = this.getThirdPartySystems(item);

      return tpss.length >= 1 ? Array.from(new Set(tpss.map(tps => $t(`enums.TpsEnum.${tps.system}`)))).join(", ") : "";
    }

    const found = ExportModule.exportReportMetas.get(item._id);

    return found && found.length >= 1
      ? found.map(f => ExportReportMetaTypeMap.get(f.exportReportMetaType)).join(", ")
      : "";
  }

  isActive(item: IReport) {
    if (!item) return "";
    return item._id === PartnerModule.report._id ? "v-item--active v-list-item--active" : "";
  }

  date(item: IReport) {
    return simpleDate(item.timestamps?.created);
  }
}
