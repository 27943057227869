











































import UserAdministrationDetailIdentityStatus from "@/components/partner/UserAdministrationDetailIdentityStatus.vue";
import TemplateDialog from "@/components/template/TemplateDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { CancelToken } from "@/lib/utility/cancelToken";
import { handleError } from "@/lib/utility/handleError";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivPartnerCustomerUserViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CustomerAccountModule } from "@/store/modules/customer-account.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import CustomerAccountDetail from "@/components/partner/CustomerAccountDetail.vue";
import { RefsMessagesModule } from "@/store/modules/refs.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    TheLayoutPortalSplit,
    TheLayoutPortalList,
    CustomerAccountDetail,
    TemplateDialog,
    UserAdministrationDetailIdentityStatus,
    Debug
  }
})
export default class PartnerCustomerList extends mixins(PaginationListVueMixin, PartnerFallbackMixin) {
  isLoadingDetail = false;
  isLoading = false;

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get customerAccount() {
    return this.getModule().currentAccount;
  }

  get to() {
    if (!this.customerAccount) {
      return [];
    }

    return [this.customerAccount.contact.email];
  }

  get from() {
    return PartnerModule.partner;
  }

  get paginationList() {
    return this.getModule().paginationList;
  }

  getModule() {
    return CustomerAccountModule;
  }

  isActive(item: MrfiktivPartnerCustomerUserViewModelGen) {
    return item.id === CustomerAccountModule.currentAccount?.id ? "v-item--active v-list-item--active" : "";
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    try {
      this.isLoading = true;
      await this.refresh({ partnerId: this.partnerId });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }

    const item = this.getModule().paginationList[0];
    if (item) {
      await this.loadDetail(item);
    }
  }

  async set(item: MrfiktivPartnerCustomerUserViewModelGen) {
    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({
        name: "CustomerAccountDetailView",
        params: { partnerId: this.partnerId, customerAccountId: item.id }
      });
    } else {
      await this.loadDetail(item);
    }
  }

  async loadDetail(customerAccount: MrfiktivPartnerCustomerUserViewModelGen) {
    let account: MrfiktivPartnerCustomerUserViewModelGen | null = null;
    try {
      this.isLoadingDetail = true;
      account = await CustomerAccountModule.getOne(customerAccount.id);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDetail = false;
    }
    if (account) {
      await this.getUserMails(account).catch(this.$log.error);
    }
  }

  async getUserMails(customer: MrfiktivPartnerCustomerUserViewModelGen) {
    if (!customer) {
      return;
    }
    await RefsMessagesModule.generateTimeLineMessagesForUser({
      ...customer,
      partnerId: this.partnerId,
      resource: BackendResourceEnum.CUSTOMER_DATA
    });
  }

  async loadAllFromLastId() {
    if (this.paginationList.length === 0) {
      await CustomerAccountModule.fetchAllFromBeginning({
        partnerId: this.partnerId
      });
    } else {
      CustomerAccountModule.cancelToken?.requestCancellation();
      const cancelToken = new CancelToken();
      CustomerAccountModule.setCancelToken(cancelToken);
      await CustomerAccountModule.fetchRest({
        query: { partnerId: this.partnerId },
        cancelToken: cancelToken
      });
    }
  }

  async onRefresh() {
    await this.refresh({ partnerId: this.partnerId });
  }

  async onLoadMore() {
    await this.loadMore({ partnerId: this.partnerId });
  }
}
