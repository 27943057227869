import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import FleetDetailView from "@/views/fleet/FleetDetailView.vue";
import FleetTableView from "@/views/fleet/FleetTableView.vue";
import FleetVehicleDetailView from "@/views/fleet/FleetVehicleDetailView.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/fleet",
    name: "FleetTableView",
    component: FleetTableView,
    permission: {
      resource: ResourceEnum.FLEET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-garage-open-variant",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partner/:partnerId/fleet/:fleetId",
    name: "FleetDetailView",
    component: FleetDetailView,
    permission: {
      resource: ResourceEnum.FLEET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/fleet/:fleetId/vehicle/",
    name: "FleetVehicleDetail",
    component: FleetVehicleDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/fleet/:fleetId/vehicle/:vehicleId/",
    name: "FleetVehicleDetailTab",
    component: FleetVehicleDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];

const router = new VueRouter({
  routes,
  base: ""
});

router.beforeEach(permissionGuard);

export default router;

export const FleetRoutesV2 = routes;
