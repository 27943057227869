

















































































import QrcodeVue from "qrcode.vue";
import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import LatestFormEntriesCard from "@/components/cards/LatestFormEntriesCard.vue";
import LatestReportEntriesCard from "@/components/cards/LatestReportEntriesCard.vue";
import StatisticsCard from "@/components/cards/StatisticsCard.vue";
import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import PartnerUserMissingPermissionsDialog from "@/components/partner/PartnerUserMissingPermissionsDialog.vue";
import { PartnerUser } from "@/models/user.entity";
import { UserModule } from "@/store/modules/me-user.store";
import TimeSeriesLineChartCard from "@/components/analytics/TimeSeriesLineChartCard.vue";
import { AnalyticsModule } from "@/store/modules/analytics.store";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    TheLayoutPortal,
    StatisticsCard,
    LatestFormEntriesCard,
    LatestReportEntriesCard,
    PartnerUserMissingPermissionsDialog,
    AnalyticsDateRangeSelectorDialog,
    TimeSeriesLineChartCard,
    PieChartCard,
    QrcodeVue,
    Debug
  }
})
export default class PartnerDashboard extends mixins(PermissionMixin, PartnerFallbackMixin) {
  foundPartner = false;
  loading = false;
  loadingReport = false;
  loadingForm = false;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get partner() {
    return PartnerModule.partner;
  }

  get authenticatedPartnerUser() {
    return new PartnerUser({ ...UserModule.user, partnerId: this.partnerId });
  }

  get headerUrl() {
    return PartnerModule.partner.settings?.headerImageUrl || "";
  }

  async mounted() {
    try {
      this.loading = true;
      this.loadingReport = true;
      this.loadingForm = true;

      this.foundPartner = await this.trySetByRouteOrDefault();
      this.loading = false;

      const promises = [];

      if (this.canReadReport) {
        ReportPaginationModule.setSearch("");

        promises.push(
          ReportPaginationModule.fetchFirstPage({
            partnerId: PartnerModule.partner._id
          }).then(() => {
            this.loadingReport = false;
          })
        );
      }

      if (this.canReadForm) {
        promises.push(
          PartnerModule.getAllFormsForPartner().then(() => {
            this.loadingForm = false;
          })
        );
      }

      if (this.canReadAnalytics) {
        const dateRangeSelector = this.$refs.dateRange as AnalyticsDateRangeSelectorDialog;
        dateRangeSelector.usedPredefinedDateRange(dateRangeSelector.previousItems[6]);
        AnalyticsModule.setDateRange(dateRangeSelector.dateRange);

        promises.push(this.getData());
      }

      await Promise.all(promises);
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
      this.loadingReport = false;
      this.loadingForm = false;
    }
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get trendData() {
    const trendData = [
      {
        name: "1 Tag",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.dayTrend
      },
      {
        name: "7 Tage",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 Tage",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.thirtyDayTrend
      }
    ];
    return trendData;
  }

  get statusDistributionData() {
    return AnalyticsModule.statusDistributionData.data.map(status => {
      return {
        name: this.$t(`enums.ProgressStatusEnum.${status.name}`),
        value: status.value
      };
    });
  }

  get trendSumKpiGroup() {
    return [
      {
        icon: "mdi-car-wrench",
        value: AnalyticsModule.trendAnalysisData.data.sum,
        name: this.$t("views.portal.PartnerDashboard.statTitle.report")
      }
    ];
  }

  get openStatusKpiGroup() {
    return [
      {
        icon: "mdi-car-wrench",
        value: AnalyticsModule.statusDistributionData.data.filter(status => status.name === "new")[0]?.value,
        name: this.$t("views.portal.PartnerDashboard.statTitle.openReport")
      }
    ];
  }

  dataHandler = {
    trendAnalysis: {
      getData: async () => {
        await AnalyticsModule.getTrendAnylsisDataPerPartner();
      },
      isLoading: false
    },
    statusDistribution: {
      getData: async () => {
        await AnalyticsModule.getStatusDistributionData();
      },
      isLoading: false
    }
  };

  async getData() {
    Object.keys(this.dataHandler).forEach(async key => {
      this.dataHandler[key].isLoading = true;

      try {
        await this.dataHandler[key].getData();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    });
  }

  get dates() {
    return AnalyticsModule.dateRange;
  }

  async setDateRange(dateRange: string[]) {
    AnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  get url() {
    if (!this.partner?.settings?.defaultUrl) {
      return undefined;
    }

    return `https://${this.partner?.settings?.defaultUrl}`;
  }

  downloadQrLink() {
    const canvas: HTMLCanvasElement = document
      .getElementById("qr")
      ?.getElementsByTagName("canvas")[0] as HTMLCanvasElement;
    const download: HTMLAnchorElement = this.$refs.download as HTMLAnchorElement;
    this.$log.debug(canvas);

    if (!canvas || !download) {
      return;
    }

    const image = canvas.toDataURL("image/png");
    download.setAttribute("href", image);
  }

  get qrCodeName() {
    return `${this.partner.settings?.defaultUrl}.png`;
  }
}
