























import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import BookingDetailCard from "@/components/booking/portal-detail-card/BookingDetailCard.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { ResourceModule } from "@/store/modules/resource.store";
import { Component, Prop } from "vue-property-decorator";
import { GoToHelper } from "@/lib/utility/goToHelper";
import {
  BookingGoToActionEmitData,
  BookingMDetailActionEnum,
  BookingPortalFormEmitInputData
} from "@/components/booking/portal-detail-card/ui-types";
import { IBooking, Booking } from "@/models/booking.entity";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { IService, Service } from "@/models/service.entity";
import { CustomFieldModule } from "@/store/modules/custom-field.store";

@Component({
  components: {
    LayoutSimple,
    ConfirmActionDialog,
    BookingDetailCard,
    LatestEntriesCardEmpty
  }
})
export default class BookingPortalDetailView extends PartnerFallbackMixin {
  /**
   * Passed from Vue Router.
   * @see booking.router.ts
   */
  @Prop()
  isEdit!: boolean;

  booking: IBooking | null = null;

  service: IService | null = null;

  isLoading = false;

  async mounted() {
    await this.trySetByRouteOrDefault();
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      const booking = await new Booking({ partnerId: this.partnerId, id: this.bookingId }).fetch();

      this.booking = booking;

      await ResourceModule.fetchAll({ partnerId: this.partnerId });

      /**
       * Blocked resources don't have a serviceId.
       */
      try {
        if (booking.serviceId) {
          this.service = await new Service({
            partnerId: this.partnerId,
            id: booking.serviceId
          }).fetch();
        }
      } catch (serviceError) {
        handleError(serviceError);
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get bookingId() {
    return this.$route.params.bookingId;
  }

  get bookingDto() {
    return this.booking;
  }

  get resource() {
    return ResourceModule.maps.id.get(this.booking?.resourceId || "")[0];
  }

  get resourceList() {
    return ResourceModule.entities;
  }

  get bookingResourceName() {
    return this.booking ? this.resource?.name : "";
  }

  get bookingServiceName() {
    if (!this.booking) {
      return undefined;
    }

    if (!this.booking.serviceId) {
      return Service.SERVICE_INTERNAL_NAME;
    }

    return this.service?.name || Service.SERVICE_INTERNAL_NAME;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async processGoToAction(data: BookingGoToActionEmitData) {
    const action = data.action;

    switch (action) {
      case BookingMDetailActionEnum.GO_TO_EDIT:
        await new GoToHelper(this.$router).goToBookingEdit(this.partnerId, data.booking.id);
        break;
      case BookingMDetailActionEnum.GO_TO_RESOURCE:
        await new GoToHelper(this.$router).goToResourceDetail(this.partnerId, data.booking.resourceId, true);
        break;
      case BookingMDetailActionEnum.GO_TO_SERVICE:
        await new GoToHelper(this.$router).goToServiceDetail(this.partnerId, data.booking.serviceId || "", true);
        break;
      case BookingMDetailActionEnum.OPEN_IN_NEW:
      case BookingMDetailActionEnum.GO_TO_OVERVIEW:
        await new GoToHelper(this.$router).goToBookingDetail(this.partnerId, data.booking.id);
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  }

  async onDeleteBooking() {
    await new GoToHelper(this.$router).goToBooking(this.partnerId);
  }

  async onUpdateBooking({ booking, notifyUserForUpdate }: BookingPortalFormEmitInputData) {
    this.isLoading = true;
    await booking.update(notifyUserForUpdate);
    this.$toast.success("👍");
    this.isLoading = false;
  }
}
