






























































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PartnerUserDangerZoneCard from "@/components/partner/PartnerUserDangerZoneCard.vue";
import UserAdministrationDetailIdentityStatus from "@/components/partner/UserAdministrationDetailIdentityStatus.vue";
import UserAdministrationDetailPermissions from "@/components/partner/UserAdministrationDetailPermissions.vue";
import ActivityCard from "@/components/thg/ActivityCard.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IPartnerUser } from "@/models/user.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { $t } from "@/lib/utility/t";
import { copyToClipboard } from "@/lib/utility/copy-to-clipboard.helper";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { PartnerUserGoToHelper } from "@/lib/utility/partner-user.go-to-helper";

enum UserTabEnum {
  OVERVIEW = "overview",
  ADMINISTRATION = "administration"
}

@Component({
  components: {
    LatestEntriesCardEmpty,
    MHeader,
    LayoutSimple,
    CustomerContactCard,
    PartnerUserDangerZoneCard,
    UserAdministrationDetailIdentityStatus,
    ActivityCard,
    UserAdministrationDetailPermissions,
    Debug
  },
  filters: {
    simpleDate,
    getFlagEmoji
  }
})
export default class PartnerUserDetail extends mixins(PaginationListVueMixin, PartnerFallbackMixin, PermissionMixin) {
  readonly copyToClipboard = copyToClipboard;

  readonly UserTabEnum = UserTabEnum;

  readonly getFlagEmoji = getFlagEmoji;

  tab = 0;

  @Prop()
  value!: IPartnerUser;

  @Prop()
  loading?: boolean;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  @Prop()
  partnerId!: string;

  get tabs() {
    let tabs = Object.values(UserTabEnum);

    if (!this.can(ActionEnum.UPDATE, ResourceEnum.USER)) {
      tabs = tabs.filter(tab => tab !== UserTabEnum.ADMINISTRATION);
    }

    return tabs;
  }

  get title() {
    if (!this.value) {
      return "";
    }

    if (!this.value.countryCode) {
      return this.value.userName;
    }

    return `${this.value.userName} ${getFlagEmoji(this.value.countryCode)}`;
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const breadCrumbs = PartnerUserGoToHelper.breadCrumbs;

    const selected = [];

    selected.push(breadCrumbs.PartnerUserTable(this.value.partnerId));
    selected.push(breadCrumbs.PartnerUserDetail(this.value.partnerId, this.value.id));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "primaryAction",
        exec: this.value.goTo(this.$router).detail
      });
    }

    if (this.can(ActionEnum.READ, ResourceEnum.ADMIN)) {
      actions.push({
        text: $t("views.ThgBillingBatchSelectionItemView.goUser") + " (admin)",
        key: "admin",
        exec: this.goToAdminUser
      });
    }

    return actions;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.value) {
      return undefined;
    }

    return {
      refType: BackendResourceEnum.USER,
      refId: this.value.id
    };
  }

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  mounted() {
    if (this.$route.query.userTab) {
      this.tab = Number(this.$route.query.userTab as any);
    }
  }

  @Watch("tab")
  onTabChange() {
    if (this.$route.name === "PartnerUserDetailView" && this.value) {
      new GoToHelper(this.$router).setUrl({
        name: "PartnerUserDetailView",
        params: { userId: this.value.id, partnerId: this.partnerId },
        query: { userTab: `${this.tab ?? 0}` }
      });
    }
  }

  goToAdminUser() {
    const location: RawLocation = {
      name: "UserAdministration",
      query: { userId: this.value?.id }
    };
    new GoToHelper(this.$router).go(location);
  }
}
