import { $t } from "./t";
import { toast } from "./toast";

/**
 * Copy text to clipboard
 * @param text Text to copy to clipboard
 */
export function copyToClipboard(text: string): void {
  navigator.clipboard.writeText(text);
  toast.info($t("components.CopyClipboardText.confirmCopy"));
}
