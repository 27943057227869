









































import CreateReportForm from "@/components/fleet/CreateReportForm.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    CreateReportForm,
    AssigneePreview
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerReportCreate extends mixins(PartnerFallbackMixin, PermissionMixin) {
  title = this.$t("nav.PartnerReportCreate.title");

  isLoading = false;
  showSideCard = false;

  isValid = false;

  report: CreateReportAsPartnerDto = new CreateReportAsPartnerDto();

  async mounted() {
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      await this.trySetByRouteOrDefault();
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  getModule() {
    return ReportPaginationModule;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];

    breadCrumbs.push({
      text: this.$t("navigation.ThePartnerList.report"),
      disabled: false,
      exact: true,
      to: {
        name: "PartnerReportTable",
        params: { partnerId: this.partnerId }
      }
    });

    breadCrumbs.push({
      text: this.$t("create"),
      disabled: true,
      exact: true,
      to: {
        name: "PartnerReportCreate",
        params: { partnerId: this.partnerId }
      }
    });

    return breadCrumbs;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get userId() {
    return UserModule.userId;
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  async save() {
    try {
      this.isLoading = true;
      const partnerId = this.partnerId;

      this.$log.debug(this.report);

      this.report.assignees = [UserModule.userId];

      const created = await this.report.create(partnerId);
      this.$emit("created", created);

      PartnerModule.setReport(created);

      await this.$router.push({
        name: "PartnerReportsDetailView",
        params: { reportId: created.id, partnerId: created.partnerId }
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
