

































































































































































































import PersonGroupCreateDialog from "@/components/person/group/PersonGroupCreateDialog.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import HasDescription from "@/components/utility/HasDescription.vue";
import RefsPerson from "@/components/utility/RefsPerson.vue";
import RefsPersonGroup from "@/components/utility/RefsPersonGroup.vue";
import { getFlagEmoji, getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { PersonLabelEnum } from "@/lib/enum/person-label.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { emailRule, phoneRuleOptional } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PersonGoToHelper, PersonRouteNames } from "@/lib/utility/person.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Address } from "@/models/address.entity";
import { PersonGroup, IPersonGroup } from "@/models/person-group.entity";
import { Person, IPerson } from "@/models/person.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PersonGroupModule } from "@/store/modules/person-group.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import { CustomFieldValue } from "@/models/custom-field-value.entity";
import { debounce } from "debounce";
import PersonDetailTable from "./PersonDetailTable.vue";
import RefsSelect from "../utility/RefsSelect.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { ContractClientModule, ContractProviderModule } from "@/store/modules/contract.store";
import ContractSelectByClientOrProviderUpdateDialog from "../contract/ContractSelectByClientOrProviderUpdateDialog.vue";
import ContractTable from "../contract/ContractTable.vue";
import PartnerHasPartnerBankingIdsTable from "../partner-banking/PartnerHasPartnerBankingIdsTable.vue";

enum PersonTabEnum {
  OVERVIEW = "overview",
  REFERENCES = "references",
  CONTRACT_TABLE = "contract-table",
  BANKING = "banking"
}

@Component({
  components: {
    HasDescription,
    MHeader,
    MDetailViewGrid,
    ActivityCard: () => import("@/components/thg/ActivityCard.vue"),
    RefsPersonGroup,
    RefsPerson,
    CustomFieldListForm,
    MActionList,
    ConfirmActionDialog,
    PersonGroupCreateDialog,
    Debug,
    CopyClipboardText,
    PersonDetailTable,
    RefsSelect,
    ContractSelectByClientOrProviderUpdateDialog,
    ContractTable,
    PartnerHasPartnerBankingIdsTable
  },
  filters: {
    getFlagEmojiByLanguage,
    getFlagEmoji
  }
})
export default class PersonDetail extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly PersonLabelEnum = PersonLabelEnum;
  readonly Person = Person;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;
  readonly formable = Person;
  readonly PersonTabEnum = PersonTabEnum;
  readonly DefaultTab = 0;
  readonly ContractProviderModule = ContractProviderModule;
  readonly ContractClientModule = ContractClientModule;

  @Prop()
  value!: IPerson;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  tab = 0;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  personGroup: IPersonGroup | null = null;

  isLoadingPersonGroup = false;

  isLoadingPersonGroups = false;

  isLoadingCustomFieldValues = false;

  isLoadingRefs = false;

  get contractClientTableFilters(): IPageFilterElement[] {
    return [
      new PageFilterElement({
        key: "clientIds.refId",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.value.id
      })
    ];
  }

  get contractProviderTableFilters(): IPageFilterElement[] {
    return [
      new PageFilterElement({
        key: "providerIds.refId",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.value.id
      })
    ];
  }

  get refsCategories(): BackendResourceEnum[] {
    return [BackendResourceEnum.COMPANY, BackendResourceEnum.FLEET];
  }

  get showMoreAction(): IAction {
    return {
      text: $t("common.nouns.detail"),
      key: "detail",
      description: $t("common.nouns.detail"),
      exec: () =>
        new PersonGoToHelper(this.$router).goToPersonDetailForm({
          partnerId: this.value.partnerId,
          personId: this.value.id,
          newTab: false
        }),
      disabled: !this.can(ActionEnum.UPDATE, ResourceEnum.PERSON)
    };
  }

  get availableLanguages() {
    return ConfigModule.availableLanguages;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [requiredRule(), emailRule()];
  }

  get phoneRule() {
    return [phoneRuleOptional()];
  }

  get subtitle() {
    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const breadCrumbs = PersonGoToHelper.breadCrumbs;

    const selected = [];

    selected.push(breadCrumbs.PersonTable(this.value.partnerId));
    selected.push(breadCrumbs.PersonDetail(this.value.partnerId, this.value.id));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonDetail({
            partnerId: this.value.partnerId,
            personId: this.value.id,
            newTab: false
          })
      });
    }

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonDetail({
            partnerId: this.value.partnerId,
            personId: this.value.id,
            newTab: false
          })
      });
    }

    const personGroupId = this.value.groupId;
    if (personGroupId) {
      actions.push({
        text: $t("person.toPersonGroup"),
        key: "toPersonGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new PersonGoToHelper(this.$router).goToPersonGroupCustomView({
            partnerId: this.value.partnerId,
            personGroupId: personGroupId,
            viewId: "0",
            newTab: false
          })
      });
    }

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.PERSON)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  get groups() {
    return PersonGroupModule.entities;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.PERSON
    };
  }

  debounceSaveCustomFields = debounce(this.saveCustomFieldValues, 1000);

  mounted() {
    this.setPersonGroup();

    if (this.$route.query.tab) {
      this.tab = Number(this.$route.query.tab as any);
    }
  }

  @Watch("tab")
  onTabChange() {
    if (this.$route.name === PersonRouteNames.PERSON_DETAIL) {
      new GoToHelper(this.$router).setUrl({
        ...PersonGoToHelper.locations.personDetail({
          partnerId: this.value.partnerId,
          personId: this.value.id,
          query: { tab: `${this.tab ?? this.DefaultTab}` }
        })
      });
    }
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  goToPersonGroupCustomView() {
    if (!this.value.groupId) return;

    new PersonGoToHelper(this.$router).goToPersonGroupCustomView({
      partnerId: this.value.partnerId,
      personGroupId: this.value.groupId,
      viewId: "0",
      newTab: false
    });
  }

  @Watch("value.groupId")
  async setPersonGroup() {
    this.personGroup = null;

    const personGroupId = this.value.groupId;
    if (personGroupId) {
      this.isLoadingPersonGroup = true;
      this.isLoadingCustomFieldValues = true;

      const personGroup =
        PersonGroupModule.maps.id.get(personGroupId)[0] ||
        (await new PersonGroup({ partnerId: this.partner.id, id: personGroupId }).fetch().catch(handleError));
      this.isLoadingPersonGroup = false;
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.personGroup = personGroup;
      });
    }
  }

  async saveRefs() {
    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: this.value.refs
      })
      .catch(handleError);
    this.isLoadingRefs = false;
  }

  async saveGroup() {
    this.isLoadingPersonGroup = true;
    await this.value
      .updatePartial({
        groupId: this.value.groupId
      })
      .catch(handleError);
    this.isLoadingPersonGroup = false;
  }

  async saveCustomFieldValues() {
    this.isLoadingCustomFieldValues = true;
    await this.value
      .updatePartial({
        values: CustomFieldValue.buildCustomFieldValuesDto(
          this.value.values.map(v => ({ id: v.id, value: v.value, timezone: v.timezone }))
        )
      })
      .catch(handleError);
    this.isLoadingCustomFieldValues = false;
  }

  async refreshPersonGroups() {
    this.isLoadingPersonGroups = true;
    try {
      PersonGroupModule.setFilters([
        new PageFilterElement({
          key: "type",
          operation: PageFilterOperationEnum.EQUAL,
          value: ResourceEnum.PERSON
        })
      ]);
      await PersonGroupModule.fetchAll({ partnerId: PartnerModule.partner.id });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingPersonGroups = false;
  }

  openAddReference() {
    (this.$refs.refsSelect as RefsSelect)?.open();
  }

  goToPersonDetailForm() {
    new PersonGoToHelper(this.$router).goToPersonDetailForm({
      partnerId: this.value.partnerId,
      personId: this.value.id,
      newTab: false
    });
  }
}
