
















































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomViews from "@/components/cost/CustomViews.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { CostGroup, ICostGroup } from "@/models/cost-group.entity";
import { ICost, Cost } from "@/models/cost.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import { CostModule } from "@/store/modules/cost.store";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import CostSideCard from "@/components/cost/CostSideCard.vue";
import CostChip from "@/components/cost/CostChip.vue";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import { Vehicle } from "@/models/vehicle.entity";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { simpleDoubleDigitDate, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReference, Reference } from "@/models/reference.entity";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { MrfiktivCostControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {
    MHeader,
    CustomViews,
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    CostSideCard,
    CostChip,
    RefsVehicle,
    CostCreateDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostGroupCustomViewView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Ref("costDialog")
  costDialog!: CostCreateDialog;

  @Ref("customViews")
  customViews!: CustomViews<ICost, MrfiktivCostControllerGetParamsGen>;

  readonly store = CostModule;

  readonly BackendResourceEnum = BackendResourceEnum;

  readonly CostTypeEnum = CostTypeEnum;

  isLoading = false;

  costGroup: ICostGroup | null = null;

  viewId = "0";

  partnerId = this.$route.params.partnerId;

  costGroupId = this.$route.params.costGroupId;

  selectedItem: ICost | null = null;

  isCostCreateDialogActive = false;

  createCostRefs: IReference[] = [];

  tableBaseConfig = {
    values: [
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "title"
      },
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "description"
      },
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "total"
      }
    ],
    filters: []
  };

  calendarBaseConfig = {
    calendarStart: {
      type: ProjectCustomViewFieldEnum.PROPERTY,
      key: "date"
    },
    filters: []
  };

  boardBaseConfig = {
    filters: []
  };

  get viewIdLocal() {
    return this.viewId;
  }

  set viewIdLocal(value: string) {
    this.viewId = value;

    this.closeSideCard();
  }

  get breadCrumbList(): IBreadcrumb[] {
    const locations = CostGoToHelper.locations;
    const crumbs: IBreadcrumb[] = [
      { text: $t("cost.costGroupTable"), exact: true, to: locations.costGroupTable({ partnerId: this.partnerId }) },
      {
        text: this.costGroup?.title || "",
        exact: false,
        to: locations.costGroupCustomViewHome({
          partnerId: this.partnerId,
          costGroupId: this.costGroupId
        })
      }
    ];

    return crumbs;
  }

  get countCostsOfCustomViews() {
    const viewId = this.viewIdLocal;
    const costView = this.costGroup?.configuration.views[Number(viewId)];
    if (!costView) return undefined;

    let costs: ICost[] = [];
    if (costView.type === ProjectCustomViewTypeEnum.TABLE) {
      costs = CostModule.filtered;
    } else if (costView.type === ProjectCustomViewTypeEnum.BOARD) {
      const boardColumn = costView.boardColumn;
      const boardColumnKey: string[] = boardColumn?.key.split("?") || [];
      const boardColumnType: string = boardColumn?.type ?? "";
      const boardColumnId: string = boardColumnKey[1];

      let keys = costView.boardColumnOrder;
      if (!keys?.length) {
        if (boardColumnType === ProjectCustomViewFieldEnum.CUSTOM_FIELD) {
          keys =
            CustomFieldModule.paginationList
              .find(f => f.id === boardColumnId)
              ?.configuration?.values.map(f => f.value) ?? [];
        }
      }

      for (const cost of CostModule.filtered) {
        const value = cost.values.find(f => f.id === boardColumnId)?.value ?? "";
        if (typeof value === "string" && keys.includes(value)) {
          costs.push(cost);
        } else if ((value as string[]).find(v => keys.includes(v))) {
          costs.push(cost);
        }
      }
    } else if (costView.type === ProjectCustomViewTypeEnum.CALENDAR) {
      return undefined;
    }

    let total = 0;
    let amount = 0;
    for (const item of costs) {
      if (item.expenseOrIncome === CostTypeEnum.EXPENSE) total -= item.absoluteTotal;
      else total += item.absoluteTotal;

      amount++;
    }

    return { total, amount };
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];
    const count = this.countCostsOfCustomViews;

    if (!count) return [];

    const total = count.total;
    const amount = count.amount;

    chips.push({
      text: `${amount} ${this.$t("cost.costs")}`,
      key: "costAmount",
      disabled: true
    });

    chips.push({
      text: `${total.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      })}`,
      key: "selectedTotal",
      disabled: true,
      color: total < 0 ? "error" : total > 0 ? "success" : ""
    });

    return chips;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.canCreateCost) {
      actions.push({
        text: $t("cost.createCost"),
        key: "createCost",
        exec: () => this.costDialog.show()
      });
    }

    if (this.costGroup?.configuration.views[Number(this.viewIdLocal)]?.type === ProjectCustomViewTypeEnum.TABLE) {
      actions.push({
        text: $t("common.verbs.download").toString(),
        key: "download",
        exec: () => this.customViews.downloadToCsv()
      });
    }

    actions.push({
      icon: "mdi-pencil",
      text: $t("project.ticket.settings"),
      key: "edit",
      exec: this.goToSettings
    });

    return actions;
  }

  goToSettings() {
    new CostGoToHelper(this.$router).goToCostGroupDetail({
      partnerId: this.partnerId,
      costGroupId: this.costGroupId
    });
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.costGroupId = this.$route.params.costGroupId;
    this.viewId = this.$route.params.viewId ?? "0";
  }

  async mounted() {
    this.isLoading = true;
    try {
      const partnerAsync = this.trySetByRouteOrDefault();
      const costGroupAsync = (
        CostGroupModule.maps.id.get(this.costGroupId)[0] ||
        new CostGroup({
          id: this.costGroupId,
          partnerId: this.partnerId
        })
      )
        .fetch()
        .then(costGroup => (this.costGroup = costGroup));

      await Promise.all([partnerAsync, costGroupAsync]);

      this.store.setHiddenFilter([new PageFilterElement({ key: "group", operation: "$eq", value: this.costGroupId })]);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    const costId = this.$route.query.costId as string;
    if (costId) {
      const cost = new Cost({ partnerId: this.partnerId, id: costId });
      this.openSideCard(cost);
    }
  }

  createCostFromVehicle(vehicleId: string) {
    this.createCostRefs.splice(0);
    this.createCostRefs.push(new Reference({ refId: vehicleId, refType: BackendResourceEnum.VEHICLE }));
    this.costDialog?.show();
  }

  closeDialog(isDialogActive: boolean) {
    this.isCostCreateDialogActive = isDialogActive;
    if (!isDialogActive) {
      this.createCostRefs.splice(0);
    }
  }

  // identify strings formatted as NNNN-NN-NNTNN:NN
  isDayTimeString(value: string) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
  }

  getDateTime(value: string) {
    return simpleDoubleDigitDate(value) + " " + formatHoursAndMinutes(new Date(value));
  }

  getTotalForItems(items: ICost[]) {
    let total = 0;

    for (const item of items) {
      if (item.expenseOrIncome === CostTypeEnum.EXPENSE) total -= item.absoluteTotal;
      else total += item.absoluteTotal;
    }

    return total;
  }

  getVehicleById(vehicleId: string) {
    let vehicle = VehicleAccessLayer.maps.id.get(vehicleId)[0];

    if (!vehicle) {
      vehicle = new Vehicle({ id: vehicleId, partnerId: this.partnerId });
      VehicleAccessLayer.set(vehicle);
      vehicle.fetch().catch(this.$log.error);
    }

    return vehicle;
  }

  goToVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.HOME, true);
  }

  closeSideCard() {
    this.selectedItem = null;

    new CostGoToHelper(this.$router).setUrl(
      CostGoToHelper.locations.costGroupCustomView({
        partnerId: this.partnerId,
        costGroupId: this.costGroupId,
        viewId: this.viewIdLocal || "0"
      })
    );
  }

  openSideCard(cost: ICost) {
    this.selectedItem = null;
    this.$nextTick(() => {
      this.selectedItem = cost;
      new CostGoToHelper(this.$router).setUrl(
        CostGoToHelper.locations.costGroupCustomView({
          partnerId: this.partnerId,
          costGroupId: this.costGroupId,
          viewId: this.viewIdLocal || "0",
          query: cost ? { costId: cost.id } : {}
        })
      );
    });
  }
}
