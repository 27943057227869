








import CustomerAccountDetail from "@/components/partner/CustomerAccountDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component } from "vue-property-decorator";
import { handleError } from "@/lib/utility/handleError";
import { CustomerAccountModule } from "@/store/modules/customer-account.store";
import { MrfiktivPartnerCustomerUserViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { RefsMessagesModule } from "@/store/modules/refs.store";
import { ReportModule } from "@/store/modules/report.store";
import { PartnerModule } from "@/store/modules/partner";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";

@Component({
  components: {
    LayoutSimple,
    CustomerAccountDetail
  }
})
export default class CustomerAccountDetailView extends mixins(PartnerFallbackMixin) {
  isLoading = false;

  get customerAccountId() {
    return this.$route.params.customerAccountId;
  }

  get customerAccount() {
    return CustomerAccountModule.currentAccount;
  }

  async mounted() {
    this.isLoading = true;
    await this.trySetByRouteOrDefault();

    let customer: MrfiktivPartnerCustomerUserViewModelGen | null = null;
    try {
      customer = await CustomerAccountModule.getOne(this.customerAccountId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
    if (customer) {
      await this.getUserMails(customer).catch(this.$log.error);
    }
  }

  async getUserMails(customer: MrfiktivPartnerCustomerUserViewModelGen) {
    if (!customer) {
      return;
    }
    const partnerId = ReportModule.partner._id || PartnerModule.partner.id;
    await RefsMessagesModule.generateTimeLineMessagesForUser({
      ...customer,
      partnerId: partnerId,
      resource: BackendResourceEnum.CUSTOMER_DATA
    });
  }
}
