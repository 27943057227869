







































































import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { PartnerUserGoToHelper } from "@/lib/utility/partner-user.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerUser } from "@/models/user.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import PartnerUserCreateDialog from "./PartnerUserCreateDialog.vue";
import PartnerUserSideCard from "./PartnerUserSideCard.vue";
import { IReference } from "@/models/reference.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import CopyClipboardText from "../utility/CopyClipboardText.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    PartnerUserCreateDialog,
    PartnerUserSideCard,
    CopyClipboardText,
    Debug
  }
})
export default class PartnerUserTable extends mixins(PartnerFallbackMixin, PermissionMixin, RulesMixin) {
  readonly store = PartnerUserModule;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop({ default: () => [] })
  refs!: IReference[];

  @Prop({ default: () => [] })
  filters!: IPageFilterElement[];

  @Prop()
  controlElements?: IControlElements[] | undefined;

  selectedItem: IPartnerUser | null = null;

  selectedItemForDelete: IPartnerUser | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  confirmUserIdToDelete = "";
  confirmUserNameToDelete = "";

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.user.isActive"),
      value: "isArchived"
    });

    headers.push({
      text: $t("objects.user.firstName"),
      value: "firstName"
    });

    headers.push({
      text: $t("objects.user.lastName"),
      value: "lastName"
    });

    headers.push({
      text: $t("objects.contact.email"),
      value: "userName"
    });

    headers.push({
      text: $t("objects.contact.phone"),
      value: "contact.phone"
    });

    headers.push({ text: "", align: "end", value: "controls", width: 200, sortable: false });

    return headers;
  }

  get _controlElements(): IControlElements[] {
    if (this.controlElements) {
      return this.controlElements;
    }

    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: IPartnerUser) =>
          new PartnerUserGoToHelper(this.$router).goToPartnerUserDetail({
            partnerUserId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("user.deactivate.action"),
        action: this.onDeleteItem,
        disabled: !this.can(ActionEnum.DELETE, ResourceEnum.USER)
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [
      {
        name: $t("objects.user.isArchived"),
        filter: [
          new PageFilterElement({
            key: "isArchived",
            value: true as any,
            operation: PageFilterOperationEnum.EQUAL
          })
        ]
      },
      {
        name: $t("objects.user.isActive"),
        filter: [
          new PageFilterElement({
            key: "isArchived",
            value: false as any,
            operation: PageFilterOperationEnum.EQUAL
          })
        ]
      }
    ];

    return predefinedFilter;
  }

  beforeMount() {
    if (this.filters?.length) {
      PartnerUserModule.setFilters(this.filters);
    } else {
      PartnerUserModule.setFilters([
        new PageFilterElement({
          key: "isArchived",
          value: false as any,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]);
    }
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  setSelectedItem(item: IPartnerUser | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: IPartnerUser) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
