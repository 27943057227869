























import { emailRuleOptional, phoneRuleOptional } from "@/lib/rules/contactRule";
import { IAttendee } from "@/models/attendee";
import { ICompanyInformation } from "@/models/company-information.entity";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {}
})
export default class AttendeeForm extends Vue {
  @Prop()
  value!: IAttendee;

  @Prop({ default: [] })
  suggestions!: ICompanyInformation[];

  isValid = false;

  @Watch("isValid")
  watchIsValid(value: boolean) {
    this.$emit("isValid", value);
  }

  get emailRuleOptional() {
    return [emailRuleOptional()];
  }

  get phoneRuleOptional() {
    return [phoneRuleOptional()];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get form() {
    return (this.$refs.form as unknown) as {
      reset: () => void;
      resetValidation: () => void;
      validate: () => boolean;
    };
  }

  handleInput(e: ICompanyInformation | string) {
    if (typeof e == "string") {
      this.value.companyName = e;
    } else {
      this.value.companyName = e.companyName;
    }
  }
}
