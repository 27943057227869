







































import { emailRuleOptional, phoneRuleOptional } from "@/lib/rules/contactRule";
import { HandoverCompanyServiceEnum, ICompanyInformation } from "@/models/company-information.entity";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CompanyForm extends Vue {
  @Prop()
  value!: ICompanyInformation;

  isValid = false;

  @Watch("isValid")
  watchIsValid(value: boolean) {
    this.$emit("isValid", value);
  }

  get items(): string[] {
    return Object.values(HandoverCompanyServiceEnum);
  }

  get isEmailRule() {
    return [emailRuleOptional()];
  }

  get isPhoneRule() {
    return [phoneRuleOptional()];
  }
}
