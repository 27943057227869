import { CompanyRouteNames } from "@/lib/utility/company.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import CompanyDetailView from "@/views/company/CompanyDetailView.vue";
import CompanyDetailFormView from "@/views/company/CompanyDetailFormView.vue";
import CompanyTableView from "@/views/company/CompanyTableView.vue";
import CompanyGroupDetailView from "@/views/company/group/CompanyGroupDetailView.vue";
import CompanyGroupTableView from "@/views/company/group/CompanyGroupTableView.vue";
import CompanyGroupCustomViewView from "@/views/company/group/CompanyGroupCustomViewView.vue";

/**
 * Routes for companies and tickets
 */
export const companyRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/company/",
    component: CompanyTableView,
    name: CompanyRouteNames.COMPANY_TABLE,
    permission: {
      resource: ResourceEnum.COMPANY,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-factory",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partner/:partnerId/company/:companyId",
    component: CompanyDetailView,
    name: CompanyRouteNames.COMPANY_DETAIL,
    permission: {
      resource: ResourceEnum.COMPANY,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/company/:companyId/form",
    component: CompanyDetailFormView,
    name: CompanyRouteNames.COMPANY_DETAIL_FORM,
    permission: {
      resource: ResourceEnum.COMPANY,
      action: ActionEnum.UPDATE,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/company-group/",
    component: CompanyGroupTableView,
    name: CompanyRouteNames.COMPANY_GROUP_TABLE,
    permission: {
      resource: ResourceEnum.COMPANY_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.ADMIN,
      icon: "mdi-factory",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partner/:partnerId/company-group/:companyGroupId/",
    component: CompanyGroupCustomViewView,
    name: "COMPANY_GROUP_CUSTOM_VIEW_HOME",
    permission: {
      resource: ResourceEnum.COMPANY_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/company-group/:companyGroupId/settings",
    component: CompanyGroupDetailView,
    name: CompanyRouteNames.COMPANY_GROUP_DETAIL,
    permission: {
      resource: ResourceEnum.COMPANY_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/company-group/:companyGroupId/view/:viewId",
    component: CompanyGroupCustomViewView,
    name: CompanyRouteNames.COMPANY_GROUP_CUSTOM_VIEW,
    permission: {
      resource: ResourceEnum.COMPANY_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
