var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.value.companyName,
          breadCrumbs: _vm.breadCrumbs,
          chips: _vm.chips,
          actions: _vm.actions
        },
        on: {
          actionClicked: function actionClicked($event) {
            return $event.exec()
          },
          alertClicked: function alertClicked($event) {
            return $event.exec()
          }
        }
      }),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function callback($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tabs-slider", {
            attrs: {
              color: "info"
            }
          }),
          _vm._l(Object.values(_vm.CompanyTabEnum), function(tab) {
            return _c(
              "v-tab",
              {
                key: "v-tab" + tab
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("enums.CompanyTabEnum.".concat(tab))) +
                    " "
                )
              ]
            )
          })
        ],
        2
      ),
      _c("v-divider"),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function callback($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            {
              key: _vm.CompanyTabEnum.OVERVIEW
            },
            [
              _c(
                "m-detail-view-grid",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "details",
                      fn: function fn() {
                        return [
                          _c("select-assignees", {
                            attrs: {
                              css: "width: 100%",
                              partnerId: _vm.partner.id,
                              outlined: true,
                              dense: false
                            },
                            on: {
                              input: _vm.onAssigneesUpdate,
                              added: _vm.onAssigneesAdded,
                              removed: _vm.onAssigneesRemoved
                            },
                            model: {
                              value: _vm.value.assignees,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "assignees", $$v)
                              },
                              expression: "value.assignees"
                            }
                          }),
                          _c("v-divider"),
                          _c("company-detail-table", {
                            attrs: {
                              value: _vm.value,
                              onDetail: _vm.goToCompanyDetailForm
                            }
                          }),
                          _c("v-divider"),
                          _c("v-select", {
                            staticClass: "mt-4",
                            attrs: {
                              "item-value": "id",
                              items: _vm.groups,
                              label: _vm.$t("objects.company.groupId"),
                              outlined: "",
                              clearable: "",
                              loading: _vm.isLoadingCompanyGroup
                            },
                            on: {
                              change: _vm.saveGroup,
                              click: _vm.refreshCompanyGroups
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function fn() {
                                  return [
                                    _c("company-group-create-dialog", {
                                      attrs: {
                                        partnerId: _vm.value.partnerId
                                      },
                                      on: {
                                        created: function created(
                                          companyGroup
                                        ) {
                                          return (_vm.value.groupId =
                                            companyGroup.id)
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function fn(_ref) {
                                            var on = _ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                    top: "-6px"
                                                  },
                                                  attrs: {
                                                    icon: "",
                                                    loading:
                                                      _vm.isLoadingCompanyGroups
                                                  },
                                                  on: {
                                                    click: function click(
                                                      $event
                                                    ) {
                                                      $event.stopPropagation()
                                                      return on.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-plus")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "item",
                                fn: function fn(_ref2) {
                                  var item = _ref2.item
                                  return [
                                    _vm._v(" " + _vm._s(item.title) + " ")
                                  ]
                                }
                              },
                              {
                                key: "selection",
                                fn: function fn(_ref3) {
                                  var item = _ref3.item
                                  return [
                                    _vm._v(" " + _vm._s(item.title) + " ")
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.value.groupId,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "groupId", $$v)
                              },
                              expression: "value.groupId"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "mt-n7",
                              staticStyle: {
                                display: "flex"
                              }
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: "",
                                    elevation: 0,
                                    loading: _vm.isLoadingCompanyGroups
                                  },
                                  on: {
                                    click: _vm.refreshCompanyGroups
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common.verbs.refresh")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: "",
                                    elevation: 0
                                  },
                                  on: {
                                    click: _vm.goToCompanyGroupCustomView
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common.verbs.view")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.companyGroup &&
                          _vm.companyGroup.configuration &&
                          _vm.companyGroup.configuration.customFieldConfig &&
                          _vm.companyGroup.configuration.customFieldConfig
                            .length
                            ? _c(
                                "div",
                                [
                                  _vm.isLoadingCustomFieldValues
                                    ? _c("v-progress-linear", {
                                        attrs: {
                                          indeterminate: ""
                                        }
                                      })
                                    : _c("v-divider"),
                                  _c("custom-field-list-form", {
                                    staticClass: "mt-6",
                                    attrs: {
                                      customFieldConfig:
                                        _vm.companyGroup.configuration
                                          .customFieldConfig
                                    },
                                    on: {
                                      change: _vm.debounceSaveCustomFields
                                    },
                                    model: {
                                      value: _vm.value.values,
                                      callback: function callback($$v) {
                                        _vm.$set(_vm.value, "values", $$v)
                                      },
                                      expression: "value.values"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function fn() {
                        return [
                          _c("v-divider", {
                            staticClass: "mt-6"
                          }),
                          _c("m-action-list", {
                            attrs: {
                              actions: _vm.bottomActions
                            },
                            on: {
                              actionClicked: function actionClicked($event) {
                                return $event.exec()
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("has-description", {
                    staticClass: "mx-2",
                    attrs: {
                      isPlainText: false
                    },
                    model: {
                      value: _vm.value,
                      callback: function callback($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  }),
                  _vm.coordinates
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    outlined: ""
                                  }
                                },
                                [
                                  _c("partner-map", {
                                    attrs: {
                                      coordinates: _vm.coordinates,
                                      height: 265,
                                      colour: _vm.color
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider", {
                    staticClass: "mt-2"
                  }),
                  _c("activity-card", {
                    attrs: {
                      partner: _vm.partner,
                      source: _vm.source,
                      config: {
                        comment: true,
                        mail: false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            {
              key: _vm.CompanyTabEnum.PEOPLE_TABLE
            },
            [
              _c("person-table", {
                attrs: {
                  filters: _vm.companyTableFilters,
                  partnerId: _vm.value.partnerId,
                  controlElements: _vm.personTableControlElements,
                  refs: _vm.personCreateRefs
                },
                scopedSlots: _vm._u([
                  {
                    key: "bodyActions",
                    fn: function fn() {
                      return [
                        _c("select-by-refs-update-dialog", {
                          staticClass: "ml-2",
                          attrs: {
                            partnerId: _vm.value.partnerId,
                            refs: _vm.personCreateRefs,
                            refType: _vm.ResourceEnum.PERSON
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-tab-item",
            {
              key: _vm.CompanyTabEnum.REFERENCES
            },
            [
              _c("refs-select", {
                ref: "refsSelect",
                staticClass: "mx-2 mt-4",
                attrs: {
                  partnerId: _vm.value.partnerId,
                  loading: _vm.isLoadingRefs,
                  suggested: [],
                  listMode: false,
                  hideEditButton: false,
                  categories: _vm.refsCategories,
                  hideSuggestions: true
                },
                on: {
                  confirm: _vm.saveRefs,
                  change: _vm.saveRefs,
                  input: _vm.saveRefs
                },
                model: {
                  value: _vm.value.refs,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "refs", $$v)
                  },
                  expression: "value.refs"
                }
              }),
              !_vm.value.refs || !_vm.value.refs.length
                ? _c(
                    "v-card",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        outlined: ""
                      }
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.openAddReference
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v(" mdi-attachment ")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.nouns.references")) +
                                    " "
                                )
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(_vm.$t("add")) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-tab-item",
            {
              key: _vm.CompanyTabEnum.CONTRACT_TABLE
            },
            [
              _c("v-card-title", [
                _vm._v(" " + _vm._s(_vm.$t("objects.contract.clientIds")) + " ")
              ]),
              _c("contract-table", {
                attrs: {
                  store: _vm.ContractClientModule,
                  filters: _vm.contractClientTableFilters,
                  partnerId: _vm.value.partnerId,
                  clientIds: [
                    {
                      refId: _vm.value.id,
                      refType: _vm.ResourceEnum.COMPANY
                    }
                  ]
                },
                scopedSlots: _vm._u([
                  {
                    key: "bodyActions",
                    fn: function fn() {
                      return [
                        _c(
                          "Can",
                          {
                            attrs: {
                              I: _vm.ActionEnum.UPDATE,
                              a: _vm.ResourceEnum.CONTRACT,
                              field: _vm.value.partnerId
                            }
                          },
                          [
                            _c(
                              "contract-select-by-client-or-provider-update-dialog",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  title: _vm.$t("contract.addClient"),
                                  isProvider: false,
                                  partnerId: _vm.value.partnerId,
                                  hideButton: false,
                                  refs: [
                                    {
                                      refId: _vm.value.id,
                                      refType: _vm.ResourceEnum.COMPANY
                                    }
                                  ]
                                }
                              }
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("v-card-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("objects.contract.providerIds")) + " "
                )
              ]),
              _c("contract-table", {
                attrs: {
                  store: _vm.ContractProviderModule,
                  filters: _vm.contractProviderTableFilters,
                  partnerId: _vm.value.partnerId,
                  providerIds: [
                    {
                      refId: _vm.value.id,
                      refType: _vm.ResourceEnum.COMPANY
                    }
                  ]
                },
                scopedSlots: _vm._u([
                  {
                    key: "bodyActions",
                    fn: function fn() {
                      return [
                        _c(
                          "Can",
                          {
                            attrs: {
                              I: _vm.ActionEnum.UPDATE,
                              a: _vm.ResourceEnum.CONTRACT,
                              field: _vm.value.partnerId
                            }
                          },
                          [
                            _c(
                              "contract-select-by-client-or-provider-update-dialog",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  title: "contract.addProvider",
                                  isProvider: true,
                                  partnerId: _vm.value.partnerId,
                                  hideButton: false,
                                  refs: [
                                    {
                                      refId: _vm.value.id,
                                      refType: _vm.ResourceEnum.COMPANY
                                    }
                                  ]
                                }
                              }
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-tab-item",
            {
              key: _vm.CompanyTabEnum.BANKING
            },
            [
              _c("partner-has-partner-banking-ids-table", {
                model: {
                  value: _vm.value,
                  callback: function callback($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.isDeleteDialogActive && _vm.value
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-company", {
                attrs: {
                  item: _vm.value
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }