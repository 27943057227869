var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("m-header", {
        attrs: {
          title: _vm.value.name,
          breadCrumbs: _vm.breadCrumbs,
          actions: _vm.actions
        },
        on: {
          actionClicked: function actionClicked($event) {
            return $event.exec()
          },
          alertClicked: function alertClicked($event) {
            return $event.exec()
          }
        }
      }),
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-text-field", {
            staticClass: "mx-6",
            attrs: {
              loading: _vm.isLoading,
              outlined: "",
              dense: "",
              placeholder: _vm.$t("designGuide.MDetailForm.search"),
              "prepend-inner-icon": "mdi-magnify"
            },
            model: {
              value: _vm.search,
              callback: function callback($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-expansion-panels",
            {
              attrs: {
                multiple: "",
                flat: ""
              },
              model: {
                value: _vm.panels,
                callback: function callback($$v) {
                  _vm.panels = $$v
                },
                expression: "panels"
              }
            },
            [
              _c(
                "v-expansion-panel",
                [
                  _c(
                    "v-expansion-panel-header",
                    [
                      _vm._t("expansionHeader", function() {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "title"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("partnerBanking.partnerBanking")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-expansion-panel-content",
                    {
                      staticClass: "pr-0 pl-0 mb-n4"
                    },
                    [
                      _vm.searchMatch([
                        _vm.partnerBankingCopy.name,
                        _vm.$t("objects.partnerBanking.name").toString()
                      ])
                        ? _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("objects.partnerBanking.name"),
                              outlined: "",
                              rules: _vm.rules(_vm.RulesEnum.REQUIRED_RULE)
                            },
                            model: {
                              value: _vm.partnerBankingCopy.name,
                              callback: function callback($$v) {
                                _vm.$set(_vm.partnerBankingCopy, "name", $$v)
                              },
                              expression: "partnerBankingCopy.name"
                            }
                          })
                        : _vm._e(),
                      _vm.searchMatch([
                        _vm.partnerBankingCopy.bank,
                        _vm.$t("objects.partnerBanking.bank").toString()
                      ])
                        ? _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("objects.partnerBanking.bank"),
                              outlined: "",
                              rules: _vm.rules(_vm.RulesEnum.REQUIRED_RULE)
                            },
                            model: {
                              value: _vm.partnerBankingCopy.bank,
                              callback: function callback($$v) {
                                _vm.$set(_vm.partnerBankingCopy, "bank", $$v)
                              },
                              expression: "partnerBankingCopy.bank"
                            }
                          })
                        : _vm._e(),
                      _vm.searchMatch([
                        _vm.partnerBankingCopy.iban,
                        _vm.$t("objects.partnerBanking.iban").toString()
                      ])
                        ? _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("objects.partnerBanking.iban"),
                              outlined: "",
                              rules: _vm.rules(
                                _vm.RulesEnum.REQUIRED_RULE,
                                _vm.RulesEnum.IBAN_RULE
                              )
                            },
                            model: {
                              value: _vm.partnerBankingCopy.iban,
                              callback: function callback($$v) {
                                _vm.$set(_vm.partnerBankingCopy, "iban", $$v)
                              },
                              expression: "partnerBankingCopy.iban"
                            }
                          })
                        : _vm._e(),
                      _vm.searchMatch([
                        _vm.partnerBankingCopy.isSepaDirectDebitMandate.toString(),
                        _vm
                          .$t("objects.partnerBanking.isSepaDirectDebitMandate")
                          .toString()
                      ])
                        ? _c("custom-field-boolean", {
                            attrs: {
                              customField: {
                                label: _vm.$t(
                                  "objects.partnerBanking.isSepaDirectDebitMandate"
                                )
                              }
                            },
                            model: {
                              value:
                                _vm.partnerBankingCopy.isSepaDirectDebitMandate,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.partnerBankingCopy,
                                  "isSepaDirectDebitMandate",
                                  $$v
                                )
                              },
                              expression:
                                "partnerBankingCopy.isSepaDirectDebitMandate"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.changesDetected
            ? _c(
                "v-card",
                {
                  staticClass: "pa-4 changes-detected-card"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        loading: _vm.isLoading,
                        elevation: "0",
                        color: "info",
                        disabled: _vm.isDisabled
                      },
                      on: {
                        click: _vm.sync
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        outlined: "",
                        color: "info"
                      },
                      on: {
                        click: _vm.abort
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "text-body-2"
                    },
                    [_vm._v(_vm._s(_vm.$t("designGuide.MDetailForm.changes")))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isDeleteDialogActive && _vm.value
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-partner-banking", {
                attrs: {
                  item: _vm.value
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }