




































import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Agreement } from "@/models/agreement.entity";
import { IHandover } from "@/models/handover.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldDateTime from "../report/CustomFieldDateTime.vue";
import TemplateEditor from "../template/TemplateEditor.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import CompanyForm from "./CompanyForm.vue";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    ConfirmActionDialog,
    RefsSelectionByType,
    CompanyForm,
    CustomFieldDateTime,
    TemplateEditor,
    Debug
  }
})
export default class CreateAgreementDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  value!: IHandover;

  isFormValid = false;

  isCreateLoading = false;

  isDialogActive = false;

  get requiredRule() {
    return [requiredRule()];
  }

  agreements = [new Agreement()];

  async open() {
    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.descriptionTextField as any)?.focus();
    });
    this.agreements.splice(0);
    this.agreements.push(new Agreement());
  }

  close() {
    this.isDialogActive = false;
  }

  addNewEmptyAgreement() {
    this.agreements.push(new Agreement());
  }

  removeAgreement(index: number) {
    this.agreements.splice(index, 1);
    if (!this.agreements.length) this.addNewEmptyAgreement();
  }

  async create() {
    try {
      this.isCreateLoading = true;

      const oldLength = this.value.agreements.length;
      const agreements = [...this.value.agreements, ...this.agreements];
      const newLength = agreements.length;

      await this.value.updatePartial({ agreements });

      if (this.$route.name !== "HandoverAgreementsTableView") {
        this.$router.push({
          name: "HandoverAgreementsTableView",
          params: {
            partnerId: this.value.partnerId,
            handoverId: this.value.id
          },
          query: {
            agreementId: (this.value.agreements.length - 1).toString()
          }
        });
      }

      await new ActivityLog({
        partnerId: this.value.partnerId,
        target: Array.from({ length: newLength - oldLength }, () => ({
          refType: (ResourceEnum.AGREEMENT as unknown) as BackendResourceEnum,
          refId: this.value.id
        })),
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.MANAGE_TARGETS_ON_SOURCE,
        source: { refId: this.value.id, refType: BackendResourceEnum.HANDOVER }
      })
        .create()
        .catch(this.$log.error);
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateLoading = false;
      this.isDialogActive = false;
    }
  }
}
