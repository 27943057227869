var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("m-detail-table-formable", {
    ref: "table",
    attrs: {
      title: _vm.$t("about"),
      item: _vm.value,
      formable: _vm.formable,
      outlined: true,
      omit: [
        "partnerId",
        ".lastModified",
        "values",
        "groupId",
        "title",
        "description",
        "number",
        "documentIds",
        "refs"
      ],
      showCustomFields: false,
      onDetail: _vm.onDetail
    },
    scopedSlots: _vm._u([
      {
        key: "item.startDateFormable",
        fn: function fn(_ref) {
          var value = _ref.value
          return [
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(
                        _vm.simpleDoubleDigitDate(value)
                      )
                    }.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm._f("simpleDoubleDigitDate")(value)) + " "
                )
              ]
            ),
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(
                        _vm.simpleDoubleDigitDate(value)
                      )
                    }.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      icon: "",
                      small: ""
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: ""
                        }
                      },
                      [_vm._v("mdi-content-copy")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "item.endDateFormable",
        fn: function fn(_ref2) {
          var value = _ref2.value
          return [
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(
                        _vm.simpleDoubleDigitDate(value)
                      )
                    }.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm._f("simpleDoubleDigitDate")(value)) + " "
                )
              ]
            ),
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(
                        _vm.simpleDoubleDigitDate(value)
                      )
                    }.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      icon: "",
                      small: ""
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: ""
                        }
                      },
                      [_vm._v("mdi-content-copy")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "item.providerIds",
        fn: function fn(_ref3) {
          var value = _ref3.value,
            item = _ref3.item
          return [
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(value)
                    }.apply(null, arguments)
                  }
                }
              },
              [
                _c("refs-selected", {
                  attrs: {
                    value: value,
                    confirmable: false,
                    draggable: false,
                    removable: false,
                    small: true,
                    partnerId: item.partnerId,
                    outlined: false
                  }
                })
              ],
              1
            ),
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(
                        value
                          .map(function(v) {
                            return v.refId
                          })
                          .join(", ")
                      )
                    }.apply(null, arguments)
                  }
                }
              },
              [
                value && value.length
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          small: ""
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              small: ""
                            }
                          },
                          [_vm._v("mdi-content-copy")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      },
      {
        key: "item.clientIds",
        fn: function fn(_ref4) {
          var value = _ref4.value,
            item = _ref4.item
          return [
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(value)
                    }.apply(null, arguments)
                  }
                }
              },
              [
                _c("refs-selected", {
                  attrs: {
                    value: value,
                    confirmable: false,
                    draggable: false,
                    removable: false,
                    small: true,
                    partnerId: item.partnerId,
                    outlined: false
                  }
                })
              ],
              1
            ),
            _c(
              "td",
              {
                on: {
                  click: function click($event) {
                    $event.stopPropagation()
                    return function() {
                      return _vm.copyToClipboard(
                        value
                          .map(function(v) {
                            return v.refId
                          })
                          .join(", ")
                      )
                    }.apply(null, arguments)
                  }
                }
              },
              [
                value && value.length
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          small: ""
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              small: ""
                            }
                          },
                          [_vm._v("mdi-content-copy")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }