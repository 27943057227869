



























import Card from "@/components/utility/Card.vue";
import ExcelImportUpdate from "@/components/utility/ExcelImportUpdate.vue";
import { UpdateVehicleDto } from "@/models/update-vehicle.dto";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import { FeatureModule } from "@/store/modules/feature.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import ExcelImport from "../utility/ExcelImport.vue";
import ExcelImportCreate from "../utility/ExcelImportCreate.vue";

@Component({
  components: { Card, ExcelImport, ExcelImportUpdate, ExcelImportCreate }
})
export default class FleetVehicleImportDialog extends Vue {
  readonly updateDto = UpdateVehicleDto;
  readonly vehicleDto = Vehicle;

  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  @Prop()
  partnerId!: string;

  get isCsvExport() {
    return FeatureModule.isCsvExport;
  }

  onUpdated(result: IVehicle) {
    VehicleAccessLayer.set(result);
  }
}
