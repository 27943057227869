import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PaginationFilterListElement } from "./base-pagination.store";
import { IPartnerBanking, PartnerBanking } from "@/models/partner-banking.entity";
import { PartnerBankingPageDataProvider } from "./page-data-provider/partner-banking.page-data-provider";
import { PartnerBankingDataAccessLayer } from "./access-layers/partner-banking.access-layer";
import { MrfiktivPartnerBankingControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "partner-banking",
  store
})
export class PartnerBankingPaginationStore extends PaginatedBaseStore<
  IPartnerBanking,
  MrfiktivPartnerBankingControllerGetParamsGen
> {
  protected _data = PartnerBankingDataAccessLayer;
  protected _pageProvider = PartnerBankingPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = PartnerBanking.filterables;
}

export const PartnerBankingModule = getModule(PartnerBankingPaginationStore);
