










































































































































































import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import RefsContract from "@/components/utility/RefsContract.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { ContractTypeEnum } from "@/lib/enum/contract-type.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ContractGoToHelper, ContractRouteNames } from "@/lib/utility/contract.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Address } from "@/models/address.entity";
import { Contract, IContract } from "@/models/contract.entity";
import { CustomFieldValue } from "@/models/custom-field-value.entity";
import { Group, IGroup } from "@/models/group.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { GroupModule } from "@/store/modules/group.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { debounce } from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import CostCardDocuments from "../cost/CostCardDocuments.vue";
import GroupTypeCreateDialog from "../group/GroupTypeCreateDialog.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import RefsSelect from "../utility/RefsSelect.vue";
import SelectAssignees from "../utility/SelectAssignees.vue";
import ContractDetailTable from "./ContractDetailTable.vue";
import HasDescription from "@/components/utility/HasDescription.vue";

enum ContractTabEnum {
  ACTIVITIES = "activities",
  REFERENCES = "references",
  ATTACHMENTS = "attachments"
}

@Component({
  components: {
    HasDescription,
    MHeader,
    MDetailViewGrid,
    ActivityCard: () => import("@/components/thg/ActivityCard.vue"),
    RefsContract,
    CustomFieldListForm,
    MActionList,
    ConfirmActionDialog,
    GroupTypeCreateDialog,
    ContractDetailTable,
    RefsSelect,
    CostCardDocuments,
    LatestEntriesCardEmpty,
    SelectAssignees
  },
  filters: {
    getFlagEmoji
  }
})
export default class ContractDetail extends mixins(PermissionMixin) {
  readonly ContractTypeEnum = ContractTypeEnum;
  readonly Contract = Contract;
  readonly Address = Address;
  readonly CountryCodeEnum = CountryCodeEnum;
  readonly formable = Contract;
  readonly ContractTabEnum = ContractTabEnum;
  readonly DefaultTab = 0;

  @Prop()
  value!: IContract;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  tab = 0;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  group: IGroup | null = null;

  isLoadingGroup = false;

  isLoadingGroups = false;

  isLoadingCustomFieldValues = false;

  isLoadingDescription = false;

  isEditDescription = false;

  descriptionCopy = this.value.description ?? "";

  isLoadingRefs = false;

  isLoadingDocumentIds = false;

  get refsCategories(): BackendResourceEnum[] {
    return [BackendResourceEnum.FLEET, BackendResourceEnum.COST];
  }

  get showMoreAction(): IAction {
    return {
      text: $t("common.nouns.detail"),
      key: "detail",
      description: $t("common.nouns.detail"),
      exec: () =>
        new ContractGoToHelper(this.$router).goToContractDetailForm({
          partnerId: this.value.partnerId,
          contractId: this.value.id,
          newTab: false
        }),
      disabled: !this.can(ActionEnum.UPDATE, ResourceEnum.CONTRACT)
    };
  }

  get subtitle() {
    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const breadCrumbs = ContractGoToHelper.breadCrumbs;

    const partnerId = this.value.partnerId;
    const contractId = this.value.id;

    const selected = [];

    selected.push(breadCrumbs.ContractTable({ partnerId }));
    selected.push(breadCrumbs.ContractDetail({ partnerId, contractId }));

    return selected;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).detail()
      });
    }

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).detail()
      });
    }

    const groupId = this.value.groupId;
    if (groupId) {
      actions.push({
        text: $t("contract.toGroup"),
        key: "toGroup",
        icon: "mdi-open-in-new",
        color: "",
        exec: () => this.value.goTo(this.$router).customView()
      });
    }

    if (this.can(ActionEnum.DELETE, BackendResourceEnum.CONTRACT)) {
      actions.push({
        text: $t("delete"),
        key: "title",
        icon: "mdi-trash-can",
        color: "red",
        exec: this.onDeleteItem
      });
    }

    return actions;
  }

  get groups() {
    return GroupModule.filteredAndSorted;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.CONTRACT
    };
  }

  debounceSaveCustomFields = debounce(this.saveCustomFieldValues, 1000);

  mounted() {
    this.setGroup();

    if (this.$route.query.tab) {
      this.tab = Number(this.$route.query.tab as any);
    }
  }

  @Watch("tab")
  onTabChange() {
    if (this.$route.name === ContractRouteNames.CONTRACT_DETAIL) {
      new GoToHelper(this.$router).setUrl({
        ...ContractGoToHelper.locations.contractDetail({
          partnerId: this.value.partnerId,
          contractId: this.value.id,
          query: { tab: `${this.tab ?? this.DefaultTab}` }
        })
      });
    }
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  goToGroupCustomView() {
    if (!this.value.groupId) return;

    this.value.goTo(this.$router).customView();
  }

  @Watch("value.groupId")
  async setGroup() {
    this.group = null;

    const groupId = this.value.groupId;
    if (groupId) {
      this.isLoadingGroup = true;
      this.isLoadingCustomFieldValues = true;

      const group =
        GroupModule.maps.id.get(groupId)[0] ||
        (await new Group({ partnerId: this.partner.id, id: groupId }).fetch().catch(handleError));
      this.isLoadingGroup = false;
      this.isLoadingCustomFieldValues = false;

      this.$nextTick(() => {
        this.group = group;
      });
    }
  }

  async saveRefs() {
    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: this.value.refs
      })
      .catch(handleError);
    this.isLoadingRefs = false;
  }

  async saveDocumentIds() {
    this.isLoadingDocumentIds = true;
    await this.value
      .updatePartial({
        documentIds: Array.from(new Set(this.value.documentIds))
      })
      .catch(handleError);
    this.isLoadingDocumentIds = false;
  }

  async saveGroup() {
    this.isLoadingGroup = true;
    await this.value
      .updatePartial({
        groupId: this.value.groupId
      })
      .catch(handleError);
    this.isLoadingGroup = false;
  }

  async saveCustomFieldValues() {
    this.isLoadingCustomFieldValues = true;
    await this.value
      .updatePartial({
        values: CustomFieldValue.buildCustomFieldValuesDto(
          this.value.values.map(v => ({ id: v.id, value: v.value, timezone: v.timezone }))
        )
      })
      .catch(handleError);
    this.isLoadingCustomFieldValues = false;
  }

  startEditDescription() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        description: this.descriptionCopy
      })
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }

  async refreshGroups() {
    this.isLoadingGroups = true;
    try {
      GroupModule.setFilter([]);
      await GroupModule.fetchAll({ partnerId: PartnerModule.partner.id });
    } catch (e) {
      handleError(e);
    }
    this.isLoadingGroups = false;
  }

  goToContractDetailForm() {
    new ContractGoToHelper(this.$router).goToContractDetailForm({
      partnerId: this.value.partnerId,
      contractId: this.value.id,
      newTab: false
    });
  }

  openAddReference() {
    (this.$refs.refsSelect as RefsSelect)?.open();
  }

  async onAssigneesUpdate(assignees: string[]) {
    if (!this.value) return;

    await this.value.updatePartial({ assignees });
  }

  async onAssigneesAdded(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, assignees);
  }

  async onAssigneesRemoved(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, assignees);
  }
}
