










































































































import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IProperty } from "@/components/utility/mmmint/MHeader.vue";
import MImageMarker, { IMarker } from "@/components/utility/mmmint/MImageMarker.vue";
import MNavigationList from "@/components/utility/mmmint/MNavigationList.vue";
import RefsDebugger from "@/components/utility/RefsDebugger.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    MHeader: MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MImageMarker,
    MNavigationList,
    RefsDebugger
  }
})
export default class DesignGuidelinesHeaderExample extends PartnerFallbackMixin {
  foundPartner = false;
  activeTab = 0;
  activated = false;

  title2 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis blandit ligula, vitae volutpat dui imperdiet eu. Donec neque quam, dignissim non mi sit amet, tincidunt efficitur lorem. Ut in tempor elit, et fermentum erat. Nullam commodo ipsum a faucibus efficitur. Nullam porttitor vulputate libero, at rutrum est consequat et. Phasellus pretium tellus eget velit rutrum consectetur. Nulla sem felis, tristique ut molestie quis, rhoncus quis turpis. Sed tincidunt mauris a leo luctus auctor. Nunc sollicitudin semper elit, ut molestie nulla ullamcorper nec. Vivamus et arcu eget nisi dictum ultricies fermentum a arcu. Curabitur ornare sagittis iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed ut arcu at metus venenatis tincidunt. Mauris sodales ex consectetur, vestibulum magna id, lacinia erat. Etiam ornare, quam ac accumsan scelerisque, urna ipsum elementum ex, et feugiat mauris sem in orci. Nullam fringilla ipsum neque, condimentum egestas mauris molestie in.";
  title = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis blandit ligula, vitae volutpa";
  subtitle =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis blandit ligula, vitae volutpat dui imperdiet eu. Donec neque quam, dignissim non mi sit amet, tincidunt efficitur lorem. Ut in tempor elit, et fermentum erat. Nullam commodo ipsum a faucibus efficitur. Nullam porttitor vulputate libero, at rutrum est consequat et. Phasellus pretium tellus eget velit rutrum consectetur. Nulla sem felis, tristique ut molestie quis, rhoncus quis turpis. Sed tincidunt mauris a leo luctus auctor. Nunc sollicitudin semper elit, ut molestie nulla ullamcorper nec. Vivamus et arcu eget nisi dictum ultricies fermentum a arcu. Curabitur ornare sagittis iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed ut arcu at metus venenatis tincidunt. Mauris sodales ex consectetur, vestibulum magna id, lacinia erat. Etiam ornare, quam ac accumsan scelerisque, urna ipsum elementum ex, et feugiat mauris sem in orci. Nullam fringilla ipsum neque, condimentum egestas mauris molestie in.";

  myAlerts = [
    {
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis blandit ligula, vitae volutpat dui imperdiet eu. Donec neque quam, dignissim non mi sit amet, tincidunt efficitur lorem. Ut in tempor elit, et fermentum erat. Nullam commodo ipsum a faucibus efficitur. Nullam porttitor vulputate libero, at rutrum est consequat et. Phasellus pretium tellus eget velit rutrum consectetur. Nulla sem felis, tristique ut molestie quis, rhoncus quis turpis. Sed tincidunt mauris a leo luctus auctor. Nunc sollicitudin semper elit, ut molestie nulla ullamcorper nec. Vivamus et arcu eget nisi dictum ultricies fermentum a arcu. Curabitur ornare sagittis iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed ut arcu at metus venenatis tincidunt. Mauris sodales ex consectetur, vestibulum magna id, lacinia erat. Etiam ornare, quam ac accumsan scelerisque, urna ipsum elementum ex, et feugiat mauris sem in orci. Nullam fringilla ipsum neque, condimentum egestas mauris molestie in.",
      type: "error",
      action: {
        text: "I Agree",
        key: "agree"
      }
    }
  ];

  imageMarkerSrc = "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg";

  // Image options for selection
  imageOptions = [
    {
      imageId: 1,
      text: "Image 1",
      src: "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg"
    },
    {
      imageId: 2,
      text: "Image 2",
      src: "https://www.schadensmeldung.digital/images/features/schadenmanagement/individueller-funnel.webp"
    }
  ];

  get tabs(): IAction[] {
    return [
      { text: "Start", key: "start", icon: "mdi-table" },
      { text: "View 1", key: "view1", icon: "mdi-view-column-outline" },
      { text: "View 2", key: "view2", icon: "mdi-calendar" }
    ];
  }

  markers: IMarker[] = [
    { top: 0.1, left: 0.2, color: "#ff0000", text: "1" }, // Red marker
    { top: 0.5, left: 0.5, color: "#00ff00", text: "2" }, // Green marker
    { top: 0.8, left: 0.3 } // Default color marker
  ];

  markerSize = "medium";

  addMarker(marker: IMarker) {
    marker.text = (this.markers.length + 1).toString();
    this.markers.push(marker);
  }

  get properties(): IProperty[] {
    return [
      {
        text: "Anzeigename",
        value: "TODO BUILD THIS as Cmponent",
        key: "name"
      },
      {
        text: "Kennzeichen",
        value: "TO DO IMPLEMENT SLOT",
        key: "numberplate"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Customfield 1",
        value: "Hello",
        key: "OS-Peter"
      },
      {
        text: "Customfield 2",
        value: "Peter",
        key: "OS-Peter"
      }
    ];
  }

  get alerts() {
    return this.myAlerts;
  }

  get otherAlerts() {
    return [
      {
        text: "Only use chips if you have important kpi's or key information like ticket status",
        type: "info"
      },
      {
        text: "If you use subtitle and chips, try only use few chips, best only one",
        type: "warning"
      },
      {
        text: "If you use multiple chips, try to provide information that has the same importance, e.g ",
        type: "warning"
      },
      {
        text: "Only use alerts directly in the header if you not use tabs (since you dont want to shift tabs)",
        type: "warning"
      }
    ];
  }

  get chips() {
    return [
      {
        text: "5 Vehicles",
        key: "primaryAction",
        icon: "mdi-car",
        disabled: true
      },
      {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam venenatis blandit ligula, vitae volutpa",
        key: "primaryAction",
        icon: "mdi-car",
        disabled: true
      }
    ];
  }

  testItem = {
    title: "Hello world",
    status: "new",
    dates: {
      today: new Date().toISOString()
    },
    type: "Car"
  };

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "title",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: "new", displayValue: "Neuer Lead", color: "warning" },
          { value: "customer", displayValue: "Kunde", color: "success" }
        ]
      },
      {
        key: "dates.today",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "657095c1504da003902cba49"
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "656f1929504da003902b0245"
      },
      {
        key: "status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "dates.today",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "657095c1504da003902cba49"
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "656f1929504da003902b0245"
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "656f1914504da003902b023b"
      },
      {
        key: "type",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP
      }
    ];
  }

  get customFieldsForm() {
    return [];
  }

  get customFieldValuesTable() {
    return [
      {
        value: "lead",
        id: "657095c1504da003902cba49"
      },
      {
        value: new Date().toISOString(),
        id: "656f1929504da003902b0245"
      },
      {
        value: "Hey this is cool!",
        id: "656f1914504da003902b023b"
      }
    ];
  }

  get tableShowMoreAction() {
    return {
      text: $t("designGuide.MDetailForm.allProps"),
      key: "showDetails"
    };
  }

  get actions() {
    return [
      {
        text: "In neuem Tab öffnen",
        key: "primaryAction",
        icon: "mdi-cog-outline",
        color: "warning",
        count: 5
      },
      {
        text: "Bearbeiten",
        key: "secondaryAction",
        icon: "mdi-cog-outline",
        color: "success",
        count: 143
      },
      {
        text: "Details anzeigen",
        key: "showDetails",
        icon: "mdi-code-tags",
        color: "info"
      },
      {
        text: "Fourth Action",
        key: "fourthAction",
        icon: "mdi-delete-outline",
        color: "red",
        count: 66
      }
    ];
  }

  get breadCrumbs() {
    return [
      {
        text: "Design Guidelines",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelines",
          params: {}
        }
      },
      {
        text: "Example with chip",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelinesHeaderExample",
          params: {}
        }
      }
    ];
  }

  goToDetail() {
    this.$router.push({ name: "DesignGuidelinesObjectDetailExample" });
  }

  processAlerts(action: IAction) {
    if (action.key === "agree") {
      this.myAlerts = [];
    }
  }

  processAction(action: IAction) {
    switch (action.key) {
      case "showDetails":
        this.goToDetail();
    }
    this.$toast("Action clicked: " + action.text);
  }

  get isMobile() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return true;
      case "sm":
        return true;
      case "md":
        return false;
      case "lg":
        return false;
      case "xl":
        return false;
      default:
        return false;
    }
  }
}
