/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { GeoEntity } from "@/models/geoEntity";

/**
 * Parses the geo code from a google maps URL
 */
export function parseGeoCodeFromGoogleMapsUrl(url: string): GeoEntity | null {
  const regex = /@([-+]?\d*\.\d+),([-+]?\d*\.\d+)/; // "@" followed by a pair of decimal numbers that can be positive or negative
  const match = url.match(regex);

  if (match && match.length >= 3) {
    return {
      lat: parseFloat(match[1]),
      lng: parseFloat(match[2])
    };
  }

  return null;
}
