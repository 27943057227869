import { GroupTypeComponentMap } from "./group-type-components.map";
import { IGroupTypeEntitySideCard } from "./interface/group-type-entity-side-card.interface";

export const GroupTypeSideCards = (() => {
  let sideCards: Record<string, IGroupTypeEntitySideCard> = {};

  Array.from(GroupTypeComponentMap.keys()).forEach(value => {
    const sideCard = GroupTypeComponentMap.get(value)?.sideCard;
    if (!sideCard) {
      return;
    }

    sideCards = {
      [`${value}-side-card`]: sideCard,
      ...sideCards
    };
  });

  return sideCards;
})();
