var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "Can",
        {
          attrs: {
            I: _vm.ActionEnum.CREATE,
            a: _vm.ResourceEnum.HANDOVER
          }
        },
        [
          _vm._t("activator", null, {
            on: _vm.open
          })
        ],
        2
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialogModel,
            absolute: true,
            rightText: _vm.$t("objects.handover.tour.start"),
            rightDisabled: !_vm.selectedBlueprint,
            hideLeft: true,
            title: _vm.$t("objects.handover.tour.title"),
            width: "1200px"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            close: _vm.close,
            rightClick: _vm.rightClick
          }
        },
        [
          _c("v-card-text", [
            _vm._v(" " + _vm._s(_vm.$t("objects.handover.tour.hint")) + " ")
          ]),
          _c("v-select", {
            attrs: {
              items: _vm.blueprints,
              "item-text": "text",
              "item-value": "id",
              "return-object": "",
              outlined: ""
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(_vm._s(item.description))
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(_vm._s(item.type))
                                ]),
                                item.location
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(_vm._s(item.location.join(", ")))
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "v-list-item",
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(_vm._s(item.description))
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(_vm._s(item.type))
                                ]),
                                item.location
                                  ? _c("v-list-item-subtitle", [
                                      _vm._v(_vm._s(item.location.join(", ")))
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedBlueprint,
              callback: function callback($$v) {
                _vm.selectedBlueprint = $$v
              },
              expression: "selectedBlueprint"
            }
          }),
          _vm.selectedBlueprint
            ? _c("m-image-marker", {
                key: _vm.mykeey,
                ref: "imageMarkerTourDialog",
                staticClass: "mx-4",
                attrs: {
                  src: _vm.bluePrintImageUrl,
                  markers: _vm.markersSorted,
                  deactivated: true,
                  markerSize: "x-small"
                },
                on: {
                  markerClicked: _vm.markerClicked
                }
              })
            : _vm._e(),
          _c("v-card-text", [
            _vm._v(" Von wo nach wo besichtigen Sie das Fahrzeug? ")
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn-toggle",
                {
                  staticClass: "mx-2",
                  attrs: {
                    dark: "",
                    mandatory: "",
                    color: "info"
                  },
                  model: {
                    value: _vm.direction,
                    callback: function callback($$v) {
                      _vm.direction = $$v
                    },
                    expression: "direction"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        value: _vm.TourDirectionEnum.LeftToRight
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        value: _vm.TourDirectionEnum.TopToBottom
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-down")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        value: _vm.TourDirectionEnum.RightToLeft
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        value: _vm.TourDirectionEnum.BottomToTop
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }