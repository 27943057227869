var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        attrs: {
          store: _vm.store,
          headers: _vm.headers,
          baseQuery: {
            partnerId: _vm.partnerId
          },
          controlElements: _vm._controlElements,
          predefinedFilter: _vm.predefinedFilter,
          showSelect: false
        },
        on: {
          "click:row": _vm.setSelectedItem
        },
        scopedSlots: _vm._u(
          [
            {
              key: "bodyActions",
              fn: function fn() {
                return [
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.CREATE,
                        a: _vm.ResourceEnum.PARTNER_CLIENT_BANKING,
                        field: _vm.partnerId
                      }
                    },
                    [
                      _c("partner-banking-create-dialog", {
                        attrs: {
                          hideButton: false,
                          partnerId: _vm.partnerId
                        },
                        on: {
                          created: function created(v) {
                            _vm.$toast.success("👍")

                            _vm.setSelectedItem(v)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._t("bodyActions")
                ]
              },
              proxy: true
            },
            {
              key: "item.isSepaDirectDebitMandate",
              fn: function fn(_ref) {
                var value = _ref.value
                return [
                  value
                    ? _c(
                        "v-chip",
                        {
                          attrs: {
                            small: "",
                            color: "success"
                          }
                        },
                        [_vm._v(" ✔︎ ")]
                      )
                    : _c("div")
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.selectedItem
        ? _c("partner-banking-side-card", {
            attrs: {
              value: _vm.selectedItem
            },
            on: {
              close: function close() {
                return _vm.setSelectedItem(null)
              }
            }
          })
        : _vm._e(),
      _vm.isDeleteDialogActive && _vm.selectedItemForDelete
        ? _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isDeleteDialogActive,
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                title: _vm.$t(
                  "partner.PartnerReportDetailChangeProgress.confirmDeleteTitle"
                ),
                rightColor: "error",
                rightLoading: _vm.isDeleteLoading
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDeleteDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDeleteDialogActive = $event
                },
                close: function close($event) {
                  _vm.isDeleteDialogActive = false
                },
                leftClick: function leftClick($event) {
                  _vm.isDeleteDialogActive = false
                },
                rightClick: _vm.onDelete
              }
            },
            [
              _c("refs-partner-banking", {
                attrs: {
                  item: _vm.selectedItemForDelete
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }