var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list",
    _vm._l(_vm.inputs, function(data, index) {
      return _c(
        "v-list-item",
        {
          key: index,
          class: "d-flex px-0 ".concat(index > 0 ? "mt-4" : ""),
          on: {
            click: function click($event) {
              return _vm.click(data)
            }
          }
        },
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", {
                domProps: {
                  textContent: _vm._s(data.title)
                }
              }),
              _c("v-list-item-subtitle", {
                domProps: {
                  textContent: _vm._s(data.subtitle)
                }
              })
            ],
            1
          ),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  }
                },
                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }