
























import { handleError } from "@/lib/utility/handleError";
import { ICompanyGroup } from "@/models/company-group.entity";
import Vue from "vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../../cards/LatestEntriesCardEmpty.vue";
import SideCard from "../../utility/SideCard.vue";
import CompanyGroupDetail from "./CompanyGroupDetail.vue";

@Component({
  components: { SideCard, CompanyGroupDetail, LatestEntriesCardEmpty }
})
export default class CompanyGroupSideCard extends mixins(Vue) {
  @Prop()
  value!: ICompanyGroup;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  isLoading = false;

  async mounted() {
    try {
      this.isLoading = true;

      await this.value.fetch();
    } catch (error) {
      handleError(error);
      this.close();
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.$emit("close");
  }
}
