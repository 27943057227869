import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import { ProjectRouteNames } from "@/views/project/mixins/ProjectGoToMixin.vue";
import ProjectCustomView from "@/views/project/ProjectCustomView.vue";
import ProjectTableView from "@/views/project/ProjectTableView.vue";
import TicketDetailView from "@/views/project/TicketDetailView.vue";
import TicketTableView from "@/views/project/TicketTableView.vue";
import ProjectDetailView from "@/views/project/ProjectDetailView.vue";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";

/**
 * Routes for projects and tickets
 */
export const projectRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/project",
    component: ProjectTableView,
    name: ProjectRouteNames.PROJECT_TABLE,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.MGMT,
      icon: "mdi-check-circle-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/project/:projectId",
    component: ProjectCustomView,
    name: ProjectRouteNames.PROJECT_DETAIL,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/project/:projectId/settings",
    component: ProjectDetailView,
    name: ProjectRouteNames.PROJECT_SETTINGS,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/ticket/",
    component: TicketTableView,
    name: ProjectRouteNames.TICKET_TABLE,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.MGMT,
      icon: "mdi-ticket-outline",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/ticket/:ticketNumber",
    component: TicketDetailView,
    name: ProjectRouteNames.TICKET_DETAIL,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/project/:projectId/view/:viewId", //?ticketNumber=123
    name: ProjectRouteNames.PROJECT_CUSTOM_VIEW,
    component: ProjectCustomView,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/project/:projectId/view/:viewId/ticket/:ticketNumber",
    component: TicketDetailView,
    name: ProjectRouteNames.PROJECT_TICKET_DETAIL,
    permission: {
      resource: ResourceEnum.TICKET,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
