





















































































































































































import { Permission } from "@/components/partner/UserPermissionAddDialog.vue";
import UserPermissionForm from "@/components/partner/UserPermissionForm.vue";
import CustomFieldBoolean from "@/components/report/CustomFieldBoolean.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { getAutocompleteFilter } from "@/lib/utility/filter";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { IPartnerUser, PartnerUser } from "@/models/user.entity";
import { MrfiktivPermissionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";

enum INVITE_STEPS {
  START = "start",
  PERMISSIONS = "permissions"
}

@Component({
  components: { ConfirmActionDialog, CustomFieldBoolean, UserPermissionForm },
  filters: {}
})
export default class PartnerUserCreateDialog extends mixins(PermissionMixin, RulesMixin) implements IDialog {
  readonly INVITE_STEPS = INVITE_STEPS;
  readonly getAutocompleteFilter = getAutocompleteFilter;
  readonly CountryCodeEnum = CountryCodeEnum;

  @Prop()
  partnerId!: string;

  @Prop()
  hideButton!: string;

  permissionForResources: Permission[] = [];

  isDialogActive = false;

  step = INVITE_STEPS.START;

  isLoading = false;

  isValid = false;

  user = new PartnerUser({ partnerId: this.partnerId });

  get rightDisabled() {
    switch (this.step) {
      case INVITE_STEPS.START:
        return !this.isValid;
      case INVITE_STEPS.PERMISSIONS:
        return false;
      default:
        return false;
    }
  }

  open(): void {
    this.step = INVITE_STEPS.START;
    this.user = new PartnerUser({ partnerId: this.partnerId });
    this.isDialogActive = true;
  }

  setPermissions(user: IPartnerUser) {
    this.permissionForResources = Object.values(BackendResourceEnum).map(r => new Permission(r, this.partnerId, user));
  }

  async leftClick() {
    this.isLoading = true;
    switch (this.step) {
      case INVITE_STEPS.START:
        this.close();
        break;
      case INVITE_STEPS.PERMISSIONS:
        this.step = INVITE_STEPS.START;
        break;
    }
    this.isLoading = false;
  }

  async rightClick() {
    this.isLoading = true;
    try {
      switch (this.step) {
        case INVITE_STEPS.START: {
          await this.user.invite();
          this.setPermissions(this.user);
          this.step = INVITE_STEPS.PERMISSIONS;
          break;
        }
        case INVITE_STEPS.PERMISSIONS: {
          const permission: MrfiktivPermissionDtoGen[] = [];
          for (const resource of this.permissionForResources) {
            if (resource.hasAction()) {
              permission.push(resource.toDto());
            }
          }
          await this.user?.addPermissions(permission);
          this.close();

          break;
        }
      }
    } catch (e) {
      handleError(e);
    }
    this.isLoading = false;
  }

  close(): void {
    this.isDialogActive = false;
    if (this.user?.id) {
      this.$emit("created", this.user);
    }
  }
}
