


























































































































import { IEventUIDto, IRecurringEventTemplate } from "@/lib/dto/event/event-ui.dto";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import SelectEntity from "@/components/utility/SelectEntity.vue";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import CustomFieldBoolean from "@/components/report/CustomFieldBoolean.vue";

@Component({
  components: { Debug, SelectEntity, CustomFieldBoolean }
})
export default class EventCreateForm extends Vue {
  readonly ResourceEnum = ResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop()
  value!: IEventUIDto;

  @Prop({ default: false })
  isRecurringSwitchDisabled!: boolean;

  @Prop({ default: false })
  showVehicleSelection!: boolean;

  @Prop()
  vehicleId!: string;

  goToVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, true);
  }

  /**
   * is the dialog active to create a new damage?
   */
  isValid = false;

  startDayMenu = false;

  atDayMenu = false;

  get selectedVehicle() {
    return this.vehicleId;
  }

  set selectedVehicle(value: string) {
    this.$emit("update:vehicleId", value);
  }

  @Watch("isValid")
  emitIsValid() {
    this.$log.debug("isValid", this.isValid);
    this.$log.debug(this.value);
    this.$emit("isValid", this.isValid);
  }

  get debug() {
    return ConfigModule.debug;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get interval(): number[] {
    const N = 99;

    return Array.from({ length: N }, (v, i) => i + 1);
  }

  get vehicles() {
    return VehicleModule.paginationList;
  }

  updateDate() {
    this.atDayMenu = false;
  }

  updateEvent(item: IRecurringEventTemplate) {
    this.$log.debug("fromTemplate", item);

    this.value.summary = this.$t(item.title).toString();
    this.value.description = this.$t(item.description).toString();
    this.value.isRecurring = item.isRecurring;
    this.value.ruleOptions = item.options;
    this.value.isAllDay = item.isAllDay;
    this.value.type = item.enum;
  }
}
