var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        subtitle: _vm.companyAddress
      }
    },
    [
      _c("v-treeview", {
        attrs: {
          items: _vm.tree
        }
      }),
      _c("debug", {
        attrs: {
          debug: _vm.fleet
        }
      }),
      _c("debug", {
        attrs: {
          debug: _vm.company
        }
      }),
      _c("debug", {
        attrs: {
          debug: _vm.vehicles
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }