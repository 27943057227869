






import PersonImportCard from "@/components/person/PersonImportCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    PersonImportCard
  }
})
export default class PersonImportView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  get partnerId() {
    return this.$route.params.partnerId;
  }
}
