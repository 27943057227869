












































































import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { vinOptionalRuleLength } from "@/lib/rules/vinRule";
import { calculateVinChecksum } from "@/lib/utility/calculateVinChecksum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivCreateVehicleDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";

enum STEPS {
  ADD = "ADD",
  SUCCESS = "SUCCESS"
}

@Component({
  components: {
    ConfirmActionDialog,
    RefsVehicle,
    NumberplateForm,
    Debug
  }
})
export default class CreateVehicleDialog extends mixins(Vue) {
  @Prop({ default: false })
  isDialogActive!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  groupId?: string;

  readonly STEPS = STEPS;

  currentStep = STEPS.ADD;

  loading = false;

  isFormValid = false;

  createVehicleDto: MrfiktivCreateVehicleDtoGen = {
    displayName: "",
    numberplate: "",
    identificationnumber: ""
  };

  uId = 0;

  created: IVehicle[] = [];

  get isTrain() {
    return PartnerModule.isTrain;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get vinRuleLength() {
    return [vinOptionalRuleLength()];
  }

  get debug() {
    return ConfigModule.debug;
  }

  get countryCode() {
    return PartnerModule?.partner?.countryCode ?? "DE";
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  get getChecksumText() {
    if (!this.createVehicleDto.identificationnumber || this.createVehicleDto.identificationnumber.length < 17) {
      return "";
    }

    return `${this.$t("common.nouns.checksum")}: ${calculateVinChecksum(
      this.createVehicleDto.identificationnumber || ""
    )}`;
  }

  async rightClick() {
    switch (this.currentStep) {
      case STEPS.ADD: {
        if (!this.loading) {
          await this.add();
        }
        break;
      }

      case STEPS.SUCCESS: {
        this.reset();
        break;
      }
    }
  }

  async add() {
    try {
      this.loading = true;

      if (!this.createVehicleDto.identificationnumber && !this.createVehicleDto.numberplate) {
        this.createVehicleDto.state = VehicleStateEnum.UPCOMING;
      }

      this.createVehicleDto.groupId = this.groupId;

      const created = await VehicleModule.create({ partnerId: this.partnerId, data: this.createVehicleDto });
      this.created.push(created);
      this.currentStep = STEPS.SUCCESS;
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.isFormValid = false;
    this.createVehicleDto = {
      displayName: "",
      numberplate: "",
      identificationnumber: ""
    };
    this.currentStep = STEPS.ADD;
    this.uId = Math.random();
  }

  close() {
    this.dialogModel = false;
    this.created.splice(0);
    this.reset();
  }

  async goToVehicleDetail(vehicle: IVehicle) {
    await new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, true);
  }
}
