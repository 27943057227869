var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c(
        "v-row",
        {
          attrs: {
            dense: ""
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("objects.address.street"),
                  outlined: ""
                },
                model: {
                  value: _vm.value.street,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "street", $$v)
                  },
                  expression: "value.street"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "4"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("objects.address.zip"),
                  outlined: ""
                },
                model: {
                  value: _vm.value.zip,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "zip", $$v)
                  },
                  expression: "value.zip"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "8"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("objects.address.city"),
                  outlined: ""
                },
                model: {
                  value: _vm.value.city,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "city", $$v)
                  },
                  expression: "value.city"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("objects.address.state"),
                  outlined: ""
                },
                model: {
                  value: _vm.value.state,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "state", $$v)
                  },
                  expression: "value.state"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }