













































































import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import RefsReport from "../utility/RefsReport.vue";
import { IReport } from "@/models/report.entity";

@Component({
  components: { RefsReport, PartnerReportDetailSideCard }
})
export default class FleetHomeVehicleTableDamageList extends DarkModeHighlightMixin {
  @Prop()
  items!: IReport[];

  report: IReport | null = null;

  loading = false;

  isNewMenu = false;

  isInProgressMenu = false;

  isSideCard = false;

  get newItems() {
    return this.items.filter(v => v.progressStatus === ProgressStatusEnum.NEW);
  }

  get inProgressItems() {
    return this.items.filter(v => v.progressStatus === ProgressStatusEnum.IN_PROGRESS);
  }

  async setReport(report: IReport) {
    try {
      this.loading = true;
      this.isSideCard = true;

      const found = await PartnerModule.getReportByIdForPartner(report.id);

      this.report = found;
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
      this.isNewMenu = false;
      this.isInProgressMenu = false;
    }
  }
}
