












import Card from "@/components/utility/Card.vue";
import {
  booleanValidation,
  companyEnumListValidation,
  countrycodeValidation,
  emailValidation,
  phoneValidation
} from "@/lib/excelImportTransformation/transformers";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";
import { CompanyAddress } from "@/models/company-address.entity";
import { Company, ICompany } from "@/models/company.entity";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import ExcelImport from "../utility/ExcelImport.vue";
import ExcelImportReport from "../utility/ExcelImportReport.vue";

@Component({
  components: { Card, ExcelImport, ExcelImportReport }
})
export default class CompanyImportDialog extends Vue {
  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: false })
  small!: boolean;

  loading = false;
  success = false;
  importedItems: ICompany[] = [];
  failedImports: { companyName: string; error: string }[] = [];
  totalItems = 0;

  /**
   * company import config (as getter to have reactive translations)
   */
  get config(): { [key: string]: IExcelImportConfig } {
    return {
      companyName: {
        label: this.$t("components.CompanyImportDialog.companyNameLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.companyNameHint").toString(),
        required: true,
        import: true,
        originColumnNameInExcelFile: "Firmenname",
        transform: value => value.toString()
      },
      firstName: {
        label: this.$t("components.CompanyImportDialog.firstNameLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.firstNameHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Vorname",
        transform: value => value.toString()
      },
      lastName: {
        label: this.$t("components.CompanyImportDialog.lastNameLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.lastNameHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Nachname",
        transform: value => value.toString()
      },
      isFleet: {
        label: this.$t("components.CompanyImportDialog.isFleetLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.isFleetHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Flottenkunde",
        transform: value => booleanValidation(value)
      },
      zip: {
        label: this.$t("components.CompanyImportDialog.zipLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.zipHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Postleitzahl",
        transform: value => value.toString()
      },
      city: {
        label: this.$t("components.CompanyImportDialog.cityLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.cityHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Stadt",
        transform: value => value.toString()
      },
      street: {
        label: this.$t("components.CompanyImportDialog.streetLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.streetHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Straße",
        transform: value => value.toString()
      },
      countryCode: {
        label: this.$t("components.CompanyImportDialog.countryCodeLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.countryCodeHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Land",
        transform: value => countrycodeValidation(value)
      },
      state: {
        label: this.$t("components.CompanyImportDialog.stateLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.stateHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Bundesland",
        transform: value => value.toString()
      },
      mail: {
        label: this.$t("components.CompanyImportDialog.mailLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.mailHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "E-Mail",
        transform: value => emailValidation(value)
      },
      phone: {
        label: this.$t("components.CompanyImportDialog.phoneLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.phoneHint").toString(),
        required: false,
        import: true,
        originColumnNameInExcelFile: "Telefon",
        transform: value => phoneValidation(value)
      },
      services: {
        label: this.$t("components.CompanyImportDialog.servicesLabel").toString(),
        hint: this.$t("components.CompanyImportDialog.servicesHint").toString(),
        required: false,
        import: false,
        originColumnNameInExcelFile: "Services",
        transform: value => companyEnumListValidation(value)
      }
    };
  }

  async create(items: { data: any[]; headers: any[] }) {
    this.importedItems = [];
    this.failedImports = [];
    this.totalItems = items.data.length;
    this.loading = true;

    if (!PartnerModule.partner.id) {
      this.$toast.error(this.$t("components.CompanyImportDialog.partnerContextNotSet").toString());
      return;
    }

    for (const company of items.data) {
      try {
        const newCompany = new Company({
          ...company,
          partnerId: PartnerModule.partner.id,
          addresses: [
            new CompanyAddress({
              zip: company["zip"],
              city: company["city"],
              street: company["street"],
              countryCode: company["countryCode"],
              state: company["state"],
              isBilling: true
            })
          ]
        });

        if (company["phone"] || company["mail"]) {
          newCompany.contact = {
            phone: company["phone"],
            email: company["mail"]
          };
        }

        this.$log.debug("createCompany", newCompany);

        this.importedItems.push(await newCompany.create());
      } catch (error) {
        const errorMessage = (error as Error)?.message || "Undefined import error";
        this.failedImports.push({ companyName: company["companyName"], error: errorMessage });
      }
    }
    this.loading = false;
    this.success = true;
  }
}
