var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.isLoading
    ? _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                color: _vm.partnerColor.primary,
                vertical: !_vm.isMobile
              }
            },
            [
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-tune")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "views.booking.ServiceForm.tabs.requiredFieldsTitle"
                        )
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-clock")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "views.booking.ServiceForm.tabs.availabilityTitle"
                        )
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-format-color-fill")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("views.booking.ServiceForm.tabs.stylingTitle")
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start",
                  attrs: {
                    disabled: true
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-currency-usd")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("views.booking.ServiceForm.tabs.costTitle")
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-map-marker-outline")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("views.booking.ServiceForm.tabs.addressTitle")
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-help-circle-outline")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "views.booking.ServiceForm.tabs.customFieldsTitle"
                        )
                      ) +
                      " "
                  )
                ],
                1
              ),
              _vm.$can(_vm.ActionEnum.MANAGE, _vm.ResourceEnum.CUSTOM_FIELD)
                ? _c(
                    "v-tab",
                    {
                      staticClass: "justify-start"
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            left: ""
                          }
                        },
                        [_vm._v("mdi-focus-field-horizontal")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("views.CustomFieldList.customFields")) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-image-multiple-outline")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "views.booking.ServiceForm.tabs.imageConfigTitle"
                        )
                      ) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-tab",
                {
                  staticClass: "justify-start"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        left: ""
                      }
                    },
                    [_vm._v("mdi-email-outline")]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("common.nouns.contact")) + " ")
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        "align-content": "space-between"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "9"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label:
                                _vm.$t(
                                  "views.booking.ServiceForm.displayName"
                                ) + " *",
                              rules: _vm.requiredRule,
                              readonly: _vm.disabled,
                              hint: _vm.$t(
                                "views.booking.ServiceForm.displayNameHint"
                              ),
                              outlined: "",
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.value.name,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "name", $$v)
                              },
                              expression: "value.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "3"
                          }
                        },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: _vm.isMobile
                                ? ""
                                : _vm.$t(
                                    "views.booking.ServiceForm.switchLabel"
                                  ),
                              readonly: _vm.disabled,
                              inset: ""
                            },
                            model: {
                              value: _vm.value.isActive,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "isActive", $$v)
                              },
                              expression: "value.isActive"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              rules: _vm.requiredRule,
                              label:
                                _vm.$t(
                                  "views.booking.ServiceForm.description"
                                ) + " *",
                              readonly: _vm.disabled,
                              hint: _vm.$t(
                                "views.booking.ServiceForm.descriptionHint"
                              ),
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.value.description,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "description", $$v)
                              },
                              expression: "value.description"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              rules: _vm.requiredRule,
                              label:
                                _vm.$t("views.booking.ServiceForm.slug") + " *",
                              readonly: _vm.disabled,
                              hint: _vm.$t(
                                "views.booking.ServiceForm.slugHint"
                              ),
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.value.slug,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "slug", $$v)
                              },
                              expression: "value.slug"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              rules: _vm.timeFormatRule,
                              readonly: _vm.disabled,
                              type: "time",
                              label:
                                _vm.$t(
                                  "views.booking.ServiceForm.durationInSeconds"
                                ) + " *",
                              hint: _vm.$t(
                                "views.booking.ServiceForm.durationInSecondsHint"
                              ),
                              "persistent-hint": ""
                            },
                            model: {
                              value: _vm.value.durationInSecondsString,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.value,
                                  "durationInSecondsString",
                                  $$v
                                )
                              },
                              expression: "value.durationInSecondsString"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              rules: _vm.numberRule,
                              readonly: _vm.disabled,
                              type: "number",
                              label:
                                _vm.$t(
                                  "views.booking.ServiceForm.leadTimeInDays"
                                ) + " *",
                              hint: _vm.$t(
                                "views.booking.ServiceForm.leadTimeInDaysHint"
                              ),
                              "persistent-hint": "",
                              suffix: _vm.$t(
                                "components.thg.ThgQuoteMeterReadingWorkItemCard.days"
                              )
                            },
                            model: {
                              value: _vm.value.leadTimeInDays,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "leadTimeInDays", $$v)
                              },
                              expression: "value.leadTimeInDays"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm.resources &&
                          _vm.resources.length > 0 &&
                          _vm.serviceResourceIds
                            ? _c("resource-list", {
                                attrs: {
                                  allResources: _vm.resources,
                                  selectedResources: _vm.serviceResourceIds
                                },
                                on: {
                                  change: _vm.onResourcesChange
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.booking.ServiceForm.availabilityTitle"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.availabilityHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.availability && _vm.availability.length > 0
                            ? _c("availability-form", {
                                attrs: {
                                  availability: _vm.availability,
                                  isLoading: _vm.isLoading
                                },
                                on: {
                                  change: _vm.onAvailabilityChange
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("views.booking.ServiceForm.display")
                                  )
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.displayHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-list",
                            {
                              staticClass: "pl-0",
                              attrs: {
                                subheader: "",
                                "three-line": "",
                                flat: ""
                              }
                            },
                            [
                              _c("v-list-item", {
                                staticClass: "pl-0",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function fn() {
                                        return [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.booking.ServiceForm.isDisplayDuration"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c(
                                                "v-list-item-subtitle",
                                                {
                                                  staticClass:
                                                    "text-caption hint"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "views.booking.ServiceForm.isDisplayDurationHint"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  inset: "",
                                                  label: _vm.$t(
                                                    "components.booking.BookingCustomerFieldConfiguration.active"
                                                  ),
                                                  "persistent-hint": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.value.isDisplayDuration,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.$set(
                                                      _vm.value,
                                                      "isDisplayDuration",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "value.isDisplayDuration"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2683670631
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "5"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.booking.ServiceForm.colorSelection"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.colorSelectionHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-color-picker", {
                            attrs: {
                              "dot-size": "25",
                              "hide-mode-switch": "",
                              "hide-sliders": "",
                              mode: "hexa",
                              "show-swatches": "",
                              "swatches-max-height": "100",
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.value.color,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "color", $$v)
                              },
                              expression: "value.color"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "7"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.booking.ServiceForm.iconSelection"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.iconSelectionHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("icon-select", {
                            attrs: {
                              disabled: _vm.disabled,
                              icon: _vm.value.icon
                            },
                            on: {
                              "icon-select": _vm.onIconSelect
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12"
                          }
                        },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("common.nouns.image")))
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.imageUrlSelectionHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "10"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "text",
                                      label: _vm.$t(
                                        "views.booking.ServiceForm.imageUrlSelection"
                                      ),
                                      disabled: _vm.disabled,
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.value.imageUrl,
                                      callback: function callback($$v) {
                                        _vm.$set(_vm.value, "imageUrl", $$v)
                                      },
                                      expression: "value.imageUrl"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "2"
                                  }
                                },
                                [
                                  !_vm.disabled
                                    ? _c("public-image-upload-dialog", {
                                        attrs: {
                                          hideFolderSelect: true,
                                          folder: _vm.folderName
                                        },
                                        on: {
                                          onUpload: _vm.handleServiceImageUpload
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.booking.ServiceForm.costTitle"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.costTitleHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              disabled: _vm.disabled,
                              outlined: "",
                              label: _vm.$t(
                                "views.booking.ServiceForm.costLabel"
                              ),
                              hint: _vm.$t(
                                "views.booking.ServiceForm.costLabelHint"
                              )
                            },
                            model: {
                              value: _vm.value.cost,
                              callback: function callback($$v) {
                                _vm.$set(_vm.value, "cost", $$v)
                              },
                              expression: "value.cost"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass: "pl-1"
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.booking.ServiceForm.addressTitle"
                                    )
                                  )
                                )
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "text-caption hint"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.booking.ServiceForm.addressHint"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("address-form", {
                            attrs: {
                              address: _vm.address
                            },
                            on: {
                              save: _vm.onAddressUpdate
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pl-1"
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.booking.ServiceForm.customerFieldsTitle"
                            )
                          )
                        )
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "text-caption hint"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "views.booking.ServiceForm.customerFieldsHint"
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _c("booking-customer-field-configuration", {
                    attrs: {
                      customerFieldConfig: _vm.value.customerFieldConfig
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pl-1"
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("views.CustomFieldList.customFields"))
                        )
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "text-caption hint"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "views.booking.ServiceForm.customerFieldsHint"
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _c("custom-field-configuration-form", {
                    attrs: {
                      valid: _vm.isValid,
                      disabled: _vm.disabled
                    },
                    on: {
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      }
                    },
                    model: {
                      value: _vm.value.customFieldConfig,
                      callback: function callback($$v) {
                        _vm.$set(_vm.value, "customFieldConfig", $$v)
                      },
                      expression: "value.customFieldConfig"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pl-1"
                    },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.booking.ServiceForm.imageConfigurationTitle"
                            )
                          )
                        )
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "text-caption hint"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "views.booking.ServiceForm.imageConfigurationHint"
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _c("booking-image-configuration", {
                    attrs: {
                      isMobile: _vm.isMobile,
                      imageConfig: _vm.value.imageConfig
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  class: !_vm.isMobile ? _vm.tabsSpaceClasses : []
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "pl-1"
                    },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.$t("common.nouns.contact")))]),
                      _c(
                        "p",
                        {
                          staticClass: "text-caption hint"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "views.booking.ServiceForm.notificationEmailHint"
                              )
                            )
                          )
                        ]
                      )
                    ]
                  ),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t("common.nouns.email"),
                      hint: _vm.$t(
                        "views.booking.ServiceForm.notificationEmailHint"
                      ),
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.value.email,
                      callback: function callback($$v) {
                        _vm.$set(_vm.value, "email", $$v)
                      },
                      expression: "value.email"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }