





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IAction } from "./MHeader.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "./MDetailTable.vue";
import { IFormableClass } from "@/lib/formable";
import { ICustomFieldValue } from "@/models/custom-field-value.entity";
import { $t } from "@/lib/utility/t";
import { getNestedObjectValues } from "@/lib/objectPath-helper";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { isIsoDateString } from "@/lib/utility/date-helper";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";

@Component({ name: "MDetailTableFormable", components: { MDetailTable } })
export default class MDetailTableFormable<T extends { values?: ICustomFieldValue[] }> extends Vue {
  /**
   * Title of the table
   */
  @Prop()
  title?: string;

  /**
   * Description of the table
   */
  @Prop()
  description?: string;

  /**
   * Item which data should be displayed
   */
  @Prop()
  item!: T;

  @Prop()
  formable!: { new (value: T): T } & IFormableClass;

  /**
   * Subset of bits of keys that should not be part of the table
   * @example ['address'] will hide all table rows with keys including address. like company.address.zip
   */
  @Prop({ default: () => [] })
  omit!: string[];

  /**
   * list of exact, handpicked Keys that should be part of the table.
   * Precedence over omit
   */
  @Prop({ default: () => [] })
  include!: string[];

  /**
   * Max number of fields to display
   */
  @Prop()
  max?: number;

  /**
   * Max number of fields to display
   */
  @Prop({ default: true })
  showCustomFields?: boolean;

  @Prop()
  onDetail?: () => void;

  detailTableConfig: IMDetailTableConfig<T>[] = [];

  nested: { key: string; item: T; formable: IFormableClass; label: string }[] = [];

  get _showMoreAction(): IAction | undefined {
    if (!this.$listeners["click:detail"] && !this.onDetail) {
      return undefined;
    }

    return {
      text: $t("common.nouns.detail"),
      key: "detail",
      description: $t("common.nouns.detail"),
      exec: this.onDetail ? this.onDetail : () => this.$emit("click:detail")
    };
  }

  mounted() {
    this.generateFieldsFromObject();
  }

  private generateCustomFields(fieldsMap: Map<string, IMDetailTableConfig<any>>) {
    if (!Array.isArray(this.item?.values) || !this.item.values?.length) {
      return fieldsMap;
    }
    for (const customFieldValue of this.item.values) {
      if (!customFieldValue.id) {
        continue;
      }

      if (!customFieldValue.value) {
        continue;
      }

      let value = customFieldValue.value as string[];
      if (!Array.isArray(value)) {
        value = [value as string];
      }

      for (const v of value) {
        const asDate = new Date(v);
        if (!isNaN(asDate.valueOf()) && asDate.toISOString() === v) {
          fieldsMap.set(customFieldValue.id, {
            key: customFieldValue.id,
            type: DetailTableTypeEnum.CUSTOM,
            visualization: DetailTableVisualizationEnum.DATE
          });
          continue;
        }

        fieldsMap.set(customFieldValue.id, {
          key: customFieldValue.id,
          type: DetailTableTypeEnum.CUSTOM,
          visualization: DetailTableVisualizationEnum.TEXT
        });
      }
    }

    return fieldsMap;
  }

  @Watch("item", { deep: true })
  generateFieldsFromObject() {
    this.nested.splice(0);
    const object = this.item;
    let fieldsMap: Map<string, IMDetailTableConfig> = new Map();

    for (const formable of this.formable.formables) {
      const key = formable.key;
      const value = getNestedObjectValues(object, formable.key) as string | boolean;
      const valueIsDate = isIsoDateString(value as string);
      const valueIsBoolean = typeof value === "boolean";
      const items: (IVSelectItem | string)[] = formable?.props?.items || formable?.props?.itemCallback?.() || [];
      const valueHasOptions = !!items.length;

      if (!formable) {
        continue;
      }

      if (!value) {
        continue;
      }

      if (
        formable.type === DetailFormComponentsEnum.CUSTOM_FIELD ||
        formable.type === DetailFormComponentsEnum.CUSTOM_FIELD_DATE_TIME
      ) {
        continue;
      }

      if (
        formable.isArray &&
        Array.isArray(value) &&
        (!this.include.length || this.include?.find(i => key.includes(i))) &&
        !this.omit?.find(o => key.includes(o))
      ) {
        for (const nestedObject of getNestedObjectValues(object, formable.key) as T[]) {
          this.nested.push({
            key: key,
            item: nestedObject,
            formable: formable.props.form,
            label: formable.props?.label ?? key
          });
        }

        continue;
      }

      if (valueIsDate) {
        fieldsMap.set(key, {
          label: formable.props?.label,
          key: key,
          type: DetailTableTypeEnum.PROPERTY,
          visualization: DetailTableVisualizationEnum.DATE
        });

        continue;
      }

      if (valueIsBoolean) {
        fieldsMap.set(key, {
          label: formable.props?.label,
          key: key,
          type: DetailTableTypeEnum.PROPERTY,
          visualization: DetailTableVisualizationEnum.CHIP,
          displayConfig: [
            {
              value: true,
              displayValue: $t("yes"),
              color: "success"
            },

            {
              value: false,
              displayValue: $t("no"),
              color: "warning"
            }
          ]
        });

        continue;
      }

      if (valueHasOptions) {
        fieldsMap.set(key, {
          key: key,
          type: DetailTableTypeEnum.PROPERTY,
          visualization: DetailTableVisualizationEnum.CHIP,
          label: formable.props?.label,
          displayConfig: (() =>
            items.map(i => {
              if ((i as IVSelectItem).text && (i as IVSelectItem).value) {
                return {
                  value: (i as IVSelectItem).value as string,
                  displayValue: $t((i as IVSelectItem).text),
                  color: "info"
                };
              }

              return {
                value: i as string,
                displayValue: i as string,
                color: "info"
              };
            }) ?? [])()
        });

        continue;
      }

      fieldsMap.set(key, {
        label: formable.props?.label,
        key: key,
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      });
    }

    if (this.showCustomFields && object.values) {
      fieldsMap = this.generateCustomFields(fieldsMap);
    }

    let fields = Array.from(fieldsMap.values());
    if (this.include.length) {
      fields = fields.filter(f => this.include?.find(i => f.key.includes(i)));
      this.detailTableConfig.splice(0);
      this.detailTableConfig.push(...fields);

      return;
    }

    if (this.omit.length) {
      fields = fields.filter(f => !this.omit?.find(o => f.key.includes(o)));
    }
    if (this.max) {
      fields = fields.slice(0, this.max);
    }

    this.detailTableConfig.splice(0);
    this.$nextTick(() => {
      this.detailTableConfig.splice(0);
      this.detailTableConfig.push(...fields);
    });
  }
}
