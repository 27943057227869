import { PartnerBankingRouteNames } from "@/lib/utility/partner-banking.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import PartnerBankingDetailFormView from "@/views/partner-banking/PartnerBankingDetailFormView.vue";
import PartnerBankingDetailView from "@/views/partner-banking/PartnerBankingDetailView.vue";
import PartnerBankingTableView from "@/views/partner-banking/PartnerBankingTableView.vue";

/**
 * Routes for partnerBankings
 */
export const partnerBankingRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/banking/",
    component: PartnerBankingTableView,
    name: PartnerBankingRouteNames.PARTNER_BANKING_TABLE,
    permission: {
      resource: ResourceEnum.PARTNER_CLIENT_BANKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.CRM,
      icon: "mdi-credit-card-multiple",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/banking/:partnerBankingId",
    component: PartnerBankingDetailView,
    name: PartnerBankingRouteNames.PARTNER_BANKING_DETAIL,
    permission: {
      resource: ResourceEnum.PARTNER_CLIENT_BANKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/banking/:partnerBankingId/form",
    component: PartnerBankingDetailFormView,
    name: PartnerBankingRouteNames.PARTNER_BANKING_DETAIL_FORM,
    permission: {
      resource: ResourceEnum.PARTNER_CLIENT_BANKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
