var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            ],
            1
          )
        : _vm.partner
        ? _c(
            "v-container",
            [
              _c("m-header", {
                attrs: {
                  title: _vm.title
                },
                on: {
                  actionClicked: function actionClicked($event) {
                    return $event.exec()
                  }
                }
              }),
              _c("paginated-table", {
                attrs: {
                  store: _vm.store,
                  headers: _vm.headers,
                  baseQuery: {
                    partnerId: _vm.partnerId
                  },
                  sortBy: "object.attributes.updated_at",
                  groupBy: _vm.groupBy ? "data.dossier.id" : undefined
                },
                on: {
                  "click:row": function clickRow($e) {
                    return (_vm.selectedItem = $e)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "bodyActions",
                    fn: function fn() {
                      return [
                        _c("v-checkbox", {
                          attrs: {
                            label: "by Dossier"
                          },
                          model: {
                            value: _vm.groupBy,
                            callback: function callback($$v) {
                              _vm.groupBy = $$v
                            },
                            expression: "groupBy"
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.event",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              small: "",
                              outlined: ""
                            }
                          },
                          [_vm._v(_vm._s(item.event))]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.data.dossier.state",
                    fn: function fn(_ref2) {
                      var item = _ref2.item
                      return [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              small: "",
                              outlined: ""
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.stateName(item)) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.data.dossier.internalState",
                    fn: function fn(_ref3) {
                      var item = _ref3.item
                      return [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              small: "",
                              outlined: ""
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.internalStateName(item)) + " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        : _vm._e(),
      _vm.selectedItem
        ? _c(
            "side-card",
            {
              attrs: {
                width: "850px"
              },
              on: {
                close: function close($event) {
                  _vm.selectedItem = null
                }
              }
            },
            [
              _c("m-detail-table", {
                attrs: {
                  title: "Webhook",
                  item: _vm.selectedItem,
                  config: _vm.detailTableConfig
                }
              }),
              _c("debug", [_vm._v(" " + _vm._s(_vm.selectedItem))])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }