var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "pa-4",
      attrs: {
        "max-width": "380px",
        elevation: "1"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row mx-4",
          staticStyle: {
            display: "inline-block"
          }
        },
        [
          _c("v-avatar", {
            staticClass: "align-self-center mr-2",
            attrs: {
              color: _vm.config.color,
              elevation: 0,
              size: "16"
            }
          }),
          _c(
            "span",
            {
              staticClass: "text-h5 font-weight-black flex-grow-1 text-truncate"
            },
            [_vm._v(_vm._s(_vm.config.title))]
          )
        ],
        1
      ),
      _c("v-card-text", [_vm._v(_vm._s(_vm.config.subtitle))]),
      _c(
        "div",
        {
          staticClass: "text-subtitle-1 font-weight-medium mx-4 mb-4",
          staticStyle: {
            color: "#4283FF"
          }
        },
        [_vm._v("Features im Paket")]
      ),
      _vm._l(_vm.config.features, function(feature, idx) {
        return _c(
          "div",
          {
            key: idx,
            staticClass: "d-flex flex-row mx-4 mb-2",
            staticStyle: {
              display: "inline-block"
            }
          },
          [
            _c(
              "span",
              {
                staticClass: "feature"
              },
              [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "open-on-hover": "",
                      bottom: "",
                      "offset-y": "",
                      right: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function fn(_ref) {
                            var on = _ref.on,
                              attrs = _ref.attrs
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "align-self-center",
                                      attrs: {
                                        color: feature.color,
                                        size: "16"
                                      }
                                    },
                                    [_vm._v(_vm._s(feature.icon))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-body-2 flex-grow-1 text-truncate ml-2"
                                    },
                                    [_vm._v(_vm._s(feature.name))]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    feature.subtitle
                      ? _c(
                          "v-card",
                          {
                            attrs: {
                              "max-width": "300px",
                              color: _vm.primaryColor
                            }
                          },
                          [
                            _c("v-card-text", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "white"
                                  }
                                },
                                [_vm._v(_vm._s(feature.subtitle))]
                              ),
                              feature.link
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mt-2"
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: {
                                            color: "white"
                                          },
                                          attrs: {
                                            href: feature.link,
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Weitere Informationen")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      }),
      _c(
        "div",
        {
          staticClass: "text-body-2 mx-4 mt-4"
        },
        [_vm._v(_vm._s(_vm.config.amountUsers) + " Nutzerlizenzen")]
      ),
      _c(
        "div",
        {
          staticClass: "text-body-2 mx-4"
        },
        [_vm._v("Unbegrenzt Schadensmeldungen")]
      ),
      _c(
        "div",
        {
          staticClass: "mt-10 mx-4",
          staticStyle: {
            "text-align": "right"
          }
        },
        [
          _c("span", [_vm._v("ab ")]),
          _c(
            "span",
            {
              staticClass: "text-h5 font-weight-black"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.priceCalculateted.toLocaleString("de-DE", {
                    style: "currency",
                    currency: _vm.currency
                  })
                ) + "/ Monat"
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "text-caption mb-8 mx-4",
          staticStyle: {
            "text-align": "right"
          }
        },
        [
          _vm.payment === "year"
            ? _c("span", [
                _vm._v(" abgerechnet mit "),
                _c("s", [
                  _vm._v(
                    _vm._s(
                      (_vm.price * 12).toLocaleString("de-DE", {
                        style: "currency",
                        currency: _vm.currency
                      })
                    ) + " "
                  )
                ]),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-black"
                  },
                  [
                    _vm._v(
                      _vm._s(
                        (_vm.priceCalculateted * 12).toLocaleString("de-DE", {
                          style: "currency",
                          currency: _vm.currency
                        })
                      ) + "/Jahr"
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm.payment === "month"
            ? _c("span", [_vm._v("monatliche Abrechnung")])
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "container"
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-item",
              style: _vm.monthPaymentStyle,
              on: {
                click: function click($event) {
                  _vm.payment = "month"
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-caption font-weight-bold"
                },
                [_vm._v("Monatlich zahlen")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-caption"
                },
                [_vm._v("6 Monate binden")]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "flex-item py-2",
              style: _vm.yearPaymentStyle,
              on: {
                click: function click($event) {
                  _vm.payment = "year"
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-caption font-weight-bold"
                },
                [_vm._v("Jährlich zahlen"), _c("br")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-caption font-weight-bold",
                  staticStyle: {
                    color: "#4283FF"
                  }
                },
                [_vm._v("10 % sparen")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-caption"
                },
                [_vm._v("1 Jahr binden")]
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "text-body-2 font-weight-bold mx-4"
        },
        [
          _vm._v(
            "Beinhaltet " +
              _vm._s(_vm.config.amountVehicles) +
              " Fahrzeuglizenzen"
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-row mx-4 mt-4",
          staticStyle: {
            display: "inline-block"
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "text-caption"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.pricePerVehicle.toLocaleString("de-DE", {
                    style: "currency",
                    currency: _vm.currency
                  })
                ) + " für jedes weitere Fahrzeug"
              )
            ]
          ),
          _c(
            "span",
            {
              staticClass: "flex-grow-1"
            },
            [
              _c("v-text-field", {
                staticStyle: {
                  "--v-text-field-fill-color": "#F5F5F5"
                },
                attrs: {
                  type: "number",
                  outlined: "",
                  dense: ""
                },
                on: {
                  input: _vm.checkAmountVehciles
                },
                model: {
                  value: _vm.amountVehicles,
                  callback: function callback($$v) {
                    _vm.amountVehicles = $$v
                  },
                  expression: "amountVehicles"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                elevation: "0",
                color: _vm.primaryColor,
                dark: "",
                block: ""
              },
              on: {
                click: _vm.order
              }
            },
            [_vm._v("Vertrieb kontaktieren")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "text-caption",
          staticStyle: {
            "text-align": "center"
          }
        },
        [_vm._v("(zzgl. 19% MwSt.)")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }