
























import TicketTableCard from "@/components/project/TicketTableCard.vue";
import Debug from "@/components/utility/Debug.vue";
import { IBreadcrumb } from "@/lib/interfaces/utility/breadcrumb-interface";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { TicketModule } from "@/store/modules/ticket.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import ProjectGoToMixin from "./mixins/ProjectGoToMixin.vue";
import ProjectInitializeListsMixin from "./mixins/ProjectInitializeListsMixin.vue";
import ProjectTableMixin from "./mixins/ProjectTableMixin.vue";

@Component({
  components: {
    TicketTableCard,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketTableView extends mixins(
  ProjectTableMixin,
  ProjectGoToMixin,
  ProjectInitializeListsMixin,
  PartnerFallbackMixin
) {
  @Ref("TicketTableCard")
  TicketTableCard!: TicketTableCard;

  readonly AMOUNT_TICKETS = "amountTicket";

  /**
   * refs that will be set in new tickets and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(this.breadCrumbs.TicketTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${TicketModule.totalItems} ${this.$t("project.ticket.tickets")}`,
        key: this.AMOUNT_TICKETS,
        disabled: true
      }
    ];
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.ticketNumber = this.$route.query.ticketNumber as string;
  }

  mounted() {
    this.trySetByRouteOrDefault();
    this.initializeProjectLists(this.partnerId);
    const number = Number(this.ticketNumber);
    if (!isNaN(number) && number >= 0) {
      this.TicketTableCard.initTicketDetail(number);
    }
  }
}
