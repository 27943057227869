var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        [
          _c("m-header", {
            attrs: {
              title: _vm.title,
              subtitle: _vm.subtitle,
              chips: _vm.chips,
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions,
              alerts: _vm.alerts
            },
            on: {
              actionClicked: _vm.processAction,
              alertClicked: _vm.processAlerts,
              "update:alerts": function updateAlerts($event) {
                _vm.alerts = $event
              }
            }
          }),
          _c(
            "m-detail-view-grid",
            {
              attrs: {
                bottomSheet: false
              },
              scopedSlots: _vm._u([
                {
                  key: "details",
                  fn: function fn() {
                    return [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: ""
                          }
                        },
                        [
                          _c("v-card-title", [_vm._v("Fahrzeug")]),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-2 mb-1 mx-4",
                              attrs: {
                                outlined: ""
                              }
                            },
                            [
                              _c(
                                "v-list-item",
                                [
                                  _c(
                                    "v-list-item-action",
                                    [_c("v-icon", [_vm._v(" mdi-car ")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    {
                                      staticClass: "pa-0"
                                    },
                                    [_vm._v(" Zug 1234 ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          attrs: {
                            flat: ""
                          }
                        },
                        [
                          _c("v-card-title", [_vm._v("Parteien")]),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-2 mb-1 mx-4",
                              attrs: {
                                outlined: ""
                              }
                            },
                            [
                              _c("v-card-text", [
                                _c("div", [
                                  _c("b", [_vm._v("LNVG (Eigentümer)")])
                                ]),
                                _c("div", [_vm._v(" info@lnvg.de ")]),
                                _c("div", [_vm._v(" +49124353 ")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-2 mb-1 mx-4",
                              attrs: {
                                outlined: ""
                              }
                            },
                            [
                              _c("v-card-text", [
                                _c("div", [
                                  _c("b", [_vm._v("DB (abgebender Halter)")])
                                ]),
                                _c("div", [_vm._v(" info@lnvg.de ")]),
                                _c("div", [_vm._v(" +49124353 ")])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-2 mb-1 mx-4",
                              attrs: {
                                outlined: ""
                              }
                            },
                            [
                              _c("v-card-text", [
                                _c("div", [
                                  _c("b", [
                                    _vm._v("NW Bahn (übernehmender Halter)")
                                  ])
                                ]),
                                _c("div", [_vm._v(" info@lnvg.de ")]),
                                _c("div", [_vm._v(" +49124353 ")])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("m-detail-table", {
                        attrs: {
                          title: "Eigenschaften",
                          i18nPath: "designGuide.MDetailTable",
                          item: _vm.testItem,
                          showMoreAction: _vm.tableShowMoreAction,
                          config: _vm.detailTableConfig
                        },
                        on: {
                          actionClicked: _vm.processAction
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function fn() {
                    return [
                      _c("m-action-list", {
                        attrs: {
                          divider: true,
                          actions: _vm.actions
                        },
                        on: {
                          actionClicked: _vm.processAction
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("m-navigation-list", {
                attrs: {
                  title: "Navigation",
                  actions: _vm.navigations
                }
              }),
              _c("m-quick-link-list", {
                attrs: {
                  title: "Schnellzugriffe",
                  actions: _vm.quicklinks
                },
                on: {
                  actionClicked: _vm.processAction
                }
              }),
              _c("create-handover-finding-dialog", {
                attrs: {
                  isDialogActive: _vm.createDialog
                },
                on: {
                  close: function close($event) {
                    _vm.createDialog = false
                  }
                }
              }),
              _c("tour-handover-finding-dialog", {
                attrs: {
                  isDialogActive: _vm.tourDialog
                },
                on: {
                  close: function close($event) {
                    _vm.tourDialog = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }