var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c(
            "v-container",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type:
                    "heading, actions, heading, table-row, list-item-two-line@4"
                }
              })
            ],
            1
          )
        : _vm.partner
        ? _c(
            "v-container",
            [
              _c("m-header", {
                attrs: {
                  title: _vm.title
                },
                on: {
                  actionClicked: function actionClicked($event) {
                    return $event.exec()
                  }
                }
              }),
              _c("paginated-table", {
                attrs: {
                  store: _vm.store,
                  headers: _vm.headers,
                  baseQuery: {
                    partnerId: _vm.partnerId
                  },
                  predefinedFilter: _vm.predefinedFilter,
                  selectedItems: _vm.selectedItems,
                  showSelect: true
                },
                on: {
                  "update:selectedItems": function updateSelectedItems($event) {
                    _vm.selectedItems = $event
                  },
                  "update:selected-items": function updateSelectedItems(
                    $event
                  ) {
                    _vm.selectedItems = $event
                  },
                  "click:row": _vm.setSelectedItem
                },
                scopedSlots: _vm._u([
                  {
                    key: "selectActions",
                    fn: function fn() {
                      return [
                        _vm.selectedItems.length
                          ? _c(
                              "span",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "caption"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.selectedItems.length) +
                                        " " +
                                        _vm._s(_vm.$t("selected"))
                                    )
                                  ]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      small: ""
                                    }
                                  },
                                  [_vm._v("mdi-arrow-right")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      outline: "",
                                      text: "",
                                      loading: _vm.isLoadingUpdate
                                    },
                                    on: {
                                      click: _vm.completedSelected
                                    }
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-check-all")]),
                                    _vm._v(" " + _vm._s(_vm.$t("acknowledge")))
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.timestamp.created",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("simpleDoubleDigitDate")(
                                item.timestamp.created
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.progressStatus",
                    fn: function fn(_ref2) {
                      var item = _ref2.item
                      return [
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              small: "",
                              color: _vm.color(item.progressStatus),
                              elevation: 0,
                              dark: ""
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "partner.PartnerReportDetailChangeProgress.".concat(
                                      item.progressStatus
                                    )
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.customerName",
                    fn: function fn(_ref3) {
                      var item = _ref3.item
                      return [
                        item.customerName
                          ? _c("span", [_vm._v(_vm._s(item.customerName))])
                          : _vm._e(),
                        item.companyName
                          ? _c("span", [_vm._v(_vm._s(item.companyName))])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.assignees",
                    fn: function fn(_ref4) {
                      var item = _ref4.item
                      return [
                        _c("assignee-preview", {
                          attrs: {
                            value: item.assignees,
                            amount: 3
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "item.damages",
                    fn: function fn(_ref5) {
                      var item = _ref5.item
                      return [
                        item.damage
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.damage
                                      .map(function(damage) {
                                        return _vm.$t(
                                          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.damages.".concat(
                                            damage
                                          )
                                        )
                                      })
                                      .join(", ")
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        item.position
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(item.position.blueprint.name) + " "
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showSideCard
        ? _c("partner-report-detail-side-card", {
            attrs: {
              loading: _vm.isLoadingReport,
              report: _vm.selectedItem
            },
            on: {
              close: _vm.closeSideCard
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }