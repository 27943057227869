














































































































import WebhookHeaderConfiguration from "@/components/webhook/WebhookHeaderConfiguration.vue";
import WebhookConfigCreateDialog from "@/components/webhook/WebhookConfigCreateDialog.vue";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import Debug from "@/components/utility/Debug.vue";
import { WebhookTypeEnum } from "@/lib/enum/webhook-type.enum";
import { WebhookGoToHelper } from "@/lib/utility/webhook.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IWebhookConfig } from "@/models/webhook-config.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { WebhookConfigModule } from "@/store/modules/webhook-config.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import debounce from "debounce";
import CustomFieldBoolean from "@/components/report/CustomFieldBoolean.vue";
import WebhookTypeSelection from "@/components/webhook/WebhookTypeSelection.vue";
import OperationTable from "@/components/operations/OperationTable.vue";
import { OperationModule } from "@/store/modules/operation.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    CustomFieldListForm,
    MActionList,
    ConfirmActionDialog,
    WebhookConfigCreateDialog,
    WebhookHeaderConfiguration,
    CustomFieldBoolean,
    WebhookTypeSelection,
    OperationTable,
    Debug
  }
})
export default class WebhookConfigCard extends mixins(PartnerFallbackMixin) {
  readonly WebhookTypeEnum = WebhookTypeEnum;

  @Prop()
  value!: IWebhookConfig;

  @Prop()
  hideBreadCrumbs!: boolean;

  @Prop()
  showDetailButton!: boolean;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  isLoadingTitle = false;

  isHeadersLoading = false;

  isSettingLoading = false;

  isEditTitle = false;

  editTitleCopy = this.value.title;

  isLoadingDescription = false;

  isEditDescription = false;

  descriptionCopy = this.value.description ?? "";

  isUrlLoading = false;

  isActiveLoading = false;

  isTypeLoading = false;

  debounceUpdateUrl = debounce(this.updateUrl, 500);

  get settingRetries() {
    return String(this.value.setting.retries);
  }

  set settingRetries(value: string) {
    this.value.setting.retries = Number(value);
  }

  get partnerId() {
    return this.value.partnerId;
  }

  get webhookTypes() {
    return Object.values(WebhookTypeEnum);
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get chips(): IAction[] {
    const actions = [];

    let name = "";
    if (this.value.resource) name += $t(`BackendResourceEnum.${this.value.resource}`);
    if (this.value.action) name += " - " + $t(`ActionEnum.${this.value.action}`);
    if (this.value.type) name += " - " + $t(`WebhookTypeEnum.${this.value.type}`);

    if (!this.isEditTitle) {
      actions.push({
        text: name,
        key: "detail"
      });
    }
    return actions;
  }

  get subtitle() {
    return $t("createdOn", { date: simpleDoubleDigitDate(this.value.timestamp.created) });
  }

  get breadCrumbs() {
    if (this.hideBreadCrumbs) {
      return undefined;
    }
    const goTo = WebhookGoToHelper.locations;

    return [
      {
        text: $t("webhook.webhookConfigTable"),
        exact: true,
        to: goTo.webhookConfigTable({ partnerId: this.value.partnerId })
      },
      {
        text: this.value.title,
        exact: true,
        to: goTo.webhookConfigDetail({ partnerId: this.value.partnerId, webhookConfigId: this.value.id })
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("webhook.actions.openInNewTab"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new WebhookGoToHelper(this.$router).goToWebhookConfigDetail({
            partnerId: this.value.partnerId,
            webhookConfigId: this.value.id,
            newTab: true
          })
      });
    }

    actions.push({
      text: $t("webhook.actions.editTitle"),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startEditTitle
    });

    return actions;
  }

  get bottomActions() {
    const actions: IAction[] = [];

    if (this.showDetailButton) {
      actions.push({
        text: $t("webhook.actions.openInNewTab"),
        key: "detail",
        icon: "mdi-open-in-new",
        color: "",
        exec: () =>
          new WebhookGoToHelper(this.$router).goToWebhookConfigDetail({
            partnerId: this.value.partnerId,
            webhookConfigId: this.value.id,
            newTab: true
          })
      });
    }

    actions.push({
      text: $t("delete"),
      key: "title",
      icon: "mdi-trash-can",
      color: "red",
      exec: this.onDeleteItem
    });

    return actions;
  }

  get groups() {
    return WebhookConfigModule.entities;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get source() {
    return {
      refId: this.value.id,
      refType: BackendResourceEnum.WEBHOOK_CONFIG
    };
  }

  get refsCategories(): BackendResourceEnum[] {
    return [BackendResourceEnum.VEHICLE, BackendResourceEnum.REPORT];
  }

  beforeMount() {
    OperationModule.setHiddenFilters([
      new PageFilterElement({
        value: this.value.id,
        operation: "$eq",
        key: "refs.refId"
      })
    ]);
  }

  beforeDestroy() {
    OperationModule.setHiddenFilters([]);
  }

  onDeleteItem() {
    this.isDeleteDialogActive = true;
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    let name = "";
    if (user) {
      name = `${user.firstName} ${user.lastName}`;
    }

    return name;
  }

  async updateSetting() {
    this.isSettingLoading = true;
    this.value
      .updatePartial({
        setting: this.value.setting
      })
      .catch(handleError);
    this.isSettingLoading = false;
  }

  async updateHeaders() {
    this.isHeadersLoading = true;
    this.value
      .updatePartial({
        headers: this.value.headers.filter(header => header.name && header.value)
      })
      .catch(handleError);
    this.isHeadersLoading = false;
  }

  async updateUrl() {
    this.isUrlLoading = true;
    await this.value
      .updatePartial({
        url: this.value.url
      })
      .catch(handleError);
    this.isUrlLoading = false;
  }

  async updateIsActive() {
    this.isActiveLoading = true;
    await this.value
      .updatePartial({
        isActive: this.value.isActive
      })
      .catch(handleError);
    this.isActiveLoading = false;
  }

  async updateType() {
    this.isTypeLoading = true;
    await this.value
      .updatePartial({
        resource: this.value.resource,
        action: this.value.action || null,
        type: this.value.type || null
      })
      .catch(handleError);
    this.isTypeLoading = false;
  }

  async onDelete() {
    try {
      this.isDeleteLoading = true;

      await this.value.delete();

      this.$toast.success("👍");

      this.isDeleteDialogActive = false;

      this.$emit("deleted");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteLoading = false;
    }
  }

  startEditTitle() {
    this.editTitleCopy = this.value.title;
    this.isEditTitle = true;
  }

  cancelEditTitle() {
    this.isEditTitle = false;
    this.editTitleCopy = this.value.title;
  }

  async saveEditTitle() {
    this.isLoadingTitle = true;
    this.value.title = this.editTitleCopy;
    await this.value
      .updatePartial({
        title: this.editTitleCopy
      })
      .catch(handleError);
    this.isLoadingTitle = false;
    this.isEditTitle = false;
  }

  startEditDescription() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = true;
  }

  abortEditDesciption() {
    this.descriptionCopy = this.value.description ?? "";
    this.isEditDescription = false;
  }

  async saveEditDescription() {
    this.isLoadingDescription = true;
    await this.value
      .updatePartial({
        description: this.descriptionCopy
      })
      .catch(handleError);
    this.isLoadingDescription = false;
    this.isEditDescription = false;
  }
}
