














































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ICompanyGroup } from "@/models/company-group.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../../utility/ConfirmActionDialog.vue";
import { IControlElements, ITableWrapperHeader } from "../../utility/TableWrapper.vue";
import CompanyGroupCreateDialog from "@/components/company/group/CompanyGroupCreateDialog.vue";
import CompanyGroupSideCard from "./CompanyGroupSideCard.vue";
import { CompanyGroupModule } from "@/store/modules/company-group.store";
import { CompanyGoToHelper } from "@/lib/utility/company.go-to-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import RefsCompanyGroup from "@/components/utility/RefsCompanyGroup.vue";
import { handleError } from "@/lib/utility/handleError";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CompanyGroupCreateDialog,
    CompanyGroupSideCard,
    RefsCompanyGroup
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CompanyGroupTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = CompanyGroupModule;

  @Prop()
  partnerId!: string;

  selectedItem: ICompanyGroup | null = null;

  selectedItemForDelete: ICompanyGroup | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    if (!CompanyGroupModule.entities.length) return [];

    return [
      {
        text: $t("project.project.created"),
        value: "timestamp.created",
        width: "110"
      },
      {
        text: $t("objects.companyGroup.title"),
        value: "title",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.goToCompanyGroupCustomView
      },
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: this.setSelectedItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  goToCompanyGroupCustomView(item: ICompanyGroup) {
    new CompanyGoToHelper(this.$router).goToCompanyGroupCustomView({
      partnerId: this.partnerId,
      companyGroupId: item.id,
      viewId: "0"
    });
  }

  setSelectedItem(item: ICompanyGroup | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  onDeleteItem(item: ICompanyGroup) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }
}
