var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _vm.allPartnerSettingsLoaded
              ? _c("partner-manage-list", {
                  attrs: {
                    partnerId: _vm.partnerId
                  }
                })
              : _c("v-skeleton-loader", {
                  staticClass: "mx-auto",
                  attrs: {
                    type:
                      "divider, card-heading, divider, list-item-two-line@8, divider"
                  }
                })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [_c("partner-manage-detail-card")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }